<template>
	<div>
		<div class="card">
            <div class="card-body">
                <span class="badge badge-primary">Titular del Beneficio</span>
                <h5 class="card-title">Beneficio {{ datosbeneficio.beneficio_id | formatearBeneficio }}</h5>
                <p>Lugar de cobro: {{ datosbeneficio.boca_pago.nombre_boca_pago }} <br>
	    			Expediente: {{ datosbeneficio.expediente }}</p>
            </div>
        </div>
    </div>
</template>

<script>

	export default {
		props: [
			'datosbeneficio'
		]
	}

</script>
