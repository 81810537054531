<template>
    <span class="badge">{{ titulo }}</span>
</template>

<script>
export default {
    name: "BadgeNotificacion",
    props: [
        'type'
    ],
    computed: {
        titulo() {
            const tipoNotificacion = this.type.split('\\').pop()
            if (tipoNotificacion === 'EnvioComple') return 'Complementaria'
            if (tipoNotificacion === 'NotificarUsuario') return 'Aviso'

            return 'Notificacion'
        }
    }
}
</script>

<style scoped>
    span {
        font-size: 0.7rem;
        padding: 0.5rem;
        font-weight: 400;
        text-transform: uppercase;
        background-color: #309de5;
        color: #e8e8e8;
    }
</style>
