const obtenerNotificaciones = () => {
    return axios.get('usuarios/notificaciones')
}

const obtenerNotificacionesSinleer = () => {
    return axios.get('usuarios/notificaciones/nuevas')
}

const leerNotificaciones = () => {
    return axios.post('usuarios/notificaciones/leer')
}

export {
    obtenerNotificaciones,
    obtenerNotificacionesSinleer,
    leerNotificaciones
}
