import {
    getBeneficiario,
    modificarEmail,
    verificarPassword,
    reenviarVerificacion,
    desbloquearUsuario
} from "../../../api/gestionbeneficiarios";

const state = {
    persona: {},
    usuario: {},
    beneficios: [],
    apoderados: [],
    recibos: [],
    certificadosGanancias: [],
    buscando: false,
    error: null,
    existeBeneficiario: false,
    accion: '',
    errorAccion: null,
    verificacionPassword: false
};

const getters = {

};

const mutations = {
    setBeneficiario(state, data) {
        const {user, beneficios, apoderados, recibos, certificadosGanancias, ...persona} = data
        state.persona = persona
        state.usuario = user
        state.beneficios = beneficios
        state.apoderados = apoderados
        state.recibos = recibos
        state.certificadosGanancias = certificadosGanancias
        state.buscando = false
        state.existeBeneficiario = true
        state.error = null
    },
    resetBeneficiario(state) {
        state.persona = {}
        state.usuario = {}
        state.beneficios = []
        state.apoderados = []
        state.recibos = []
        state.certificadosGanancias = []
        state.existeBeneficiario = false
        state.error = null
        state.accion = ''
        state.errorAccion = null
    },
    setBuscando(state) {
        state.buscando = true
    },
    setError(state, error) {
        state.error = error
        state.buscando = false
    },
    setEmail(state, email) {
        state.usuario.email = email
        state.usuario.verificado = 'N'
    },
    setAccion(state, accion) {
        state.accion = accion
    },
    setErrorAccion(state, error) {
        state.errorAccion = error
    },
    resetAccion(state) {
        state.accion = ''
    },
    resetErrorAccion(state) {
        state.errorAccion = null
    },
    setVerificacionPassword() {
        state.verificacionPassword = true
    },
    setUsuarioActivo(state) {
        state.usuario.activo = 'S'
    }
};

const actions = {
    resetBeneficiario({commit}) {
        commit('resetBeneficiario')
    },
    resetErrorAccion({commit}) {
        commit('resetErrorAccion')
    },
    async buscarBeneficiario({commit}, numero_cuil) {
        commit('setBuscando', true)
        commit('resetBeneficiario')

        try {
            getBeneficiario(numero_cuil)
            .then( response=> {
                for(let i = 0; i< response.data.persona.beneficios.length; i++){
                           
                    response.data.persona.beneficios[i].jerarquia = response.data.jerarquia[i]
            }
                commit('setBeneficiario', response.data.persona)
            })
            
        }
        catch (e) {
            commit('setError', e.response.data.data)
        }
    },
    async verificarPassword({state, commit}, password) {
        commit('resetErrorAccion')
        commit('setAccion', 'Verificando password...')

        try {
            await verificarPassword(password)
            commit('setVerificacionPassword')
            commit('resetAccion')
        }
        catch (e) {
            commit('setErrorAccion', e.response.data.error)
            commit('resetAccion')
        }
    },
    async modificarEmail({state, commit}, email) {
        commit('resetErrorAccion')
        commit('setAccion', 'Modificando email...')

        try {
            const datos = {
                persona_id: state.usuario.persona_id,
                email_nuevo: email
            }
            await modificarEmail(datos)
            commit('setEmail', email)
            commit('resetAccion')
        }
        catch (e) {
            if(e.response.status === 422) {
                commit('setErrorAccion', e.response.data.errors.email_nuevo[0])
            } else {
                commit('setErrorAccion', 'Hubo un error al modificar el email')
            }
            commit('resetAccion')
        }
    },
    async reenviarVerificacion({state, commit}) {
        commit('resetErrorAccion')
        commit('setAccion', 'Enviando verificacion...')

        try {
            await reenviarVerificacion(state.usuario.email)
            commit('resetAccion')
        }
        catch (e) {
            if(e.response.status === 409) {
                commit('setErrorAccion', e.response.data.error)
            } else {
                commit('setErrorAccion', 'Hubo un error al enviar la verificacion')
            }
            commit('resetAccion')
        }
    },
    async desbloquearUsuario({state, commit}, email) {
        commit('resetErrorAccion')
        commit('setAccion', 'Desbloqueando usuario...')

        try {
            const datos = {
                persona_id: state.usuario.persona_id,
                email: email
            }
            await desbloquearUsuario(datos)
            commit('setEmail', email)
            commit('setUsuarioActivo')
            commit('resetAccion')
        }
        catch (e) {
            if(e.response.status === 422) {
                commit('setErrorAccion', e.response.data.errors.email[0])
            } else {
                commit('setErrorAccion', 'Hubo un error al desbloquear el usuario')
            }
            commit('resetAccion')
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
