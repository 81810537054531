<template>
	<div>
		
		<div class="alertas" v-if="transferencias == null">
			<div v-if="errores">
				<div class="alert alert-danger">
					{{ errores }}
				</div>
			</div>
			<div v-else>
				<div v-if="buscando">
					<div class="alert alert-info">Buscando novedades...</div>
				</div>
				<div class="alert alert-warning" v-else>
					No hay novedades
				</div>
			</div>			
		</div>		
		<div v-else>
			<h5 class="busqueda-transferencias__texto-paginacion text-muted">Tiene {{total}} <span v-if="total==1">novedad.</span> <span v-else>novedades.</span></h5>
				<transition-group name="fade" tag="div" :duration="200"> 
					<div class="col-xs-12" v-for="transferencia in transferencias" :key="transferencia.id"> 

						<div class="card transferencia">
							<transferencia :transferencia='transferencia' :sectores='sectores' :pagina='pagina'></transferencia>
						</div>
					</div>
				</transition-group>				
			</div>
		</div>
	</div>
</template>

<script>

	import Transferencia from '../transferencia/Transferencia.vue'
	
	export default {	
		data () {
			return {
				subsector: this.$store.state.user.subsectores[0].id,
				sector: this.$store.state.user.subsectores[0].sector.id,
				pagina: 'novedades',
				buscando: null,
				transferencias: null,
				status_error: null,
				errores: null,
				mensaje: null,
				query: this.$route.query,
				busqueda: null,
				resultado: false,
				link_primero: '',
				link_ultimo: '',
				link_anterior: '',
				link_siguiente: '',
				sectores: '',
				por_pagina: 20

			}
		},
		components: {
			'transferencia': Transferencia
		},
		created: function() {
			this.buscando = true
			axios.get('transferencias-novedades/' + this.subsector + '/' + this.sector)
				.then((response) => {
					this.buscando = false
					this.errores = null
					this.mensaje = null
					if(response.data.data.length >= 1){
						this.transferencias = response.data.data

						this.link_primero = response.data.first_page_url
						this.link_anterior = response.data.prev_page_url
						this.link_ultimo = response.data.last_page_url
						this.link_siguiente = response.data.next_page_url
						this.resultado = false
						this.current_page = response.data.current_page
						this.total = response.data.total
						this.last_page = response.data.last_page
						
						axios.get('subsectores-caja/' + this.subsector + '/' + this.sector)
			            .then((response) => {
			                this.sectores = response.data.data
							
			            })
			            .catch((error) => {
			            	this.status_error = error.response.status
			            	this.errores = error.response.data.error
			            })
					}
				})
				.catch((error) => {
					this.buscando = false
					this.mensaje = false
					this.status_error = error.response.status
					this.errores = error.response.data.error
					return
				})

			
		}
	}

</script>