<template>
    <div class="container contenedor-novedades">
        <div class="row">
            <div class="col-12 card card__novedades">
                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="card-body">
                            <h5 class="card-title">
                                Todavía no verificó su cuenta
                            </h5>
                            <div class="alert alert-info">
                                <p class="card-text">
                                    Para verificar su cuenta, haga click en el
                                    botón "Verificar mi nuevo correo
                                    electrónico" en el email que fue enviado a
                                    <strong v-if="nuevo_email">{{
                                        nuevo_email
                                    }}</strong>
                                    <strong v-else
                                        ><span
                                            v-if="!$store.state.user.email"
                                            >{{
                                                $store.state.user
                                                    .email_alternativo
                                            }}</span
                                        >
                                        <span v-else>{{
                                            $store.state.user.email
                                        }}</span>
                                    </strong>
                                    Es importante que realice esta verificación
                                    antes de poder acceder a su información.<br />
                                    En el caso que no haya recibido el email,
                                    puede reenviarlo haciendo click en el
                                    siguiente botón:
                                </p>
                                <button
                                    class="btn btn-success"
                                    @click="reenviarVerificacion"
                                    v-if="verificacionReenviada"
                                >
                                    <span v-if="nuevo_email"
                                        >Email de verificación reenviado a
                                        {{ nuevo_email }}</span
                                    >
                                    <span v-else
                                        >Email de verificación reenviado a
                                        <span v-if="!$store.state.user.email">{{
                                            $store.state.user.email_alternativo
                                        }}</span>
                                        <span v-else>{{
                                            $store.state.user.email
                                        }}</span>
                                    </span>
                                </button>

                                <button
                                    class="btn btn-primary"
                                    @click="reenviarVerificacion"
                                    v-else
                                >
                                    <span v-if="reenviandoVerificacion"
                                        >Reenviando email de
                                        verificación...</span
                                    >
                                    <span v-else
                                        >Reenviar email de verificación</span
                                    >
                                </button>
                            </div>

                            <div class="alert alert-light">
                                <p class="card-text">
                                    En el caso que la dirección de correo
                                    electrónico no fuese correcta, puede
                                    modificarla y reenviar el email de
                                    verificación a la nueva casilla a través del
                                    siguiente botón:
                                </p>

                                <button
                                    class="btn btn-success"
                                    data-toggle="modal"
                                    data-target="#verificarPassword"
                                    v-if="emailModificado"
                                >
                                    <span
                                        >Dirección de email modificado a
                                        {{ nuevo_email }}</span
                                    >
                                </button>

                                <button
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#verificarPassword"
                                    v-else
                                >
                                    <span v-if="modificandoEmail"
                                        >Se está modificando su dirección de
                                        email...</span
                                    >
                                    <span v-else
                                        >Modificar dirección de email</span
                                    >
                                </button>

                                <p class="card-text" v-if="emailModificado">
                                    Se envió un nuevo correo electrónico a
                                    {{ nuevo_email }} para que pueda verificar
                                    su cuenta.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal verificación de contraseña -->
        <div
            class="modal fade"
            id="verificarPassword"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ventanaVerificarPassword"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Verificar Contraseña
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-if="errores"
                        >
                            {{ errores }}
                        </div>
                        <p>
                            Por motivos de seguridad, antes de realizar alguna
                            modificación en su perfil, le pedimos que ingrese su
                            contraseña.
                        </p>

                        <form @submit.prevent="">
                            <label for="email_nuevo">Contraseña</label>
                            <input
                                class="form-control"
                                type="password"
                                name="verificar_password"
                                v-model="verificar_password"
                            />
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="verificarPassword"
                            @keyup.enter="verificarPassword"
                        >
                            Continuar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal modificación de email -->
        <div
            class="modal fade"
            id="modificarEmail"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ventanaModificarEmail"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Modificar correo electrónico
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-if="errores"
                        >
                            {{ errores }}
                        </div>
                        <p>
                            Al modificar su correo electrónico, le enviaremos un
                            email a su nueva casilla para que lo verifique. Si
                            no verifica el nuevo correo electrónico, no podrá
                            acceder a su información.
                        </p>

                        <form @submit.prevent="">
                            <label for="email_nuevo"
                                >Nuevo correo electrónico</label
                            >
                            <input
                                class="form-control"
                                type="email"
                                name="email_nuevo"
                                v-model="email_nuevo"
                            />
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="modificarEmail('modificar')"
                            @keyup.enter="modificarEmail('modificar')"
                        >
                            <span v-if="!modificandoEmail"
                                >Modificar dirección de email</span
                            >
                            <span v-else
                                >Modificando dirección de email
                                <i
                                    class="fas fa-circle-notch faa-spin animated faa-fast"
                                ></i
                            ></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            reenviandoVerificacion: false,
            verificacionReenviada: false,
            modificandoEmail: false,
            emailModificado: false,
            confirmacion: null,
            errores: null,
            email_nuevo: null,
            nuevo_email: null,
            password_original: null,
            password_nueva: null,
            password_nueva_repetir: null,
            verificar_password: null,
            acciones: ""
        };
    },
    methods: {
        reenviarVerificacion: function() {
            this.reenviandoVerificacion = true;
            this.verificacionReenviada = false;
            axios
                .post(
                    "usuarios/reenviar-verificacion",
                    {})
                .then(response => {
                    this.reenviandoVerificacion = false;
                    this.verificacionReenviada = true;
                })
                .catch(error => {
                    this.reenviandoVerificacion = false;
                    this.verificacionReenviada = false;
                });
        },
        modificarEmail: function(accion) {
            this.emailModificado = false;
            this.modificandoEmail = true;
            axios
                .post(
                    "modificar-email",
                    {
                        email_nuevo: this.email_nuevo
                    }
                )
                .then(response => {
                    this.acciones = "";
                    this.nuevo_email = this.email_nuevo;
                    this.confirmacion = response.data.data;
                    this.$store.dispatch("modificarVerificado");
                    $("#modificarEmail").modal("hide");
                    this.emailModificado = true;
                    this.modificandoEmail = false;
                })
                .catch(error => {
                    this.acciones = "";

                    this.modificandoEmail = false;
                    this.emailModificado = false;
                    if (error.response.data.code == 422) {
                        this.errores = "Este correo electrónico ya está en uso";
                    } else {
                        this.errores = error.response.data.error;
                    }
                });
        },
        verificarPassword: function() {
            axios
                .post(
                    "verificar-password",
                    {
                        verificar_password: this.verificar_password
                    }
                    
                )
                .then(response => {
                    this.errores = null;
                    $("#modificarEmail").modal("show");
                    $("#verificarPassword").modal("hide");
                    this.verificar_password = null;
                })
                .catch(error => {
                    this.errores = error.response.data.error;
                });
        }
    }
};
</script>
