<template>
    <div class="container">
        <div class="row">
            <div
                class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
            >
                <div class="alert alert-info" role="alert">
                    Salió correctamente de MI CAJA<br />
                    <a class="alert-link" :href="mix_url"
                        >¿Quiere volver a ingresar?</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            mix_url: process.env.MIX_URL
        };
    }
};
</script>
