<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <form class="formulario" v-on:submit.prevent>
                    <h4 class="busqueda-transferencias__titulo mb-3">
                        Empleados eliminados
                    </h4>

                    <div class="buscador-usuario">
                        <input
                            type="text"
                            class="form-control"
                            name="usuario"
                            id="usuario"
                            placeholder="Buscar por email crjppf"
                            v-model="usuario"
                        />
                        <button
                            class="btn btn-primary"
                            @click="obtenerEmpleadoEliminado(usuario)"
                        >
                            <i class="fas fa-search px-5"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div
            class="alert alert-success alert-dismissible fade show"
            role="alert"
            v-if="mensaje"
        >
            {{ mensaje }}
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                @click="mensaje = ''"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
            v-if="error"
        >
            {{ error }}
            <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                @click="error = ''"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <button
            class="btn btn-outline-danger transferencia__accion mb-2"
            v-if="busqueda"
            @click="obtenerEliminados()"
        >
            <i class="fas fa-trash"></i> Limpiar búsqueda
        </button>

        <div v-if="buscando">
            <div class="alert alert-info" role="alert">
                Buscando usuario...
            </div>
        </div>
        <div v-else-if="noEncontrado">
            <div class="alert alert-warning" role="alert">
                {{ noEncontrado }}
            </div>
        </div>
        <div
            class="row"
            v-else
            v-for="user in usuariosEliminados"
            :key="user.persona_id"
        >
            <div class="col-12">
                <usuario
                    :usuario="user"
                    :restaurar="restaurar"
                    v-on:mensajeRestaurado="mostrarMensaje($event)"
                    v-on:mensajeError="mostrarError($event)"
                ></usuario>
            </div>
        </div>
    </div>
</template>

<script>
import usuario from "./elementos/Usuario.vue";

export default {
    components: {
        usuario: usuario
    },
    data() {
        return {
            usuario: "",
            usuarios: [],
            buscando: false,
            noEncontrado: "",
            restaurar: true,
            mensaje: "",
            error: "",
            busqueda: false
        };
    },
    created() {
        this.obtenerEliminados();
    },
    computed: {
        usuariosEliminados() {
            return this.usuarios;
        }
    },
    methods: {
        obtenerEliminados() {
            this.buscando = true;
            this.noEncontrado = "";

            axios
                .get("obtener-empleados-eliminados")
                .then(response => {
                    this.usuarios = [...response.data.data];
                    this.buscando = false;
                    this.busqueda = false;
                })
                .catch(error => {
                    this.noEncontrado = error.response.data.data;
                    this.buscando = false;
                });
        },
        obtenerEmpleadoEliminado(usuario) {
            this.buscando = true;
            this.noEncontrado = "";

            axios
                .get("obtener-empleado-eliminado/" + usuario)
                .then(response => {
                    this.usuarios = [...response.data.data];
                    this.buscando = false;
                    this.busqueda = true;
                })
                .catch(error => {
                    this.noEncontrado = error.response.data.data;
                    this.buscando = false;
                    this.busqueda = true;
                });
        },
        mostrarMensaje(msj) {
            this.obtenerEliminados();
            setTimeout(() => (this.mensaje = msj), 250);
        },
        mostrarError(msj) {
            this.error = msj;
        }
    }
};
</script>
