const state = {
	cdi_cuil: null,
	nombre: '',
	apellido: '',
	tipo_documento: '',
	numero_documento: null,
	afiliado: '',
	fecha_nacimiento: null,
	sexo: '',
	nacionalidad: ''
}

const getters = {
    getPersona: state => {
        return state
    }
}

const mutations = {
	guardarPersona(state, personaData){
        state.cdi_cuil = personaData.cdi_cuil
        state.nombre = personaData.nombre
        state.apellido = personaData.apellido
        state.tipo_documento = personaData.tipo_documento
        state.numero_documento = personaData.numero_documento
        state.afiliado = personaData.afiliado
        state.fecha_nacimiento = personaData.fecha_nacimiento
        state.sexo = personaData.sexo
        state.nacionalidad = personaData.nacionalidad
	},
	eliminarPersona(state){
	    state.cdi_cuil = null
        state.nombre = ''
        state.apellido = ''
        state.tipo_documento = ''
        state.numero_documento = null
        state.afiliado = ''
        state.fecha_nacimiento = null
        state.sexo = ''
        state.nacionalidad = ''
	}
}

const actions = {

};

export default {
	state,
	actions,
	mutations,
	getters
}
