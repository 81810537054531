<template>
    <div class="container">
        <div class="row">
            <div v-for="(tipo_busqueda, indice ) in opciones_busqueda"  
            class="col btn btn-light btn_busqueda d-flex justify-content-center align-items-center"
            :class="[tipodato==indice?'btn_busqueda_selected':'']"
            @click="setearBusqueda(indice)"
            >
                <i  :class="[indice==1? 'fas fa-address-card fa-lg' : 'fas fa-money-check-alt fa-lg']"></i>
                <span>Buscar por {{ tipo_busqueda }}</span>
            </div>
        </div>
        <br/>
        <div class="row">
            <h5>Ingrese el numero de {{opciones_busqueda[tipodato]}}:</h5>
        </div>
        <div class="row no-gutters barra_buscador">
            <input class="w-100 col-11 col-sm-10" type="text" v-model="textoBusqueda"></input>
            <button class="btn btn-primary col-1 col-sm-2" @click="buscarRecibos()"><i class="fas fa-search"></i></button>
        </div>
        <div class="card">
            <div class="card-body">  
                <custom-calendar-mi-caja @fechaInicio="setearInicio" @fechaFin="setearFin" :fecha_tope_inicio="fecha_primer_recibo"></custom-calendar-mi-caja>       
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import DateRangePickerDemo from "../../../../elementos/daterangepicker/DateRangePicker.vue"
import CustomCalendarMiCaja from "../../../../elementos/CustomCalendarioRangos/CustomCalendarMiCaja.vue";

export default {
    components: { 
        DateRangePickerBusqueda : DateRangePickerDemo,
        CustomCalendarMiCaja
    },
    name : "BuscadorRecibosPorRango",        
        
        data(){
            return{
                tipodato: 1,
                textoBusqueda:"",
                fecha_inicio:null,
                fecha_finalizacion:null,
                rango_fechas:null,
                tipo_busqueda:null,   
            }
        },   
        
        computed: {
            ...mapState({
                loading_opciones_busqueda: state => {
                    return state.administradorreciboshaberes.loading_opciones_busqueda
                },
                opciones_busqueda : state=>{
                    return state.administradorreciboshaberes.opciones_busqueda
                },
                fecha_primer_recibo: state =>{
                    return state.administradorreciboshaberes.fecha_primer_recibo

                }
            })
        },   
        methods: {
            ...mapActions('administradorreciboshaberes', [
                "traerRecibosPorRango",
                "limpiarListaDescargas"

            ]),

            setearInicio(valor){
                this.fecha_inicio =valor
                console.log(this.fecha_inicio)

            },

            setearFin(valor){
                this.fecha_finalizacion =valor
                console.log(this.fecha_finalizacion)
            },

            setearBusqueda(indice){
                this.tipodato = indice;
            },
            buscarRecibos(){
                var formDataBusqueda = new FormData()
                formDataBusqueda.append("rango_fechas", [this.fecha_inicio, this.fecha_finalizacion])
                formDataBusqueda.append("texto_busqueda", this.textoBusqueda)
                formDataBusqueda.append("tipo_busqueda", this.tipodato)
                this.traerRecibosPorRango(formDataBusqueda)   
                this.limpiarListaDescargas()   
            }   
        },        
    }
</script>

<style lang="scss" scoped>
.btn_busqueda{
    height:200px;
    vertical-align: middle;
    
}
.btn_busqueda_selected{
    background-color: #0c659c;
    color:white;
    font-weight: bold;

}
.barra_buscador{
    height:70px;
}
.spinner_opciones_busqueda{
    position:absolute

    
}
</style>