<template>
    <!-- <div class="container"> -->
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en:
                    <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Programa Transaccional
                </li>
            </ol>
        </nav>
        <div>
            <div class="row">
                <div class="col-12 alert alert-primary">
                    <p>
                        El uso de este simulador
                        <strong>no implica celebrar contrato alguno</strong> con
                        la Caja de Retiros, Jubilaciones y Pensiones de la
                        Policía Federal. La exhibición de la información que se
                        brinda a través del mismo,
                        <strong>sólo tiene carácter ilustrativo</strong> y
                        orientativo para el interesado respecto del Programa
                        Transaccional. <br />
                        <strong
                            >IMPORTANTE: el importe a percibir será calculado a
                            la fecha de la firma del acuerdo.</strong
                        >
                    </p>
                </div>

                <div class="col-12 alert alert-info" v-if="buscando">
                    {{ buscando }}
                </div>
                <div class="col-12" v-else>
                    <transition-group
                        appear
                        name="mostrar-recibos"
                        tag="div"
                        mode="out-in"
                        v-for="beneficio in beneficios"
                        :key="beneficio.beneficio_id"
                        v-if="beneficio.tiene_transaccional"
                    >
                        <beneficio-transaccional
                            :beneficio="beneficio"
                            :key="beneficio.beneficio_id"
                        ></beneficio-transaccional>
                    </transition-group>
                </div>
            </div>

            <div class="row" v-if="errores">
                <div class="alert alert-warning">
                    {{ errores }}
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.mostrar-recibos-enter-active {
    transition: opacity 0.5s;
}
.mostrar-recibos-leave-active {
    transition: opacity 0s;
}
.mostrar-recibos-enter,
.mostrar-recibos-leave-to {
    opacity: 0;
}
</style>

<script>
import BeneficioTransaccional from "./BeneficioTransaccional.vue";

export default {
    components: {
        BeneficioTransaccional: BeneficioTransaccional
    },
    data() {
        return {
            beneficios: this.$store.state.beneficios.beneficios,
            errores: null,
            // legajo: this.$store.state.empleados.legajo,
            // dni: this.$store.state.user.dni,
            tipo: null,
            buscando: false
        };
    }
};
</script>
