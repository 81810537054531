<template>
    <span class="badge badge--tramite badge--tramite__certhaberes">{{tramiteNombre}}</span>
</template>

<script>
    export default {
        props: [
            'tramite'
        ],
        computed: {
            tramiteNombre() {
                if (this.tramite === 'CERTHABER') return 'Certificado de Haberes'
                if (this.tramite === 'CERTCOMPLEMENTARIA') return  'Certificado de Complementaria'
            }
        }
    };
</script>
