<template>
	<div class="badges transferencia__badges">
		<div class="transferencia__badges badges__destino">

            <badge-organismo class="transferencia__badge badge__organismo" :organismo="datosTransferencia.organismo.codigo" :nombreOrganismo="datosTransferencia.organismo.nombre_organismo" v-if="td!=1"></badge-organismo>

            <div class="transferencia__badge badge__sector">
                <span class="badge transferencia__badge--estado badge-azul">
                    {{datosTransferencia.subsector.sector.nombre_sector}}            
                </span>    
            </div>

            <div class="transferencia__badge badge__sector" v-if="td==1">
                <span class="badge transferencia__badge--estado badge-azulado">
                    {{datosTransferencia.subsector.subsector}}                            
                </span>    
            </div>

			

			<span> <i class="fas fa-arrow-right"></i> </span>

            <div class="transferencia__badge badge__subsector" v-if="td==2 || td==3 || td==4">
                <span class="badge transferencia__badge--estado badge-azulado">
                    {{ultima_historia.subsector.subsector}}            
                </span>    
            </div>

			<div class="transferencia__badge badge__sector" v-if="td==1 || td==5">
                <span class="badge transferencia__badge--estado badge-azul">
                    {{ultima_historia.subsector.sector.nombre_sector}}            
                </span>    
            </div>

            
		</div>

		<div class="transferencia__badges badge__estado">
            <badge-estado :estado="ultima_historia.estado.estado"></badge-estado>
        </div>
    </div>
</template>

<script>
	
	import BadgeEstado from '../badges/BadgeEstado.vue'
	import BadgeOrganismo from '../badges/BadgeOrganismo.vue'

	export default {
        components: {
        	'badge-estado': BadgeEstado,
        	'badge-organismo': BadgeOrganismo
        },
        props: [
        	'td',
        	'datosTransferencia',
        	'ultima_historia'
        ],

      
    }


</script>