import "./bootstrap";
import "./filters.js";
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue from 'vue'
import router from './routes';
import store from './store/store';
import App from './components/App.vue'
import moment from 'moment';
import VueTheMask from 'vue-the-mask'
import VueCookies from 'vue-cookies';
import VueCurrencyFilter from 'vue-currency-filter'
import VueSweetalert2 from "vue-sweetalert2";
import VueToastify from "vue-toastify";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@kangc/v-md-editor/lib/style/preview-html.css';

const SocialSharing = require('vue-social-sharing');

Vue.prototype.moment = moment
moment.locale('es')
Vue.use(SocialSharing)
Vue.use(VueCurrencyFilter, {
    symbol: '',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
})
Vue.use(DatePicker);
Vue.use(DateRangePicker);
Vue.use(VueCookies)
Vue.use(VueSweetalert2)
Vue.use(VueTheMask)
Vue.use(VueToastify, {
    position: 'top-right',
    successDuration: 2000,
    theme: 'light'
});


new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})
