<template>
    <div class="container">
        <div
            class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
        >
            <div class="row">
                <div class="contenedor-titulo-seccion">
                    <div class="titulo-seccion">
                        <!--<img class="titulo-seccion__icono" src="imagenes/iconos/signup.png" alt="">-->
                        <h2 class="titulo-seccion__texto">Registrarse</h2>
                        <p class="subtitulo-seccion__texto text-muted">
                            Registro para empleados
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <form
                    @submit.prevent
                    v-on:keyup.right.prevent="siguiente_pantalla"
                    class="container p-4 formulario formulario-ingresar"
                >
                    <div class="row">
                        <div class="col-12 alertas" v-if="errores">
                            <div class="alert alert-warning" role="alert">
                                {{ errores }}
                            </div>
                        </div>
                    </div>

                    <div v-show="step === 1">
                        <div class="row">
                            <div class="col my-2">
                                <h5>Paso 1 de 3: Información personal</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="cdi_cuil">Número de CUIL</label>
                                <input
                                    type="text"
                                    id="cdi_cuil"
                                    name="cdi_cuil"
                                    autofocus
                                    class="form-control"
                                    v-model="cdi_cuil"
                                    placeholder="Ingrese su CUIL"
                                    tabindex="0"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="select-container form-group col-12">
                                <label for="tipo_documento"
                                    >Tipo de documento</label
                                >
                                <select
                                    class="form-control"
                                    id="tipo_documento"
                                    name="tipo_documento"
                                    v-model="tipo_documento"
                                    tabindex="0"
                                >
                                    <option value="DNI">DNI</option>
                                    <option value="LC">LC</option>
                                    <option value="LE">LE</option>
                                    <option value="CI">CI</option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12">
                                <label for="numero_documento"
                                    >Número de documento</label
                                >
                                <input
                                    type="text"
                                    id="numero_documento"
                                    name="numero_documento"
                                    class="form-control"
                                    v-model="numero_documento"
                                    placeholder="Ingrese su número de documento"
                                    tabindex="0"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6">
                                <div
                                    class="boton-anterior-siguiente text-center"
                                    v-on:click.prevent="siguiente_pantalla"
                                    tabindex="0"
                                >
                                    Siguiente
                                    <i class="fas fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="step === 2">
                        <div class="row">
                            <div class="col my-2">
                                <h5>Paso 2 de 3: Sobre su legajo</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 form-group input-password-login">
                                <label for="legajo"
                                    >¿Cuál es su número de legajo?</label
                                >
                                <input
                                    class="form-control"
                                    type="text"
                                    name="legajo"
                                    v-model="legajo"
                                    id="legajo"
                                    tabindex="0"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12 select-container">
                                <label for="sector_id"
                                    >¿En qué sector de la Caja trabaja?</label
                                >
                                <select
                                    class="form-control"
                                    name="sector_id"
                                    v-model="sector_id"
                                    id="sector_id"
                                    tabindex="0"
                                >
                                    <option value="6">Presidencia</option>
                                    <option value="5"
                                        >Unidad de Auditoría Interna</option
                                    >
                                    <option value="22"
                                        >Ayudantía Policial</option
                                    >
                                    <option value="2"
                                        >Dirección General de
                                        Operaciones</option
                                    >
                                    <option value="98"
                                        >Secretaría General</option
                                    >
                                    <option value="13">
                                        -- Sector Despacho</option
                                    >
                                    <option value="9"> -- Sector Mesa</option>
                                    <option value="12">
                                        -- Sector Resoluciones</option
                                    >
                                    <option value="99"
                                        >Dirección de Control y
                                        Prevención</option
                                    >
                                    <option value="15"> -- Pasividades</option>
                                    <option value="3"
                                        >Dirección de Beneficiarios</option
                                    >
                                    <option value="16">
                                        -- Departamento Actividades</option
                                    >
                                    <option value="17">
                                        -- Departamento Ganancias</option
                                    >
                                    <option value="18">
                                        -- Departamento Información
                                        Pasivos</option
                                    >
                                    <option value="19">
                                        -- Departamento Liquidación de
                                        Prestaciones</option
                                    >
                                    <option value="20">
                                        -- Departamento Sentencias
                                        Judiciales</option
                                    >
                                    <option value="55">
                                        -- Verificaciones/Informaciones
                                        Sumarias</option
                                    >
                                    <option value="7"
                                        >Dirección de Asuntos Jurídicos</option
                                    >
                                    <option value="4"
                                        >Dirección de Administración</option
                                    >
                                    <option value="10">
                                        -- Departamento Compras</option
                                    >
                                    <option value="11">
                                        -- Departamento Contabilidad</option
                                    >
                                    <option value="14">
                                        -- Departamento Tesorería</option
                                    >
                                    <option value="23">
                                        -- Reg. y Depuración de
                                        Expedientes</option
                                    >
                                    <option value="8"
                                        >Dirección de Recursos Humanos</option
                                    >
                                    <option value="1"
                                        >Dirección de Informática y
                                        Sistemas</option
                                    >
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div
                                    class="boton-anterior-siguiente text-center"
                                    v-on:click.prevent="anterior_pantalla"
                                >
                                    <i class="fas fa-chevron-left"></i> Anterior
                                </div>
                            </div>
                            <div class="col-6">
                                <div
                                    class="boton-anterior-siguiente text-center"
                                    v-on:click.prevent="siguiente_pantalla"
                                    tabindex="0"
                                >
                                    Siguiente
                                    <i class="fas fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="step === 3">
                        <div class="row">
                            <div class="col-12">
                                <h5>Paso 3: Datos de acceso</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12">
                                <div class="group input-usuario-login">
                                    <label for="email"
                                        >Correo electrónico</label
                                    >
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="email"
                                        v-model="email"
                                        id="email"
                                        tabindex="0"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12">
                                <div class="group input-usuario-login">
                                    <label for="confirmar_email"
                                        >Repita su correo electrónico</label
                                    >
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="confirmar_email"
                                        v-model="confirmar_email"
                                        id="confirmar_email"
                                        tabindex="0"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12">
                                <div class="group input-password-login">
                                    <label for="password">Contraseña</label>
                                    <input
                                        class="form-control"
                                        type="password"
                                        name="password"
                                        v-model="password"
                                        id="password"
                                        tabindex="0"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12">
                                <div class="group input-password-login">
                                    <label for="confirmar_password"
                                        >Repetir Contraseña</label
                                    >
                                    <input
                                        class="form-control"
                                        type="password"
                                        name="confirmar_password"
                                        v-model="confirmar_password"
                                        id="confirmar_password"
                                        tabindex="0"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div
                                    class="boton-anterior-siguiente text-center"
                                    v-on:click.prevent="anterior_pantalla"
                                >
                                    <i class="fas fa-chevron-left"></i> Anterior
                                </div>
                            </div>
                            <div class="col-6">
                                <button
                                    class="btn btn-primary boton-formulario-login"
                                    v-if="acciones"
                                >
                                    {{ acciones }}
                                    <i
                                        class="fas fa-circle-notch faa-spin animated faa-fast"
                                    ></i>
                                </button>
                                <button
                                    class="btn btn-primary boton-registrarse"
                                    v-on:click.prevent="registrarEmpleado"
                                    v-on:keyup.enter.prevent="registrarEmpleado"
                                    v-else
                                    tabindex="0"
                                >
                                    Registrarse
                                </button>
                            </div>
                        </div>
                        <!--<button class="btn btn-primary boton-formulario-login" @click.prevent="anterior_pantalla()"><i class="fas fa-chevron-left"></i> Anterior</button>
						<button class="btn btn-primary boton-formulario-login" v-if="acciones">{{ acciones }} <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></button>
						<button class="btn btn-primary boton-formulario-login" @click.prevent="registrarEmpleado()" v-else>Registrarse</button>-->
                    </div>
                    <div v-show="step === 4">
                        <h5>Registro finalizado</h5>

                        <div class="alert alert-primary">
                            Le enviamos un correo electrónico a la casilla que
                            elegió para que pueda verificar su cuenta.
                        </div>
                    </div>
                </form>
            </div>

            <div class="row">
                <div
                    class="col-12 p-2 mt-3 text-center formulario-ingresar__otras-acciones"
                >
                    <router-link
                        class="otras-acciones__link text-decoration-none"
                        :to="{ name: 'Ingresar' }"
                        tabindex="-1"
                        >Ya estoy registrado</router-link
                    >
                </div>
                <div
                    class="col-12 p-2 text-center formulario-ingresar__otras-acciones"
                >
                    <router-link
                        class="otras-acciones__link text-decoration-none"
                        :to="{ name: 'solicitudRecuperarPassword' }"
                        tabindex="-1"
                        >Estoy registrado, pero no recuerdo mi
                        contraseña</router-link
                    >
                </div>
                <div
                    class="col-12 p-2 text-center formulario-ingresar__otras-acciones"
                >
                    <router-link
                        class="otras-acciones__link text-decoration-none"
                        :to="{ name: 'Registrarse' }"
                        tabindex="-1"
                        >Soy Beneficiario de la Caja</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            step: 1,
            acciones: "",
            email: "",
            confirmar_email: "",
            password: "",
            confirmar_password: "",
            cdi_cuil: "",
            tipo_documento: "DNI",
            numero_documento: "",
            legajo: "",
            sector_id: "",
            errores: ""
        };
    },
    methods: {
        anterior_pantalla() {
            $("html,body").animate({ scrollTop: 0 }, 300);
            this.step--;
        },
        siguiente_pantalla() {
            $("html,body").animate({ scrollTop: 0 }, 300);
            if (this.step === 1) {
                if (
                    this.tipo_documento == "" ||
                    this.numero_documento == "" ||
                    this.cdi_cuil == ""
                ) {
                    this.errores = "Tiene que completar todos los campos";
                } else {
                    this.errores = "";
                    this.step++;
                }
            } else if (this.step === 2) {
                if (this.legajo == "" || this.sector_id == "") {
                    this.errores = "Tiene que completar todos los campos";
                } else {
                    this.errores = "";
                    this.step++;
                }
            }
        },
        async registrarEmpleado() {
            this.acciones = "Registrando usuario";
            this.errores = "";

            if (
                this.email == "" ||
                this.confirmar_email == "" ||
                this.password == "" ||
                this.confirmar_password == ""
            ) {
                $("html,body").animate({ scrollTop: 0 }, 300);
                this.errores = "Tiene que completar todos los campos";
                this.acciones = "";
                return;
            }

            if (this.email != this.confirmar_email) {
                $("html,body").animate({ scrollTop: 0 }, 300);
                this.errores = "Los correos electrónicos no coinciden";
                this.acciones = "";
                return;
            }

            if (this.password != this.confirmar_password) {
                $("html,body").animate({ scrollTop: 0 }, 300);
                this.errores = "Las contraseñas no coinciden";
                this.acciones = "";
                return;
            }

            let dato_usuario = this.cdi_cuil.replace(/-/g, "");
            dato_usuario = dato_usuario.replace(/_/g, "");
            dato_usuario = dato_usuario.replace(/ /g, "");

            const formData = {
                email: this.email,
                password: this.password,
                confirmar_password: this.confirmar_password,
                persona_id: dato_usuario,
                tipo_documento: this.tipo_documento,
                numero_documento: this.numero_documento,
                legajo: this.legajo,
                sector_id: this.sector_id
            };

            try {
                await axios.get("/sanctum/csrf-cookie");
                await axios.post("/registrarse/empleados", formData)

                $("html,body").animate({ scrollTop: 0 }, 300);
                this.errores = "";
                this.acciones = "";
                this.step = 4;
            }
            catch (e) {
                $("html,body").animate({ scrollTop: 0 }, 300);
                this.acciones = "";
                if (e.response.status === 500) {
                    this.errores = "Hubo un inconveniente con el ingreso a la aplicación. Por favor intente en unos minutos.";
                } else if (e.response.status === 422) {
                    this.errores = "Algunos de los datos ingresados no son válidos";
                } else if (e.response.status === 409) {
                    this.errores = e.response.data.error;
                } else {
                    this.errores = "Los datos ingresados no coinciden con los registros en nuestras bases de datos";
                }
            }
        }
    }
};
</script>
