<template>
	<div>
		<div class="card recibo-haberes">
  			<div class="recibo-haberes__titulo">
  				<h5 class="card-title recibo-haberes__concepto">{{ certificado['archivo'] | certificadoGanancias}}</h5>
	    		<p class="text-muted recibo-haberes__info"><small>Beneficio {{certificado['beneficio_id'] | formatearBeneficio }}</small></p>
	    	</div>

	    	<div class="recibo-haberes__acciones">
		    	<div class="accion-recibo">
		    		<button class="btn btn-outline-secondary accion-recibo__descargar" @click="descargarCertificado()" v-if="descargandoCertificado==0">
			    		<i class="fas fa-cloud-download-alt"></i>
			    		<span>Descargar</span>
			    	</button>
			    	<button class="btn btn-outline-info accion-recibo__descargar" v-else-if="descargandoCertificado==1">
			    		<i class="fas fa-ellipsis-h"></i>
			    		<span>Descargando certificado</span>
			    	</button>
			    	<button class="btn btn-outline-danger accion-recibo__descargar" v-else-if="descargandoCertificado==3">
			    		<i class="fas fa-times-circle"></i>
			    		<span>Error en la descarga</span>
			    	</button>
			    	<button class="btn btn-outline-success accion-recibo__descargar" @click="descargarCertificado()" v-else>
			    		<i class="fas fa-check-circle"></i>
			    		<span>Certificado descargado</span>
			    	</button>
		    	</div>
		   		<div class="accion-recibo">
					<button class="btn btn-outline-secondary accion-recibo__enviar" v-if="enviandoCertificadoMail==0" data-toggle="modal" :data-target="['#compartirPorMail' + certificado['archivo']]">
							<i class="fas fa-envelope"></i>
							<span>Enviar por mail</span>
					</button>
					<button class="btn btn-outline-info  accion-recibo__enviar" v-else-if="enviandoCertificadoMail==1">
							<i class="fas fa-ellipsis-h"></i>
							<span>Enviando certificado</span>
					</button>
					<button class="btn btn-outline-danger  accion-recibo__enviar" v-else-if="enviandoCertificadoMail==3" data-toggle="modal" :data-target="['#compartirPorMail' + certificado['archivo']]">
							<i class="fas fa-times-circle"></i>
							<span>Error en el envío</span>
					</button>
					<button class="btn btn-outline-success  accion-recibo__enviar" v-else data-toggle="modal" :data-target="['#compartirPorMail' + certificado['archivo']]">
							<i class="fas fa-check-circle"></i>
							<span>Certificado enviado</span>
					</button>
				</div>
			</div>
		</div>

		<!-- Modal envío certificado por mail -->
		<div class="modal fade" :id="['compartirPorMail' + certificado['archivo']]" tabindex="-1" role="dialog" aria-labelledby="ventanaCompartirPorMail" aria-hidden="true">
		  	<div class="modal-dialog modal-dialog-centered" role="document">
		    	<div class="modal-content">
		      		<div class="modal-header">
		        		<h5 class="modal-title" id="exampleModalLongTitle"><i class="fas fa-share-square"></i> Compartir archivo</h5>
		        		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          			<span aria-hidden="true">&times;</span>
		        		</button>
		      		</div>
		      		<div class="modal-body">
		      			<div class="alert alert-danger" role="alert" v-if="errores">
							{{ errores }}
						</div>
						Archivo: {{ certificado['archivo'] }}
		      			<form @submit.prevent="">
		      				<label for="email">Correo electrónico de destino</label>
		      				<input class="form-control" type="email" id="email" name="email" v-model="email">
		      			</form>
		      		</div>
		      		<div class="modal-footer">
		        		<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
		        		<button type="button" class="btn btn-primary" @click="enviarCertificadoMail()" @keyup.enter="enviarCertificadoMail()">Enviar</button>
		      		</div>
		    	</div>
		  	</div>
		</div>
	</div>
</template>

<script>

	export default {
		data () {
			return {
				descargandoCertificado: 0,
				enviandoCertificadoMail: 0,
				errores: null,
				buscando: null,
				email: '',
				persona_id: ''
			}
		},
		props: [
			'certificado',
			'index',
			'seccion'
		],
        created() {
            if(this.seccion === 'gb') {
                this.email = this.$store.state.gestionbeneficiarios.usuario ? this.$store.state.gestionbeneficiarios.usuario.email : ''
                this.persona_id = this.$store.state.gestionbeneficiarios.persona.persona_id
            } else {
                this.email = this.$store.state.user.email
                this.persona_id = this.$store.state.persona.persona_id
            }
        },
        computed: {
            url() {
                let url

                if(this.seccion === "gb") {
                    url ='gestion-beneficiarios/certificados-ganancias/'
                }else {
                    url = 'beneficiarios/mis-documentos/certificados-ganancias/'
                }

                return url
            },
        },
		methods: {
			descargarCertificado: function(){
				this.certificado_descargado = this.certificado['archivo']
				this.descargandoCertificado = 1

				axios.post(this.url + this.certificado['archivo'] + '/descargar',{}, {
					responseType: 'blob'
				})
				.then((response) => {
					this.descargandoCertificado = 2
					const filename = response.headers['content-disposition'].split('=')[1].replace(/^\"+|\"+$/g, '')
					const url = window.URL.createObjectURL(new Blob([response.data]));
   					const link = document.createElement('a');
   					link.href = url;
   					link.setAttribute('download', filename);
   					document.body.appendChild(link);
   					link.click();
				})
				.catch((error) => {
					this.descargandoCertificado = 3
				})
			},
			enviarCertificadoMail: function(){
				$('#compartirPorMail' + this.certificado['archivo']).modal('hide');
				this.enviandoCertificadoMail = 1

                const data = {
				    email: this.email,
                    persona_id: this.persona_id
                }

				axios.post(this.url + this.certificado['archivo'] + '/email', data)
				.then((response) => {
					this.enviandoCertificadoMail = 2
					this.enviadoEmail = response.data.data
				})
				.catch((error) => {
					this.enviandoCertificadoMail = 3
				})
			}
		}
	}

</script>
