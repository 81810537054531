<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Notificaciones</li>
            </ol>
        </nav>

        <div class="row">
            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;" :to="{ name: 'MisAplicacionesNotificaciones' }">
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-paper-plane fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Generar</h5>
                        <p class="card-text text-muted"></p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "MisAplicacionesNotificacionesMenu"
}
</script>

<style scoped>

</style>
