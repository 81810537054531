<template>
    <div>
        <h4>{{ persona.nombre }} {{ persona.apellido }}</h4>
        <div class="card transferencia">
            <div class="transferencia__contenedor">
                <p>
                    <strong>Datos Personales</strong> <br/>
                    Nombre: {{ persona.nombre }} {{ persona.apellido }}<br/>
                    CUIL / CDI: {{ persona.cdi_cuil }} <br/>
                    Documento: {{ persona.tipo_documento }} {{ persona.numero_documento }} <br/>
                    Sexo: {{ persona.sexo }} <br/>
                    Nacionalidad: <span v-if="persona.nacionalidad === 'A'">Argentina</span><span v-else>Extranjero</span> <br/>
                    Afiliado: {{ persona.afiliado }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "Persona",
    computed: {
        ...mapState({
            persona: state => {
                return state.gestionbeneficiarios.persona
            }
        })
    }
}
</script>

<style scoped>

</style>
