<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en: <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{ name: 'MisTramitesMenu' }">Mis Trámites</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Certificados de Haberes
                </li>
            </ol>
        </nav>

        <menu-certificados></menu-certificados>

        <router-view class="mt-4"></router-view>

    </div>
</template>
<script>
    import MenuCertificados from "./CertificadosHaberesMenu"

    export default {
        components: {
            "menu-certificados" : MenuCertificados
        }
    }
</script>
