<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Está en: Mi Caja</li>
            </ol>
        </nav>

        <novedad-noticias-beneficiarios  v-if="rolBeneficiario">

        </novedad-noticias-beneficiarios>
       
        <novedad-ultimo-recibo
            v-if="rolBeneficiario"
        ></novedad-ultimo-recibo>

        <novedad-punto-a-punto
            v-if="rolUsuario"
        ></novedad-punto-a-punto>

        <novedad-empleados-agosto
            v-if="rolEmpleado"
        ></novedad-empleados-agosto>

        <novedad-mis-recibos
            v-if="rolEmpleado"
        ></novedad-mis-recibos>

        <novedad-aplicacion-usuarios
            v-if="appOrganismoConsulta"
        ></novedad-aplicacion-usuarios>
    </div>
</template>

<script>
import {mapState} from "vuex";
import NovedadMisRecibos from "./novedades/NovedadMisRecibos.vue";
import NovedadPuntoAPunto from "./novedades/NovedadPuntoAPunto.vue";
import NovedadProgramaTransaccional from "./novedades/NovedadProgramaTransaccional.vue";
import NovedadUltimoRecibo from "./novedades/NovedadUltimoRecibo.vue";
import NovedadAplicacionUsuarios from "./novedades/NovedadAplicacionUsuarios.vue";
import NovedadEmpleadosAgosto from "./novedades/NovedadEmpleadosAgosto.vue";
import NovedadNoticiasBeneficiarios from "./novedades/NovedadesBeneficiariosMiCaja.vue"

export default {
    components: {
        "novedad-mis-recibos": NovedadMisRecibos,
        "novedad-punto-a-punto": NovedadPuntoAPunto,
        "novedad-programa-transaccional": NovedadProgramaTransaccional,
        "novedad-ultimo-recibo": NovedadUltimoRecibo,
        "novedad-aplicacion-usuarios": NovedadAplicacionUsuarios,
        "novedad-empleados-agosto": NovedadEmpleadosAgosto,
        "novedad-noticias-beneficiarios": NovedadNoticiasBeneficiarios
    },
    computed: mapState({
        rolBeneficiario: state => {
            return state.user.roles.includes('BENEFICIARIO')
        },
        rolUsuario: state => {
            return state.user.roles.includes('USUARIO')
        },
        rolEmpleado: state => {
            return state.user.roles.includes('EMPLEADO')
        },
        appOrganismoConsulta: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'USUARIOS' && a.funcion === 'USUARIOS-ORGANISMOS-CONSULTA') && state.user.roles.includes('EMPLEADO')
        }
    }),
};
</script>
