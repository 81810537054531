<template>
    <div>
        <span class="badge transferencia__badge--estado badge-tomate" v-if="estado=='enviado'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-lima" v-else-if="estado=='recibido'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-rojo" v-else-if="estado=='rechazado'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-verdin" v-else-if="estado=='aceptado'">{{estado}}</span>
        <!-- <span class="badge transferencia__badge--estado badge-rojo" v-else-if="estado=='rechazo'">{{estado}}</span> -->
        <span class="badge transferencia__badge--estado badge-maiz" v-else-if="estado=='observado'">{{estado}}</span>
        
        <span class="badge transferencia__badge--estado badge-azulado" v-else-if="estado=='contestado'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-tomate" v-else-if="estado=='reenviado'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-arandano" v-else-if="estado=='transferido'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-amarillo" v-else-if="estado=='devuelto'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-azul" v-else-if="estado=='procesado'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-verde" v-else-if="estado=='finalizado'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-gris-claro" v-else-if="estado=='archivado'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-azulado" v-else-if="estado=='respondido'">{{estado}}</span>
        <span class="badge transferencia__badge--estado badge-gris" v-else>{{estado}}</span>
    </div>
</template>

<script>
    
    export default {
        props: [
            'estado'
        ]
    }

</script>