<template>
    <div>
        <buscador-recibos-gendarmeria></buscador-recibos-gendarmeria>
        <br/>
        <div class="alert alert-primary w-100 d-inline-flex align-items-center justify-content-center" v-if="loading">
            <div class="mr-2 spinner-border text-info" role="status">
            </div>
            <div class="ml-2" >Buscando Los Datos ...</div>
        </div>
        <div v-else>
            <div class="alert alert-primary" v-if="recibos.length == 0 && !recibosBuscados">
            Puede realizar la búsqueda de información por número de documento o número de CUIL
            </div>
            <div v-if="errores.length>0">
                <div v-for="error in errores" class="alert alert-danger" >
                    {{error}}
                </div>
            </div>
            <lista-recibos-gendarmeria v-else :recibosEncontrados="recibos" :persona="persona"></lista-recibos-gendarmeria>
        </div>
    </div>
</template>
<script>
import BuscadorRecibosGendarmeria from './buscador/BuscadorRecibosGendarmeria.vue';
import ListaRecibosGendarmeria from "./lista/ListaRecibosGendarmeria.vue";
import {mapState} from 'vuex';

export default {
    name: "MisAplicacionesGestionRecibosGendarmeria",
    
    components:{
        BuscadorRecibosGendarmeria,
        ListaRecibosGendarmeria,
        
    },
    
    computed: {
        ...mapState({
            recibos: state => {
                return state.gestiongendarmeria.recibos
            },
            persona: state=>{
                return state.gestiongendarmeria.persona
            },
            errores: state=>{
                return state.gestiongendarmeria.errors
            },
            loading: state=>{
                return state.gestiongendarmeria.loading
            },
            recibosBuscados: state => {
                return  state.gestiongendarmeria.recibosBuscados
            }
        })
    }

}
</script>