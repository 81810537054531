<template>
	<div>
		<div class="card transferencia">
			<div class="row usuarios_contenedor">
				<div class="col-md-7">
					<p><span class="card-title"><strong>{{ usuario.persona.nombre }} {{ usuario.persona.apellido }}</strong></span></p>
					<p><span ><a :href="`mailto:${usuario.email}`">{{ usuario.email}}</a></span></p>
                    <p v-if="usuario.activo === 'N'" class="text-danger">Bloqueado</p>
                    <p>Verficado: <span :class="{'text-danger' : usuario.verificado == 'N'}">{{ usuario.verificado }}</span></p>
					<p>Cuil: {{usuario.persona.cdi_cuil}}</p>
					<p v-for="subsector in usuario.subsectores" :key="subsector.id">
                        Organismo: {{ subsector.sector.sede.organismo.nombre_organismo }} <br>
                        Sector: {{ subsector.sector.nombre_sector }}
                    </p>
                    <p>Creado: <span class="text-muted">{{ fechaFormateada }}</span></p>
				</div>
				<div class="col-md-4" v-if="esAdministrador">
					<button class="btn btn-outline-info mt-sm-2" data-toggle="modal" :data-target="`#modalEditar${index}`" @click="cargarSectoresOrganismo()">
              			<i class="fas fa-pencil-alt"></i>
            		</button>
            		<button v-if="usuario.activo === 'S'" class="btn btn-outline-danger mt-sm-2" data-toggle="modal" :data-target="`#modalBloquear${index}`">
              			<i class="fas fa-trash-alt"></i>
            		</button>
            		<button v-if="usuario.activo === 'N'" class="btn btn-outline-success" data-toggle="modal" :data-target="`#modalRestaurar${index}`">
              			<i class="fas fa-user-plus"></i>
            		</button>
				</div>
			</div>
		</div>

        <!-- Modal modificación de usuario -->
        <div class="modal fade" :id="`modalEditar${index}`" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-center" id="exampleModalLongTitle">{{ usuarioEditado.persona.nombre }} {{ usuarioEditado.persona.apellido }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent>
                            <div class="row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="nombre">Nombre</label>
                                    <input type="text" id="nombre" name="nombre" class="form-control" v-model="usuarioEditado.persona.nombre" placeholder="Ingrese el nombre" autofocus/>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="apellido">Apellido</label>
                                    <input type="text" id="apellido" name="apellido" class="form-control" v-model="usuarioEditado.persona.apellido" placeholder="Ingrese el apellido" autofocus/>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="select-container form-group col-12 col-sm-4">
                                    <label for="tipo_documento">Tipo de documento</label>
                                    <select class="form-control" id="tipo_documento" name="tipo_documento" v-model="usuarioEditado.persona.tipo_documento" tabindex="0">
                                        <option value="DNI" selected="selected">DNI</option>
                                        <option value="LC">LC</option>
                                        <option value="LE">LE</option>
                                        <option value="CI">CI</option>
                                    </select>
                                </div>
                                <div class="form-group col-12 col-sm-4">
                                    <label for="numero_documento">Número de documento</label>
                                    <input type="text" id="numero_documento" name="numero_documento" class="form-control" v-model="usuarioEditado.persona.numero_documento" placeholder="Ingrese el número de documento" v-mask="['#######', '########']" tabindex="0"/>
                                </div>
                                <div class="form-group col-12 col-sm-4">
                                    <label for="cdi_cuil">CUIL o CDI</label>
                                    <input type="text" id="cdi_cuil" name="cdi_cuil" class="form-control" v-model="usuarioEditado.persona.persona_id" placeholder="Ingrese el CUIL o CDI" autofocus/>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="select-container form-group col-12 col-sm-4">
                                    <label for="sexo">Sexo</label>
                                    <select class="form-control" id="sexo" name="sexo" v-model="usuarioEditado.persona.sexo" tabindex="0">
                                        <option value="Femenino">Femenino</option>
                                        <option value="Masculino">Masculino</option>
                                    </select>
                                </div>
                                <div class="select-container form-group col-12 col-sm-4">
                                    <label for="nacionalidad">Nacionalidad</label>
                                    <select class="form-control" id="nacionalidad" name="nacionalidad" v-model="usuarioEditado.persona.nacionalidad" tabindex="0">
                                        <option value="A" selected="selected">Argentina</option>
                                        <option value="E">Extranjero</option>
                                    </select>
                                </div>
                                <div class="form-group col-12 col-sm-4">
                                    <label for="fecha_nacimiento">Fecha Nacimiento:</label>
                                    <input type="date" id="fecha_nacimiento" name="fecha_nacimiento" class="form-control" v-model="usuarioEditado.persona.fecha_nacimiento"/>
                                </div>
                            </div>
                            <hr/>
                            <div class="row" v-for="(subsector, key) in usuarioEditado.subsectores" :key="subsector.id">
                                <div class="select-container col-12 col-lg-6 form-group" >
                                    <label class="typo__label" for="organismo">Organismo</label>
                                    <select class="form-control" name="organismo" v-model="usuarioEditado.subsectores[key].sector.sede.organismo.codigo" id="organismo" @change="cargarSectoresOrganismo($event.target.value)">
                                        <option v-for="organismo in organismos" :value="organismo.codigo">
                                            {{ organismo.nombre_organismo }}
                                        </option>
                                    </select>
                                </div>

                                <div class="select-container col-12 col-lg-6 form-group" >
                                    <label for="subsector_id">Sector del organismo</label>
                                    <select class="form-control" name="subsector_id" v-model="usuarioEditado.subsectores[key].id" id="subsector_id">
                                        <option v-for="sector in sectoresOrganismo" :value="sector.subsectores[0].id">
                                            {{ sector.nombre_sector }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                <div class="col-12 form-group">
                                    <label for="email">Correo electrónico</label>
                                    <input class="form-control" type="text" name="email" id="email" v-model="usuarioEditado.email" placeholder="Ingrese el correo electrónico" tabindex="0"/>
                                </div>
                            </div>
                        </form>
                        <div class="row d-flex justify-content-end px-3">
                            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                                Cancelar
                            </button>

                            <button type="button" class="btn btn-primary" @click="editarUsuario()" @keyup.enter="editarUsuario()">
                                <span v-if="acciones === ''">Modificar</span>
                                <span v-else>{{ acciones }}<i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                            </button>
                        </div>
                        <div class="row px-3">
                            <div class="alert alert-warning col-12 mt-4 px-3" role="alert" v-if="errorModalModificarUsuario.length > 0">
                                <div v-for="error in errorModalModificarUsuario">
                                    {{ error }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal confirmar elimacion de usuario -->
        <div class="modal fade" tabindex="-1" role="dialog" :id="`modalBloquear${index}`">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Bloquear usuario</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-white bg-danger">
                        <p>Esta seguro que desea bloquear el usuario: {{usuario.persona.nombre}} {{usuario.persona.apellido}} ?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="bloquearUsuario()">
                            Bloquear
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" tabindex="-1" role="dialog" :id="`modalRestaurar${index}`">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Restaurar usuario</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Esta seguro que desea restaurar el usuario: {{usuario.persona.nombre}} {{usuario.persona.apellido}} ?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="restaurarUsuario()">
                            Restaurar
                        </button>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
	import {mapState} from "vuex";
    import axios from "axios";

    export default {
		name: 'Usuario',
		props: [
		    'usuario',
            'organismos',
            'index'
        ],
        data() {
		    return {
		        usuarioEditado: {},
                acciones: '',
                sectoresOrganismo: [],
                errorModalModificarUsuario: [],
                errores: ''
            }
        },
        created() {
		    this.usuarioEditado = JSON.parse(JSON.stringify(this.usuario))
        },
        computed: {
            ...mapState({
                esAdministrador: state => {
                    return state.user.roles.includes('ADMINISTRADOR')
                },
            }),
            fechaFormateada() {
                const fechaActual = new Date(this.usuario.created_at);

                const opciones = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                };

                return fechaActual.toLocaleDateString('es-AR', opciones)
            }
        },
        methods: {
            async editarUsuario() {
                this.errorModalModificarUsuario = []

                let user = {
                    ...this.usuarioEditado,
                    subsectores: this.usuarioEditado.subsectores.map(sub => sub.id)
                }

                delete user.persona.cdi_cuil
                user.persona.sexo = user.persona.sexo === 'Masculino' ? 'M' : 'F'

                try {
                    const { data } = await axios.put('usuarios/organismos/editar-usuario', user)
                    this.$emit('actualizarUser', {usuario: data.data, index: this.index})
                    $('#modalEditar' + this.index).modal('hide')
                }
                catch (e) {
                    if (e.response.status === 422) {
                        const {errors} = e.response.data
                        this.errorModalModificarUsuario = Object.values(errors).map(error => error[0])
                    }
                    else {
                        this.errorModalModificarUsuario = ['Hubo un error al actualizar el usuario']
                    }
                }
            },
            async bloquearUsuario() {
                try {
                    const { data } = await axios.post('usuarios/organismos/bloquear-usuario', {id: this.usuario.id})
                    this.$emit('actualizarUser', {usuario: data.data, index: this.index})
                    $('#modalBloquear' + this.index).modal('hide')
                }
                catch (e) {
                    this.errores = 'Hubo un error al bloquear el usuario'
                }
            },
            async restaurarUsuario() {
                try {
                    const { data } = await axios.post('usuarios/organismos/activar-usuario', {id: this.usuario.id})
                    this.$emit('actualizarUser', {usuario: data.data, index: this.index})
                    $('#modalRestaurar' + this.index).modal('hide')
                }
                catch (e) {
                    this.errores = 'Hubo un error al actualizar el usuario'
                }
            },
            async cargarSectoresOrganismo(codigo = null) {
                let organismo = codigo == null ? this.usuarioEditado.subsectores[0].sector.sede.organismo.codigo : codigo

                const {data} = await axios.get("organismos/" + organismo + "/sectores")
                this.sectoresOrganismo = data.data
            }
        }
	}
</script>
