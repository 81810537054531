<template>
	<div>		
		<div class="card recibo-haberes">  			
  			<div class="recibo-haberes__titulo">
	    		<h5 class="card-title recibo-haberes__concepto">Beneficio {{beneficio.beneficio_id | formatearBeneficio }}</h5>
	    		<div v-if="valoresTransaccional">
	    			<p>Total: $ {{ valoresTransaccional.monto_total }} </p>
	    			<p>50%:  $ {{ valoresTransaccional.monto_cincuenta }} en 1 cuota de $ {{ valoresTransaccional.cuota_cincuenta }}</p>
	    			<p>60%:  $ {{ valoresTransaccional.monto_sesenta }} en 6 cuotas de $ {{ valoresTransaccional.cuota_sesenta }}</p>
	    			<p>70%:  $ {{ valoresTransaccional.monto_setenta }} en 12 cuotas de $ {{ valoresTransaccional.cuota_setenta }}</p>

	    		</div>

	    	</div>
    	</div>		
    </div>
</template>

<script>
	
	export default {
		data () {
			return {
				valoresTransaccional: null,
				errores: null,
			
			}
		},
		created: function(){
			axios.get('beneficio-transaccional/' + this.beneficio.beneficio_id)
			.then((response) => {
				this.valoresTransaccional = response.data
			})
			.catch((error) => {
			})
		},		
		props: [
			'beneficio'
		]	
	}

</script>