<template>
  <div>
    <nav class="navbar fixed-top navbar-expand-xl header-app">
      <a class="navbar-brand d-flex align-items-center icono-app">
        <img alt="Escudo Caja de Retiros, Jubilaciones y Pensiones de la Policía Federal"
          src="https://micaja.crjppf.gov.ar/imagenes/logo-caja.png" height="70" class="logo-caja">
        <div class="titulo-app">
          <h1 class="titulo-app__principal">Mi Caja</h1>
          <h2 class="titulo-app__secundario">Caja de Retiros, Jubilaciones <br> y Pensiones de la Policía Federal</h2>
        </div>
      </a>

      <button class="btn btn-menu-principal text-white d-lg-none icon-menu" v-if="loggedIn">
        <router-link class="nav-link h-100 align-self-center nav-link__header" :to="{ name: 'MisNotificaciones' }"><i
            class="far fa-bell"></i> <span v-if="notificaciones.length > 0"
            class="badge badge-light">{{ notificaciones.length }}</span></router-link>
      </button>

      <button class="btn btn-menu-principal icon-menu" id="hamburger_menu" type="button"
        data-target=".navbarPrincipalApp" aria-controls="navbarPrincipalApp" aria-expanded="false"
        aria-label="Toggle navigation" @click="menu = !menu" :class="{ collapsed: !menu }">
        <span class="menu" id="menu" v-if="!menu">
          <i class="fas fa-bars"></i>
        </span>
        <span class="cerrar" id="cerrar" v-else>
          <i class="fas fa-times"></i>
        </span>
      </button>

      <div class="collapse navbar-collapse h-100 justify-content-end navbarPrincipalApp" :class="{ show: menu }">
        <ul class="navbar-nav ml-auto h-100">
          <template v-if="loggedIn" class="dropdown-menu">
            <li class="nav-item nav-item__subrayado">
              <router-link class="nav-link h-100 align-self-center nav-link__header"
                :to="{ name: 'Inicio' }">Inicio</router-link>
            </li>
            <li class="nav-item nav-item__subrayado" v-if="mostrarABeneficiarios">
              <router-link class="nav-link h-100 align-self-center nav-link__header" :to="{ name: 'MisTramitesMenu' }"
                role="button">Mis Trámites</router-link>
            </li>
            <li class="nav-item nav-item__subrayado" v-if="mostrarMisAplicaciones">
              <router-link class="nav-link h-100 align-self-center nav-link__header" :to="{ name: 'MisAplicacionesMenu' }"
                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mis
                Aplicaciones</router-link>
            </li>
            <li class="nav-item dropdown nav-item__subrayado" v-if="mostrarMisDocumentos">
              <router-link class="nav-link h-100 align-self-center nav-link__header"
                :to="{ name: 'MisDocumentosMenu' }">Mis Documentos</router-link>
            </li>
            <li class="nav-item nav-item__subrayado" v-if="loggedIn && mostrarABeneficiarios">
              <router-link class="nav-link h-100 align-self-center nav-link__header"
                :to="{ name: 'BeneficioSube' }">Beneficio SUBE</router-link>
            </li>
            <li class="nav-item nav-item__subrayado">
              <router-link class="nav-link h-100 align-self-center nav-link__header"
                :to="{ name: 'MisNotificaciones' }">Mis Notificaciones <span v-if="notificaciones.length > 0"
                  class="badge badge-light">{{ notificaciones.length }}</span></router-link>
            </li>
            <li class="nav-item dropdown nav-item__subrayado">
              <router-link class="nav-link dropdown-toggle align-self-center nav-link__header" to="" id="menuMiPerfil"
                role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mi Caja</router-link>
              <div class="dropdown-menu" aria-labelledby="menuMiPerfil">
                <router-link class="nav-link dropdown-item submenu" :to="{ name: 'MisConsultas' }"
                  v-if="mostrarMisConsultas">Mis Consultas</router-link>
                <router-link class="nav-link dropdown-item submenu" :to="{ name: 'MiPerfil' }">Mi Perfil</router-link>
                <a class="nav-link dropdown-item submenu" href="#" @click="salir">Salir</a>
              </div>
            </li>
          </template>
          <template v-else class="dropdown-menu">
            <li class="nav-item nav-item__subrayado menu-collapse">
              <router-link class="nav-link h-100 align-self-center nav-link__header"
                :to="{ name: 'Ingresar' }">Ingresar<span class="sr-only">(current)</span></router-link>
            </li>
            <li class="nav-item nav-item__subrayado menu-collapse">
              <router-link class="nav-link h-100 align-self-center nav-link__header"
                :to="{ name: 'Registrarse' }">Registrarse</router-link>
            </li>
            <li class="nav-item nav-item__subrayado menu-collapse">
              <router-link class="nav-link h-100 align-self-center nav-link__header"
                :to="{ name: 'CapacitacionFormulario' }">Capacitaciones</router-link>
            </li>

          </template>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data: () => {
    return {
      menu: false
    }
  },
  watch: {
    $route(to, from) {
      this.menu = false
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.user.loggedIn,
      mostrarMisDocumentos: state => state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO', 'EMPLEADO'].includes(r)),
      mostrarMisConsultas: state => state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO'].includes(r)),
      mostrarMisBeneficios: state => state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO', 'EMPLEADO'].includes(r)),
      mostrarABeneficiarios: state => state.user.roles.includes('BENEFICIARIO'),
      mostrarMisAplicaciones: state => state.user.roles.some(r => ['EMPLEADO', 'USUARIO', 'GENDARMERIA', 'PENITENCIARIA'].includes(r)) && state.user.aplicaciones.some(a => a.aplicacion !== 'MIS-RECIBOS')
    }),
    ...mapGetters({
      notificaciones: 'notificaciones/getNotificacionesSinLeer'
    })
  },
  methods: {
    salir() {
      this.$store.dispatch('salir').then(() => {
        this.$store.dispatch('setGuest', 'isGuest');
        this.$router.push({ name: 'Salir' });
      });
    }
  }
};
</script>

<style scoped>
.icon-menu {
  font-size: 1.2em;
}
</style>