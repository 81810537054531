<template>
    <div class=" container  pb-4">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesGestionNovedadesMenu'}">Gestion De Novedades</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Crear Novedad</li>
            </ol>
        </nav>

        <h2 class="titulo-aplicacion">Datos de publicacion <span class="email_icons"><i class="fas fa-newspaper"></i></span></h2>
        <br/>
        <div class="nueva_publicacion_form_card  card p-5">
        <form class="nueva_publicacion_form"  @submit.prevent v-on:keyup.right.prevent="siguiente_pantalla">
            <div>
                <div class="row">
                    <div class="col-12 my-2">
                        <h3>Titulo: </h3>
                        <small class="nueva_publicacion_form_error" v-if= error_titulo>*obligatorio</small>
                        <input type="text" class="col-12" id="titulo_novedad" aria-describedby="listaHelp" placeholder="Ej: Haberes Noviembre 2023" v-model="titulo_novedad">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 my-2">
                        <h4>Descripcion: (opcional) </h4>
                        <input type="text" class="col-12" id="descripcion_novedad" aria-describedby="listaHelp" placeholder="Información sobre los haberes de Noviembre 2023" v-model="descripcion_novedad">
                    </div>
                </div>
            </div>
        </form>
        <div class="row ">
            <h4 class="ml-3">Mensaje</h4>
            <small class="nueva_publicacion_form_error" v-if= error_cuerpo>*obligatorio</small>
        </div>
        <VueEditor  
        v-model="cuerpo_novedad"
        
        ></VueEditor>
        <h4 class="mt-2">Seleccione la imagen:</h4>
        <small class="nueva_publicacion_form_error" v-if= error_portada>*obligatorio</small>
        <vueDropzone 
            id="subida_imagen_novedad" 
            :options="dropzoneOptions"
            ref="dropzone_novedad"
            v-on:vdropzone-file-added="guardarImagenPortada"
           
            >
        </vueDropzone>
        <div class="ml-1" v-for="error in errors">
            <ul>
                <li style="color:red;"><b><small>{{ error }}</small></b></li>
            </ul>
        </div>
        <div class="w-100 mt-4 mb-4">
            <button class="btn btn-primary boton-envio mb-4 " @click="enviarNovedad" >Publicar</button>
        </div>
    </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import vue2Dropzone from 'vue2-dropzone';
import {mapActions} from "vuex";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default{
    data(){
        return{
            titulo_novedad:"",
            descripcion_novedad:"",
            cuerpo_novedad:"",
            errors:[],
            error_portada:false,
            error_titulo:false,
            error_cuerpo:false,
            image_file_novedad:null,
            formContent: new FormData(),
            dropzoneOptions: {
                dictDefaultMessage: '<p><i class="fas fa-image fa-2x"></i> <br/>Arrastre la imagen o haga click para subir un archivo local</p>',
                url: 'api/envio-mails/listas/subir_csv',
                acceptedFiles: ".png, .jpg, .jpeg",
                createImageThumbnails: true,
                autoProcessQueue: false,
                maxFiles:1,
                method: "post",
                addRemoveLinks: true,
                chunking: false,
                headers: {
                    'X-XSRF-TOKEN': $cookies.get("XSRF-TOKEN")
                }
            },
        }
    },
    components:{
        VueEditor ,
        vueDropzone: vue2Dropzone,
    },

    methods: {
        ...mapActions('novedades', [
            'agregarNovedad'
        ]),
        async enviarNovedad(){

            this.error_portada = this.image_file_novedad == null ? true: false ;
            this.error_titulo = this.titulo_novedad == "" ? true : false ;
            this.error_cuerpo = this.cuerpo_novedad == "" ? true : false ;
           
            if((!this.error_cuerpo && !this.error_portada && !this.error_titulo)){
                let formDataNovedad = new FormData();
                formDataNovedad.append("imagen_portada",this.image_file_novedad);
                formDataNovedad.append('titulo', this.titulo_novedad);
                formDataNovedad.append('cuerpo' , this.cuerpo_novedad);
                formDataNovedad.append('id_categoria', 1);
                formDataNovedad.append('id_tema', 1);
                if(this.descripcion_novedad!==""){
                    formDataNovedad.append('descripcion' , this.descripcion_novedad);
                }
               const result = this.agregarNovedad(formDataNovedad);
                if(result.message==null){
                    this.$swal.fire({
                    title: "La publicacion fue generada con exito ",
                    icon: 'success',
                    showConfirmButton: false,
                    timer:3500
                    
                }).then(response => this.$router.push({name: 'MisAplicacionesGestionNovedadesMenu'}))
                }else{
                    this.$swal.fire({
                            title: "Debe Seleccionar al menos una lista, o generar una lista nueva",
                            icon: 'error',
                            showConfirmButton: false,
                            timer:3500
                           
                        })
                };
            }
        },
        
        guardarImagenPortada(file){
            this.image_file_novedad = file;
        }       
    },
}
</script>
<style scoped>
.boton-envio{
    float: right;
    max-width: 20%;
    min-width: 30%;
    padding-right: 5%;
}
.nueva_publicacion_form_error{
    color:red;
    font-weight:  bold;
}
.nueva_publicacion_form_card{
    border-radius: 2rem;
    background-color: rgba(233, 233, 233, 0.8);
}
</style>
