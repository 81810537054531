const accederPublicacionesMenu = async () => {
    
    axios.get('publicaciones-edicion/acceder')
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return false;
    });
}

const traerPublicacionesParaEdicion =  async() =>{
    return await axios.get('publicaciones-edicion/todas')
}


const traerPublicacionPorID= async (id) =>{
    return await axios.get(`publicaciones/${id}/buscar`)
}

const  obtenerPublicaciones = async () => {
    return await axios.get('publicaciones/portada')
}

const eliminarPublicaciones = async (id) => {
    return await axios.delete(`publicaciones-edicion/${id}/eliminar`)
}

const crearPublicacion = async(novedad) =>{
    return await axios.post("publicaciones-edicion/crear", novedad); 
}

const editarPublicacion =async (formData) => {
    
    return await axios.post(`publicaciones-edicion/actualizar`, formData);
}


export {
    accederPublicacionesMenu,
    traerPublicacionesParaEdicion,
    obtenerPublicaciones,
    eliminarPublicaciones,
    crearPublicacion,
    editarPublicacion,
    traerPublicacionPorID,
}
