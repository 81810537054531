const getRecibosPorRango = async (payload) => {
    return axios.post("administrador-haberes/buscar-rango" , payload)
}
const getOpcionesDeBusquedaPermitidas = async () => {
    return axios.get("administrador-haberes/opciones-busqueda")
}
const obtenerFechaPrimerRecibo = async() => {
    return axios.get("administrador-haberes/fecha-primera-subida")
}
export {
    getRecibosPorRango,
    getOpcionesDeBusquedaPermitidas,
    obtenerFechaPrimerRecibo
}
