<template>
    <div>
        <p>Estado: <span class="text-success">Activo</span></p>
        <div class="card">
        <p class="beneficio_sube_card_body_text">Ya tenés asignada la Tarifa Social Federal en transporte en tu Tarjeta SUBE.</p>
        <p class="beneficio_sube_card_body_text">Si diste de alta el beneficio, por unica vez debes apoyar tu tarjeta SUBE en una <a href="https://tarjetasube.sube.gob.ar/SubeWeb/WebForms/admin/views/mapa-sube.aspx?id=4" target="_blank">Terminal Automática</a> o consultar saldo desde la <a href="https://play.google.com/store/apps/details?id=com.sube.app&hl=es_AR&gl=US&pli=1" target="_blank">App SUBE</a> (celular Andriod 6 o superior y tecnologia NFC) para que el beneficio se haga efectivo en tu tarjeta.</p>
    </div>
        <button class="btn btn-danger mt-3 boton_alta" @click="darDeBajaBeneficio">Dar de baja beneficio SUBE</button>
    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex';

export default{
    
    computed: {
        ...mapState({
        numeroDocumento: state => state.persona.numero_documento,
        tipoDocumento: state => state.persona.tipo_documento,
        }),
    },
    methods: {
        ...mapActions('gestionbeneficiosube', [
            'deshabilitarBeneficioSube',
        ]),
        darDeBajaBeneficio() {
            this.$swal.fire({
            title: 'Dar de Baja Beneficio SUBE',
            text: '¿Está seguro que desea dar de baja el beneficio SUBE?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Dar de Baja',
            cancelButtonText: 'Cancelar',
            customClass: {
            confirmButton: 'btn btn-danger rounded-pill',
            cancelButton: 'btn btn-secondary rounded-pill',
            },
            buttonsStyling: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deshabilitarBeneficioSube({ "tipo_documento": this.tipoDocumento, "numero_documento": this.numeroDocumento })
                }
            })
        }
    }
}
</script>