<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesOperacionesMenu'}">Operaciones</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Sube</li>
            </ol>
        </nav>
        <h1 class="titulo-aplicacion titulo_gestion_comple">Administracion de Beneficio Sube</h1>

        <menu-administracion-sube></menu-administracion-sube>
        

    </div>
</template>

<script>
import AdministracionBeneficioSubeMenu from "./AdministracionBeneficioSubeMenu.vue";

export default {
    components: {
        "menu-administracion-sube": AdministracionBeneficioSubeMenu
    },
}
   
</script>

<style scoped>
.titulo_gestion_comple{
    text-align: center;
}

.aplicacionesContainer{
    margin-top:50px;
}

</style>
