<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Operaciones</li>
            </ol>
        </nav>
        <h2 class="titulo-aplicacion">Operaciones  <i class="fas fa-clipboard-list"></i></h2>

        <div class="alert alert-success alert-dismissible fade show my-2" role="alert" v-if="mensaje !== ''">
            {{mensaje}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="mensaje = ''">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="row">
            <router-link v-if="esAdministrador" class="col-12 col-sm-6 col-lg-4 mb-4" to="#" style="text-decoration: none" v-on:click.native="procesarBajasCertificadosHaberes" >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="far fa-file-pdf fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Procesar bajas de certificados de haberes</h5>
                        <p class="card-text" v-if="procesarBajasCertHab == '0'">Hacé click para procesar las bajas.</p>
                        <p class="card-text" v-else-if="procesarBajasCertHab == 1">Cargando las bajas <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></p>
                        <p class="card-text" v-else-if="procesarBajasCertHab == '2'">Proceso finalizado.</p>
                        <p class="card-text" v-else>{{ procesarBajasCertHab }}</p>
                    </div>
                </div>
            </router-link>

            <router-link v-if="esAdministrador" class="col-12 col-sm-6 col-lg-4 mb-4" :to="{name: 'MisAplicacionesOperacionesOrganismosBuscar'}" style="text-decoration: none" >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-city fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Gestion de organismos</h5>
                        <p class="card-text text-muted">Gestion organismos asociados a la caja</p>
                    </div>
                </div>
            </router-link>

            <router-link class="col-12 col-sm-6 col-lg-4 mb-4" :to="{name: 'MisAplicacionesOperacionesGestionComplementariasContenedor'}" style="text-decoration: none" >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-user-plus fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Gestion de Recibos de Complementarias </h5>
                        <p class="card-text" >Carga y Control de Complementarias </p>
                    </div>
                </div>
            </router-link>

            <div class="col-12 col-sm-6 col-lg-4 mb-4"  style="text-decoration: none" @click="openDialog" >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fa fa-database fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Procesar Margen de Afectacion</h5>
                        <p class="card-text" v-if="procesarAfectacion == '0'">Click para Cargar Nueva Tabla de Afectacion</p>
                        <p class="card-text" v-else-if="procesarAfectacion == 1">Cargando el proceso <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></p>
                        <p class="card-text" v-else-if="procesarAfectacion == '2'">Proceso finalizado. La tabla se actualizará 72 hs antes de fecha de cobro</p>
                        <p class="card-text" v-else>{{ procesarAfectacion }}</p>
                    </div>
                </div>
            </div>

             <carga-afectacion v-if=showModal @close="showModal=false"></carga-afectacion>

             <router-link class="col-12 col-sm-6 col-lg-4 mb-4" :to="{name: 'AdministracionBeneficioSubeMenu'}" style="text-decoration: none" >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-user-plus fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Administracion Sube</h5>
                        <p class="card-text" >Bajada y Subida de Beneficios </p>
                    </div>
                </div>
            </router-link>

            
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import cargaAfectacion from "./margen-de-afectacion/carga-afectacion.vue";

export default {
    name: "MisAplicacionesOperacionesMenu",
    components:{
         cargaAfectacion: cargaAfectacion 
    },

    data() {
        return {
            generarListadoCertHabText: '0',
            procesarBajasCertHab: '0',
            mensaje: '',
            mensajeComple:"",
            estadoEnvioRecibos: "0",
            afectacion_chunks: [],
            showModal : false,
            uploaded: 0,
            afectacion_file: null,
            procesarAfectacion:0
        }
    },

    watch: {
            afectacion_chunks(n, o) {
                if (n.length > 0) {
                    this.upload();
                }
            }
    },

    computed: 
    mapState({
        esAdministrador: state => {
            return state.user.roles.includes('ADMINISTRADOR')
        },
       /*  progress() {
                return Math.floor((this.uploaded * 100) / this.afectacion_file.size);
            },

        formData() {
            let formData = new FormData;

            formData.set('is_last', this.afectacion_chunks.length === 1);
            formData.set('file', this.afectacion_chunks[0], `${this.afectacion_file.name}.part`);

            return formData;
        },
        config() {
                return {
                    method: 'POST',
                    data: this.formData,
                    url: 'cargar-nueva-tabla-afectacion',
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                    onUploadProgress: event => {
                        this.uploaded += event.loaded;
                    }
                };
        } */
    }),


    methods: {

       /*  createChunks() {
            let size = 2048, chunks = Math.ceil(this.afectacion_file.size / size);

            for (let i = 0; i < chunks; i++) {
                this.afectacion_chunks.push(this.afectacion_file.slice(
                    i * size, Math.min(i * size + size, this.afectacion_file.size), this.afectacion_file.type
                ));
            }
        },

        upload() {
                axios(this.config).then(response => {
                    this.afectacion_chunks.shift();
                }).catch(error => {
                });
            },
            */
        openDialog(){
            this.showModal = true;
        }, 
        
        async procesarNuevaAfectacion(){
            const { value: file } = await this.$swal.fire({
                title: 'Seleccione el archivo con los nuevos margenes',
                input: 'file',
                inputAttributes: {
                    'accept': '.txt',
                }
            })
            if(file){
                this.procesarAfectacion = "1"
                this.afectacion_file = file;
                this.createChunks();

            }
        },

        async procesarBajasCertificadosHaberes(){
            const { value: file } = await this.$swal.fire({
                title: 'Seleccione el archivo de bajas',
                input: 'file',
                inputAttributes: {
                    'accept': '.txt',
                }
            })
    
            if (file) {
                this.procesarBajasCertHab = '1'
                const formData = new FormData();
                formData.append("archivo", file)
                axios.post('cargar-bajas-certificados', formData)
                    .then((response) => {
                        this.procesarBajasCertHab = '2'
                        this.mensaje = response.data.data
                    })
                    .catch((error) => {
                        if(error.response.status >= 500){
                            this.procesarBajasCertHab = error.response.data.message
                        }else{
                            this.procesarBajasCertHab = error.response.data
                        }
                    })
            }
        },     
        
    }
}
</script>

<style scoped>


</style>
