<template>
    <div class=" w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Gestion De Novedades</li>
            </ol>
        </nav>
        <h2 class="titulo-aplicacion">Publicación de Novedades <span class="email_icons"><i class="fas fa-newspaper"></i></span></h2>
        
        <router-link :to="{name: 'MisAplicacionesGestionNovedadesNueva'}">
            <button tag="button" class="btn btn-primary btn_complementarias w-100">
                <i class="fas fa-plus fa-2x icono-micaja"></i><br>
                Publicar Novedad
            </button>
        </router-link>
        <dashboard>  </dashboard>
    </div>

</template>
<script>
import Dashboard from './Dashboard.vue';

export default {
    data(){
        return{

        }
    },
    components:{
        dashboard:Dashboard
    }
}

</script>