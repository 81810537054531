<template>
    <div class="page-container">
        <header class="header">
            <app-header></app-header>
        </header>

        <main id="main" class="main">
            <transition name="main-fade" mode="out-in" :duration="{ enter: 500, leave: 0 }">
                <router-view></router-view>
            </transition>
        </main>

        <footer class="footer">
            <app-footer></app-footer>
        </footer>
    </div>
</template>

<script>
    import Header from './header/Header.vue'
    import Footer from './footer/Footer.vue'

    export default {
        components: {
            'app-header': Header,
            'app-footer': Footer
        }
    };
</script>


