import {
    leerNotificaciones,
    obtenerNotificaciones,
    obtenerNotificacionesSinleer
} from "../../../api/notificaciones";

const state = {
    notificaciones: [],
    notificacion: null
};

const getters = {
    getNotificacionesSinLeer(state) {
        return state.notificaciones.filter(n => n.read_at == null)
    }
};

const mutations = {
    guardarNotificaciones(state, data) {
        state.notificaciones = [...data]
    },
    eliminarNotificaciones(state) {
        state.notificaciones = []
        state.notificacion = null
    }
};

const actions = {
    async obtenerNotificaciones({commit}) {
        const { data } = await obtenerNotificaciones()
        commit('guardarNotificaciones', data.notificaciones)
    },
    async leerNotificaciones({commit}) {
        const { data } = await leerNotificaciones()
        commit('guardarNotificaciones', data.notificaciones)
    },
    eliminarNotificaciones({commit}) {
        commit('eliminarNotificaciones')
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
