import Vue from "vue";
import VueRouter from 'vue-router'
import store from './store/store'

Vue.use(VueRouter)

/** INGRESO USUARIOS */
import Ingresar from './components/ingresar/Ingresar.vue'
import Salir from './components/salir/Salir.vue'

/** REGISTRO USUARIOS */
import Registrarse from './components/registrarse/Registrarse.vue'
import RegistroEmpleados from './components/registrarse/RegistroEmpleados.vue'
import VerificarCuenta from './components/registrarse/VerificarCuenta'
import BeneficioSube  from "./components/aplicaciones/beneficioSube/BeneficioSube.vue"
/** GESTION DE CONTRASEÑAS */
import solicitudRecuperarPassword from './components/password/SolicitudRecuperarPassword'
import ReestablecerPassword from './components/password/ReestablecerPassword'

/** ERRORES */
import Error404 from './components/errores/Error404.vue'

import Contenedor from './components/Contenedor.vue'
import Inicio from './components/inicio/Inicio.vue'
import MiPerfil from './components/mi-perfil/MiPerfil.vue'
import MisConsultas from './components/aplicaciones/mis-consultas/MisConsultas.vue'

import MisAplicacionesContenedor from './components/aplicaciones/mis-aplicaciones/MisAplicacionesContenedor.vue'
import MisAplicacionesMenu from './components/aplicaciones/mis-aplicaciones/MisAplicacionesMenu.vue'

/* VERIFICACION DE DOCUMENTOS - PARA USO SIN LOGUEO */
import VerificacionDocumentos from './components/aplicaciones/verificaciones/VerificacionDocumentos.vue'

/* VERIFICACION DE CUENTA PARA ALTAS AUTOMATICAS */
import VerificacionAlta from "./components/aplicaciones/verificaciones/VerificacionAlta";

/* INSCRIPCION A CAPACITACION - USO PUBLICO */
import CapacitacionFormulario from "./components/capacitacion/FormularioCapacitacion.vue";
import ConfirmacionInscripcion from "./components/capacitacion/ConfirmacionInscripcion.vue";


/** MIS DOCUMENTOS */
import MisDocumentosContenedor from './components/aplicaciones/mis-documentos/MisDocumentosContenedor.vue'
import MisDocumentosMenu from './components/aplicaciones/mis-documentos/MisDocumentosMenu.vue'

/** MIS DOCUMENTOS - MIS RECIBOS DE HABERES */
import MisRecibos from './components/aplicaciones/mis-documentos/mis-recibos/MisRecibos.vue'

/** MIS DOCUMENTOS - MIS CERTIFICADOS DE GANANCIAS */
import MisCertificadosGanancias
    from './components/aplicaciones/mis-documentos/mis-certificados-ganancias/MisCertificadosGanancias.vue'

/** MIS DOCUMENTOS - MIS CERTIFICADOS DE HABERES */
import MisCertificadosHaberes
    from './components/aplicaciones/mis-documentos/mis-certificados-haberes/MisCertificadosHaberes.vue'

/** MIS DOCUMENTOS - MIS CERTIFICADOS DE COMPLEMENTARIAS **/
import MisCertificadosComplementarias
    from "./components/aplicaciones/mis-documentos/mis-certificados-complementarias/MisCertificadosComplementarias.vue";

/** MIS APLICACIONES - MIS TRAMITES */
import MisAplicacionesTramitesADistanciaContenedor
    from './components/aplicaciones/mis-aplicaciones/tramites-a-distancia/MisAplicacionesTramitesADistanciaContenedor.vue'
import MisAplicacionesTramitesADistanciaMenu
    from './components/aplicaciones/mis-aplicaciones/tramites-a-distancia/MisAplicacionesTramitesADistanciaMenu.vue'
import MisAplicacionesTramitesADistanciaNovedades
    from './components/aplicaciones/mis-aplicaciones/tramites-a-distancia/MisAplicacionesTramitesADistanciaNovedades.vue'
import MisAplicacionesTramitesADistanciaCertificadosHaberesContenedor
    from './components/aplicaciones/mis-aplicaciones/tramites-a-distancia/certificados-haberes/MisAplicacionesTramitesADistanciaCertificadosHaberesContenedor.vue'
import MisAplicacionesTramitesADistanciaCertificadosHaberesNuevo
    from './components/aplicaciones/mis-aplicaciones/tramites-a-distancia/certificados-haberes/MisAplicacionesTramitesADistanciaCertificadosHaberesNuevo.vue'
import MisAplicacionesTramitesADistanciaCertificadosHaberesBuscar
    from './components/aplicaciones/mis-aplicaciones/tramites-a-distancia/certificados-haberes/MisAplicacionesTramitesADistanciaCertificadosHaberesBuscar.vue'

/** MIS APLICACIONES - PUNTO A PUNTO */
import PuntoAPuntoContenedor from './components/aplicaciones/mis-aplicaciones/punto-a-punto/PuntoAPuntoContenedor.vue'
import PuntoAPuntoNovedades from './components/aplicaciones/mis-aplicaciones/punto-a-punto/novedades/Novedades.vue'
import PuntoAPuntoNueva from './components/aplicaciones/mis-aplicaciones/punto-a-punto/nueva/Nueva.vue'
import PuntoAPuntoBuscar from './components/aplicaciones/mis-aplicaciones/punto-a-punto/buscar/Buscar.vue'
import PuntoAPuntoEnviadas from './components/aplicaciones/mis-aplicaciones/punto-a-punto/enviadas/Enviadas.vue'
import PuntoAPuntoRecibidas from './components/aplicaciones/mis-aplicaciones/punto-a-punto/recibidas/Recibidas.vue'
import PuntoAPuntoArchivadas from './components/aplicaciones/mis-aplicaciones/punto-a-punto/archivadas/Archivadas.vue'
import PuntoAPuntoDocumentacion
    from './components/aplicaciones/mis-aplicaciones/punto-a-punto/documentacion/Documentacion.vue'

/** MIS APLICACIONES - GESTION BENEFICIARIOS */
import GestionBeneficiariosContenedor
    from './components/aplicaciones/mis-aplicaciones/gestion-beneficiarios/Contenedor.vue'
import GestionBeneficiariosBuscar from './components/aplicaciones/mis-aplicaciones/gestion-beneficiarios/Buscar.vue'

/** MIS APLICACIONES - USUARIOS */
import MisAplicacionesUsuariosContenedor
    from './components/aplicaciones/mis-aplicaciones/usuarios/MisAplicacionesUsuariosContenedor.vue'
//import MisAplicacionesUsuariosMenu from './components/aplicaciones/mis-aplicaciones/usuarios/MisAplicacionesUsuariosMenu.vue'

/** MIS APLICACIONES - USUARIOS - ORGANISMOS */
import MisAplicacionesUsuariosOrganismosContenedor
    from './components/aplicaciones/mis-aplicaciones/usuarios/organismos/MisAplicacionesUsuariosOrganismosContenedor.vue'
import MisAplicacionesUsuariosOrganismosBuscar
    from './components/aplicaciones/mis-aplicaciones/usuarios/organismos/MisAplicacionesUsuariosOrganismosBuscar.vue'
import MisAplicacionesUsuariosOrganismosNuevo
    from './components/aplicaciones/mis-aplicaciones/usuarios/organismos/MisAplicacionesUsuariosOrganismosNuevo.vue'

/** MIS APLICACIONES - USUARIOS - BENEFICIARIOS */
import MisAplicacionesUsuariosBeneficiariosContenedor
    from './components/aplicaciones/mis-aplicaciones/usuarios/beneficiarios/MisAplicacionesUsuariosBeneficiariosContenedor.vue'
import MisAplicacionesUsuariosBeneficiariosBuscar
    from './components/aplicaciones/mis-aplicaciones/usuarios/beneficiarios/MisAplicacionesUsuariosBeneficiariosBuscar.vue'

/** MIS APLICACIONES - USUARIOS - EMPLEADOS */
import MisAplicacionesUsuariosEmpleadosContenedor
    from './components/aplicaciones/mis-aplicaciones/usuarios/empleados/MisAplicacionesUsuariosEmpleadosContenedor.vue'
import MisAplicacionesUsuariosEmpleadosBuscar
    from './components/aplicaciones/mis-aplicaciones/usuarios/empleados/MisAplicacionesUsuariosEmpleadosBuscar.vue'
import MisAplicacionesUsuariosEmpleadosNuevo
    from './components/aplicaciones/mis-aplicaciones/usuarios/empleados/MisAplicacionesUsuariosEmpleadosNuevo.vue'
import MisAplicacionesUsuariosEmpleadosEliminados
    from './components/aplicaciones/mis-aplicaciones/usuarios/empleados/MisAplicacionesUsuariosEmpleadosEliminados.vue'

/** MIS APLICACIONES - OPERACIONES */
import MisAplicacionesOperacionesContenedor
    from './components/aplicaciones/mis-aplicaciones/operaciones/MisAplicacionesOperacionesContenedor.vue'
import MisAplicacionesOperacionesMenu
    from "./components/aplicaciones/mis-aplicaciones/operaciones/MisAplicacionesOperacionesMenu.vue";

/** MIS APLICACIONES - OPERACIONES - ORGANISMOS */
import MisAplicacionesOperacionesOrganismosContenedor
    from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-organismos/MisAplicacionesOperacionesOrganismosContenedor.vue";

import MisAplicacionesOperacionesOrganismosBuscar
    from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-organismos/organismos/MisAplicacionesOperacionesOrganismosBuscar";
import MisAplicacionesOpereacionesOrganismosNuevo
    from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-organismos/organismos/MisAplicacionesOpereacionesOrganismosNuevo";
import MisAplicacionesOperacionesOrganismosEditar
    from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-organismos/organismos/MisAplicacionesOperacionesOrganismosEditar";
import MisAplicacionesOperacionesOrganismosSedeBuscar
    from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-organismos/sedes/MisAplicacionesOperacionesOrganismosSedeBuscar";
import MisAplicacionesOperacionesOrganismosSectorBuscar
    from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-organismos/sector/MisAplicacionesOperacionesOrganismosSectorBuscar";
import MisAplicacionesOperacionesOrganismosSubsectorBuscar
    from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-organismos/subsectores/MisAplicacionesOperacionesOrganismosSubsectorBuscar";

/** MIS APLICACIONES - OPERACIONES - GESTION DE COMPLEMENTARIAS */
import MisAplicacionesOperacionesGestionComplementariasContenedor
from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-complementarias/MisAplicacionesOperacionesGestionComplementariasContenedor.vue";
import EnvioNotificaciones
from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-complementarias/envio-notificaciones/EnvioNotificaciones.vue";
import CargaComplementariasRecibos
from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-complementarias/carga-recibos/CargaComplementariasRecibos.vue";
import AltasWebComplementarias
from "./components/aplicaciones/mis-aplicaciones/operaciones/gestion-complementarias/altas-web/AltasWebComplementarias.vue";


/** ADMINISTRACION SUBE */
import MisAplicacionesOperacionesAdministracionBeneficioSubeContenedor
 from "./components/aplicaciones/mis-aplicaciones/operaciones/administracion-beneficio-sube/MisAplicacionesOperacionesAdministracionBeneficioSubeContenedor.vue";
import AdministracionBeneficioSubeMenu from "./components/aplicaciones/mis-aplicaciones/operaciones/MisAplicacionesOperacionesMenu.vue";


/** MIS TRAMITES */
import MisTramitesContenedor from './components/aplicaciones/mis-tramites/MisTramitesContenedor.vue'
import MisTramitesMenu from './components/aplicaciones/mis-tramites/MisTramitesMenu.vue'

/** MIS TRAMITES - CERTIFICADOS DE HABERES */
import CertificadosHaberesContenedor
    from './components/aplicaciones/mis-tramites/certificados-haberes/CertificadosHaberesContenedor.vue'
import CertificadosHaberesMenu
    from './components/aplicaciones/mis-tramites/certificados-haberes/CertificadosHaberesMenu.vue'
import CertificadosHaberes from './components/aplicaciones/mis-tramites/certificados-haberes/CertificadosHaberes.vue'
import CertificadosHaberesNuevo
    from './components/aplicaciones/mis-tramites/certificados-haberes/CertificadosHaberesNuevo.vue'
// import CertificadosHaberesSolicitarAnulacion from './components/aplicaciones/mis-tramites/certificados-haberes/CertificadosHaberesSolicitarAnulacion.vue'
import CertificadosHaberesFAQ
    from './components/aplicaciones/mis-tramites/certificados-haberes/CertificadosHaberesFAQ.vue'

/** COMPLES Y SUPLES */
import DptoLiquidacionesContenedor from './components/aplicaciones/mis-aplicaciones/dpto-liquidaciones/Contenedor.vue'
import DptoLiquidacionesRecibosComplesBuscar
    from './components/aplicaciones/mis-aplicaciones/dpto-liquidaciones/recibos-comples/Buscar.vue'

/** RED DE BENEFICIOS */
import MisBeneficios from './components/aplicaciones/mis-beneficios/MisBeneficios.vue'
import RedBeneficios from './components/aplicaciones/mis-beneficios/RedBeneficios.vue'

/** PROGRAMA TRANSACCIONAL */
import ProgramaTransaccional from './components/aplicaciones/programa-transaccional/ProgramaTransaccional.vue'

/** MIS NOTIFICACIONES **/
import MisNotificaciones from "./components/aplicaciones/mis-notificaciones/MisNotificaciones.vue";

import MisAplicacionesNotificacionesContenedor
    from "./components/aplicaciones/mis-aplicaciones/notificaciones/MisAplicacionesNotificacionesContenedor.vue";
import MisAplicacionesNotificacionesMenu
    from "./components/aplicaciones/mis-aplicaciones/notificaciones/MisAplicacionesNotificacionesMenu.vue";
import MisAplicacionesNotificaciones
    from "./components/aplicaciones/mis-aplicaciones/notificaciones/MisAplicacionesNotificaciones.vue";

/*ENVIO DE MAILS */
//import EnvioDeMails from "./components/aplicaciones/mis-aplicaciones/envio-mails/MisAplicacionesEnvioDeMails.vue";
/* import MisAplicacionesEnvioDeMailsContenedor from "./components/aplicaciones/mis-aplicaciones/envio-mails/MisAplicacionesEnvioDeMailsContenedor.vue";
import MisAplicacionesEnvioDeMailsMenu from "./components/aplicaciones/mis-aplicaciones/envio-mails/MisAplicacionesEnvioDeMailsMenu.vue";
import MisAplicacionesEnvioDeMailsBeneficiarios from "./components/aplicaciones/mis-aplicaciones/envio-mails/campanias/beneficiarios/MisAplicacionesEnvioDeMailsBeneficiarios.vue";
import MisAplicacionesEnvioDeMailsPersonal from "./components/aplicaciones/mis-aplicaciones/envio-mails/campanias/personal/MisAplicacionesEnvioDeMailsPersonal.vue"; */

/*PUBLICACION DE NOVEDADES*/
import MisAplicacionesGestionNovedadesContenedor from "./components/aplicaciones/mis-aplicaciones/novedades/MisAplicacionesGestionNovedadesContenedor.vue";
import MisAplicacionesGestionNovedadesMenu from "./components/aplicaciones/mis-aplicaciones/novedades/MisAplicacionesGestionNovedadesMenu.vue"
import MisAplicacionesGestionNovedadesNueva from "./components/aplicaciones/mis-aplicaciones/novedades/nueva/Nueva.vue";
import MisAplicacionesGestionNovedadesEdicion from "./components/aplicaciones/mis-aplicaciones/novedades/edicion/Edicion.vue";
import NovedadesBeneficiariosDetalle from './components/inicio/detalleNoticias/NovedadesBeneficiariosDetalle.vue';

/*GESTION RECIBOS GENDARMERIA*/
import MisAplicacionesGestionRecibosGendarmeriaContenedor from "./components/aplicaciones/mis-aplicaciones/gestion-recibos-gendarmeria/MisAplicacionesGestionRecibosGendarmeriaContenedor.vue";
import MisAplicacionesGestionRecibosGendarmeria from "./components/aplicaciones/mis-aplicaciones/gestion-recibos-gendarmeria/MisAplicacionesGestionRecibosGendarmeria.vue"

/*GESTION RECIBOS PENITENCIARIA*/
import MisAplicacionesGestionRecibosPenitenciariaContenedor from "./components/aplicaciones/mis-aplicaciones/gestion-recibos-penitenciaria/MisAplicacionesGestionRecibosPenitenciariaContenedor.vue";
import MisAplicacionesGestionRecibosPenitenciaria from "./components/aplicaciones/mis-aplicaciones/gestion-recibos-penitenciaria/MisAplicacionesGestionRecibosPenitenciaria.vue"

/*ADMINISTRADOR DE RECIBOS INFO PASIVOS*/
import MisAplicacionesAdministracionRecibosContainer from "./components/aplicaciones/mis-aplicaciones/administrador-recibos-haberes/MisAplicacionesAdministracionRecibosContainer.vue";
import MisAplicacionesAdministracionRecibos from "./components/aplicaciones/mis-aplicaciones/administrador-recibos-haberes/MisAplicacionesAdministracionRecibos.vue";

const routes = [
    {path: '/', redirect: {name: 'Ingresar'}},
    {path: '/ingresar', name: 'Ingresar', component: Ingresar, beforeEnter: (to, from, next) => {
            const storageItem = window.localStorage.getItem("crjppf");
            if(storageItem === "isNotGuest") {
                next({name: 'Inicio'})
            } else {
                next()
            }
        }},
    {path: '/salir', name: 'Salir', component: Salir},
    {path: '/registrarse', name: 'Registrarse', component: Registrarse},
    {path: '/capacitacion-uso-de-tecnologia', name: 'CapacitacionFormulario', component: CapacitacionFormulario},
    {path: '/capacitacion-uso-de-tecnologia/verificar-token/:token', name: 'ConfirmacionInscripcion', component: ConfirmacionInscripcion},
    {path: '/registro-empleados', name: 'RegistroEmpleados', component: RegistroEmpleados},
    {path: '/verificacion', name: 'VerificacionDocumentos', component: VerificacionDocumentos},
    {path: '/verificar-cuenta/:token', name: 'VerificacionAlta', component: VerificacionAlta},
    {path: '/solicitud-recuperar-password', name: 'solicitudRecuperarPassword', component: solicitudRecuperarPassword},
    {path: '/reestablecer-password', name: 'reestablecerPassword', component: ReestablecerPassword},
    {path: '/mi-caja/verificar-cuenta', name: 'verificarCuenta', component: VerificarCuenta},
    {path: '/mi-caja', component: Contenedor,
        beforeEnter: async (to, from, next) => {
            try {
                const storageItem = window.localStorage.getItem("crjppf");
                if (storageItem !== "isNotGuest") {
                    next({ name: "Ingresar" })
                } else {
                    if(!store.state.user.loggedIn) await store.dispatch("guardarDatosUsuario")
                    if (store.state.user.verificado === 'N') {
                        next({name: 'verificarCuenta'})
                    } else {
                        next()
                    }
                }
            }
            catch (e) {
                await store.dispatch("setGuest", "isGuest");
                next({ name: "Ingresar" });
            }
        },
        children: [
            {path: 'inicio', name: 'Inicio', component: Inicio},
 	    {path: 'noticias/:id_noticia', name: 'DetalleNoticiasBeneficiarios', component: NovedadesBeneficiariosDetalle},
            {path: 'mis-beneficios', name: 'MisBeneficios', component: MisBeneficios},
            //{ path: 'red-beneficios', name: 'RedBeneficios', component: RedBeneficios },
            //{path: 'programa-transaccional', name: 'ProgramaTransaccional', component: ProgramaTransaccional},
            {path: 'mi-perfil', name: 'MiPerfil', component: MiPerfil},
            {path: 'mis-consultas', name: 'MisConsultas', component: MisConsultas},
            {path:'BeneficioSube', name:'BeneficioSube', component : BeneficioSube},
            {path: 'mis-notificaciones', name: 'MisNotificaciones', component: MisNotificaciones},
            {path: 'mis-documentos', name: 'MisDocumentosContenedor', component: MisDocumentosContenedor,
                children: [
                    {path: 'menu', name: 'MisDocumentosMenu', component: MisDocumentosMenu},
                    {path: 'mis-recibos', name: 'MisRecibos', component: MisRecibos},
                    {path: 'mis-certificados-ganancias', name: 'MisCertificadosGanancias', component: MisCertificadosGanancias},
                    {path: 'mis-certificados-haberes', name: 'MisCertificadosHaberes', component: MisCertificadosHaberes},
                    {path: 'mis-certificados-complementarias', name: 'MisCertificadosComplementarias', component: MisCertificadosComplementarias},
                ]
            },
            {path: 'mis-tramites', component: MisTramitesContenedor,
                children: [
                    {path: 'menu', name: 'MisTramitesMenu', component: MisTramitesMenu},
                    {path: 'certificados-haberes', name: 'CertificadosHaberesContenedor', component: CertificadosHaberesContenedor,
                        children: [
                            {path: 'certificados', name: 'CertificadosHaberes', component: CertificadosHaberes},
                            {path: 'nuevo', name: 'CertificadosHaberesNuevo', component: CertificadosHaberesNuevo},
                            {path: 'ayuda', name: 'CertificadosHaberesFAQ', component: CertificadosHaberesFAQ},
                        ]
                    },
                ]
            },
            {path: 'mis-aplicaciones', component: MisAplicacionesContenedor,
                children: [
                    {path: 'menu', name: 'MisAplicacionesMenu', component: MisAplicacionesMenu},
                    {path: 'punto-a-punto', name: 'PuntoAPuntoContenedor', component: PuntoAPuntoContenedor,
                        children: [
                            {path: 'novedades', name: 'PuntoAPuntoNovedades', component: PuntoAPuntoNovedades},
                            {path: 'nueva', name: 'PuntoAPuntoNueva', component: PuntoAPuntoNueva},
                            {path: 'buscar', name: 'PuntoAPuntoBuscar', component: PuntoAPuntoBuscar},
                            {path: 'enviadas', name: 'PuntoAPuntoEnviadas', component: PuntoAPuntoEnviadas},
                            {path: 'recibidas', name: 'PuntoAPuntoRecibidas', component: PuntoAPuntoRecibidas},
                            {path: 'archivadas', name: 'PuntoAPuntoArchivadas', component: PuntoAPuntoArchivadas},
                            {path: 'documentacion', name: 'PuntoAPuntoDocumentacion', component: PuntoAPuntoDocumentacion},
                        ]
                    },
                    {path: 'gestion-beneficiarios', component: GestionBeneficiariosContenedor,
                        children: [
                            {path: 'buscar', name: 'GestionBeneficiariosBuscar', component: GestionBeneficiariosBuscar}
                        ]
                    },
                    {path: 'usuarios', name: 'MisAplicacionesUsuariosContenedor', component: MisAplicacionesUsuariosContenedor},
                    {path: 'usuarios/organismos', name: 'MisAplicacionesUsuariosOrganismosContenedor', component: MisAplicacionesUsuariosOrganismosContenedor,
                        children: [
                            {path: 'buscar', name: 'MisAplicacionesUsuariosOrganismosBuscar', component: MisAplicacionesUsuariosOrganismosBuscar},
                            {path: 'nuevo', name: 'MisAplicacionesUsuariosOrganismosNuevo', component: MisAplicacionesUsuariosOrganismosNuevo},
                        ]
                    },
                    {path: 'usuarios/beneficiarios', name: 'MisAplicacionesUsuariosBeneficiariosContenedor', component: MisAplicacionesUsuariosBeneficiariosContenedor,
                        children: [
                            {path: 'buscar', name: 'MisAplicacionesUsuariosBeneficiariosBuscar', component: MisAplicacionesUsuariosBeneficiariosBuscar},
                        ]
                    },
                    {path: 'usuarios/empleados', name: 'MisAplicacionesUsuariosEmpleadosContenedor', component: MisAplicacionesUsuariosEmpleadosContenedor,
                        children: [
                            {path: 'buscar', name: 'MisAplicacionesUsuariosEmpleadosBuscar', component: MisAplicacionesUsuariosEmpleadosBuscar},
                            {path: 'nuevo', name: 'MisAplicacionesUsuariosEmpleadosNuevo', component: MisAplicacionesUsuariosEmpleadosNuevo},
                            {path: 'eliminados', name: 'MisAplicacionesUsuariosEmpleadosEliminados', component: MisAplicacionesUsuariosEmpleadosEliminados},
                        ]
                    },
                    {path: 'dpto-liquidaciones', name: 'DptoLiquidaciones', component: DptoLiquidacionesContenedor,
                        children: [
                            {path: 'recibos-comples', name: 'DptoLiquidacionesRecibosComplesBuscar', component: DptoLiquidacionesRecibosComplesBuscar}
                        ]
                    },
                    {path: 'tramites-a-distancia', name: 'MisAplicacionesTramitesADistanciaContenedor', component: MisAplicacionesTramitesADistanciaContenedor,
                        children: [
                            {path: 'menu', name: 'MisAplicacionesTramitesADistanciaMenu', component: MisAplicacionesTramitesADistanciaMenu},
                            {path: 'novedades', name: 'MisAplicacionesTramitesADistanciaNovedades', component: MisAplicacionesTramitesADistanciaNovedades},
                            {path: 'certificados-haberes', name: 'MisAplicacionesTramitesADistanciaCertificadosHaberesContenedor', component: MisAplicacionesTramitesADistanciaCertificadosHaberesContenedor,
                                children: [
                                    {path: 'nuevo', name: 'MisAplicacionesTramitesADistanciaCertificadosHaberesNuevo', component: MisAplicacionesTramitesADistanciaCertificadosHaberesNuevo},
                                    {path: 'buscar', name: 'MisAplicacionesTramitesADistanciaCertificadosHaberesBuscar', component: MisAplicacionesTramitesADistanciaCertificadosHaberesBuscar}
                                ]
                            },
                        ]
                    },
                    {path: 'operaciones', name: 'MisAplicacionesOperacionesContenedor', component: MisAplicacionesOperacionesContenedor,
                        children: [
                            {path: 'menu', name: 'MisAplicacionesOperacionesMenu', component: MisAplicacionesOperacionesMenu},
                            {path: 'gestion-organismos', name: 'MisAplicacionesOperacionesOrganismosContenedor', component: MisAplicacionesOperacionesOrganismosContenedor,
                                children: [
                                    {path: 'buscar-organismos', name: 'MisAplicacionesOperacionesOrganismosBuscar', component: MisAplicacionesOperacionesOrganismosBuscar},
                                    {path: 'nuevo-organismo', name: 'MisAplicacionesOpereacionesOrganismosNuevo', component: MisAplicacionesOpereacionesOrganismosNuevo},
                                    {path: 'editar-organismo/:id', name: 'MisAplicacionesOperacionesOrganismosEditar', component: MisAplicacionesOperacionesOrganismosEditar},
                                    {path: 'buscar-sede', name: 'MisAplicacionesOperacionesOrganismosSedeBuscar', component: MisAplicacionesOperacionesOrganismosSedeBuscar},
                                    {path: 'buscar-sector', name: 'MisAplicacionesOperacionesOrganismosSectorBuscar', component: MisAplicacionesOperacionesOrganismosSectorBuscar},
                                    {path: 'buscar-subsector', name: 'MisAplicacionesOperacionesOrganismosSubsectorBuscar', component: MisAplicacionesOperacionesOrganismosSubsectorBuscar}
                                ]
                            },
                            {path:'gestion-complementarias', name: 'MisAplicacionesOperacionesGestionComplementariasContenedor', component: MisAplicacionesOperacionesGestionComplementariasContenedor,
                                children:[
                                    {path:'envio-notificaciones-complementarias', name: 'EnvioNotificaciones', component: EnvioNotificaciones},
                                    {path:'carga-recibos-complementarias', name: 'CargaComplementariasRecibos', component: CargaComplementariasRecibos},
                                    {path:'altas-web-complementarias', name: 'AltasWebComplementarias', component: AltasWebComplementarias},
                                ]
                            },
                            {path:'administracion-sube', name: 'MisAplicacionesOperacionesAdministracionBeneficioSubeContenedor', component: MisAplicacionesOperacionesAdministracionBeneficioSubeContenedor,
                                children:[
                                    {path:'administracion-sube-menu', name: 'AdministracionBeneficioSubeMenu', component: AdministracionBeneficioSubeMenu},
                                ]
                            },
                        ]
                    },
                    {path: 'notificaciones', name: 'MisAplicacionesNotificacionesContenedor', component: MisAplicacionesNotificacionesContenedor,
                        children: [
                            {path: 'menu', name: 'MisAplicacionesNotificacionesMenu', component: MisAplicacionesNotificacionesMenu},
                            {path: 'nueva', name: 'MisAplicacionesNotificaciones', component: MisAplicacionesNotificaciones}
                        ]
                    },
                    /* {path: 'envio-mails', name: 'MisAplicacionesEnvioDeMailsContenedor', component: MisAplicacionesEnvioDeMailsContenedor,
                        children: [
                            {path: 'menu', name: 'MisAplicacionesEnvioDeMailsMenu', component: MisAplicacionesEnvioDeMailsMenu},
                            {path: 'camapania-beneficiario', name: 'MisAplicacionesEnvioDeMailsBeneficiarios', component: MisAplicacionesEnvioDeMailsBeneficiarios},
                            {path: 'camapania-personal', name: 'MisAplicacionesEnvioDeMailsPersonal', component: MisAplicacionesEnvioDeMailsPersonal}                            
                        ]
                    }, */
		            {path: 'gestion-novedades', name: 'MisAplicacionesGestionNovedadesContenedor', component: MisAplicacionesGestionNovedadesContenedor,
                        children: [
                            {path: 'menu', name: 'MisAplicacionesGestionNovedadesMenu', component: MisAplicacionesGestionNovedadesMenu},
                            {path: 'nueva', name: 'MisAplicacionesGestionNovedadesNueva', component: MisAplicacionesGestionNovedadesNueva},
                            {path: 'editar/:id_noticia', name: 'MisAplicacionesGestionNovedadesEdicion', component: MisAplicacionesGestionNovedadesEdicion}
                     
                        ]
                    },
                    {path:'gestion-recibos-gendarmeria', name:"MisAplicacionesGestionRecibosGendarmeriaContenedor", component:MisAplicacionesGestionRecibosGendarmeriaContenedor,
                        children:[
                            {path:'buscador', name: "MisAplicacionesGestionRecibosGendarmeria", component:MisAplicacionesGestionRecibosGendarmeria },
                        ]
                    },
                    {path:'gestion-recibos-penitenciaria', name:"MisAplicacionesGestionRecibosPenitenciariaContenedor", component:MisAplicacionesGestionRecibosPenitenciariaContenedor,
                    children:[
                        {path:'buscador', name: "MisAplicacionesGestionRecibosPenitenciaria", component:MisAplicacionesGestionRecibosPenitenciaria },
                    ]
                    },
                    {path:'administrador-recibos-haberes', name:"MisAplicacionesAdministracionRecibosContainer", component:MisAplicacionesAdministracionRecibosContainer,
                        children:[
                            {path:'busqueda', name: "MisAplicacionesAdministracionRecibos", component:MisAplicacionesAdministracionRecibos },
                        ]
                    }


                ]
            },
        ],
    },
    {path: '*', component: Error404}
];

const router = new VueRouter({
    //mode: "history",
    routes
});

router.afterEach((to) => {
    gtag('config', 'UA-89504298-2', {
        page_path: to.fullPath,
        app_name: 'Monitive Web App',
        send_page_view: true,
    });
});

export default router;
