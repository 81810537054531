const state = {
	beneficios: [],
	apoderados: []
};

const getters = {
    getBeneficios: state => {
        return state.beneficios
    },
    getApoderados: state => {
        return state.apoderados
    }
};

const mutations = {
	guardarBeneficios(state, beneficiosData){
	    state.beneficios = beneficiosData
	},
	guardarApoderados(state, apoderadosData){
	    state.apoderados = apoderadosData
	},
	eliminarBeneficios(state){
		state.beneficios = []
	},
	eliminarApoderados(state){
		state.apoderados = []
	}
};

const actions = {

};

export default {
	state,
	actions,
	mutations,
	getters
}
