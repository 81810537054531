<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en:
                    <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Mi Perfil
                </li>
            </ol>
        </nav>

        <div>
            <div class="row">
                <div class="col-12">
                    <h3 class="nombre-persona">
                        {{ datos_persona.nombre }} {{ datos_persona.apellido }}
                    </h3>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <datos-usuario
                        :datosusuario="datos_usuario"
                    ></datos-usuario>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <datos-persona
                        :datospersona="datos_persona"
                    ></datos-persona>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatosUsuario from "./DatosUsuario.vue";
import DatosPersona from "./DatosPersona.vue";
import DatosBeneficio from "./DatosBeneficio.vue";
import DatosApoderado from "./DatosApoderado.vue";

export default {
    components: {
        "datos-usuario": DatosUsuario,
        "datos-persona": DatosPersona,
        "datos-beneficio": DatosBeneficio,
        "datos-apoderado": DatosApoderado
    },
    data: function() {
        return {
            datos_persona: this.$store.state.persona,
            datos_usuario: this.$store.state.user,
            datos_beneficios: this.$store.state.beneficios.beneficios,
            datos_apoderados: this.$store.state.beneficios.apoderados
        };
    }
};
</script>
