<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesNotificacionesMenu'}">Notificaciones</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Generar</li>
            </ol>
        </nav>

        <h2 class="titulo-aplicacion">Notificaciones <i class="fas fa-clipboard-list"></i></h2>

        <div class="bg-white rounded p-2">
            <div class="alert alert-success alert-dismissible fade show my-2" role="alert" v-if="mensaje">
                {{mensaje}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="mensaje = ''">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="alert alert-danger alert-dismissible fade show mb-2" role="alert" v-if="errores.length > 0">
                <ul v-for="error in errores">
                    <li>{{error}}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="errores = []">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <h5 class="text-black-50">Destinos</h5>

            <div class="d-md-flex mb-2">
                <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="pc" value="35,85,36,86" v-model="destinos.pc.clases">
                            <label class="form-check-label" for="pc">Policia Ciudad</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.pc.clases.length === 0" type="checkbox" id="pc-exceptuado" value="true" v-model="destinos.pc.exceptuar">
                            <label class="form-check-label" for="pc-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="afi" value="34,84" v-model="destinos.afi.clases">
                            <label class="form-check-label" for="afi">AFI</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.afi.clases.length === 0" type="checkbox" id="afi-exceptuado" value="true" v-model="destinos.afi.exceptuar">
                            <label class="form-check-label" for="afi-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="spf" value="39,89" v-model="destinos.spf.clases">
                            <label class="form-check-label" for="spf">SPF</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.spf.clases.length === 0" type="checkbox" id="spf-exceptuado" value="true" v-model="destinos.spf.exceptuar">
                            <label class="form-check-label" for="spf-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="escalafon_13" value="31,32,33,81,82,83" v-model="destinos.escalafon_13.clases">
                            <label class="form-check-label" for="escalafon_13">Escalafon 13</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.escalafon_13.clases.length === 0" type="checkbox" id="escalafon_13-exceptuado" value="true" v-model="destinos.escalafon_13.exceptuar">
                            <label class="form-check-label" for="escalafon_13-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="graficos" value="09,59" v-model="destinos.graficos.clases">
                            <label class="form-check-label" for="graficos">Graficos</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.graficos.clases.length === 0" type="checkbox" id="graficos-exceptuado" value="true" v-model="destinos.graficos.exceptuar">
                            <label class="form-check-label" for="graficos-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="side" value="02,06,52,56" v-model="destinos.side.clases">
                            <label class="form-check-label" for="side">SIDE</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.side.clases.length === 0" type="checkbox" id="side-exceptuado" value="true" v-model="destinos.side.exceptuar">
                            <label class="form-check-label" for="side-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="psa_civil" value="17,67" v-model="destinos.psa_civil.clases">
                            <label class="form-check-label" for="psa_civil">PSA Civil</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.psa_civil.clases.length === 0" type="checkbox" id="psa_civil-exceptuado" value="true" v-model="destinos.psa_civil.exceptuar">
                            <label class="form-check-label" for="psa_civil-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="sinep" value="13,14,15,16,63,64,65,66" v-model="destinos.sinep.clases">
                            <label class="form-check-label" for="sinep">SINEP</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.sinep.clases.length === 0" type="checkbox" id="sinep-exceptuado" value="true" v-model="destinos.sinep.exceptuar">
                            <label class="form-check-label" for="sinep-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="inteligencia" value="01,04,05,07,08,51,54,55,57,58" v-model="destinos.inteligencia.clases">
                            <label class="form-check-label" for="inteligencia">Inteligencia</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.inteligencia.clases.length === 0" type="checkbox" id="inteligencia-exceptuado" value="true" v-model="destinos.inteligencia.exceptuar">
                            <label class="form-check-label" for="inteligencia-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="pfa" value="10,11,12,21,22,23,24,25,26,27,28,60,61,62,71,72,73,74,75,76,77,78" v-model="destinos.pfa.clases">
                            <label class="form-check-label" for="pfa">PFA</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.pfa.clases.length === 0" type="checkbox" id="pfa-exceptuado" value="true" v-model="destinos.pfa.exceptuar">
                            <label class="form-check-label" for="pfa-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="pna_gna" value="37,87,38,88" v-model="destinos.pna_gna.clases">
                            <label class="form-check-label" for="pna_gna">PNA GNA</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.pna_gna.clases.length === 0" type="checkbox" id="pna_gna-exceptuado" value="true" v-model="destinos.pna_gna.exceptuar">
                            <label class="form-check-label" for="pna_gna-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="form-check col">
                            <input class="form-check-input" type="checkbox" id="psa_seguridad" value="29,79" v-model="destinos.psa_seguridad.clases">
                            <label class="form-check-label" for="psa_seguridad">PSA Seguridad</label>
                        </div>
                        <div class="form-check col">
                            <input class="form-check-input" :disabled="destinos.psa_seguridad.clases.length === 0" type="checkbox" id="psa_seguridad-exceptuado" value="true" v-model="destinos.psa_seguridad.exceptuar">
                            <label class="form-check-label" for="psa_seguridad-exceptuado">Exceptuar retiro militar</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <vue-editor v-model="notificacion"></vue-editor>
                </div>
                <button class="mt-3 btn btn-primary btn-block" @click="enviarNotificaciones">
                    Enviar
                </button>
            </div>
        </div>

        <div class="bg-white p-4 mt-4 rounded">
            <div class="card border-light">
                <div class="card-header">
                    Aviso
                </div>
                <div class="card-body w-100" v-html="notificacion"></div>
                <div class="card-footer text-muted">
                    {{ moment().format('LLL') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
    name: "MisAplicacionesNotificaciones",
    components: {
        VueEditor
    },
    data() {
        return {
            destinos: {
                pc: {
                    clases: [],
                    exceptuar: false
                },
                afi: {
                    clases: [],
                    exceptuar: false
                },
                spf: {
                    clases: [],
                    exceptuar: false
                },
                escalafon_13: {
                    clases: [],
                    exceptuar: false
                },
                graficos: {
                    clases: [],
                    exceptuar: false
                },
                side: {
                    clases: [],
                    exceptuar: false
                },
                psa_civil: {
                    clases: [],
                    exceptuar: false
                },
                sinep: {
                    clases: [],
                    exceptuar: false
                },
                inteligencia: {
                    clases: [],
                    exceptuar: false
                },
                pfa: {
                    clases: [],
                    exceptuar: false
                },
                pna_gna: {
                    clases: [],
                    exceptuar: false
                },
                psa_seguridad: {
                    clases: [],
                    exceptuar: false
                },
            },
            notificacion: '',
            errores: [],
            mensaje: '',
        };
    },
    methods: {
        async enviarNotificaciones() {
            this.errores = []

            if(!Object.values(this.destinos).some(d => d.clases.length > 0)) return this.errores.push("Tenes que seleccionar un destino")

            if(this.notificacion === '') return this.errores.push("El mensaje no puede estar vacio")

            const request = {
                notificacion: this.notificacion,
                destinos: this.destinos,
            }

            try {
                const { data } = await axios.post('notificaciones', request)
                this.notificacion = ''
                this.mensaje = data.mensaje
            }
            catch (e) {
                if(e.response.status === 422) this.errores = Object.values(e.response.data.errors).map(error => error[0])
                this.errores.push("Hubo un error al generar las notificaciones")
            }
        }
    }
}
</script>

<style scoped>

</style>
