<template>
    <div :class="[notificacion.read_at ? '' : 'nueva-notificacion', 'card mb-3 p-2']">
        <div class="row no-gutters flex-column">
            <div class="col-auto p-2 text-right">
                <BadgeNotificacion
                    :type="notificacion.type"
                />
            </div>
            <div class="col">
                <div class="card-body">
                    <p class="card-text">{{ notificacion.data.notificacion }}</p>
                    <p class="card-text"><small class="text-muted">{{ moment(notificacion.created_at).format('LLL') }}</small></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BadgeNotificacion from "./BadgeNotificacion.vue";
export default {
    name: "Notificacion",
    props: [
        'notificacion'
    ],
    components: {
        BadgeNotificacion
    }
}
</script>

<style scoped>
    .nueva-notificacion {
        background-color: rgb(195 231 255 / 20%);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
</style>
