<template>
    <div class="container" v-if="accesoPermitido">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en:<router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name: 'MisAplicacionesMenu' }">Mis Aplicaciones</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Usuarios
                </li>
            </ol>
        </nav>
        <mis-aplicaciones-usuarios-menu></mis-aplicaciones-usuarios-menu>
    </div>
    <div class="w-100" v-else>
        <div class="alert alert-primary text-center">{{ mensaje }}</div>
    </div>
</template>

<script>
import MisAplicacionesUsuariosMenu from "./MisAplicacionesUsuariosMenu.vue";

export default {
    data() {
        return {
            accesoPermitido: false,
            mensaje: "Verificando acceso a la aplicación..."
        };
    },
    components: {
        "mis-aplicaciones-usuarios-menu": MisAplicacionesUsuariosMenu
    },
    created() {
        this.accederAdministracionUsuarios();
    },
    methods: {
        accederAdministracionUsuarios: function() {
            axios
                .get("acceder-administracion-usuarios")
                .then(response => {
                    this.accesoPermitido = true;
                })
                .catch(error => {
                    this.accesoPermitido = false;
                    this.mensaje =
                        "No puede acceder a la aplicación Usuarios en este momento.";
                });
        }
    }
};
</script>
