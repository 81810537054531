<template>
    <Loader v-if="cargando" />
    <div class="card card__novedades card__novedades--destacada" v-else-if="!cargando && recibos.length > 0">
        <div class="row no-gutters m-3">
            <div class="col-12">
                <div class="card-body">
                    <h3 class="card-title text-center">Ultimo recibo de haber</h3>
                    <div class="col-6 mx-auto">
                        <hr />
                    </div>
                    <div class="card-text px-4 text-muted text-justify">
                        <div class="mt-4">
                            <p>Ya está disponible el recibo correspondiente al {{ nombreProceso | nombreReciboPB }}.<br></p>
                            <Recibo
                                v-for="(recibo, index) in recibos"
                                :key="recibo['ruta_recibo']"
                                :recibo="recibo"
                                :tipo="'PB'"
                                :index="index"
                                :seccion="'rh'"
                                :persona="persona"
                            ></Recibo>
                        </div>
                        <p>Para visualizar sus otros recibos, ingrese a <router-link class="nav-item text-white" :to="{ name: 'MisRecibos' }">Mis Recibos</router-link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card card__novedades" v-else>
        <div class="row no-gutters m-3">
            <div class="col-12">
                <div class="card-body">
                    <h3 class="card-title text-center">Mis Recibos</h3>
                    <div class="col-6 mx-auto">
                        <hr />
                    </div>
                    <div class="card-text px-4 text-muted text-justify">
                        <p>Para visualizar, descargar y enviar sus recibos de haberes, puede hacerlo ingresando a <router-link class="nav-item" :to="{ name: 'MisRecibos' }">Mis Recibos</router-link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Recibo from '../../aplicaciones/mis-documentos/mis-recibos/Recibo.vue'
import Loader from "../../elementos/loaders/Loader";

export default {
    components: {
        Recibo,
        Loader
    },
    data() {
        return {
            recibos: [],
            nombreProceso: '',
            persona: this.$store.state.persona,
            cargando: true
        };
    },
    async created() {
        try {
            const { data } = await axios.get("beneficiarios/mis-documentos/recibos/ultimo-recibo")
            this.recibos = data.data
            this.nombreProceso = this.recibos[0].recibo.substring(0, 8);
            this.cargando = false
        }
        catch (e) {
            this.recibos = []
            this.cargando = false
        }
    }
};
</script>

<style>
.btn-link {
    padding: 0 !important;
}
</style>
