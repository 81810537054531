<template>
    <div>
        <div>
            <h1 class="dashboard_titulo_pestaña">Noticias</h1>
            <div class="line"></div>
            <div class="line2"></div>
            <div v-if="novedades != null" class="row flex-fill justify-content-between align-items-start " style="height:100%">
                <div v-for="novedad in novedades" class=" p-0 m-0 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 dashboard_card_noticias ">
                    
                            <img class="dashboard_card_noticias_imagen" v-bind:src="'data:' + novedad.extension_imagen + ';base64,' + novedad.image_blob_publicacion"/>
                        
                            <div class="dashboard_card_noticias_contenido">
                                <div class="dashboard_card_noticias_contenido_texto">
                                    <h5 class=" dashboard_card_noticias_titulo" style="font-size:calc(0.4rem + 0.6vw)">{{ novedad.titulo_publicacion }}</h5>
                                    <p style="font-size: calc(0.3rem + 0.5vw)" v-if="novedad.descripcion_novedad != ''">{{ novedad.descripcion_publicacion }}</p>
                                </div>
                                <div class=" dashboard_card_noticias_options" >
                                    <i @click="editar(novedad.id)" class="icono_editar fas fa-edit fa-lg" ></i>
                                    <i @click="eliminar(novedad.id)" class="icono_borrar fas fa-trash-alt fa-lg" ></i>
                                    
                                </div>
                            </div> 
                        </div>


                    </div>
                </div>
                    
                      
                        
                        
                        
                    
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "Dashboard",

    data() {
        return {
            novedades: [],
            novedad_seleccionada: null,
        }
    },
    methods: {
        ...mapActions('novedades', [
            'getNovedadesParaEdicion',
            'eliminarNoticia'
        ]),

        async eliminar(id){
            const response = await this.eliminarNoticia(id);
            if(response.error_message==null){
                        this.$swal.fire({
                        title: "La publicacion fue eliminada con exito ",
                        icon: 'success',
                        showConfirmButton: false,
                        timer:3500
                        
                })
                }else{
                        this.$swal.fire({
                                title: response.error_message,
                                icon: 'error',
                               
                        })
                };

                this.novedades = await this.getNovedadesParaEdicion();
        },
        async editar(id){
           this.$router.push({ name: 'MisAplicacionesGestionNovedadesEdicion',params: { id_noticia: '' + id +'' } })
    
        },
    },
    async created() {
        this.novedades = await this.getNovedadesParaEdicion();
    },
}
</script>
<style scoped lang="scss">

.dashboard_titulo_pestaña {
    margin-top: 2rem;
}

.line {
    height: 1rem;
    width: 10rem;
    background-color: rgb(13, 13, 125);
    margin-left: 0rem;
    margin-top: -0.6rem;
}


.line2 {
    height: 1rem;
    background-color: rgba(0, 0, 255, 0.401);
    width: 10rem;
    margin-left: 1rem;
    margin-top: -0.6rem;
}

.dashboard_card_noticias {
    display:flex;
    flex-direction: column;
    margin-top: 5px;
    min-width: 200px;
    border-radius: calc(1vw * 3rem);
    min-height: 290px;
    padding-bottom:10px;
    box-shadow: 0px 1px 3px 0px rgba(59, 143, 197, 1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.24);
    transition: box-shadow 0.3s ease-in-out;
}

.dashboard_card_noticias:hover {
    box-shadow: 0px -25px 20px -20px rgba(0, 114, 187, 1),
        25px 0px 20px -20px rgba(0, 0, 0, 0.45),
        0px 25px 20px -20px rgba(0, 0, 0, 0.45),
        -25px 0px 20px -20px rgba(0, 0, 0, 0.45);
}

.dashboard_card_noticias:hover>img {
    opacity: 0.7;
}


.dashboard_card_noticias_imagen {
    object-fit: contain;
    max-width: 100%;
    
}


.dashboard_card_noticias_titulo {
    font-size: calc(0.4rem + 0.6vw);
    font-weight: 600;
    font-size: 0.6rem;
    margin-top: 3%;
    width:100%
    
}

.dashboard_card_noticias_options {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-right: 10px;
    display:flex;
            flex-direction: row;
            justify-content: flex-end;
}
/*color: #ffb100;
*/

.dashboard_card_noticias_contenido{
    display:flex;
    flex-direction: column;
    padding-left: 5px;
}
.dashboard_card_noticias_contenido_texto{
  
    padding-left: 5px;
}
.icono_editar{
    color: #ffb100;
}
.icono_editar:hover {
    color: #a35c00  
}
.icono_borrar{
    color: #ff0000;
    height: 100%;
}
.icono_borrar:hover {
    color: #790000;
    
    
}

@media only screen and (max-width: 575px) {
        .dashboard_card_noticias {
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-height: 100px;
        }

        .dashboard_card_noticias_imagen {
            object-fit: contain;
            width: 30%;
            height: 100%;
            
        }
        .dashboard_card_noticias_contenido{
            display:flex;
            flex-direction: row;
            flex-flow: nowrap;
            width: 70%;
            padding-left: 5px;

        }
        .dashboard_card_noticias_contenido_texto{
            width:60%;
        }
        .dashboard_card_noticias_contenido_titulo{
            width:70%;
        }
        .dashboard_card_noticias_options{
            width:40%;
            padding:3px;
            display:flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 10px;

        }
}




</style>
