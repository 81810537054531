import Vue from "vue";

Vue.filter('formatearBeneficio', function(value){
    if(!value) {
        return ''
    }
    var clase = value.toString().padStart(9, '0').substring(0, 2)
    var beneficio = value.toString().padStart(9, '0').substring(2,8)
    var barra = value.toString().padStart(9, '0').substring(8,9)
    beneficio = clase + '-' + beneficio + '/' + barra

    return beneficio
})

Vue.filter('formatearBeneficioDeRecibo', function(value){
    if(!value) {
        return ''
    }

    var numero_beneficio = value.toString().substring(value.length - 13, value.length - 4);

    var clase = numero_beneficio.toString().padStart(9, '0').substring(0, 2)
    var beneficio = numero_beneficio.toString().padStart(9, '0').substring(2,8)
    var barra = numero_beneficio.toString().padStart(9, '0').substring(8,9)
    beneficio = clase + '-' + beneficio + '/' + barra

    return beneficio
})

Vue.filter('formatearBeneficioSinBarra', function(value){
    if(!value) {
        return ''
    }
    var clase = value.toString().padStart(9, '0').substring(0, 2)
    var beneficio = value.toString().padStart(9, '0').substring(2,8)
    beneficio = clase + '-' + beneficio
    return beneficio
})

Vue.filter('formatearCuil', function(value){
    if(!value) {
        return ''
    }
    var dos = value.toString().substring(0, 2)
    var ocho = value.toString().substring(2,10)
    var uno = value.toString().substring(10,11)
    return dos + ocho + uno
})

Vue.filter('formatearMoneda', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: '$',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString().toLowerCase()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('numSerie', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(6, 0)
})

Vue.filter('formatoNumBeneficio', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(6, 0)
})

Vue.filter('formatoNumClase', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(2, 0)
})

Vue.filter('formatoNumBarra', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(1, 0)
})

Vue.filter('formatearIdTramite', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(6, 0)
})

Vue.filter('nombreReciboPB', function(value){
    var nombre_proceso = value.substr(0, 8)
    var tipo_proceso = value.substr(6, 1)
    var año_proceso = value.substr(4, 2)

    switch (tipo_proceso) {
        case 'M':
            var concepto_recibo = 'Haber de '
            var mes_proceso = nombre_proceso.substr(2, 2)
            switch (mes_proceso) {
                case '01':
                    var mes_recibo = 'Enero '
                    break

                case '02':
                    var mes_recibo = 'Febrero '
                    break

                case '03':
                    var mes_recibo = 'Marzo '
                    break

                case '04':
                    var mes_recibo = 'Abril '
                    break

                case '05':
                    var mes_recibo = 'Mayo '
                    break

                case '06':
                    var mes_recibo = 'Junio-SAC '
                    break

                case '07':
                    var mes_recibo = 'Julio '
                    break

                case '08':
                    var mes_recibo = 'Agosto '
                    break

                case '09':
                    var mes_recibo = 'Septiembre '
                    break

                case '10':
                    var mes_recibo = 'Octubre '
                    break

                case '11':
                    var mes_recibo = 'Noviembre '
                    break

                case '12':
                    var mes_recibo = 'Diciembre '
                    break

                default:
                    var mes_recibo = ''
                    break
            }
            var nombre_recibo = concepto_recibo + mes_recibo + '20' + año_proceso

            break

        case 'A':
            var concepto_recibo = 'SAC 2da cuota '
            var nombre_recibo = concepto_recibo + año_proceso
            break

        default:
            var concepto_recibo = 'Complementaria ' + nombre_proceso
            var nombre_recibo = concepto_recibo
            break
    }

    return nombre_recibo


})

Vue.filter('nombreReciboPI', function(value) {
    var posicion_año_proceso = value.indexOf("recibos-pi") + 11

    var año_proceso = value.substr(posicion_año_proceso, 4)
    var posicion_mes_proceso = value.indexOf(año_proceso) + 5

    var mes_proceso = value.substr(posicion_mes_proceso, 2)

    var posicion_tipo_proceso = value.indexOf(mes_proceso) + 3
    var tipo_proceso = value.substr(posicion_tipo_proceso, 5)

    switch (tipo_proceso) {
        case 'haber':
            var concepto_recibo = 'Haber '
            break

        default:
            var concepto_recibo = 'Recibo '
            break
    }

    switch (mes_proceso) {
        case '01':
            var mes_recibo = 'Enero '
            break

        case '02':
            var mes_recibo = 'Febrero '
            break

        case '03':
            var mes_recibo = 'Marzo '
            break

        case '04':
            var mes_recibo = 'Abril '
            break

        case '05':
            var mes_recibo = 'Mayo '
            break

        case '06':
            var mes_recibo = 'Junio-SAC '
            break

        case '07':
            var mes_recibo = 'Julio '
            break

        case '08':
            var mes_recibo = 'Agosto '
            break

        case '09':
            var mes_recibo = 'Septiembre '
            break

        case '10':
            var mes_recibo = 'Octubre '
            break

        case '11':
            var mes_recibo = 'Noviembre '
            break

        case '12':
            var mes_recibo = 'Diciembre '
            break

        default:
            var mes_recibo = ''
            break
    }

    var nombre_recibo = concepto_recibo + mes_recibo + año_proceso

    return nombre_recibo

})


Vue.filter('certificadoGanancias', function(value) {
    var año_certificado = value.substr(11,4)

    return 'Certificado Impuesto a las Ganancias ' + año_certificado
})

Vue.filter('numSerie', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(5, 0)
})

Vue.filter('formatoNumBeneficio', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(6, 0)
})

Vue.filter('formatoNumClase', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(2, 0)
})

Vue.filter('formatoNumBarra', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(1, 0)
})

Vue.filter('formatoCodigoOrganismo', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.padStart(2, 0)
})

Vue.filter('esconderEmail',function (value) {
    if (!value) return ''
    var arrayPalabras
    arrayPalabras = value.split('@')
    var email_codificado = arrayPalabras[0]
    var palabra
    palabra = email_codificado.split("")
    for(let i=1; i<palabra.length-1; i++){
        palabra[i] = "*";
    }
    var email_terminado = palabra.join("");
    email_terminado+='@'+arrayPalabras[1];
    return email_terminado
})
