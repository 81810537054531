<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Mis Documentos</li>
            </ol>
        </nav>

        <div class="row">
            <router-link
                class="col-12 col-sm-6 col-lg-4 mb-4"
                style="text-decoration: none;"
                :to="{ name: 'MisRecibos' }"
                v-if="mostrarMisRecibos"
            >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Recibos</h5>
                        <p class="card-text text-muted">Haberes mensuales <span v-if="esBeneficiario">y complementarias</span></p>
                    </div>
                </div>
            </router-link>

            <router-link
                class="col-12 col-sm-6 col-lg-4 mb-4"
                style="text-decoration: none;"
                :to="{ name: 'MisCertificadosGanancias' }"
                v-if="mostrarMisCertificadosGanancias"
            >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Certificados Ganancias</h5>
                        <p class="card-text text-muted">Certificados de impuesto a las ganancias</p>
                    </div>
                </div>
            </router-link>

            <router-link
                class="col-12 col-sm-6 col-lg-4 mb-4"
                style="text-decoration: none;"
                :to="{ name: 'MisCertificadosHaberes' }"
                v-if="mostrarMisCertificadosHaberes"
            >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Certificados de Haberes</h5>
                        <p class="card-text text-muted">Consulta de certificados de haberes</p>
                    </div>
                </div>
            </router-link>

            <router-link
                class="col-12 col-sm-6 col-lg-4 mb-4"
                style="text-decoration: none;"
                :to="{ name: 'MisCertificadosComplementarias' }"
                v-if="esBeneficiario"
            >
                <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                    <div class="card-body text-center card-menu__body">
                        <i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
                        <h5 class="card-title card-menu__titulo">Mis Certificados de Complementarias</h5>
                        <p class="card-text text-muted">Consulta de certificados de complementarias</p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    computed: mapState({
        mostrarMisRecibos: state => {
            return state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO', 'EMPLEADO'].includes(r))
        },
        mostrarMisCertificadosGanancias: state => {
            return state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO'].includes(r))
        },
        mostrarMisCertificadosHaberes: state => {
            return state.user.roles.some(r => ['BENEFICIARIO', 'APODERADO'].includes(r))
        },
        esBeneficiario: state => {
            return state.user.roles.includes('BENEFICIARIO')
        }
    }),
};
</script>
