<template>
    <div>
        <form class="formulario" @submit.prevent>
            <h4 class="subtitulo-liquidaciones">Buscar recibos</h4>

            <div class="form-row align-items-end">
                <div class="col-4 my-1">
                    <label for="beneficio">Beneficio</label>
                    <input class="form-control" type="text" id="beneficio" name="beneficio" v-model="beneficio" v-mask="'##-######/#'" placeholder="00-000000/0">
                </div>

                <div class="col-4 my-1">
                    <label for="proceso">Proceso</label>
                    <input class="form-control" type="text" id="proceso" name="proceso" v-model="proceso" v-mask="'######A#'" placeholder="000000L0">
                </div>

                <div class="col-auto my-1">
                    <button class="btn btn-primary" @keyup.enter="buscarRecibosComples()" @click="buscarRecibosComples()">
                        Buscar <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>

            <small class="text-muted busqueda-transferencias__texto-ayuda">
                Ingrese el beneficio para realizar una busqueda, puede ingresar el proceso para especificar el recibo.<br>
                En caso de detectar algún dato o información errónea, por favor escribinos a <a :href="`mailto:soporteweb@crjppf.gov.ar`">soporteweb@crjppf.gov.ar</a>
            </small>
        </form>

        <div class="row">
            <div class="col-12 alert alert-danger" v-if="errores">
                {{errores}}
            </div>

            <div class="col-12 alert alert-info" v-if="buscando">
                Buscando complementaria...
            </div>
            <div class="col-12" v-else-if="recibosTotal > 0 && !buscando">
                <transition-group appear name="mostrar-recibos" tag="div" mode="out-in"
                                  v-for="(recibo, index) in recibosEncontrados"
                                  :key="recibo['ruta_recibo']"
                                  :index="index">

                    <recibo :recibo="recibo"
                            :tipo="'PB'"
                            :index="index"
                            :key="recibo['ruta_recibo']"
                            :seccion="'cr'"
                            :beneficiario="''"
                    ></recibo>
                </transition-group>
            </div>
            <div class="col-12 alert alert-warning" v-else-if="recibosTotal === 0 && !buscando">
                No se encontraron recibos.
            </div>
        </div>
    </div>
</template>

<script>
import Recibo from '../../../mis-documentos/mis-recibos/Recibo.vue'
import axios from "axios";

export default {
    components: {
        'recibo': Recibo
    },
    data() {
        return {
            proceso: '',
            beneficio: '',
            limpiarBusqueda: false,
            recibos: {
                data: [],
            },
            mensaje: '',
            buscando: null,
            errores: '',
        }
    },
    computed: {
        recibosEncontrados: function () {
            return this.recibos.data
        },
        recibosTotal: function () {
            return this.recibosEncontrados.length
        }
    },
    methods: {
        buscarRecibosComples: function () {
            this.errores = ''

            if (this.beneficio == null || this.beneficio === '') {
                $('html,body').animate({scrollTop: 0}, 300)
                this.errores = 'Tenés que ingresar un beneficio'
                return
            }

            var beneficio_format = this.beneficio.replace(/ /g, '')
            beneficio_format = beneficio_format.replace(/-/g, '')
            beneficio_format = beneficio_format.replace(/\//g, '')
            beneficio_format = beneficio_format.padEnd(9, '0')

            this.buscando = true
            axios.post('recibos-comples', {
                beneficio: beneficio_format,
                proceso: this.proceso.toUpperCase()
            })
                .then((response) => {
                    this.buscando = false
                    this.recibos = response.data
                })
                .catch((error) => {
                    this.buscando = false
                    this.errores = error.response.data.error
                })
        },
    }
};
</script>
