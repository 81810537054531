<template>
	<div class="contenedor__menu-transferencias">
		<router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;" :to="{name: 'MisAplicacionesUsuariosOrganismosBuscar'}" v-if="mostrarUsuariosOrganismos">
			<div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
				<div class="card-body text-center card-menu__body">
					<i class="fas fa-user-tie mb-3 fa-4x icono-micaja"></i>
					<h5 class="card-title card-menu__titulo">Organismos</h5>
					<p class="card-text text-muted">Usuarios pertenecientes a personal de organismos</p>
				</div>
			</div>
		</router-link>

		<router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;" :to="{name: 'MisAplicacionesUsuariosEmpleadosBuscar'}" v-if="esAdministrador">
			<div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
				<div class="card-body text-center card-menu__body">
					<i class="fas fa-user-tie mb-3 fa-4x icono-micaja"></i>
					<h5 class="card-title card-menu__titulo">Empleados</h5>
					<p class="card-text text-muted">Usuarios pertenecientes a empleados de la Caja</p>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
	import {mapState} from "vuex";

    export default {
	    computed: mapState({
            esAdministrador: state => {
                return state.user.roles.includes('ADMINISTRADOR')
            },
            mostrarUsuariosOrganismos: state => {
                return state.user.roles.includes('ADMINISTRADOR') || state.user.aplicaciones.some(a => a.funcion === 'USUARIOS-ORGANISMOS-CONSULTA')
            }
        })
	};

</script>
