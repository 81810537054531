<template>
    <div>
        <h2 class="titulo-aplicacion">Carga y Control de Complementarias</h2>
        <div class="contenedor_gestion_complementarias">
            <button tag="button" class="btn btn-primary btn_complementarias" to="#" @click="verCargaRecibos">
                <i class="fas fa-plus fa-2x icono-micaja"></i><br>
                Cargar Complementaria
            </button>
        </div>

        <div v-if="cargaRecibos">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <div class="card">
                            <h5 class="card-header card_header_carga">Cargar Recibo</h5>
                            <div class="card-body">
                                <label class="col-md-4 col-sm-12">Fecha de Pago</label>
                                <input class="col-md-7 col-sm-12" v-model="fechaRecibos" type="date" name="date"
                                    :min="minDate" />
                            </div>
                            <vue-dropzone v-show="mostrarDropzone" v-on:vdropzone-sending="sendFile"
                                v-on:vdropzone-success="completedUpdate" v-on:vdropzone-error="updateError"
                                ref="myVueDropzone" id="dropzone" name="vueDropzone" :options="dropzoneOptions">
                            </vue-dropzone>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="uploaded" id="uploadedAlert"
            class="alert alert-success alert-dismissible fade show d-flex justify-content-between " role="alert">
            <span>{{ successMessage }}</span>
            <span @click="uploaded = false"><i class="fas fa-times "></i></span>
        </div>
        <div v-if="success" class="alert alert-success alert-dismissible fade show d-flex justify-content-between "
            role="alert">
            <span>{{ successMessage }}</span>
            <span @click="success = false"><i class="fas fa-times "></i></span>
        </div>
        <div v-if="uploadError" class="alert alert-danger alert-dismissible fade show  d-flex justify-content-between "
            role="alert">
            <span>{{ errorMessage }}</span>
            <span @click="uploadError = false"><i class="fas fa-times "></i></span>
        </div>

        <div class="w-100 searchBarContainer">
            <h4>Búsqueda por proceso</h4>
            <div class=" searchBarComplementarias navbar row justify-content-around">
                <input class="col-md-9 col-lg-9 col-sm-12" type="search" v-model="busquedaComplementarias"
                    name="busquedaComplementarias" id="busquedaComplementarias" placeholder="Ej:110622Y1"
                    aria-label="Search" />
                <button class="btn btn-outline-primary col-lg-2 col-md-2 col-sm-12 btn-search" @click="buscarComple()"
                    type="submit"><b>Buscar</b><i class="fas fa-search searchIcon"></i></button>
            </div>
        </div>
        <div class="row filterSelect align-items-end">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                <p>Filtrar Por:</p>
                <select class="form-control" v-model="selection">
                    <option value="" disabled hidden >Seleccionar opcion...</option>
                    <option value="usuario">Usuario</option>
                    <option value="estado">Estado</option>
                    <option value="fecha_de_carga">Fecha de carga</option>
                    <option value="fecha_subida">Fecha de pago</option>
                </select>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                <p v-if="selection=='usuario'">Usuario</p>
                <p v-else-if="selection=='estado'">Estado</p>
                <p v-else-if="selection=='fecha_de_carga'">Fecha de carga</p>
                <p v-else-if="selection=='fecha_subida'">Fecha de pago</p>
                <p v-else></p>

                <div>
                <select v-if="selection=='usuario'" class="form-control" placeholder="Seleccione un Usuario" v-model="filterData" @change="getComplementarias('id','desc')">
                    <option value="" disabled hidden >Seleccione usuario...</option>
                    <option v-for="usuarioCarga in usuariosCarga" :value=usuarioCarga>{{usuarioCarga}}</option>
                </select>
                <select v-else-if="selection=='estado'" class="form-control" placeholder="Seleccionar Estado" v-model="filterData" @change="getComplementarias('id','desc')">
                    <option value="" disabled hidden >Seleccione estado...</option>
                    <option value="pendiente">Pendientes</option>
                    <option value="subidos">Subidos</option>
                </select>
                
                <date-picker 
                v-else-if="selection=='fecha_de_carga' || selection=='fecha_subida'" 
                v-model="filterData" 
                range 
                placeholder="Seleccione Fecha..." 
                @change="getComplementarias('id','desc')"
                value-type="YYYY-MM-DD"
                >
                </date-picker>
                </div>
                
            </div>
            <div class="filterBtnContainer col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"><button class="btn btn-primary" @click="eliminarFiltros()">Eliminar Filtros</button></div>
        </div>
        <div class="row">
            <div class="col-12 orderMobile" >
                <p>Ordenar Por:</p>
                <select class="form-control" v-model="selection_mobile" >
                    <option value="" disabled hidden >Seleccionar opcion...</option>
                    <option value="usuario">Usuario</option>
                    <option value="estado">Estado</option>
                    <option value="fecha_de_carga">Fecha de carga</option>
                    <option value="fecha_subida">Fecha de pago</option>
                    <option value="fecha_subida">Cantidad</option>
                </select>
            </div>
        </div>
        <div class="row orderMobile" v-if="selection_mobile">
            <div class="col-12">
                <p>Tipo de orden:</p>
                <select class="form-control" v-model="order_mobile" @change="getComplementarias('id','desc')" >
                <option value="" disabled hidden >Seleccionar opcion...</option>
                <option value="desc">Descendente - (De mayor a menor)</option>
                <option value="asc">Ascendente - (De menor a mayor)</option>
                </select>
            </div>
        </div>
        
        <div class="w-100 container container-desktop">
            <div v-if="estado != ''">
                <div class="alert alert-info" role="alert">
                    {{ estado }}
                </div>
            </div>
            <div v-else>
                <table class="formulario table table-hover">
                    <thead>
                        <tr>
                            <th  scope="col">
                                <div class="columnName"  >
                                    <span>Proceso</span>
                                    
                                </div>
                            </th>
                            <th scope="col">
                                <div class="columnName"  >
                                    <span>Fecha de Carga</span>
                                    <div class="filters">
                                        <i @click="getComplementarias('fecha_de_carga', 'asc')" class="fas fa-solid fa-sort-up"></i>
                                        <i @click="getComplementarias('fecha_de_carga','desc')" class="fas fa-solid fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th scope="col">
                                <div class="columnName"  >
                                    <span>Usuario</span>
                                    <div class="filters">
                                        <i @click="getComplementarias('usuario', 'asc')" class="fas fa-solid fa-sort-up"></i>
                                        <i @click="getComplementarias('usuario','desc')" class="fas fa-solid fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th scope="col">
                                <div class="columnName"  >
                                    <span>Fecha de Pago</span>
                                    <div class="filters">
                                        <i @click="getComplementarias('fecha_subida', 'asc')" class="fas fa-solid fa-sort-up"></i>
                                        <i @click="getComplementarias('fecha_subida','desc')" class="fas fa-solid fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th scope="col">
                                <div class="columnName"  >
                                    <span>Estado</span>
                                    <div class="filters">
                                        <i @click="getComplementarias('estado', 'asc')" class="fas fa-solid fa-sort-up"></i>
                                        <i @click="getComplementarias('estado','desc')" class="fas fa-solid fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th scope="col">
                                <div class="columnName"  >
                                    <span>Cantidad de Recibos</span>
                                    <div class="filters">
                                        <i @click="getComplementarias('cantidad', 'asc')" class="fas fa-solid fa-sort-up"></i>
                                        <i @click="getComplementarias('cantidad','desc')" class="fas fa-solid fa-sort-down"></i>
                                    </div>
                                </div>
                            </th>
                            <th  scope="col">Acciones</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="complementaria in complementariasEncontradas" :key="complementaria.id">
                            <td class="align-content-center">{{ complementaria.proceso }}</td>
                            <td class="align-content-center">{{ complementaria.fecha_de_carga }}</td>
                            <td class="align-content-center">{{ complementaria.usuario }}</td>
                            <td class="align-content-center">{{ complementaria.fecha_subida }}</td>
                            <td class="align-content-center">{{ complementaria.estado }}</td>
                            <td class="align-content-center">{{ complementaria.cantidad || "pendiente" }}</td>
                            <td class="d-flex flex-lg-row flex-md-column flex-sm-column justify-content-between row ">
                                <button
                                    v-if="complementaria.estado == 'pendiente' || complementaria.estado == 'cancelado'"
                                    class="my-sm-1 btn btn-primary align-content-center col-md-12 col-sd-12 "
                                    @click="cambiarFechaPago(complementaria.id, complementaria.proceso)">
                                    Cambiar Fecha
                                </button>
                                <button v-if="complementaria.estado == 'pendiente'"
                                    class=" my-sm-1 btn btn-warning  align-content-center col-12"
                                    @click="suspender(complementaria.id, complementaria.proceso)"> Suspender </button>
                                <button v-else-if="complementaria.estado == 'cancelado'"
                                    class=" my-sm-1 btn btn-success  align-content-center col-12 "
                                    @click="reactivar(complementaria.id, complementaria.proceso)">Reactivar</button>
                                <button v-else class="btn btn-secondary align-content-center col-12 " disabled>Subidos
                                </button>
                                <button
                                    v-if="complementaria.estado == 'pendiente' || complementaria.estado == 'cancelado'"
                                    class="my-sm-1 btn btn-danger align-content-center col-12  "
                                    @click="eliminar(complementaria.id)">
                                    Eliminar
                                </button>


                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>

        <div class="w-100 container container-mobile">
            
            <div class="col-md-12">
                <div v-if="estado != ''">
                    <div class="alert alert-info" role="alert">
                        {{ estado }}
                    </div>
                </div>
                <div v-else>
                    
                    <div class="card registro" v-for="complementaria in complementariasEncontradas" :key="complementaria.id">
                        <div div class="registroTexto card-body">
                            <div class="info_proceso">
                                <p><span><b>Proceso: </b></span><span>{{ complementaria.proceso }}</span></p>
                                <p><span><b>Fecha de Carga: </b></span><span>{{ complementaria.fecha_de_carga }}</span></p>
                                <p><span><b>Usuario: </b></span><span>{{ complementaria.usuario }}</span></p>
                                <p><span><b>Fecha de Pago: </b></span><span>{{ complementaria.fecha_subida }}</span></p>
                                <p><span><b>Estado: </b></span><span>{{ complementaria.estado }}</span></p>
                                <p><span><b>Cantidad de Recibos: </b></span><span>{{
                                    complementaria.cantidad ||
                                        "pendiente"
                                }}</span></p>
                            </div>
                            <div>
                                <button
                                    v-if="complementaria.estado == 'pendiente' || complementaria.estado == 'cancelado'"
                                    class="my-sm-1 btn btn-primary align-content-center col-md-12 col-sd-12 "
                                    @click="cambiarFechaPago(complementaria.id, complementaria.proceso)">
                                    Cambiar Fecha
                                </button>
                                <button v-if="complementaria.estado == 'pendiente'"
                                    class=" my-sm-1 btn btn-warning  align-content-center col-12"
                                    @click="suspender(complementaria.id, complementaria.proceso)"> Suspender </button>
                                <button v-else-if="complementaria.estado == 'cancelado'"
                                    class=" my-sm-1 btn btn-success  align-content-center col-12"
                                    @click="reactivar(complementaria.id, complementaria.proceso)">Reactivar</button>
                                <button v-else class="btn btn-secondary align-content-center col-12 " disabled>Subidos
                                </button>
                                <button
                                    v-if="complementaria.estado == 'pendiente' || complementaria.estado == 'cancelado'"
                                    class="my-sm-1 btn btn-danger align-content-center col-12  "
                                    @click="eliminar(complementaria.id)">
                                    Eliminar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <paginacion v-if="mostrarTodas" :pagination="complementarias" v-on:cambiar-pagina="cambiarPagina($event)">
            </paginacion>
        </div>
    </div>
</template> 
 
<script>

import vue2Dropzone from 'vue2-dropzone';
import DatePicker from 'vue2-datepicker';
import pagination from "../../../../../elementos/pagination/Pagination";
import axios from "axios";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'vue2-datepicker/index.css';
import { fileURLToPath } from 'url';

export default {
    name: "CargaComplementariasRecibos",

    components: {
        DatePicker ,
        vueDropzone: vue2Dropzone,
        'paginacion': pagination,
    },

    data() {
        return {
            estadoEnvioRecibos: "0",
            fechaRecibos: false,
            mostrarDropzone: false,
            timeout: 50000,
            dropzoneOptions: {
                url: 'api/subir-recibos',
                acceptedFiles: ".tar, .rar",
                autoProcessQueue: true,
                method: "post",
                maxFilesize: 5000000,
                chunking: true,
                chunkSize: 2000000,
                headers: {
                    'X-XSRF-TOKEN': $cookies.get("XSRF-TOKEN")
                }
            },
            cargaRecibos: false,
            complementarias: [],
            estado: '',
            complementarias_buscar: '',
            success: false,
            resultado: '',
            busqueda: false,
            por_pagina: 10,
            uploaded: false,
            uploadError: false,
            successMessage: "",
            errorMessage: "",
            minDate: null,
            busquedaComplementarias: "",
            mostrarTodas: true,
            mostrarUna: false,
            selection:"",
            timeRangeFilter:null,
            filterData:null,
            usuariosCarga:null,
            selection_mobile:null,
            order_mobile:null,
            
        }
    },
    created() {
        if (this.$route.params.msg) {
            this.successMessage = this.$route.params.msg
        }
        this.getComplementarias('id','desc',true);
        let newDate = new Date();
        let month = '' + (newDate.getMonth() + 1);
        let day = '' + (newDate.getDate() + 2);
        let year = newDate.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        this.minDate = [year, month, day].join('-');
    },

    computed: {
        complementariasEncontradas() {

            if (this.mostrarTodas) {
                return this.complementarias.data
            } else {
                return this.complementarias
            }
        },

        computedMinDate() {
            return this.minDate;
        },


    },

    watch: {
        fechaRecibos(newFecha) {

            if (newFecha) {
                this.mostrarDropzone = true;
            }

        }
    },

    methods: {
       
        buscarComple() {
            
            const formdata = new FormData()
            formdata.append("proceso", this.busquedaComplementarias)
            axios.get(`gestion-complementarias/complementarias/buscar/${this.busquedaComplementarias}`)
            .then(
                response => {
                        this.complementarias = [response.data];
                        this.mostrarTodas = false;
                    }
            )
            .catch(
                (error) => {
                    
                    this.uploadError = true;
                    this.errorMessage = error.response.data.error
                    setTimeout(() => this.uploadError = false, 5000)
                    
                }
            )
        },
                    
        cambiarFechaPago(id, proceso) {
                        
            let editarFecha = this.complementariasEncontradas.filter(comple => comple.id === id)
            
            this.$swal.fire({
                title: "Fecha Anterior: " + editarFecha[0].fecha_subida,
                html: `<input id="swal-input-date" type="date" class="swal2-input" min=${this.minDate}>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed){
                    const formData = new FormData();
                    formData.append("fecha", document.querySelector('#swal-input-date').value)
                    formData.append("id", id)
                    axios.post('gestion-complementarias/complementarias/modificarFechaPago', formData)
                    .then(response => {
                        if (this.mostrarTodas) {
                            this.getComplementarias('id', 'asc', true)
                        } else {
                            this.buscarComple(proceso)
                        }
                        this.successMessage = response.data.data
                    })
                }
            })
        },
        
        cambiarPagina(url) {
            axios.get(url)
            .then(response => {
                this.complementarias = response.data.comples
            })
            .catch(error => {
                this.estado = "Hubo un error, no se encontraron complementarias"
            })
        },
        
        completedUpdate() {
            this.getComplementarias('id','desc', true);
            this.cargaRecibos = !this.cargaRecibos;
            this.uploaded = true;
            this.successMessage = "Complementaria Subida Correctamente";
            setTimeout(function () { this.uploaded = false }, 5000);
        },

        eliminar(id) {
            let eliminarComple = this.complementariasEncontradas.filter(comple => comple.id === id)
            this.$swal.fire({
                title: "¿Desea Continuar? Se eliminara el proceso y todos los archivos asociados",
                text: eliminarComple[0].proceso,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('gestion-complementarias/complementarias/eliminarSubida', { id: id })
                    .then(response => {
                        this.getComplementarias()
                        this.mostrarTodas = true;
                        this.successMessage = response.data.data
                        this.success = true;
                        setTimeout(() => this.success = false, 5000)
                        
                        
                    })
                }
            })
        },
        
        eliminarFiltros(){
            this.selection=null;
            this.filterData=null;
            this.selection_mobile=null;
            this.order_mobile=null;
            this.getComplementarias('id', 'desc')
        },

        getComplementarias(campo, orden, primero){
            this.complementarias_buscar = ''
            this.estado = "Buscando..."
            this.busqueda = false
            if(this.order_mobile){
                var campo = this.selection_mobile
                var orden = this.order_mobile
            }
            let route='gestion-complementarias/complementarias?por_pagina=' + this.por_pagina + '&campo=' + campo +'&orden=' + orden
            if(primero){
                
                route= route + '&primero=primero'
            }
            if(this.filterData){
                route= route + `&filtro=${this.selection}&opcion=${this.filterData}`
            }

            
            axios.get(route)
                .then(response => {
                    this.estado = '';
                    this.complementarias = response.data.comples;
                    this.usuariosCarga = response.data.usuarios;
                    this.resultado = "Se encontraron " + response.data.comples.total + " complementarias"
                   
                    
                })
                .catch(error => {
                    this.estado = "Hubo un error, no se encontraron complementarias"
                })
        },
        
        reactivar(id, proceso) {
            let cancelarComple = this.complementariasEncontradas.filter(comple => comple.id === id)
        
            this.$swal.fire({
                title: '¿Esta seguro que desea reactivar la subida de esta Complementaria?',
                text: cancelarComple[0].proceso,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('gestion-complementarias/complementarias/reactivarSubida', { id: id })
                        .then(response => {
                            if (this.mostrarTodas) {
                                this.getComplementarias('id', 'asc', true)
        
                            } else {
                                this.buscarComple(proceso)
        
                            }
                            this.successMessage = response.data.data
                            this.success = true;
                            setTimeout(() => this.success = false, 5000)
        
                        })
                }
            })
        },

        sendFile(file, xhr, formData) {
            formData.append('fecha', this.fechaRecibos);
        },
        
        suspender(id, proceso) {
            let cancelarComple = this.complementariasEncontradas.filter(comple => comple.id === id)

            this.$swal.fire({
                title: '¿Esta seguro que desea suspender la subida de esta Complementaria?',
                text: cancelarComple[0].proceso,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('gestion-complementarias/complementarias/cancelarSubida', { id: id })
                        .then(response => {
                            if (this.mostrarTodas) {
                                this.getComplementarias('id', 'asc', true)

                            } else {
                                this.buscarComple(proceso)

                            }

                            this.successMessage = response.data.data
                            this.success = true;
                            setTimeout(() => this.success = false, 5000)

                        })
                }
            })
        },

        updateError(file, message, xhr) {
            this.uploadError = true;
            this.errorMessage = message.error
            this.cargaRecibos = !this.cargaRecibos

            setTimeout(() => this.uploadError = false, 5000)
        },
        
        verCargaRecibos: function () {
            this.cargaRecibos = !this.cargaRecibos;
            this.uploaded = false;
            this.successMessage = "";
        },     
       
    }
    
    
}
</script>
<style scoped>
.searchBarComplementarias {
    background-color: #e9ecef;
    border-radius: 0.25rem;
    min-width: 100%;
}

.searchIcon {
    margin-left: 8px;
}

.btn-search {
    height: 4rem;
}

.btn_complementarias {
    width: 100%;
    
}

.formulario th {
    text-align: center;
}

.table tbody {
    padding: 0 5px;
}

.formulario tr {
    padding: 0px 5px;
}

.formulario td {
    text-align: center;
    vertical-align: middle;
}

.lista-container {
    width: 100%
}

.contenedor_gestion_complementarias {
    text-align: center;
    margin-top: 40px;

}

.card_header_carga {
    text-align: center;
}

.searchBarContainer {
    margin-top: 40px
}

.registro{
    margin-bottom:5px;
}

.registroTexto div p{
    line-height: 0.5;
}

.info_proceso p{
    display:flex;
    flex-flow: row wrap;
}

.columnName{
display:flex;
flex-flow: row nowrap;
align-items: flex-end;
justify-content: center;
}
.filters{
    display:flex ;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;
}

.filterSelect{
    margin-bottom: 5px;
}

.orderMobile{
    margin-bottom: 5px;
}
.filterBtnContainer{
    margin-bottom: 5px;
    margin-top:5px;
}

@media (max-width: 768px) {
    .container-desktop {
        display: none;
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .container-mobile{
        display: none;
    }
    .orderMobile{
        display: none;
    }
}
@media (max-width: 366px) {
    .registroTexto div p{
        line-height: 1;
    }
}
</style>