import {
    getRecibosPenitenciariaPorCUIT,
    getRecibosPenitenciariaPorBeneficio,
} from "../../../api/gestionpenitenciaria";

const state = {
    recibos: [],
    recibosBuscados:false,
    loading:false,
    beneficios:[],
    apoderados:[],
    persona:[],
    errors: []
};

const getters= {}

const mutations = {
    
    setBeneficios(state, beneficios){
        //Devuelve Objeto Beneficios
        state.beneficios = beneficios;
    },
    setApoderados(state, apoderados){
        state.apoderados = apoderados;
    },
    setRecibos(state,recibos,beneficios){
        state.recibos = recibos;
    },
    setPersona(state, persona){
        state.persona = persona;
    },
    emptyErrors(state){
        state.errors=[];
    },
    setError(state, error) {
        state.errors = error;
    },
    setLoading(state, active){
        state.loading = active
    },
    setRecibosBuscados(state, buscado){
        state.recibosBuscados = buscado;
    }
}
const actions = {
  
    async traerRecibosPorCUIT({commit},cuit){
        commit("setLoading", true);
        try {
            commit("setError", []);
            const { data } = await getRecibosPenitenciariaPorCUIT(cuit)
            const {beneficios, apoderados, recibos, ...persona} = data;
            const recibosArray = []
            for (const [beneficioKey, arrayRecibos] of Object.entries(recibos)) {
                arrayRecibos.forEach(recibo => {
                    recibosArray.push(recibo);
                });
            }
            commit('setBeneficios', beneficios);
            commit('setApoderados', apoderados);
            commit('setRecibos', recibosArray);
            commit('setPersona', persona);
            commit("setLoading", false);
            commit("setRecibosBuscados", true)
        }
        catch (e) {
            commit('setBeneficios', []);
            commit('setApoderados', []);
            commit('setRecibos', []);
            commit('setPersona', []);    
            commit('setError',[ e.response.data.error])
            commit("setLoading", false);
            commit("setRecibosBuscados", false)
        }
    },
    async traerRecibosPorBeneficio({commit},beneficio){
        commit("setLoading", true);
        try {
            commit("setError",[])
            const { data } = await getRecibosPenitenciariaPorBeneficio(beneficio)
            const {beneficios, apoderados, recibos, ...persona} = data;
            const recibosArray = []
            
            for (const [beneficioKey, arrayRecibos] of Object.entries(recibos)) {
                arrayRecibos.forEach(recibo => {
                    recibosArray.push(recibo);
                });
            }
            commit('setBeneficios', beneficios);
            commit('setApoderados', apoderados);
            commit('setRecibos', recibosArray);
            commit('setPersona', persona);    
            commit("setLoading", false);
            commit("setRecibosBuscados", true)
        }
        catch (e) {
            commit('setBeneficios', []);
            commit('setApoderados', []);
            commit('setRecibos', []);
            commit('setPersona', []);    
            commit('setError', [e.response.data.error]);
            commit("setLoading", false);
            commit("setRecibosBuscados", false)
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}