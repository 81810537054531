<template>
    <div>
        <h2 class="titulo-aplicacion">Altas Web</h2>
        <div class="contenedor_gestion_complementarias">
            <button tag="button" class="btn btn-primary btn_complementarias" to="#" @click="verCargaAltasWeb">
                <i class="fas fa-plus fa-2x icono-micaja"></i><br>
                Generar Altas Automaticas (Q y L)
            </button>
        </div>

       
        <div v-if="cargaAltasWeb">
            <div class="container"> 
                <div class="row justify-content-center"> 
                    <div class="col-md-12"> 
                        <div class="card"> 
                            <h5 class="card-header card_header_carga">Cargar Archivo de Altas</h5> 
                            <div class="card-body"> 
                                <label class="col-md-4 col-sm-12">Nombre del Proceso</label>
                                <input class="col-md-7 col-sm-12" v-model="nombreProceso" type="text" placeholder="Ej: 011222Q1" name="nombreProceso" />
                            </div>
                            <div class="card-body" > 
                                <label class="col-md-4 col-sm-12">Fecha de Pago</label>
                                <input class="col-md-7 col-sm-12" v-model="fechaDePago" type="date" name="date" :min="minDate" />
                            </div>                                
                            <vue-dropzone 
                                v-show="mostrarDropzone" 
                                v-on:vdropzone-sending="sendFile" 
                                v-on:vdropzone-success="completedUpdate" 
                                v-on:vdropzone-error="updateError" 
                                ref="myVueDropzone" 
                                id="dropzone" 
                                name="vueDropzone" 
                                :options="dropzoneOptions">
                            </vue-dropzone> 
                        </div>                         
                    </div>
                </div> 
            </div>    
        </div>
        <div v-if="uploaded" id="uploadedAlert" class="alert alert-success alert-dismissible fade show d-flex justify-content-between " role="alert">
            <span>{{successMessage}}</span>
            <span @click="uploaded=false" ><i class="fas fa-times "></i></span> 
        </div>
        <div v-if="success"  class="alert alert-success alert-dismissible fade show d-flex justify-content-between " role="alert">
                <span>{{successMessage}}</span>
                <span @click="success=false" ><i class="fas fa-times "></i></span> 
        </div>
        <div v-if="uploadError" class="alert alert-danger alert-dismissible fade show  d-flex justify-content-between " role="alert">
                <span>{{errorMessage}}</span>
                <span @click="uploadError=false" ><i class="fas fa-times "></i></span> 
        </div>

        <div class="w-100 searchBarContainer">
            <h4 >Búsqueda por proceso</h4>
            <div class=" searchBarAltasWeb navbar row justify-content-around">
                <input class="col-md-9 col-lg-9 col-sm-12" type="search" v-model="busquedaAltasWeb" name="busquedaAltasWeb" id="busquedaAltasWeb" placeholder="Ej:110622Y1" aria-label="Search" />
                <button class="btn btn-outline-primary col-lg-2 col-md-2 col-sm-12 btn-search" @click="buscarAltasWeb()" type="submit"><b>Buscar</b><i class="fas fa-search searchIcon" ></i></button>
            </div>
        </div>  


        <div class="w-100 container container-desktop">
            <div v-if="estado != ''">
                <div class="alert alert-info" role="alert">
                    {{estado}}
                </div>
            </div>

            <div v-else>
                <table class="formulario table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Proceso</th>
                            <th scope="col">Fecha de Carga</th>
                            <th scope="col">Usuario</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Totales</th>
                            <th scope="col">Pendientes</th>
                            <th scope="col">Fallidos</th>
                            <th scope="col">acciones</th>
        
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="alta in altasWebEncontradas" :key="alta.id">
                            <td class="align-content-center">{{alta.proceso}}</td>
                            <td class="align-content-center">{{alta.fecha_carga}}</td>
                            <td class="align-content-center">{{alta.user}}</td>
                            <td class="align-content-center">{{alta.estado}}</td>
                            <td class="align-content-center">{{alta.total_altas}}</td>
                            <td class="align-content-center">{{alta.pendientes}}</td>
                            <td class="align-content-center">{{alta.fallidos}}</td>
                            <td class="d-flex flex-lg-row flex-md-column flex-sm-column justify-content-between row">
                                <button class="my-sm-1 btn btn-primary align-content-center col-12" @click="descargarArchivo(alta.id)" type="submit" >Descargar Archivo</button>
                                <button class="my-sm-1 btn btn-success align-content-center col-12" @click="setModalOpen(alta.proceso)" type="submit" >Ver Detalles</button>
                            </td>
                            
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        </div>

        <div class="w-100 container container-mobile">
            <div class="col-md-12">
                <div v-if="estado != ''">
                    <div class="alert alert-info" role="alert">
                        {{ estado }}
                    </div>
                </div>
                <div v-else>
                    <div class="card registro" v-for="alta in altasWebEncontradas" :key="alta.id">
                        <div div class="registroTexto card-body">
                            <div class="info_proceso">
                                <p><span><b>Proceso: </b></span><span>{{alta.proceso}}</span></p>
                                <p><span><b>Fecha de Carga: </b></span><span>{{alta.fecha_carga}}</span></p>
                                <p><span><b>Usuario: </b></span><span>{{alta.user}}</span></p>
                                <p><span><b>Estado: </b></span><span>{{alta.estado}}</span></p>
                                <p><span><b>Totales: </b></span><span>{{alta.total_altas}}</span></p>
                                <p><span><b>Pendientes: </b></span><span>{{alta.pendientes}}</span></p>
                                <p><span><b>Fallidos: </b></span><span>{{alta.fallidos}}</span></p>
                            </div>
                            <div>
                                <button
                                    class="my-sm-1 btn btn-primary align-content-center col-md-12 col-sd-12 "
                                    @click="descargarArchivo(alta.id)">
                                    Descargar Archivo
                                </button>
                                <button
                                    class=" my-sm-1 btn btn-success  align-content-center col-12"
                                    @click="setModalOpen(alta.proceso)">
                                    Ver Detalles
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div>
            <paginacion v-if="showModal" :pagination="altasWeb"
                        v-on:cambiar-pagina="cambiarPagina($event)"
            ></paginacion>
        </div>
        <div>
            <alta-detalle v-if="showModal" @close="showModal = false"  :proceso_modal="this.procesoModal" ></alta-detalle>
        </div>

    </div>
   
</template>
<script>
 import vue2Dropzone from 'vue2-dropzone';
    import pagination from "../../../../../elementos/pagination/Pagination";
    import axios from "axios";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import { fileURLToPath } from 'url';
    import DetalleAltaModal from './detallles-alta/DetalleAltaModal.vue'


    export default {
        name: "AltasWeb",

        components: {
        vueDropzone: vue2Dropzone,
        altaDetalle : DetalleAltaModal,
        'paginacion' : pagination,
        },
    
    
    
        data() {
            return {
                mostrarDropzone:false,
                timeout: 50000,
                fechaDePago: false,
                nombreProceso:'',
                dropzoneOptions: {
                    url: 'api/gestion-altas-web/enviar-altas',
                    acceptedFiles: ".txt",
                    autoProcessQueue: true,
                    method:"post",
                    maxFilesize: 5000000,
                    chunking: true,
                    chunkSize : 2000000,
                    headers: {
                    'X-XSRF-TOKEN' : $cookies.get("XSRF-TOKEN")
                    }
                },
                showModal:false,
                cargaAltasWeb:false,
                altasWeb: [],
                estado: '',
                success: false,
                resultado: '',
                busqueda: false,
                por_pagina: 10,
                uploaded: false,
                uploadError: false,
                successMessage :"",
                errorMessage : "",
                busquedaAltasWeb:"",
                mostrarTodas:true,
                mostrarUna:false,
                minDate:null,
                procesoModal:""
            }

        },

        created() {
            if (this.$route.params.msg){
                this.successMessage = this.$route.params.msg
            }
            this.getAltasWeb();
            let newDate = new Date();
            let month = '' + (newDate.getMonth() + 1);
            let day = '' + (newDate.getDate() + 2);
            let year = newDate.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            this.minDate = [year, month, day].join('-');
            
        },

        computed: {
            altasWebEncontradas(){
                if(this.mostrarTodas){
                    return this.altasWeb.data
                }else{
                    return this.altasWeb
                }
            },

            computedMinDate(){
            return this.minDate;
        },
  
        },

        watch:{
            fechaDePago(newFecha){
                
                if(newFecha){
                    this.mostrarDropzone = true;
                }

            },

           
        },
       
        methods: {
            verCargaAltasWeb:function(){
                this.cargaAltasWeb = !this.cargaAltasWeb;
                this.uploaded = false;
                this.successMessage = "";
            },

            sendFile(file, xhr, formData){
                formData.append('fecha', this.fechaDePago);
                formData.append('proceso', this.nombreProceso);  
            },
            
            completedUpdate(){
                this.getAltasWeb();
                this.cargaAltasWeb = !this.cargaAltasWeb;
                this.uploaded = true;
                this.successMessage = "Altas Web Generadas Correctamente";
                setTimeout(function() {this.uploaded = false} , 5000);
            },

            updateError(file, message, xhr){    
                this.uploadError = true;
                this.errorMessage = message.error
                this.cargaAltasWeb = !this.cargaAltasWeb
                setTimeout(() => this.uploadError = false, 5000)
            }, 

            getAltasWeb(){
                this.estado = "Buscando..."
                this.busqueda = false

                axios.get('gestion-altas-web/altasweb?por_pagina='+this.por_pagina)
                .then(response => {
                    this.estado = '';
                    this.altasWeb = response.data;
                    this.resultado = "Se encontraron "+ response.data.total +" procesos de alta"
                })
                .catch(error => {
                    this.estado = "Hubo un error, no se encontraron procesos con alta"
                })
            },

            descargarArchivo(id){
                axios.post('gestion-altas-web/altasweb/descargar', {id: id} , {
					responseType: 'blob'
				})
                .then((response) => {
					const filename = response.headers['content-disposition'].split('=')[1].replace(/^\"+|\"+$/g, '')
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                   	const link = document.createElement('a');
   					link.href = url;
   					link.setAttribute('download', filename);
   					document.body.appendChild(link);
   					link.click();                
				})
            },

           
            cambiarPagina(url){
                axios.get(url)
                    .then(response =>{
                        this.altasWeb = response.data
                    })
                    .catch(error =>{
                        this.estado = "Hubo un error, no se encontraron procesos con alta"
                    })
            },

            buscarAltasWeb( ){

                const formdata = new FormData()
                formdata.append("proceso", this.busquedaAltasWeb)
                
              axios.get(`gestion-altas-web/altasweb/buscar/${this.busquedaAltasWeb}`)
              .then(
                response=>{
                    this.altasWeb = [response.data];
                    this.mostrarTodas =false;
                }
              )
              .catch(
                (error)=>{
                   
                    this.uploadError = true;
                    this.errorMessage= error.response.data.error
                    setTimeout(() => this.uploadError = false, 5000)
                   
                }
              )
            },
            
            setModalOpen($proceso){
                this.showModal=true;
                this.procesoModal = $proceso;
            }

        } 


}

</script>

<style scoped>
.searchBarAltasWeb{
   background-color: #e9ecef;
   border-radius: 0.25rem;
}
.searchIcon{
    margin-left: 8px;
}
.btn-search{
    height: 4rem;
}

.btn_complementarias{
    width:100%;

}
.formulario th{
    text-align: center;
}
.formulario td{
    text-align: center;
}
.card-header{
    text-align: center;
}

.card_header_carga{
    text-align: center;
}
.contenedor_gestion_complementarias{
    text-align: center;
    margin-top:40px;
    
}

.formulario td {
    text-align: center;
    vertical-align: middle;
}
.searchBarContainer{
    margin-top:40px
}

.registroTexto div p{
    line-height: 0.5;
}

@media (max-width: 991px) {
    .container-desktop {
        display: none;

    }
}
@media (min-width: 992px) {
    .container-mobile{
        display: none;
    }
}

@media (max-width: 376px) {
    .registroTexto div p{
        line-height: 1;

    }
}
</style>