<template>
	<div class="card certificado-haberes shadow-sm rounded-lg border-0 mb-3 p-4">
		<div class="alert alert-danger" v-if="mensajeError">{{ mensajeError }}</div>
		<div class="d-flex flex-column flex-md-row justify-content-start justify-content-md-between">
			<div class="certificado-haberes__badges mb-3 order-1 order-md-2">
				<badge-tramite :tramite="certificado.tramite.codigo.codigo"></badge-tramite>
				<badge-estado :estado="certificadoHaber.historias[0].estado.estado"></badge-estado>
			</div>
	  		<div class="certificado-haberes__titulo order-2 order-md-1">
	  			<p>{{ certificado.tramite.codigo.codigo }}-{{ certificado.numero }}</p>
	  			<h4>{{ certificado.beneficio.beneficio_id | formatearBeneficio }} | {{ certificado.beneficio.personas[0].nombre }} {{ certificado.beneficio.personas[0].apellido }}</h4>
	  		</div>
	  	</div>
	  	<div class="d-flex flex-column justify-content-start justify-content-md-between">
  			<p>Válido hasta el {{ moment(certificado.created_at).add(20, 'days').format('LL') }}</p>

			<div v-for="historia in certificadoHaber.historias" :key="historia.id">
                <p>
                    <badge-estado :estado="historia.estado.estado"></badge-estado> <br>
                    <span v-if="historia.estado.estado === 'emitido'">
                        <strong>Destino: </strong>
                        <span v-if="certificado.organismo_id">{{ certificado.organismo.nombre_organismo }}</span>
                        <span v-else>{{ certificado.destino }}</span>
                        <br>
                    </span>
                    <strong>Observaciones: </strong>{{ historia.observaciones }} <br>
                    <small class="text-muted">{{ moment(historia.created_at).format('LLL') }} <span v-if="historia.user">por {{ historia.user.persona.nombre }} {{ historia.user.persona.apellido }}</span></small>
                </p>
            </div>
  		</div>

    	<div class="certificado-haberes__acciones">
    		<div class="accion-recibo d-flex flex-column flex-md-row justify-content-start justify-content-md-end">
                <button v-if="certificadosHaberesJefe" :disabled="certificadoHaber.historias[0].estado.estado === 'anulado'" type="button" class="btn btn-danger m-0 ml-md-2 btn-sm mb-2 mb-md-0" data-toggle="modal" :data-target="'#observacionAnulacion' + certificado.id">
                    <i class="fad fa-file-times fas-2x orange-icon"></i>
                    <span class="px-2">{{ certificadoHaber.historias[0].estado.estado === 'anulado' ? 'Anulado' : 'Anular'}}</span>
                </button>

    			<button type="button" class="btn btn-primary mr-0 ml-md-2 btn-sm" @click="descargarCertificado()">
					<i class="fad fa-cloud-download fa-2x"></i>
					<span class="px-2">Descargar</span>
				</button>

			</div>
   		</div>

   		<!-- Modal Observaciones anulación -->
		<div class="modal fade" :id="'observacionAnulacion' + certificado.id" tabindex="-1" role="dialog" aria-labelledby="observacionAnulacion" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
			    <div class="modal-content">
			    	<div class="modal-header">
			    		<h5 class="modal-title">¿Anular certificado {{ certificado.numero }} de {{ certificado.beneficio.personas[0].nombre }} {{ certificado.beneficio.personas[0].apellido }}?</h5>
			    	</div>
			       	<div class="modal-body d-flex flex-column justify-content-center">
			       		<p>Estás por anular el certificado {{ certificado.numero }} del beneficio {{ certificado.beneficio.beneficio_id | formatearBeneficio }} con destino <span v-if="certificado.organismo_id">{{ certificado.organismo.nombre_organismo }}</span><span v-else>{{ certificado.destino }}</span></p>
			      		<textarea v-model="observaciones" placeholder=" Observaciones..." rows="4" cols="40"></textarea>
			      	</div>
			      	<div class="modal-footer">
			        	<button type="button" class="btn btn-primary" v-on:click="anularCertificado(certificadoAAnular)">Si, anular</button>
			        	<button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="cancelarAnulacionCertificado">No, no anular</button>
			      	</div>
			    </div>
			</div>
		</div>
	</div>
</template>

<script>

	import BadgeEstado from '../../../../../elementos/badges/BadgeEstado.vue'
	import BadgeTramite from '../../../../../elementos/badges/BadgeTramite.vue'
    import {mapState} from "vuex";

	export default {
		data () {
			return {
				certificadoHaber: this.certificado,
				certificadoAAnular: null,
				mensajeError: '',
				observaciones: ''
			}
		},
		components: {
        	'badge-estado': BadgeEstado,
        	'badge-tramite': BadgeTramite
        },
		props: [
			'certificado',
			'beneficio'
		],
		computed: {
			historias(){
				return this.certificadoHaber.historias
			},
            ...mapState({
                certificadosHaberesJefe: state => {
                    return state.user.aplicaciones.some(a => a.aplicacion === 'TAD-CERTIFICADOS-HABERES' && a.funcion === 'CERTHABERES-JEFE')
                }
            })
		},
		methods: {
			descargarCertificado() {
			 	this.descargandoCertificado = 1
			 	axios.get('/tramites-a-distancia/certificados-haberes/' + this.certificado.id + '/descargar', {
			 		responseType: 'blob'
			 	})
                    .then((response) => {
                        var filename = 'CERTHAB-' + this.certificado.numero + '-' + this.certificado.beneficio_id + '.pdf'
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(() => {
                        this.mensajeError = "Hubo un inconveniente al intentar descargar el certificado de haber."
                    })
			},
			anularCertificado: function(numero_certificado){
				axios.put('/tramites-a-distancia/certificados-haberes/' + this.certificado.id + '/anular', {'observaciones': this.observaciones})
                    .then((response) => {
                        $('#observacionAnulacion' + this.certificado.id).modal('hide')
                        this.observaciones = ''
                        this.certificadoHaber = response.data
                    })
                    .catch(() => {
                        this.observaciones = ''
                    })
			},
			cancelarAnulacionCertificado(){
				this.observaciones = ''
			}
		}
	};

</script>
