<template>
    <div class="demo w-100">
      <div class="py-5">
        <div class="form-group flex">
          <label>Fechas: </label>
          <date-range-picker
            :locale-data="locale"
            class="ml-1 w-100"
            ref="picker"
            :opens="opens"
            :minDate="minDate" :maxDate="maxDate"
            :showDropdowns="showDropdowns"
            :autoApply="autoApply"
            v-model="dateRange"
            :ranges=" getRanges() "
            @update="updateValues"
            :linkedCalendars="linkedCalendars"
            :dateFormat="dateFormat"
            :always-show-calendars="false"
            :alwaysShowCalendars="alwaysShowCalendars"
            :append-to-body="appendToBody"
            :closeOnEsc="closeOnEsc"
          >
            <template #input="picker" style="min-width: 350px;">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
          </date-range-picker>
  
          <button class="btn btn-danger " @click="handleReset">
            Limpiar
          </button>
          <button @click.stop="$refs.picker.togglePicker()" class="btn btn-secondary ml-1">Mostrar</button>
        </div>
    </div>
    </div>
  </template>
  
  <script>
import DateRangePicker from 'vue2-daterange-picker'
import dateUtil from "vue2-daterange-picker/src/components/date_util/native"

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Vue from 'vue';

  export default {
    components: {DateRangePicker},
    name: 'DateRangePickerDemo',
    emits: ['fechaAgregada'],
    filters: {
      date (value) {
        if (!value)
          return ''
  
        let options = {year: 'numeric', month: 'long', day: 'numeric'};
        return Intl.DateTimeFormat('en-US', options).format(value)
      }
    },
    data () {
  
      return {
        opens: 'center',
        maxDate: '2024-31-06',
        minDate: '2022-01-01',

              //PARA REUSAR EL COMPONENTE HAY QUE ENVIAR POR PROP ESTE Objeto

        dateRange: {
          startDate: Vue.prototype.moment(),
          endDate: Vue.prototype.moment(),
        },
        locale:{
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        },
        single_range_picker: false,
        singleDatePicker: false,
        show_ranges: true,
        showDropdowns: true,
        autoApply: true,
        alwaysShowCalendars:true,
        showWeekNumbers: true,
        linkedCalendars: false,
        appendToBody: false,
        closeOnEsc: true,
      }
    },
    
    methods: {
      handleReset(){
        this.dateRange.startDate = null
        this.dateRange.endDate = null
        this.$emit('fechaAgregada', null)
      },
      
      updateValues (values) {
        this.dateRange.startDate = dateUtil.format(values.startDate, 'mm/dd/yyyy');
        this.dateRange.endDate = dateUtil.format(values.endDate, 'mm/dd/yyyy');
        this.$emit('fechaAgregada', [this.dateRange.startDate, this.dateRange.endDate])
      },


      //PARA REUSAR EL COMPONENTE HAY QUE ENVIAR POR PROP ESTA FUNCION
      getRanges(){
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        let todayEnd = new Date()
        todayEnd.setHours(11, 59, 59, 999);

        let yesterdayStart = new Date()
        yesterdayStart.setDate(today.getDate() - 1)
        yesterdayStart.setHours(0, 0, 0, 0);

        let yesterdayEnd = new Date()
        yesterdayEnd.setDate(today.getDate() - 1)
        yesterdayEnd.setHours(11, 59, 59, 999);

        let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

        return {
          'Hoy': [today, todayEnd],
          'Ayer': [yesterdayStart, yesterdayEnd],
          'Este Mes': [thisMonthStart, thisMonthEnd],
          'Este Año': [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
          ],
          'Ultimo Mes': [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
          ],
        }
      
      },
      dateFormat (classes, date) {
        let yesterday = new Date();
        let d1 = dateUtil.format(date, 'isoDate')
        let d2 = dateUtil.format(yesterday.setDate(yesterday.getDate() - 1), 'isoDate')
        if (!classes.disabled) {
          classes.disabled = d1 === d2
        }
        return classes
      }
    }
  }
  </script>
  
  <style scoped>
  .py-5 {
    padding: 3rem 0;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: inline-block;
  }
  

  
  .form-control {
    display: block;
    max-width: 100%;
    padding: .3rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
  
  .flex {
    display: flex;
    align-items: center;
  }
  
  .ml-1 {
    margin-left: 0.25rem;
  }
  .text-muted {
    color: #6c757d!important;
  }
  </style>