<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div class="contenedor-titulo-seccion">
                    <div class="titulo-seccion">
                        <h2 class="titulo-seccion__texto">
                            Generar nueva contraseña
                        </h2>
                    </div>
                </div>

                <div class="row">
                    <form
                        @submit.prevent="onLogin"
                        class="container p-4 formulario formulario-ingresar"
                        enctype="multipart/form-data"
                    >
                        <div class="row">
                            <div class="col-12 alert alert-danger" role="alert" v-if="errores">
                                {{ errores }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 form-group ingresar__password">
                                <label for="password">Contraseña</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    class="form-control"
                                    :class="{campo__error: errorPassword}"
                                    v-model="password"
                                    placeholder="Nueva contraseña"
                                />
                                <span class="campo__error-mensaje" v-if="errorPassword">Tiene que ingresar su contraseña.<a href="#">¿No la recuerda?</a></span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 form-group ingresar__password">
                                <label for="repetir_password">
                                    Repetir nueva contraseña
                                </label>
                                <input
                                    type="password"
                                    name="repetir_password"
                                    id="repetir_password"
                                    class="form-control"
                                    :class="{campo__error: errorPassword}"
                                    v-model="repetir_password"
                                    placeholder="Repetir contraseña"
                                />
                                <span class="campo__error-mensaje" v-if="errorPassword">Tiene que ingresar su contraseña.<a href="#">¿No la recuerda?</a></span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 form-group">
                                <button class="btn btn-primary boton-formulario-ingresar" @click.prevent="reestablecerPassword">
                                    <span v-if="acciones == ''">Reestablecer Contraseña</span>
                                    <span v-else>{{ acciones }} <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            password: "",
            repetir_password: "",
            errores: null,
            acciones: "",
            errorPassword: false,

            token: this.$route.query.token,
            email: this.$route.query.email
        };
    },
    methods: {
        async reestablecerPassword() {
            this. errores = null

            if (this.password === "" || this.repetir_password === "") {
                this.errores = "Tiene que completar los dos campos con la misma contraseña";
                return;
            }

            if (this.password !== this.repetir_password) {
                this.errores = "Las contraseñas no coinciden";
                return;
            }

            const data = {
                email: this.email,
                token: this.token,
                password: this.password,
                password_confirmation: this.repetir_password
            }

            this.acciones = "Guardando contraseña...";

            try {
                await axios.get("/sanctum/csrf-cookie");
                await axios.post("/reset-password", data)
                this.acciones = "";
                await this.$router.push({name: "Ingresar", query: { password: "modificada" }});
            }
            catch (e) {
                this.acciones = "";
                if (e.response.status === 422) {
                    let erroresValidacion = Object.values(e.response.data.errors)
                    this.errores = erroresValidacion[0][0]
                }
                else {
                    this.errores = "Hubo un error al reestablecer su contraseña";
                }
            }
        }
    }
};
</script>
