<template>
	<div>
		<nav aria-label="breadcrumb">
  			<ol class="breadcrumb">
  				<li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
	    		<li class="breadcrumb-item active"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
	    		<li class="breadcrumb-item active" aria-current="page">Consulta de recibos</li>
  			</ol>
		</nav>
		<div class="w-100">
			<h3>Consulta de recibos</h3>
			<div class="alert alert-warning" v-if="status_error==403">
				En este momento no podés acceder a esta aplicación. Si considerás que se trata de un error o querés saber más al respecto, por favor escribinos a <a href="mailto:soporteweb@crjppf.gov.ar">soporteweb@crjppf.gov.ar</a>
			</div>
			<div v-else-if="status_ok">
				<!--<menu-dpto-liquidaciones></menu-dpto-liquidaciones>-->
				<transition name="fade" mode="out-in" :duration="100"> 
					<router-view></router-view>
				</transition> 
			</div>
			<div v-else>
				<div class="alert alert-info">Accediendo a la aplicación...</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Menu from './menu/MenuDptoLiquidaciones.vue'
	
	export default {
		data(){
			return {
				errores: null,
				status_error: null,
				status_ok: true
			}
		}
		/*components: {
			'menu-dpto-liquidaciones': Menu
		},*/
	};

</script>