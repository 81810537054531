<template>
	<div class="container-fluid">
		<div class="card_usuario">
			<div class="col-12 col-md-8">
				<p class="card-title">{{usuario.nombre}} {{usuario.apellido}}</p>
				<div v-if="usuario.user">
					<a v-if="usuario.user.email" href="mailto:'{ usuario.user.email }">{{ usuario.user.email }}</a>
					<a v-else href="mailto:'{ usuario.user.email_alternativo }">{{ usuario.user.email_alternativo }}</a>
				</div>
				<p class="text-danger" v-else>No se encuentra registrado</p>
                <p>Email institucional: {{usuario.legajos[0].email_institucional}}</p>
				<p>Cuil: {{ usuario.cdi_cuil }}</p>
				<p>Sector: {{usuario.legajos[0].sector.nombre_sector}}</p>
			</div>
			<div class="col-12 col-md-4 d-flex justify-content-center align-items-center" v-if="usuario.user">
				<button @click="filtro = !filtro">
					<i v-if="filtro" class="fas fa-chevron-circle-up"></i>
					<i v-else class="fas fa-chevron-circle-down"></i>
				</button>
			</div>
			<div class="col-12 mt-2 funciones_usuario" v-if="filtro && usuario.user">
				<div>
					<h4>Funciones otorgadas</h4>
					<div v-for="funcion in funcionesUsuario">
						<a href="" @click.prevent :id="'descripcion'+funcion.id" data-toggle="popover" :title="funcion.funcion" :data-content="funcion.descripcion" @mouseover="abrirDescripcion(funcion.id)" @mouseout="cerrarDescripcion(funcion.id)">{{funcion.funcion}}</a>
					</div>
				</div>
				<div class="acciones_usuario">
					<h4>Acciones</h4>

					<button class="btn btn-info shadow-none" v-if="!restaurar" data-toggle="modal" :data-target="'#funciones'+index">
						Agregar/eliminar funciones
					</button>
					<button class="btn btn-info shadow-none" v-if="td && !restaurar" data-toggle="modal" :data-target="'#subsectores'+index">
						Agregar/eliminar subsectores
					</button>
					<button class="btn btn-info shadow-none" v-if="!restaurar" data-toggle="modal" :data-target="'#modal-eliminar'+index">
						Eliminar usuario
					</button>
					<button class="btn btn-info shadow-none" v-if="restaurar" @click="restaurarUsuario()">
						Restaurar usuario
					</button>
				</div>
			</div>
		</div>


		<div class="modal fade" :id="'funciones'+index" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			    <div class="modal-content modal_usuario">
			        <div class="modal-header">
				        <h5 class="modal-title" id="exampleModalLongTitle">{{usuario.nombre}} {{usuario.apellido}}</h5>
				        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
			        </div>
			        <div class="modal-body">
			        	<h2 class="text-center mb-4">Funciones</h2>

						<div v-for="funcion in funcionesUsuario">
							<div class="row mb-1">
								<div class="col-8 funcion_usuario">
				        			<p>{{ funcion.funcion }}</p>
				        		</div>
				        		<div class="col-4 d-flex justify-content-center">
				        			<button class="btn btn-danger" @click="eliminarFuncion(funcion.id)">Eliminar</button>
				        		</div>
							</div>
			        	</div>

						<div class="row">
							<div class="col-8 select-container px-0">
			        			<select name="nuevaFuncion" id="nuevaFuncion" class="form-control" v-model="funcionSelect">
				        			<option value="">Selecciona una funcion</option>
				        			<option v-for="fun in functions" :value="fun">{{ fun.funcion }}</option>
				        		</select>
			        		</div>
			        		<div class="col-4 d-flex justify-content-center">
			        			<button class="btn btn-success" @click="agregarFuncion(funcionSelect)">Agregar</button>
			        		</div>
						</div>

						<div class="row mt-4">
							<div class="col-12">
								<button type="button" class="btn btn-primary float-right ml-1" @click="guardarFuncion(usuario.persona_id)">Guardar</button>

								<button type="button" class="btn btn-secondary float-right" data-dismiss="modal">Cancelar</button>
							</div>

							<div class="col-12 mt-2 alert alert-warning" role="alert" v-if="erroresFuncion">
								<p class="text-dark">{{ erroresFuncion }}</p>
							</div>
						</div>

			        </div>
			    </div>
		    </div>
		</div>


		<div class="modal fade" :id="'subsectores'+index" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			    <div class="modal-content modal_usuario">
			        <div class="modal-header">
				        <h5 class="modal-title" id="exampleModalLongTitle">{{usuario.nombre}} {{usuario.apellido}}</h5>
				        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
			        </div>
			        <div class="modal-body">
			        	<h2 class="text-center mb-4">Subsectores</h2>

						<div v-for="subsector in subsectoresUsuario">
							<div class="row mb-1">
								<div class="col-8 funcion_usuario">
				        			<p>{{ subsector.subsector }}</p>
				        		</div>
				        		<div class="col-4 d-flex justify-content-center">
				        			<button class="btn btn-danger" @click="eliminarSubsector(subsector.id)">Eliminar</button>
				        		</div>
							</div>
			        	</div>

						<div class="row">
							<div class="col-8 select-container px-0">
			        			<select name="nuevaFuncion" id="nuevaFuncion" class="form-control" v-model="subsectorSelect">
				        			<option value="">Selecciona un subsector</option>
				        			<option v-for="sub in subsectoresDisponibles" :value="sub">{{ sub.subsector }}</option>
				        		</select>
			        		</div>
			        		<div class="col-4 d-flex justify-content-center">
			        			<button class="btn btn-success" @click="agregarSubsector(subsectorSelect)">Agregar</button>
			        		</div>
						</div>

						<div class="row mt-4">
							<div class="col-12">
								<button type="button" class="btn btn-primary float-right ml-1" @click="guardarSubsector(usuario.persona_id)">Guardar</button>

								<button type="button" class="btn btn-secondary float-right" data-dismiss="modal">Cancelar</button>
							</div>

							<div class="col-12 mt-2 alert alert-warning" role="alert" v-if="erroresSubsectores">
								<p class="text-dark">{{ erroresSubsectores }}</p>
							</div>
						</div>

			        </div>
			    </div>
		    </div>
		</div>


		<div class="modal fade" :id="'modal-eliminar'+index" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
			    <div class="modal-content">
			      	<div class="modal-header">
				        <h5 class="modal-title" id="exampleModalLongTitle">{{usuario.nombre}} {{usuario.apellido}}</h5>
				        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
			      	</div>
				    <div v-if="usuario.user" class="modal-body">
				    	<div class="alert alert-danger" role="alert">
				    		<p>Desea eliminar el usuario <span v-if="usuario.user.email">{{usuario.user.email}}</span> <span v-else-if="usuario.user.email_alternativo">{{usuario.user.email_alternativo}}</span></p>
				    	</div>
				    </div>
				    <div class="modal-footer">
				        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
				        <button type="button" class="btn btn-primary" @click="eliminarUsuario()">Eliminar</button>
				    </div>
			    </div>
			</div>
		</div>

	</div>
</template>

<script>

	export default{
		props:[
			'usuario',
			'index',
			'functions',
			'restaurar'
		],
		data(){
			return{
				filtro: false,
				funciones: [],
				subsectores: [],
				subsectoresDisponibles: [],
				funcionSelect: '',
				subsectorSelect: '',
				erroresFuncion: '',
				erroresSubsectores: ''
			}
		},
		created(){
			this.cargarFunciones()
		},
		computed:{
			funcionesUsuario(){
				return this.funciones
			},
			subsectoresUsuario(){
				return this.subsectores
			},
			td(){
				if (this.funciones.some(fun => fun.aplicacion_id == 4)) {
					this.obtenerSubsectores()
					return true
				}
				return false
			}
		},
		methods:{
			cargarFunciones(){
				if (this.usuario.user) {
					this.funciones = [...this.usuario.user.funciones]
					this.subsectores = [...this.usuario.user.subsectores]
				}
			},
			eliminarFuncion(funcion){
				this.funciones = this.funciones.filter(fun =>{
					return fun.id != funcion
				})
			},
			eliminarSubsector(subsector){
				this.subsectores = this.subsectores.filter(sub => {
					return sub.id != subsector
				})
			},
			agregarFuncion(funcion){
				var error = false
				this.erroresFuncion = ''

				if (funcion == '') {
					return this.erroresFuncion =  'Tenes que seleccionar una funcion'
				}

				const existe_funcion = this.funciones.some(fun => fun.id === funcion.id)

				if (existe_funcion) {
					error = true
					return this.erroresFuncion = 'El usuario ya dispone de esta funcion'
				}

				if (!error) this.funciones.push(funcion)

			},
			agregarSubsector(subsector){
				var error = false
				this.erroresSubsectores = ''

				if (subsector == '') {
					return this.erroresSubsectores = 'Tenes que seleccionar un subsector'
				}

				const existe_subsector = this.subsectores.some(sub => sub.id === subsector.id)

				if(existe_subsector){
					error = true
					return this.erroresSubsectores = 'El usuario ya dispone de este subsector'
				}

				if (!error) this.subsectores.push(subsector)

			},
			guardarFuncion(usuario){
				var funcionesEnviar = new FormData()

				this.funciones.forEach(fun =>{
					funcionesEnviar.append(fun.aplicacion_id, fun.id)
				})

				axios.post('agregar-funciones-empleado/' + usuario, funcionesEnviar)
				.then(response =>{
					$('#funciones'+this.index).modal('hide')
					this.$emit('mensaje', "Se actualizaron correctamente las funciones")
				})
				.catch(error =>{
					this.erroresFuncion = 'Hubo un error, en este momento no se pueden actualizar las funciones'
				})
			},
			guardarSubsector(usuario){
				var subsectoresEnviar = new FormData()

				this.subsectores.forEach(sub => {
					subsectoresEnviar.append(sub.subsector, sub.id)
				})

				axios.post('agregar-subsectores-empleado/' + usuario, subsectoresEnviar)
				.then(response => {
					$('#subsectores'+this.index).modal('hide')
					this.$emit('mensaje', "Se actualizaron correctamente los subsectores")
				})
				.catch(error => {
					this.erroresSubsectores = 'Hubo un error en este momento no se pueden actualizar los subsectores'
				})
			},
			obtenerSubsectores(){
				axios.get('obtener-subsectores/' + this.usuario.legajos[0].sector_id)
				.then(response => {
					this.subsectoresDisponibles = response.data.data
				})
				.catch(error => {
				})
			},
			abrirDescripcion(id){
				$('#descripcion'+id).popover('show')
			},
			cerrarDescripcion(id){
				$('#descripcion'+id).popover('hide')
			},
			eliminarUsuario(){

				axios.delete('eliminar-usuario/'+this.usuario.persona_id)
				.then(response => {
					var email = ''
					if (response.data.email != '') {
						email = response.data.email
					}
					else{
						email = response.data.email_alternativo
					}

					$('#modal-eliminar'+this.index).modal('hide')
					this.$emit('mensaje', "Se elimino el usuario "+email)
				})
				.catch(error => {
				})
			},
			restaurarUsuario(){


				axios.put('restaurar-usuario', {usuario: this.usuario.persona_id})
				.then(response => {
					var email = ''
					if (response.data.email != '') {
						email = response.data.email
					}
					else{
						email = response.data.email_alternativo
					}

					this.$emit('mensajeRestaurado', "El usuario " + email + " se restauro correctamente")
				})
				.catch(error => {
					this.$emit('mensajeError', error.response.data)
				})
			}
		}
	};
</script>
