<template>
    <div class="row justify-content-around">
        <div class="col-12 col-sm-6 col-lg-4 mb-4"  style="text-decoration: none" @click="descargarNovedadesSUBE" >
            <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                <div class="card-body text-center card-menu__body">
                    <i class="fa fa-database fa-4x mb-3 icono-micaja"></i>
                    <h5 class="card-title card-menu__titulo">Bajada de Beneficios Sube</h5>
                    <p class="card-text">Descargar novedades mensuales SUBE</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4 mb-4"  style="text-decoration: none" >
            <div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
                <div class="card-body text-center card-menu__body">
                    <i class="fa fa-database fa-4x mb-3 icono-micaja"></i>
                    <h5 class="card-title card-menu__titulo">Subida de Beneficios Sube</h5>
                    <p class="card-text">Carga Novedades mensuales SUBE</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default{

    methods: {
        ...mapActions("gestionbeneficiosube",[
            "descargarNovedadesSUBE"
        ]),
       
    },
}
</script>