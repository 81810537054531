<template>
    <div class="d-flex justify-content-center my-4">
        <div class="d-flex align-items-center">
            <button class="btn btn-outline-primary mx-2" @click="cambiarPagina(pagination.links.first)" :disabled="pagination.meta.current_page <=1"><i class="fas fa-angle-double-left"></i> Primera página</button>
            <button  class="btn btn-outline-primary mx-2" @click="cambiarPagina(pagination.links.prev)" :disabled="pagination.links.prev <= pagination.meta.current_page"><i class="fas fa-angle-left"></i> Página anterior</button>
            <span>Página {{ pagination.meta.current_page }} de {{ pagination.meta.last_page }}</span>
            <button  class="btn btn-outline-primary mx-2" @click="cambiarPagina(pagination.links.next)" :disabled="pagination.links.next <= pagination.meta.last_page">Próxima página <i class="fas fa-angle-right"></i></button>
            <button class="btn btn-outline-primary mx-2"  @click="cambiarPagina(pagination.links.last)" :disabled="pagination.meta.current_page >= pagination.meta.last_page">Ultima página <i class="fas fa-angle-double-right"></i></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Paginacion",
    props: [
        'pagination'
    ],
    methods: {
        cambiarPagina(nuevaPagina){
            this.$emit('cambiar-pagina', nuevaPagina)
        }
    }
}
</script>

<style scoped>

</style>
