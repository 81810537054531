<template>
    <div>
        <div v-if="recibosEncontrados?.length > 0 && existeDescargaMasiva">
           <button class="btn btn-primary" style="float:right; margin-bottom:2em" @click="descargarMasivo">Descargar Seleccion</button>
        </div>
        <div v-if="recibosEncontrados?.length > 0 && !existeDescargaMasiva">
            <button class="btn btn-primary" style="float:right; margin-bottom:2em" @click="descargarTodos">Descargar Todos</button>
         </div>
        
         <div v-if="recibosEncontrados?.length > 0">
             <transition-group  appear name="administrador-recibos-haberes" id="administrador-recibos-haberes" tag="div" mode="out-in" v-for="(recibo, index) in recibosEncontrados"
                                  :key="recibo['ruta_recibo']"
                                  :index="index">
                    <recibo
                        :key="recibo['ruta_recibo']"
                        :recibo="recibo"
                        :index="index"
                        :persona="persona"
                    />
            </transition-group>
         </div>
        <div v-else-if="recibosEncontrados?.length ==0 && recibosBuscados" class="card">
            <div class="card-body">No se encontraron Recibos para ese CUIT / BENEFICIO</div>
        </div>
    </div>
</template>

<script>
import Recibo from '../recibo/Recibo.vue'
import  {mapState, mapActions} from 'vuex';
    
export default {
    name : "ListaAdministradorRecibos",
    props:["persona", "recibosEncontrados"], 
    
    components:{
            Recibo
    },
    methods:{
        ...mapActions('administradorreciboshaberes', [
                "descargarLoteRecibos"
            ]),
        async descargarMasivo(){
            var payload = new FormData()
            payload.append("lote", JSON.stringify(this.listadoArchivosDescarga))
            const{data, headers} = await axios.post("administrador-haberes/descarga-masiva", payload, { responseType: 'blob'})
            const filename = headers['content-disposition'].split('=')[1].replace(/^\"+|\"+$/g, '')
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

        },
        async descargarTodos(){
            var payload = new FormData()
            payload.append("lote", JSON.stringify(this.recibosEncontrados))
            const{data, headers} = await axios.post("administrador-haberes/descarga-masiva", payload, { responseType: 'blob'})
            const filename = headers['content-disposition'].split('=')[1].replace(/^\"+|\"+$/g, '')
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

        }
    },
    computed: {
        ...mapState({
            recibosBuscados: state => {
                return  state.administradorreciboshaberes.recibosBuscados
            }, 
            existeDescargaMasiva : state => {
                return Object.keys(state.administradorreciboshaberes.recibosDescargaMasiva).length>0
            },
            listadoArchivosDescarga : state =>{
                return  state.administradorreciboshaberes.recibosDescargaMasiva
            } 


        })
        
    },
    watch: {
        existeDescargaMasiva(val){
            return val
    } 
}
    

}
</script>