<template>
    <div class="d-flex justify-content-center align-items-center">
        <div class="row d-flex justify-content-center">
            <p class="w-100 text-center"><i class="far fa-folder-open display-1 text-muted"></i></p>
            <p class="w-100 text-center text-muted">No existen certificados de complementaria para el beneficio <strong>{{ beneficio | formatearBeneficio }}</strong></p>

        </div>
    </div>
</template>

<script>
export default {
    name: "SinCertificadoComplementaria",
    props: [
        'beneficio'
    ]
}
</script>

<style scoped>

</style>
