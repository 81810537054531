<template>
    <div class="container">
        <div
            class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
        >
            <div class="row">
                <div class="contenedor-titulo-seccion">
                    <div class="titulo-seccion">
                        <h2 class="titulo-seccion__texto">Registrarse</h2>
                        <p class="subtitulo-seccion__texto text-muted">
                            Registro para beneficiarios
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <form class="container p-4 formulario formulario-ingresar" @submit.prevent v-on:keyup.right.prevent="siguiente_pantalla">
                    <div class="row">
                        <div class="col-12 alertas" v-if="errores">
                            <div class="alert alert-warning" role="alert" v-for="error in errores">
                                {{ error }}
                            </div>
                        </div>
                    </div>

                    <div v-show="step === 1">
                        <div class="row">
                            <div class="col my-2">
                                <h3 class="subtitulo-formulario__texto">
                                    Paso 1 de 3: Información personal
                                </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="cuil_cdi">CUIL o CDI</label>
                                <input
                                    type="text"
                                    id="cuil_cdi"
                                    name="cuil_cdi"
                                    class="form-control"
                                    v-model="cuil_cdi"
                                    placeholder="Ingrese su CUIL o CDI"
                                    autofocus
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="select-container form-group col-12">
                                <label for="tipo_documento">Tipo de documento</label>
                                <select
                                    class="form-control"
                                    id="tipo_documento"
                                    name="tipo_documento"
                                    v-model="tipo_documento"
                                    tabindex="0"
                                >
                                    <option value="DNI" selected="selected"
                                        >DNI</option
                                    >
                                    <option value="LC">LC</option>
                                    <option value="LE">LE</option>
                                    <option value="CI">CI</option>
                                </select>
                            </div>
                            <div class="form-group col-12">
                                <label for="numero_documento">Número de documento</label>
                                <input
                                    type="text"
                                    id="numero_documento"
                                    name="numero_documento"
                                    class="form-control"
                                    v-model="numero_documento"
                                    placeholder="Ingrese su número de documento"
                                    tabindex="0"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="select-container form-group col-12">
                                <label for="sexo">Sexo</label>
                                <select
                                    class="form-control"
                                    id="sexo"
                                    name="sexo"
                                    v-model="sexo"
                                    tabindex="0"
                                >
                                    <option value="F">Femenino</option>
                                    <option value="M">Masculino</option>
                                </select>
                            </div>
                            <div class="select-container form-group col-12">
                                <label for="nacionalidad">Nacionalidad</label>
                                <select
                                    class="form-control"
                                    id="nacionalidad"
                                    name="nacionalidad"
                                    v-model="nacionalidad"
                                    tabindex="0"
                                >
                                    <option value="A">Argentina</option>
                                    <option value="E">Extranjero</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6">
                                <button
                                    class="btn boton-anterior-siguiente text-center"
                                    v-on:click.prevent="siguiente_pantalla"
                                    tabindex="0"
                                >
                                    Siguiente
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="container" v-show="step === 2">
                        <div class="row">
                            <div class="col">
                                <h3 class="subtitulo-formulario__texto">
                                    Paso 2 de 3: Sobre su beneficio
                                </h3>
                                <p class="text-muted">
                                    <small
                                        >En caso de poseer más de un beneficio,
                                        puede completar con la información de
                                        cualquiera de ellos, siempre y cuando
                                        toda la información ingresada
                                        corresponda al mismo beneficio.</small
                                    >
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="select-container col-12 col-md-3 form-group"
                            >
                                <label for="clase"> Clase</label>
                                <select
                                    class="form-control"
                                    name="clase"
                                    id="clase"
                                    v-model="clase"
                                    placeholder="Número de clase"
                                    tabindex="0"
                                >
                                    <option
                                        v-for="clase in clases"
                                        :value="clase"
                                        :key="clase"
                                        >{{ clase }}</option
                                    >
                                </select>
                            </div>
                            <div class="col-12 col-md-6 form-group">
                                <label for="beneficio">Beneficio</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    id="beneficio"
                                    name="beneficio"
                                    v-model="beneficio"
                                    placeholder="Número de beneficio"
                                    tabindex="0"
                                    maxlength="6"
                                />
                            </div>
                            <div class="col-12 col-md-3 form-group">
                                <label for="barra">Barra</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    id="barra"
                                    name="barra"
                                    v-model="barra"
                                    placeholder="Número de barra"
                                    tabindex="0"
                                    maxlength="1"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-12 select-container">
                                <label for="boca_pago_id"
                                    >¿Dónde percibe el último haber de este
                                    beneficio?</label
                                >
                                <select
                                    class="form-control"
                                    name="boca_pago_id"
                                    id="boca_pago_id"
                                    v-model="boca_pago_id"
                                    tabindex="0"
                                >
                                    <option
                                        v-for="boca in bocas_pago"
                                        :key="boca.id"
                                        :value="boca.boca_pago"
                                        >{{ boca.nombre_boca_pago }}</option
                                    >
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="select-container col-12 form-group">
                                <label for="apoderado"
                                    >El beneficio ingresado, ¿tiene
                                    apoderado?</label
                                >
                                <select
                                    class="form-control"
                                    name="apoderado"
                                    id="apoderado"
                                    v-model="apoderado"
                                    tabindex="0"
                                >
                                    <option value="S"
                                        >Sí, el beneficio tiene
                                        apoderado</option
                                    >
                                    <option value="N"
                                        >No, el beneficio no tiene
                                        apoderado</option
                                    >
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="select-container col-12 form-group">
                                <small
                                    >Para seleccionar la jerarquía
                                    correspondiente al beneficio, primero tiene
                                    que ingresar su número de clase</small
                                >
                                <label for="jerarquia"
                                    >El beneficio ingresado, ¿qué jerarquía
                                    posee?</label
                                >
                                <select
                                    class="form-control"
                                    id="jerarquia"
                                    name="jerarquia"
                                    v-model="jerarquia"
                                    tabindex="0"
                                >
                                    <option v-for="jerarquia in jerarquias" :value="jerarquia.jerarquia">
                                        {{jerarquia.nombre_jerarquia}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div
                                    class="boton-anterior-siguiente text-center"
                                    v-on:click.prevent="anterior_pantalla"
                                    tabindex="-1"
                                >
                                    <i class="fas fa-chevron-left"></i> Anterior
                                </div>
                            </div>
                            <div class="col-6">
                                <div
                                    class="boton-anterior-siguiente text-center"
                                    v-on:click.prevent="siguiente_pantalla"
                                    tabindex="0"
                                >
                                    Siguiente
                                    <i class="fas fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="step === 3">
                        <div class="row">
                            <div class="col">
                                <h3 class="subtitulo-formulario__texto">
                                    Último paso: Datos de acceso
                                </h3>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="group input-usuario-login">
                                <label for="email">Correo electrónico</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="email"
                                    id="email"
                                    v-model="email"
                                    placeholder="Ingrese su correo electrónico"
                                    tabindex="0"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="group input-usuario-login">
                                <label for="confirmar_email"
                                    >Repita su correo electrónico</label
                                >
                                <input
                                    class="form-control"
                                    id="confirmar_email"
                                    type="text"
                                    name="confirmar_email"
                                    v-model="confirmar_email"
                                    placeholder="Repita su correo electrónico"
                                    tabindex="0"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="group input-password-login">
                                <label for="password">Contraseña</label>
                                <input
                                    class="form-control"
                                    id="password"
                                    type="password"
                                    name="password"
                                    v-model="password"
                                    placeholder="Ingrese su nueva contraseña"
                                    tabindex="0"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="group input-password-login">
                                <label for="confirmar_password"
                                    >Repetir Contraseña</label
                                >
                                <input
                                    class="form-control"
                                    type="password"
                                    id="confirmar_password"
                                    name="confirmar_password"
                                    v-model="confirmar_password"
                                    placeholder="Repita su nueva contraseña"
                                    tabindex="0"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div
                                    class="boton-anterior-siguiente text-center"
                                    v-on:click.prevent="anterior_pantalla"
                                >
                                    <i class="fas fa-chevron-left"></i> Anterior
                                </div>
                            </div>
                            <div class="col-6">
                                <button
                                    class="btn btn-primary boton-formulario-login"
                                    v-if="acciones"
                                >
                                    {{ acciones }}
                                    <i
                                        class="fas fa-circle-notch faa-spin animated faa-fast"
                                    ></i>
                                </button>
                                <button
                                    class="btn btn-primary boton-registrarse"
                                    v-else
                                    v-on:click.prevent="registrarBeneficiario"
                                    v-on:keyup.enter.prevent="
                                        registrarBeneficiario
                                    "
                                >
                                    Registrarse
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-show="step === 4">
                        <h3 class="subtitulo-formulario__texto">
                            Registro finalizado
                        </h3>

                        <div class="alert alert-primary">
                            Le enviamos un correo electrónico a
                            {{ email_registro }} para que pueda verificar su
                            cuenta.
                        </div>
                    </div>
                </form>
            </div>

            <div class="row">
                <div
                    class="col-12 p-2 mt-3 text-center formulario-ingresar__otras-acciones"
                >
                    <router-link
                        class="otras-acciones__link text-decoration-none"
                        :to="{ name: 'Ingresar' }"
                        tabindex="-1"
                        >Ya estoy registrado</router-link
                    >
                </div>
                <div
                    class="col-12 p-2 text-center formulario-ingresar__otras-acciones"
                >
                    <router-link
                        class="otras-acciones__link text-decoration-none"
                        :to="{ name: 'solicitudRecuperarPassword' }"
                        tabindex="-1"
                        >Estoy registrado, pero no recuerdo mi
                        contraseña</router-link
                    >
                </div>
                <div
                    class="col-12 p-2 text-center formulario-ingresar__otras-acciones"
                >
                    <a
                        href="https://micaja.crjppf.gov.ar/documentos/instructivo-registro-beneficiarios.pdf"
                        target="_blank"
                        class="otras-acciones__link text-decoration-none mt-2"
                        >Necesito ayuda para registrarme</a
                    >
                </div>
                <div
                    class="col-12 p-2 text-center formulario-ingresar__otras-acciones"
                >
                    <router-link
                        class="otras-acciones__link text-decoration-none"
                        :to="{ name: 'RegistroEmpleados' }"
                        tabindex="-1"
                        >Soy Empleado de la Caja</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            step: 1,
            acciones: null,
            errores: [],
            bocas_pago: [],
            clases: [],
            email: "",
            confirmar_email: "",
            email_registro: "",
            password: "",
            confirmar_password: "",
            cuil_cdi: "",
            tipo_documento: "DNI",
            numero_documento: "",
            sexo: "",
            nacionalidad: "A",
            clase: "",
            beneficio: "",
            barra: "",
            boca_pago_id: "",
            apoderado: "",
            jerarquia: "",
            jerarquias: []
        };
    },
    methods: {
        buscarBocas() {
            axios.get("consultar-bocas")
                .then(response => {
                    this.bocas_pago = response.data.data;
                });
        },
        buscarClases() {
            axios.get("consultar-clases").then(response => {
                const data = response.data.data;
                this.clases = data.map(clase => {
                    if (clase.id < 10) {
                        return "0" + clase.id;
                    } else {
                        return String(clase.id);
                    }
                });
            });
        },
        anterior_pantalla() {
            $("html,body").animate({ scrollTop: 0 }, 300);
            this.step--;
        },
        siguiente_pantalla() {
            this.errores=[];
            $("html,body").animate({ scrollTop: 0 }, 300);
            if (this.step === 1) {
                if (
                    this.tipo_documento == "" ||
                    this.numero_documento == "" ||
                    this.sexo == "" ||
                    this.nacionalidad == ""
                ) {
                    this.errores.push("Tiene que completar todos los campos");
                } else {
                    this.errores = [];
                    this.buscarBocas();
                    this.buscarClases();
                    this.step++;
                }
            } else if (this.step === 2) {
                if (
                    this.clase == "" ||
                    this.beneficio == "" ||
                    this.barra == "" ||
                    this.apoderado == "" ||
                    this.jerarquia == "" ||
                    this.boca_pago_id == ""
                ) {
                    this.errores.push("Tiene que completar todos los campos");
                } else {
                    this.errores=[];
                    this.step++;
                }
            }
        },
        async registrarBeneficiario() {
            this.errores=[];
            this.acciones = "Registrando ";

            if (
                this.email == "" ||
                this.confirmar_email == "" ||
                this.password == "" ||
                this.confirmar_password == ""
            ) {
                $("html,body").animate({ scrollTop: 0 }, 300);
                this.errores.push("Tiene que completar todos los campos");
                this.acciones = "";
                return;
            }

            if (this.email != this.confirmar_email) {
                $("html,body").animate({ scrollTop: 0 }, 300);
                this.errores.push("Los correos electrónicos no coinciden");
                this.acciones = "";
                return;
            }

            if (this.password != this.confirmar_password) {
                $("html,body").animate({ scrollTop: 0 }, 300);
                this.errores.push("Las contraseñas no coinciden");
                this.acciones = "";
                return;
            }

            let dato_usuario = this.cuil_cdi.replace(/-/g, "");
            dato_usuario = dato_usuario.replace(/_/g, "");
            dato_usuario = dato_usuario.replace(/ /g, "");

            const formData = {
                email: this.email,
                password: this.password,
                confirmar_password: this.confirmar_password,
                cdi_cuil: dato_usuario,
                tipo_documento: this.tipo_documento,
                numero_documento: this.numero_documento,
                sexo: this.sexo,
                nacionalidad: this.nacionalidad,
                clase_id: this.clase,
                beneficio: this.beneficio,
                barra: this.barra,
                boca_pago_id: this.boca_pago_id,
                apoderado: this.apoderado,
                jerarquia: this.jerarquia
            };

            $("html,body").animate({ scrollTop: 0 }, 300);

            try {
                await axios.get("/sanctum/csrf-cookie");
                const { data } = await axios.post("/registrarse/beneficiarios", formData)

                this.errores = [];
                this.acciones = "";
                this.step = 4;
                this.email_registro = data.email;
            }
            catch (e) {
                this.acciones = "";
                if (e.response.status === 422) {
                    const {errors} = e.response.data
                    this.errores = Object.values(errors).map(error => error[0])
                } else {
                    this.errores = ["Hubo un inconveniente con la aplicación. Por favor intente en unos minutos."];
                }
            }
        }
    },
    watch: {
        clase: function(newClase) {
            axios.get("consultar-jerarquias/" + newClase)
                .then(response => {
                    this.jerarquias = response.data.data;
                });
        }
    }
};
</script>
