<template>
    <div
        v-if="
            historia.estado.estado == 'comentado' &&
                $store.state.user.funciones.td == 1
        "
    ></div>

    <div class="historia-transferencia text-muted" v-else>
        <small>
            {{ moment(historia.created_at).format("LLL") }} (
            {{
                moment(historia.created_at, "YYYY-MM-DD h:mm:ss").fromNow()
            }}
            )<br />
            <strong
                >Estado: {{ historia.estado.estado }}
                <span v-if="historia.estado.estado == 'transferido'"
                    >a {{ historia.subsector.sector.nombre_sector }} -
                    {{ historia.subsector.subsector }}</span
                ></strong
            ><br />
            Usuario: {{ historia.user.persona.nombre }}
            {{ historia.user.persona.apellido }} -
            <a
                v-if="historia.user.email_alternativo"
                :href="`mailto:${historia.user.email_alternativo}`"
                >{{ historia.user.email_alternativo }}</a
            >
            <a
                v-else-if="historia.user.email"
                :href="`mailto:${historia.user.email}`"
                >{{ historia.user.email }}</a
            >
            <br />

            {{ historia.user.subsectores[0].sector.nombre_sector }} -
            <span
                v-if="
                    historia.user.subsectores[0].sector.sede.organismo_id == 0
                "
            >
                Caja de Retiros, Jubilaciones y Pensiones de la Policía
                Federal</span
            >
            <span v-else>{{
                historia.user.subsectores[0].sector.sede.organismo
                    .nombre_organismo
            }}</span>

            <br />

            <span v-if="historia.observaciones"
                >Observaciones: {{ historia.observaciones }}<br
            /></span>
        </small>

        <button
            class="btn btn-outline-secondary transferencia__accion-descargar"
            v-if="historia.archivo"
            @click="descargarArchivo(historia.archivo)"
        >
            <span v-if="descargando">Descargando...</span>
            <span v-else>Descargar archivo</span>
        </button>
        <span v-if="descargando"
            ><small class="text-muted"
                >La descarga puede demorar en base al tamaño del archivo.</small
            ></span
        >
        <span v-else-if="descargado"
            ><small class="text-success"
                >Archivo descargado correctamente.</small
            ></span
        >
        <span v-else-if="errorDescarga"
            ><small class="text-danger"
                >Error al descargar el archivo. Intente nuevamente.</small
            ></span
        >
    </div>
</template>

<script>
export default {
    data() {
        return {
            user_id: this.$store.state.user.user_id,
            descargando: false,
            descargado: false,
            errorDescarga: false
        };
    },
    props: ["historia"],
    methods: {
        descargarArchivo: function(archivo) {
            this.descargando = true;
            this.descargado = false;
            this.errorDescarga = false;
            axios
                .post(
                    "transferencias/descargar-archivo",
                    { archivo: archivo },
                    {
                        responseType: "blob"
                    }
                )
                .then(response => {
                    this.descargando = false;
                    this.descargado = true;
                    this.errorDescarga = false;
                    var filename = response.headers["content-disposition"]
                        .split("=")[1]
                        .replace(/^\"+|\"+$/g, "");
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    this.descargando = false;
                    this.descargado = false;
                    this.errorDescarga = true;
                });
        }
    }
};
</script>