import { DateTime } from "luxon";

const formatearFechaYHora = date => {
    return DateTime.fromISO(date)
        .setLocale('es')
        .toFormat('dd LLLL yyyy HH:mm')
}

const formatearFecha = date => {
    return DateTime.fromISO(date)
        .setLocale('es')
        .toFormat('DDD')
}

const formatearStringFechaPublicacion = (date) =>{
    const objectDate = new Date(date);
    const day = new Intl.DateTimeFormat("es-ES", {weekday:"long"}).format(objectDate);
    const month = new Intl.DateTimeFormat("es-ES", {month:"long"}).format(objectDate);
    const year = objectDate.getFullYear();
    return `${day.charAt(0).toUpperCase() + day.slice(1)} ${objectDate.getDate()} de ${month.charAt(0).toUpperCase() + month.slice(1)} de ${year}`;
}
const formatearFechaHace = date => {
    return DateTime.fromISO(date).toRelative()
}

const formatearAgregarDias = (date, dias) => {
    return DateTime.fromISO(date)
        .plus({days: dias})
        .toFormat('DDD')
}

const fechaActual = () => {
    return DateTime.local()
        .toFormat('DDD')
}

export {
    formatearFechaYHora,
    formatearFecha,
    formatearFechaHace,
    formatearAgregarDias,
    fechaActual,
    formatearStringFechaPublicacion
}