<template>
    <div class="container">
        <div
            class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
        >
            <h2 class="mt-3 text-center">
                Verificar Cuenta
            </h2>

            <div
                class="alert alert-danger"
                role="alert"
                v-if="errores.length > 0"
            >
                <div v-for="error in errores" :key="error.cod">
                    {{ error.error }}
                </div>
            </div>

            <div
                class="alert alert-success mt-2"
                role="alert"
                v-if="validacion_ok"
            >
                <p>
                    La verificación de su cuenta se ha efectuado
                    satisfactoriamente.<br />
                    En breve recibirá en su correo electrónico los datos de
                    acceso a Mi Caja. <br />
                    ¡Bienvenido!
                </p>
                <router-link :to="{ name: 'Ingresar' }"
                    >Ingresar a Mi Caja</router-link
                >
            </div>

            <form
                class="formulario"
                v-on:submit.prevent
                v-if="errores.length == 0"
                v-show="!validacion_ok"
            >
                <div class="row mb-2">
                    <div class="col-12">
                        <p>Para verificar su cuenta debe seleccionar sus datos correspondiente, en caso de ingresar datos incorrectos su cuenta se bloqueara y
                            debera contactarse con la casilla de correo: informacion_pasivos@crjppf.gov.ar informando su desbloqueo</p>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <label class="d-block font-weight-bold"
                            >Tipo de Documento</label
                        >
                        <div
                            class="form-check form-check-inline"
                            v-for="tipo in datos.tipos_documentos"
                            :key="tipo.tipo_documento"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                :name="tipo.tipo_documento"
                                :id="tipo.tipo_documento"
                                :value="tipo.tipo_documento.trim()"
                                v-model="tipo_documento"
                            />
                            <label
                                class="form-check-label"
                                :for="tipo.tipo_documento"
                            >
                                {{ tipo.tipo_documento }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <label class="d-block font-weight-bold"
                            >Numero de Documento</label
                        >
                        <div
                            class="form-check form-check-inline"
                            v-for="numero in datos.numeros_documentos"
                            :key="numero"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                :name="numero"
                                :id="numero"
                                :value="numero"
                                v-model="numero_documento"
                            />
                            <label class="form-check-label" :for="numero">
                                {{ numero }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <label class="d-block font-weight-bold"
                            >Fecha de Nacimiento</label
                        >
                        <div
                            class="form-check form-check-inline"
                            v-for="(fecha, index) in datos.fechas"
                            :key="index"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                :name="index"
                                :id="index"
                                :value="fecha"
                                v-model="fecha_nacimiento"
                            />
                            <label class="form-check-label" :for="index">
                                {{ fecha }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <label class="d-block font-weight-bold"
                            >Jerarquía</label
                        >
                        <div
                            class="form-check"
                            v-for="Jerarquia in datos.jerarquias"
                            :key="Jerarquia.jerarquia"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                :name="Jerarquia.jerarquia"
                                :id="Jerarquia.jerarquia"
                                :value="Jerarquia.jerarquia.trim()"
                                v-model="jerarquia"
                            />
                            <label
                                class="form-check-label"
                                :for="Jerarquia.jerarquia"
                            >
                                {{ Jerarquia.nombre_jerarquia }}
                            </label>
                        </div>
                    </div>
                </div>
                <button
                    :disabled="!btn"
                    class="btn btn-primary btn-block"
                    @click="verificar"
                    v-if="estado === ''"
                >
                    Verificar
                </button>
                <button class="btn btn-primary btn-block" v-else>
                    {{ estado }}
                    <i
                        class="fas fa-circle-notch faa-spin animated faa-fast"
                    ></i>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "VerificacionAlta",
    data() {
        return {
            token: "",
            tipo_documento: "",
            numero_documento: "",
            fecha_nacimiento: "",
            jerarquia: "",
            errores: [],
            estado: "",
            datos: [],
            validacion_ok: false
        };
    },
    created() {
        if (this.$route.params.token) {
            this.token = this.$route.params.token;
        }
        this.getDatos();
        
    },
    computed: {
        btn() {
            if (
                this.tipo_documento !== "" &&
                this.numero_documento !== "" &&
                this.fecha_nacimiento !== "" &&
                this.jerarquia !== ""
            ) {
                return true;
            }
        }
    },
    methods: {
        getDatos() {
            axios
                .get("datos-alta/" + this.token)
                .then(response => {
                    this.datos = response.data;
                })
                .catch(error => {
                    this.errores.push(error.response.data);
                });
        },
        verificar() {
            this.estado = "Verificando...";
            this.errores = [];

            const formData = new FormData();
            formData.append("token", this.token);
            formData.append("tipo_documento", this.tipo_documento);
            formData.append("numero_documento", this.numero_documento);
            formData.append("fecha_nacimiento", this.fecha_nacimiento);
            formData.append("jerarquia", this.jerarquia);

            axios
                .post("verificar-alta", formData)
                .then(response => {
                    this.validacion_ok = true;
                })
                .catch(error => {
                    this.estado = "";
                    if (error.response.status === 422) {
                        let erroresValidacion = Object.values(
                            error.response.data.errors
                        );
                        this.errores = erroresValidacion.map(error => {
                            return error[0];
                        });
                    }
                    if (error.response.status === 400) {
                        this.errores.push(error.response.data);
                    }
                });
        }
    }
};
</script>

<style scoped></style>
