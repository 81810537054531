<template>
    <div>
        <p class="text-danger">Estado: {{estado =='sin_beneficio' ? 'Sin Beneficio Activo': 'Beneficio Dado De Baja'}}</p>
    <div class="card card_condiciones_servicio">
        <h5 >Puede {{estado =='sin_beneficio' ? 'habilitar': 'reactivar'}} su beneficio, recuerde: </h5>
        <div>
        <ul class="beneficio_sube_card_body_text">
            <li>Ciertos datos personales serán enviados a NSSA (Nación Servicios)</li>
            <li>El beneficio solo se aplicará en la Tarjeta SUBE que tenga registrada bajo el mismo número de DNI que tenga el usuario de “Mi caja”.</li>
        </ul>
    </div>
    </div>
    <button v-if="estado =='sin_beneficio'" class="btn btn-primary  mt-3 boton_alta" @click="activarBeneficio">Activar beneficio SUBE</button>
    <button v-else class="btn btn-primary  mt-3 boton_alta" @click="activarBeneficio">Reactivar beneficio SUBE</button>

</div>
</template>
<script>

import {mapActions, mapState} from 'vuex';

export default{
    
    computed: {
        ...mapState({
        numeroDocumento: state => state.persona.numero_documento,
        tipoDocumento: state => state.persona.tipo_documento,
        estado: state => state.gestionbeneficiosube.estado,
    }),
    },
    methods: {
        ...mapActions('gestionbeneficiosube', [
            'habilitarBeneficioSube',
        ]),
        async activarBeneficio(){
            const { value: accept } = await this.$swal.fire({
            title: "Consentimiento para la Utilización de Datos Personales",
            input: "checkbox",
            inputValue: 0,
            inputPlaceholder: `
                He leído las condiciones y manifiesto mi consentimiento
            `,
            customClass: {
                input: 'checkbox_terminos'
            },
            html:`<div class="card">
                    <p>Manifiesto mi consentimiento expreso e informado para la utilización de mis datos personales conforme a los términos establecidos en la Ley 25.236 de Protección de Datos Personales y en el Convenio de Intercambio Electrónico de Información Recíproca celebrado entre la Caja de Retiros, Jubilaciones y Pensiones de la Policía Federal (en adelante "LA CAJA") y Nación Servicios Sociedad Anónima (en adelante "NSSA"), con los siguientes fines:</p>
                    <ul>
                        <li>Complementar las Bases de Datos del Sistema Único de Boleto Electrónico (SUBE): Para la implementación del beneficio de tarifa social de transporte al que tengo derecho según el convenio.</li>
                        <li>Verificación y Actualización de Datos: Para asegurar la precisión de los datos en los sistemas de LA CAJA y NSSA.</li>
                    </ul>
                </div>`,
            confirmButtonText: `Activar`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            inputValidator: (result) => {
                return !result && "Necesita confirmar su consentimiento antes de continuar";
            }
            });
            if (accept) {
                this.habilitarBeneficioSube({ "tipo_documento": this.tipoDocumento, "numero_documento": this.numeroDocumento }).catch(
                    error => {
                        this.$swal.fire({
                        title: "Error al crear el beneficio.",
                        inputPlaceholder: `
                            Ha surgido un error en la aplicacion. Intente Nuevamente mas tarde
                        `,
                        icon: "error",
                        confirmButtonText: `Aceptar`,
                    })
                
                })
            }
        }
    }
}

</script>
