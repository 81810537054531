<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesOperacionesMenu'}">Operaciones</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Gestion de Complementarias</li>
            </ol>
        </nav>
        <h1 class="titulo-aplicacion titulo_gestion_comple">Gestion de Complementarias</h1>
      

        <menu-gestion-complementarias></menu-gestion-complementarias>
        <div class="aplicacionesContainer">
        <router-view></router-view>
        </div>

    </div>
</template>

<script>
import MenuGestionComplementarias from "./menu/MenuGestionComplementarias.vue";

export default {
    components: {
        "menu-gestion-complementarias": MenuGestionComplementarias
    },
}
   
</script>

<style scoped>
.titulo_gestion_comple{
    text-align: center;
}

.aplicacionesContainer{
    margin-top:50px;
}

</style>
