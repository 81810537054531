<template>
	<div class="w-100">		
		<router-view></router-view>
		<!--<sin-tramites v-else></sin-tramites>-->
	</div>
</template>

<script>

	import SinTramites from './elementos/SinTramites.vue'
	
	export default {
		components: {
			'sin-tramites': SinTramites
		},
		data(){
			return{
				tiene_tramites: false
			}
		}
	};


</script>