<template>
    <div class="transferencia__contenedor">
        <div class="transferencia__body" v-for="ultima_historia in ultimaHistoria.slice(0,1)"
             :key="ultima_historia.created_at">
            <div class="card-title transferencia__titulo">
                <h5><small class="text-muted">ID {{ transferencia.id | numSerie }}</small> {{ transferencia.asunto }}
                </h5>
            </div>

            <badges-enviadas v-if="(pagina=='enviadas')" :td="td" :datosTransferencia="datosTransferencia"
                             :ultima_historia="ultima_historia"></badges-enviadas>

            <badges-recibidas v-if="(pagina=='recibidas')" :td="td" :datosTransferencia="datosTransferencia"
                              :ultima_historia="ultima_historia"></badges-recibidas>

            <badges-novedades v-if="(pagina=='novedades')" :td="td" :datosTransferencia="datosTransferencia"
                              :ultima_historia="ultima_historia"></badges-novedades>

            <badges-archivadas v-if="(pagina=='archivadas')" :td="td" :datosTransferencia="datosTransferencia"
                               :ultima_historia="ultima_historia"></badges-archivadas>

            <div class="text-muted transferencia__fecha">
                <i class="far fa-calendar-alt transferencia__icono"></i>
                <small class=" transferencia__texto buscar-transferencia__fecha-texto"><strong>Ultima
                    actualización:</strong><br> {{ moment(ultima_historia.created_at).format('LLL') }} (
                    {{ moment(ultima_historia.created_at, "YYYY-MM-DD h:mm:ss").fromNow() }} )
                </small>
            </div>

            <div class="text-muted card-text transferencia__observacion">
                <i class="far fa-comment transferencia__icono"></i>
                <small class="transferencia__texto buscar-transferencia__observacion-texto"
                       v-if="ultima_historia.observaciones">
                    <strong>Observaciones:</strong><br> {{ ultima_historia.observaciones }}
                </small>
                <small class="buscar-transferencia__observacion-texto" v-else>
                    <strong>Observaciones:</strong> Sin observaciones realizadas
                </small>
            </div>

            <div class="transferencia__errores alert alert-danger" v-if="errores">
                {{ errores }}
            </div>

            <div class="transferencia__errores alert alert-success" v-if="mensaje">
                {{ mensaje }}
            </div>

            <div class="transferencia__acciones">
                <button class="btn btn-outline-secondary transferencia__accion" @click="masInformacion" v-if="mostrarMasInformacion">
                    <i class="far fa-folder-open"></i><span> Cerrar transferencia</span>
                </button>
                <button class="btn btn-outline-secondary transferencia__accion" @click="masInformacion(ultima_historia.estado.estado)" v-else>
                    <i class="far fa-folder"></i><span> Abrir transferencia</span>
                </button>
            </div>

            <div class="contenedor__transferencia-informacion text-muted" v-if="mostrarMasInformacion">
                <div class="transferencia-informacion__acciones">
                    <div class="texto" v-if="datosTransferencia.tipo_id==1">
                        <small><strong>Tipo de transferencia:</strong> masiva</small><br>
                        <small v-if="datosTransferencia.nombre_proceso"><strong>Corresponde al mes o proceso:</strong>
                            {{ datosTransferencia.nombre_proceso }} </small>
                    </div>
                    <div class="texto" v-else>
                        <small><strong>Tipo de transferencia:</strong> individual</small><br>
                        <small v-if="datosTransferencia.numero_documento"><strong>Documento de identidad:</strong>
                            {{ datosTransferencia.tipo_documento }}
                            {{ datosTransferencia.numero_documento }}</small><br>
                        <small v-if="datosTransferencia.cdi_cuil"><strong>CDI / CUIL:</strong>
                            {{ datosTransferencia.cdi_cuil }}</small><br>
                        <small v-if="datosTransferencia.nombre"><strong>Nombre:</strong> {{ datosTransferencia.nombre }}</small>
                    </div>
                </div>

                <div class="transferencia-informacion__acciones">
                    <!-- VER HISTORIA -->
                    <button class="btn btn-outline-secondary transferencia__accion"
                            @click="mostrarSecciones('historia')">
                        <span>Ver historial</span>
                    </button>
                    <button class="btn btn-outline-secondary transferencia__accion"
                    @click="imprimirReporteDescuentos"
                    v-if="mostrarBotonErroresDescuento">
                        <span><i class="fas fa-download p-1"></i>Reporte</span>
                    </button>

                    <!-- ESTADO: RECIBIDO -->
                    <button class="btn btn-outline-secondary transferencia__accion"
                            @click="mostrarSecciones('transferir')"
                            v-if="mostrarBotonTransferir">
                        <span>Transferir</span>
                    </button>

                    <button class="btn transferencia__accion btn_aceptar"
                            @click="mostrarSecciones('aceptar')"
                            v-if="mostrarBotonAceptar">
                        <span>Aceptar</span>
                    </button>
                    <button class="btn transferencia__accion btn_rechazar"
                            @click="mostrarSecciones('rechazar')"
                            v-if="mostrarBotonDevolver">
                        <span>Devolver al organismo</span>
                    </button>

                    <!-- ESTADO: ACEPTADO -->
                    <button class="btn btn-outline-secondary transferencia__accion"
                            @click="mostrarSecciones('comentar')"
                            v-if="mostrarBotonAgregarNota">
                        <span>Agregar Nota</span>
                    </button>
                    <button class="btn transferencia__accion btn_observar"
                            @click="mostrarSecciones('observar')"
                            v-if="mostrarBotonObservar">
                        <span>Observar</span>
                    </button>

                    <button class="btn transferencia__accion btn_archivar"
                            @click="mostrarSecciones('archivar')"
                            v-if="mostrarBotonArchivar">
                        <span>Archivar</span>
                    </button>

                    <button class="btn transferencia__accion btn_procesar"
                            @click="mostrarSecciones('procesar')"
                            v-if="mostrarBotonProcesar">
                        <span>Procesar</span>
                    </button>

                    <button class="btn transferencia__accion btn_contestar"
                            @click="mostrarSecciones('contestar')"
                            v-if="mostrarBotonContestar">
                        <span>Contestar</span>
                    </button>

                    <button class="btn btn-warning transferencia__accion"
                            @click="mostrarSecciones('reenviar')"
                            v-if="mostrarBotonReenviarInformacion">
                        <span>Reenviar información</span>
                    </button>

                </div>
            </div>
        </div>

        <div v-if="mostrarMasInformacion">

            <!-- VER HISTORIAS -->
            <div class="buscar-transferencia__mas-informacion" v-if="historialTransferencia">
                <div v-for="historia in datosTransferencia.historias" :key="historia.updated_at">
                    <historias-transferencia :historia="historia"></historias-transferencia>
                </div>
            </div>

            <!-- RECHAZAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formRechazarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formRechazarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Motivo del rechazo..."
                              name="observacionRechazar" v-model="observacionRechazar"></textarea>
                    <button type="button" class="btn btn_rechazar" @click="rechazarTransferencia"
                            @keyup.enter="rechazarTransferencia">Rechazar transferencia
                    </button>
                </form>
            </div>

            <!-- OBSERVAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formObservarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formObservarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Observaciones..."
                              name="observacionObservar" v-model="observacionObservar"></textarea>
                    <button type="button" class="btn btn_observar" @click="observarTransferencia"
                            @keyup.enter="observarTransferencia">Observar Transferencia
                    </button>
                </form>
            </div>

            <!-- ACEPTAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formAceptarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formAceptarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Observaciones (opcional)"
                              name="observacionAceptar" v-model="observacionAceptar"></textarea>
                    <button type="button" class="btn btn_aceptar" @click="aceptarTransferencia"
                            @keyup.enter="aceptarTransferencia">Aceptar transferencia
                    </button>
                </form>
            </div>


            <!-- TRANSFERIR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formTransferirTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formTransferirTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Motivos de la transferencia..."
                              name="observacionTransferir" v-model="observacionTransferir"></textarea>

                    <div class="select-container form-group col-12">
                        <label for="sector">Sector</label>
                        <select id="sector" class="form-control" v-model="subsector_id" name="subsector_id">
                            <option v-for="sector in sectores" :value="sector.id">{{ sector.sector.nombre_sector }} -
                                {{ sector.subsector }}
                            </option>
                        </select>
                    </div>

                    <button type="button" class="btn btn_transferir" @click="transferirTransferencia"
                            @keyup.enter="transferirTransferencia">Transferir
                    </button>
                </form>
            </div>

            <!-- REENVIAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formReenviarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formReenviarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Agregar observaciones..."
                              name="observacionReenviar" v-model="observacionReenviar"></textarea>
                    <input class="form-control-file" type="file" ref="file" name="archivo" id="archivo" accept=".zip"
                           @change="subirArchivo">
                    <span class="text-muted aclaracion__archivo"><small>sólo se admite un archivo .zip con todos los documentos a enviar</small></span>

                    <button type="button" class="btn btn_observar" v-if="accionReenviar">Reenviando información</button>
                    <button type="button" class="btn btn_observar" @click="reenviarTransferencia"
                            @keyup.enter="reenviarTransferencia" v-else>Reenviar información
                    </button>

                </form>
                <span v-if="accionReenviar"><small class="text-muted">El reenvío de información puede demorar en base al tamaño del archivo.</small></span>
            </div>

            <!-- CONTESTAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formContestarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formContestarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Agregar observaciones..."
                              name="observacionContestar" v-model="observacionContestar"></textarea>
                    <input class="form-control-file" type="file" ref="file" name="archivo" id="archivo" accept=".zip"
                           @change="subirArchivo">
                    <span class="text-muted aclaracion__archivo"><small>sólo se admite un archivo .zip con todos los documentos a enviar</small></span>

                    <button type="button" class="btn btn_contestar" v-if="accionContestar">Enviando contestación
                    </button>
                    <button type="button" class="btn btn_contestar" @click="contestarTransferencia"
                            @keyup.enter="contestarTransferencia" v-else>Contestar transferencia
                    </button>
                </form>
                <span v-if="accionContestar"><small class="text-muted">La contestación puede demorar en base al tamaño del archivo.</small></span>
            </div>

            <!-- DEVOLVER TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formDevolverTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formDevolverTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Motivos de la devolución..."
                              name="observacionDevolver" v-model="observacionDevolver"></textarea>
                    <button type="button" class="btn btn_devolver" @click="devolverTransferencia"
                            @keyup.enter="devolverTransferencia">Devolver transferencia
                    </button>
                </form>
            </div>

            <!-- PROCESAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formProcesarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formProcesarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Observaciones (opcional)"
                              name="observacionProcesar" v-model="observacionProcesar"></textarea>
                    <button type="button" class="btn btn_procesar" @click="procesarTransferencia"
                            @keyup.enter="procesarTransferencia">Procesar transferencia
                    </button>
                </form>
            </div>

            <!-- FINALIZAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formFinalizarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formFinalizarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Observaciones (opcional)"
                              name="observacionFinalizar" v-model="observacionFinalizar"></textarea>
                    <button type="button" class="btn btn_finalizar" @click="finalizarTransferencia"
                            @keyup.enter="finalizarTransferencia">Finalizar transferencia
                    </button>
                </form>
            </div>

            <!-- ARCHIVAR TRANSFERENCIA -->
            <div class="contenedor__transferencia-correccion" v-if="formArchivarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formArchivarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Observaciones (opcional)"
                              name="observacionArchivar" v-model="observacionArchivar"></textarea>
                    <button type="button" class="btn btn_archivar" @click="archivarTransferencia"
                            @keyup.enter="archivarTransferencia">Archivar transferencia
                    </button>
                </form>
            </div>

            <!-- COMENTARIO - NOTA -->
            <div class="contenedor__transferencia-correccion" v-if="formComentarTransferencia">
                <div class="transferencia__errores alert alert-danger" v-if="erroresForm">
                    {{ erroresForm }}
                </div>
                <form @submit.prevent id="formComentarTransferencia" class="formulario__corregir-observacion">
                    <textarea class="form-control textarea-observaciones" placeholder="Agregar nota..."
                              name="observacionComentar" v-model="observacionComentar"></textarea>
                    <button type="button" class="btn btn-outline-secondary" @click="comentarTransferencia"
                            @keyup.enter="comentarTransferencia">Agregar nota
                    </button>
                </form>
            </div>
        </div>
    </div>

</template>

<script>

    import HistoriasTransferencia from './HistoriasTransferencia.vue'
    import BadgeEstado from '../badges/BadgeEstado.vue'
    import BadgeOrganismo from '../badges/BadgeOrganismo.vue'
    import BadgesPaginaNovedades from '../badges/BadgesPaginaNovedades.vue'
    import BadgesPaginaEnviadas from '../badges/BadgesPaginaEnviadas.vue'
    import BadgesPaginaRecibidas from '../badges/BadgesPaginaRecibidas.vue'
    import BadgesPaginaArchivadas from '../badges/BadgesPaginaArchivadas.vue'
    export default {
        components: {
            'historias-transferencia': HistoriasTransferencia,
            'badge-estado': BadgeEstado,
            'badge-organismo': BadgeOrganismo,
            'badges-novedades': BadgesPaginaNovedades,
            'badges-enviadas': BadgesPaginaEnviadas,
            'badges-recibidas': BadgesPaginaRecibidas,
            'badges-archivadas': BadgesPaginaArchivadas
        },
        props: [
            'transferencia',
            'sectores',
            'pagina'
        ],
        data () {
            return {
                datosTransferencia: this.transferencia,
                primeraHistoria: this.transferencia.historias[0],
                mostrarMasInformacion: false,
                informacionTransferencia: true,
                accionReenviar: false,
                accionContestar: false,
                accionRechazar: false,
                // formularios
                formRechazarTransferencia: false,
                formObservarTransferencia: false,
                formAceptarTransferencia: false,
                formTransferirTransferencia: false,
                formDevolverTransferencia: false,
                formProcesarTransferencia: false,
                formFinalizarTransferencia: false,
                formArchivarTransferencia: false,
                formComentarTransferencia: false,
                formContestarTransferencia: false,
                formReenviarTransferencia: false,
                historialTransferencia: false,
                // campo observaciones
                observacionRechazar: null,
                observacionObservar: null,
                observacionAceptar: null,
                observacionTransferir: null,
                observacionDevolver: null,
                observacionProcesar: null,
                observacionFinalizar: null,
                observacionArchivar: null,
                observacionComentar: null,
                observacionContestar: null,
                observacionReenviar: null,
                //td: this.$store.state.user.funciones.td,
                subsector_id: null,
                errores: null,
                erroresForm: null,
                mensaje: null,
                archivo: null,
                sectores_id_usuario: []
            }
        },
        computed: {
            funciones: {
                get(){
                    return Object.keys(this.$store.state.user.roles);
                },
                set(val){
                    this.$store.state.user.funciones = val;
                }
            },
            td(){
                if(this.$store.state.user.aplicaciones.some(f=>f.funcion==='TRANSFDOC-USUARIO')) {return 1; }
                if(this.$store.state.user.aplicaciones.some(f=>f.funcion==='TRANSFDOC-EMPLEADO')) {return 2; }
                if(this.$store.state.user.aplicaciones.some(f=>f.funcion==='TRANSFDOC-COORDINADOR')) {return 3; }
                if(this.$store.state.user.aplicaciones.some(f=>f.funcion==='TRANSFDOC-JEFE')) {return 4; }
                if(this.$store.state.user.aplicaciones.some(f=>f.funcion==='TRANSFDOC-DIRECTOR')) {return 5; }
            },
            ultimaHistoria() {
                var historias = []
                for(let historia of this.datosTransferencia.historias){

                    if(historia.estado == null){
                        continue;
                    }
                    if( historia.estado.estado == 'comentado'){
                        continue
                    }
                    else if((historia.estado.estado == 'transferido' || historia.estado.estado == 'devuelto') && this.td==1){
                        continue
                    }


                    historias.push(historia)
                }
                return historias
            },
            mostrarBotonAceptar(){
                if(this.ultimaHistoria[0].estado.estado === 'recibido'){
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td ==1 || this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonDevolver() {
                if(this.ultimaHistoria[0].estado.estado === 'recibido'){
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonTransferir(){
                if(this.ultimaHistoria[0].estado.estado === 'recibido'){
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonAgregarNota(){
                if( this.ultimaHistoria[0].estado.estado === 'aceptado' ||
                    this.ultimaHistoria[0].estado.estado === 'contestado' ||
                    this.ultimaHistoria[0].estado.estado === 'observado' ||
                    this.ultimaHistoria[0].estado.estado === 'procesado'){
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonObservar(){
                if( this.ultimaHistoria[0].estado.estado === 'aceptado'){
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonArchivar(){
                let i;
                if( this.ultimaHistoria[0].estado.estado === 'aceptado' ||
                    this.ultimaHistoria[0].estado.estado === 'contestado' ||
                    this.ultimaHistoria[0].estado.estado === 'rechazado' ||
                    this.ultimaHistoria[0].estado.estado === 'procesado' ) {
                    if(this.td == 2){
                        for(i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 1 || this.td == 3 || this.td == 4 || this.td == 5){
                        for(i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonProcesar(){
                if( this.ultimaHistoria[0].estado.estado === 'aceptado' ||
                    this.ultimaHistoria[0].estado.estado === 'contestado'){
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonContestar(){
                if( this.ultimaHistoria[0].estado.estado === 'aceptado' ||
                    this.ultimaHistoria[0].estado.estado === 'procesado'){
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                           if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonReenviarInformacion(){
                if( this.ultimaHistoria[0].estado.estado === 'observado'){
                    if (this.td == 1){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.datosTransferencia.subsector.sector_id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 2){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].id == this.ultimaHistoria[0].subsector.id){
                                return true;
                            }
                        };
                    }
                    if(this.td == 1 || this.td == 3 || this.td == 4 || this.td == 5){
                        for(var i = 0; i < this.$store.state.user.subsectores.length; i++) {
                            if(this.$store.state.user.subsectores[i].sector_id == this.ultimaHistoria[0].subsector.sector_id){
                                return true;
                            }
                        };
                    }
                }
                return false;
            },
            mostrarBotonErroresDescuento(){
                return this.transferencia.descuento!==null
            }
        },
        methods: {
            masInformacion(ultimo_estado) {
                this.mostrarMasInformacion = ! this.mostrarMasInformacion
                for(let subsector of this.$store.state.user.subsectores) {
                    if((this.td==3 || this.td==4 || this.td==5) && (subsector.id != this.ultimaHistoria[0].subsector.sector_id)) continue
                    if((this.td==1 || this.td== 2) && (subsector.id != this.ultimaHistoria[0].subsector.id)) continue
                    if(ultimo_estado === 'enviado' || ultimo_estado === 'reenviado' || ultimo_estado === 'devuelto' || ultimo_estado === 'transferido'){
                        this.mensaje = 'Recibiendo transferencia'
                        axios.post('transferencias/' + this.transferencia.id + '/recibir', {})
                        .then((response) => {
                            this.datosTransferencia = response.data
                            this.mensaje = null
                        })
                        .catch((error) => {
                            this.errores = error.response.data.error
                            this.mensaje = null
                        })
                    }
                }
            },
            imprimirReporteDescuentos(){
               axios.get('transferencias/descuento/' + this.transferencia.id + '/descargar-reporte')
                .then(response=>{
                    var filename = 'reporte-transferencia' + this.transferencia.id + '.txt'
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error=>{
                    this.errores = error.response.data.error
                })
            },
            cambiarMasInformacion(seccion){
                if(seccion == 'historia') {
                    this.historialTransferencia = !this.historialTransferencia
                }
            },
            mostrarSecciones(seccion){
                this.errores = null
                this.mensaje = null
                this.formRechazarTransferencia = this.formRechazarOrganismoTransferencia = this.formObservarTransferencia = this.formAceptarTransferencia = this.formTransferirTransferencia = this.formDevolverTransferencia = this.formProcesarTransferencia = this.formFinalizarTransferencia = this.formArchivarTransferencia = this.formComentarTransferencia = this.historialTransferencia = this.formContestarTransferencia = this.formReenviarTransferencia = false
                if(seccion == 'aceptar') this.formAceptarTransferencia = true
                if(seccion == 'rechazar') this.formRechazarTransferencia = true
                if(seccion == 'contestar') this.formContestarTransferencia = true
                if(seccion == 'transferir') this.formTransferirTransferencia = true
                if(seccion == 'devolver') this.formDevolverTransferencia = true
                if(seccion == 'observar') this.formObservarTransferencia = true
                if(seccion == 'procesar') this.formProcesarTransferencia = true
                if(seccion == 'archivar') this.formArchivarTransferencia = true
                if(seccion == 'comentar') this.formComentarTransferencia = true
                if(seccion == 'reenviar') this.formReenviarTransferencia = true
                if(seccion == 'historia') this.historialTransferencia = true
            },
            rechazarTransferencia(){
                if(this.observacionRechazar == null){
                    this.erroresForm = "Tiene que agregar un detalle de porqué está rechazando el envío"
                    this.accionRechazar = false
                    return
                }
                axios.post('transferencias/' + this.transferencia.id + '/rechazar', {
                    observaciones: this.observacionRechazar
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionRechazar = null,
                    this.formRechazarTransferencia = false,
                    this.mensaje = 'Se rechazó la transferencia correctamente'
                })
                .catch((error) => {
                    this.observacionRechazar = null,
                    this.formRechazarTransferencia = false,
                    this.errores = error.response.data.error
                })
            },
            contestarTransferencia(){
                this.accionContestar = true
                this.erroresForm = null
                if(this.observacionContestar == null){
                    this.erroresForm = "Tiene que agregar una observación"
                    this.accionContestar = false
                    return
                }
                var form = document.getElementById('formContestarTransferencia')
                let formData = new FormData(form)
                if(this.archivo != null){
                    if(this.archivo.name.split(".").pop() != 'zip'){
                        this.erroresForm = "El archivo adjunto debe tener la extensión .zip"
                        this.accionContestar = false
                        return false;
                    }
                    formData.append('archivo', this.archivo)
                }
                formData.append('observaciones', this.observacionContestar)
                axios.post('transferencias/' + this.transferencia.id + '/contestar', formData)
                .then((response) => {
                    this.accionContestar = false
                    this.datosTransferencia = response.data
                    this.formContestarTransferencia = false
                    this.observacionContestar = null
                    this.mensaje = 'Contestación enviada'
                    this.erroresForm = null
                })
                .catch((error) => {
                    this.accionContestar = false
                    this.observacionContestar = null
                    this.formContestarTransferencia = false
                    this.errores = 'No se pudo enviar la contestación'
                    this.erroresForm = null
                })
            },
            reenviarTransferencia(){
                this.accionReenviar = true
                this.erroresForm = null
                if(this.observacionReenviar == null){
                    this.erroresForm = "Tiene que enviar un archivo o bien agregar una observación"
                    return
                }
                var form = document.getElementById('formReenviarTransferencia')
                let formData = new FormData(form)
                if(this.archivo != null){
                    if(this.archivo.name.split(".").pop() != 'zip'){
                        this.erroresForm = "El archivo adjunto debe tener la extensión .zip"
                        return false;
                    }
                    formData.append('archivo', this.archivo)
                }
                formData.append('observaciones', this.observacionReenviar)
                axios.post('transferencias/' + this.transferencia.id + '/reenviar', formData)
                .then((response) => {
                    this.accionReenviar = false
                    this.mensaje = 'El reenvio se efectuó correctamente'
                    this.datosTransferencia = response.data
                    this.formReenviarTransferencia = false
                    this.observacionReenviar = null
                    this.erroresForm = null
                })
                .catch((error) => {
                    this.accionReenviar = false
                    this.errores = error.response.data.error
                    this.observacionReenviar = null
                    this.formReenviarTransferencia = false
                })
            },
            observarTransferencia(){
                if(this.observacionObservar == null){
                    this.erroresForm = "Tiene que agregar el motivo por el cual se observa la transferencia"
                    return
                }
                axios.post('transferencias/' + this.transferencia.id + '/observar', {
                    observaciones: this.observacionObservar
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionObservar = null,
                    this.formObservarTransferencia = false,
                    this.mensaje = 'Se envió la observación'
                })
                .catch((error) => {
                    this.observacionObservar = null,
                    this.formObservarTransferencia = false,
                    this.errores = error.response.data.error
                })
            },
            aceptarTransferencia(){
                axios.post('transferencias/' + this.transferencia.id + '/aceptar', {
                    observaciones: this.observacionAceptar
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionAceptar = null
                    this.formAceptarTransferencia = false
                    this.mensaje = 'La transferencia fue aceptada.'
                })
                .catch((error) => {
                    this.observacionAceptar = null
                    this.formAceptarTransferencia = false
                    this.errores = error.response.data.error
                })
            },
            transferirTransferencia(){
                axios.post('transferencias/' + this.transferencia.id + '/transferir', {
                    subsector_id: this.subsector_id,
                    observaciones: this.observacionTransferir
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionTransferir = null
                    this.formTransferirTransferencia = false
                    this.mensaje = 'Se transfirió correctamente.'
                })
                .catch((error) => {
                    this.observacionTransferir = null,
                    this.formTransferirTransferencia = false,
                    this.errores = error.response.data.error
                })
            },
            devolverTransferencia(){
                if(this.observacionDevolver == null){
                    this.erroresForm = "Tiene que agregar el motivo por el cual se devuelve"
                    return
                }
                axios.post('transferencias/' + this.transferencia.id + '/devolver', {
                    observaciones: this.observacionDevolver
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionDevolver = null,
                    this.formDevolverTransferencia = false,
                    this.mensaje = 'La transferencia fue devuelta al usuario'
                })
                .catch((error) => {
                    this.observacionDevolver = null,
                    this.formDevolverTransferencia = false,
                    this.errores = error.response.data.error
                })
            },
            procesarTransferencia(){
                axios.post('transferencias/' + this.transferencia.id + '/procesar', {
                    observaciones: this.observacionProcesar
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionProcesar = null,
                    this.formProcesarTransferencia = false,
                    this.mensaje = 'Transferencia procesada'
                })
                .catch((error) => {
                    this.observacionProcesar = null,
                    this.formProcesarTransferencia = false,
                    this.errores = error.response.data.error
                })
            },
            archivarTransferencia(){
                axios.post('transferencias/' + this.transferencia.id + '/archivar', {
                    observaciones: this.observacionArchivar
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionArchivar = null,
                    this.formArchivarTransferencia = false,
                    this.mensaje = 'Transferencia archivada'
                })
                .catch((error) => {
                    this.observacionArchivar = null,
                    this.formArchivarTransferencia = false,
                    this.errores = error.response.data.error
                })
            },
            /********************** CAMBIAR COMO VAN LOS DATOS POR FORMULARIO ********************/
            comentarTransferencia(){
                if(this.observacionComentar == null){
                    this.erroresForm = "Tiene que agregar contenido a la nota"
                    return
                }
                axios.post('transferencias/' + this.transferencia.id + '/comentar', {
                    observaciones: this.observacionComentar
                })
                .then((response) => {
                    this.datosTransferencia = response.data
                    this.observacionComentar = null
                    this.formComentarTransferencia = false
                    this.mensaje = 'Nota agregada correctamente.'
                })
                .catch((error) => {
                    this.observacionComentar = null
                    this.formComentarTransferencia = false
                    this.errores = error.response.data.error
                })
            },
            subirArchivo: function(archivo){
                this.archivo = this.$refs.file.files[0]
            },
        }
    }
</script>
