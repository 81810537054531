<template>
    <div v-if="accesoPermitido">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en:
                    <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name: 'MisAplicacionesMenu' }"
                        >Mis Aplicaciones</router-link
                    >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Punto a Punto
                </li>
            </ol>
        </nav>
        <h2 class="titulo-aplicacion">
            Punto a Punto <i class="far fa-share-square"></i>
        </h2>
        <menu-punto-a-punto></menu-punto-a-punto>

        <router-view></router-view>

        <!-- Modal -->
        <div
            class="modal fade"
            id="recordatorioHorarioPuntoAPunto"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <p>
                            Recuerde que el horario por defecto para utilizar la
                            aplicación Punto a Punto es de lunes a viernes de
                            07:00 hs a 19:00 hs.<br />
                            Cualquier duda o consulta escríbanos a
                            <a href="mailto:soporteweb@crjppf.gov.ar"
                                >soporteweb@crjppf.gov.ar</a
                            >
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Recordármelo la próxima vez
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            v-on:click="guardarRecordatorioCookie"
                        >
                            No mostrar de nuevo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-100" v-else>
        <div class="alert alert-primary text-center">{{ mensaje }}</div>
    </div>
</template>

<script>
import MenuPuntoAPunto from "./menu/MenuPuntoAPunto.vue";

export default {
    components: {
        "menu-punto-a-punto": MenuPuntoAPunto
    },
    mounted() {
        if (!$cookies.get("crjppf_mi_caja_recordar_horario_punto_a_punto")) {
            $("#recordatorioHorarioPuntoAPunto").modal("show");
        }
    },
    data() {
        return {
            accesoPermitido: false,
            mensaje: "Verificando acceso a la aplicación..."
        };
    },
    created() {
        this.accederPuntoAPunto();
    },
    methods: {
        guardarRecordatorioCookie: function() {
            $cookies.set(
                "crjppf_mi_caja_recordar_horario_punto_a_punto",
                "true",
                60 * 60 * 24 * 30 * 12 * 10
            );
            $("#recordatorioHorarioPuntoAPunto").modal("hide");
        },
        accederPuntoAPunto: function() {
            axios
                .get("acceder-punto-a-punto", {
                  
                })
                .then(response => {
                    this.accesoPermitido = true;
                })
                .catch(error => {
                    this.accesoPermitido = false;
                    this.mensaje =
                        "No puede acceder a la aplicación Punto a Punto en este momento.";
                });
        }
    }
};
</script>
