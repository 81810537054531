<template>
    <div>
        <div class="card datos-persona">
            <div class="card-body">
                <h5 class="card-title">Datos personales</h5>
                <p class="card-text">
                    <span v-if="datospersona.cdi_cuil">CDI/CUIL: {{ datospersona.cdi_cuil }}</span>
                    <br />
                    <span v-if="datospersona.numero_documento">Documento:<span v-if="datospersona.tipo_documento">{{datospersona.tipo_documento }}</span>{{ datospersona.numero_documento }}</span>
                    <br />
                    <span v-if="datospersona.fecha_nacimiento">Fecha de nacimiento:{{moment(datospersona.fecha_nacimiento).format("LL") }}</span>
                </p>

                <p class="small text-muted" v-if="esBeneficiario">
                    Cualquier información personal errónea, por favor infórmelo a la brevedad <router-link :to="{ name: 'MisConsultas' }">aquí</router-link>
                </p>
                <p class="small text-muted" v-else>
                    Cualquier información personal errónea, por favor infórmelo a la brevedad escribiendo a
                    <a :href="`mailto:soporteweb@crjppf.gov.ar`">soporteweb@crjppf.gov.ar</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    data: function() {
        return {
            errores: null
        };
    },
    props: ["datospersona"],
    computed: mapState({
        esBeneficiario: state =>  {
            return state.user.roles.includes('BENEFICIARIO')
        },
    }),
    methods: {
        irConstanciaCUIL: function() {
            window.open(
                "https://www.argentina.gob.ar/descarga-constancia-cuil",
                "_blank"
            );
            $("#irConstanciaCUIL").modal("hide");
        }
    }
};
</script>
