<template>
    <div class="container-fluid usuarios_empleados_buscar">
        <div class="row">
            <div class="col-12">
                <form class="formulario" v-on:submit.prevent>
                    <h4 class="busqueda-transferencias__titulo mb-3">
                        Buscar empleados
                    </h4>
                    <div class="row">
                        <div class="form-group select-container col-12 col-md-4">
                            <label for="select">Buscar por:</label>
                            <select class="form-control" name="select" id="select" v-model="select">
                                <option value="1">Usuario</option>
                                <option value="2">Sector</option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-8" v-if="select === '1'">
                            <label class="d-block" for="usuario">Buscar</label>
                            <div class="buscador-usuario">
                                <input class="form-control" type="text" name="usuario" id="usuario" placeholder="Buscar por email crjppf" v-model="usuario"/>
                                <button class="btn btn-primary" @click="buscarEmpleado(usuario)">
                                    <i class="fas fa-search px-5"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group select-container col-12 col-md-8" v-if="select === '2'">
                            <label for="sector">Sector</label>
                            <select class="form-control" name="sector" id="sector" v-model="sectorSelect">
                                <option value="">Todos los empleados</option>
                                <option v-for="sector in sectores" :value="sector.id">
                                    {{ sector.nombre_sector }}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="mensaje">
            {{ mensaje }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="mensaje = ''">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="row  justify-content-end">
            <div class= "col-12 col-md-4 vaciar-busqueda-usuario" v-if="showClear">
                <button class ="btn btn-primary" @click="obtenerUsuarios()">
                    <i class="fas fa-trash px-2"></i><p>Limpiar Busqueda</p>
                </button>
            </div>
        </div>

        <div v-if="!noEncontrado" id="encontrados">
            <div class="busqueda-transferencias__texto-paginacion text-muted">
                Se encontraron <strong> {{ total }} empleados </strong> en
                {{ nombreSectorSelect[0].nombre_sector }}
            </div>
        </div>

        <div v-if="buscando">
            <div class="alert alert-info" role="alert">
                Buscando usuario...
            </div>
        </div>
        <div v-else-if="noEncontrado">
            <div class="alert alert-warning" role="alert">
                {{ noEncontrado }}
            </div>
        </div>
        <div class="row" v-else v-for="(usuario, index) in usuarios" :key="usuario.persona_id">
            <div class="col-12">
                <usuario
                    :usuario="usuario"
                    :index="index"
                    :functions="funcionesDisponibles"
                    v-on:mensaje="mostrarMensaje($event)"
                ></usuario>
            </div>
        </div>

        <div v-if="total > 10 && !noEncontrado">
            <paginacion
                :pagination="urls"
                v-on:cambiar-pagina="cambiarPagina($event)"
            ></paginacion>
        </div>
    </div>
</template>

<script>
import usuario from "./elementos/Usuario.vue";
import pagination from "../../../../elementos/pagination/Pagination.vue";
import axios from "axios";

export default {
    components: {
        usuario: usuario,
        paginacion: pagination
    },
    data() {
        return {
            cuil: "",
            errores: [],
            mensaje: "",
            usuarios: [],
            noEncontrado: "",
            buscando: false,
            sectores: [],
            select: '1',
            sectorSelect: "",
            total: 0,
            nombreSectorSelect: [{nombre_sector: "Todos los sectores"}],
            urls: "",
            funcionesDisponibles: [],
            usuario: "",
            showClear: false,
        };
    },
    created() {
        this.obtenerUsuarios();
        this.obtenerSectores();
        this.obtenerFunciones();

        if (this.$route.params.mensaje) {
            this.mensaje = this.$route.params.mensaje;
        }
    },
    watch: {
        sectorSelect: function (newValor, oldValor) {
            if (newValor === '') {
                this.obtenerUsuarios();
                this.nombreSectorSelect = [
                    {nombre_sector: "Todos los sectores"}
                ];
            } else {
                this.buscando = true;
                this.noEncontrado = "";

                axios.get("obtener-empleados-sector/" + newValor + "?por_pagina=10")
                    .then(response => {
                        this.urls = response.data;
                        this.usuarios = response.data.data;
                        this.nombreSectorSelect = this.sectores.filter(
                            sector => {
                                if (sector.id == newValor) {
                                    return sector;
                                }
                            }
                        );
                        this.total = response.data.total;
                        this.buscando = false;
                    })
                    .catch(error => {
                        this.errores.push(error.response.data);
                        this.buscando = false;
                    });
            }
        }
    },
    methods: {
        obtenerUsuarios() {
            this.buscando = true;
            this.noEncontrado = "";
            this.sectorSelect = "";
            this.usuario="";

            axios.get("obtener-empleados?por_pagina=10")
                .then(response => {
                    this.urls = response.data;
                    this.buscando = false;
                    this.usuarios = response.data.data;
                    this.total = response.data.total;
                    this.nombreSectorSelect = [
                        {nombre_sector: "Todos los sectores"}
                    ];
                    this.showClear=false
                })
                .catch(error => {
                    this.buscando = false;
                    this.noEncontrado = error.response.data;
                });
        },
        obtenerSectores() {
            axios.get("sectores-caja")
                .then(response => {
                    this.sectores = response.data.data;
                });
        },
        async buscarEmpleado(usuario) {
            this.buscando = true;
            this.noEncontrado = '';

            if (usuario === '') {
                this.buscando = false;
                return (this.noEncontrado = "Tiene que ingresar un usuario");
            }

            try {
                const { data } = await axios.get(`buscar-empleado/${usuario}`)
                this.total = 1
                this.usuarios.splice(0, this.usuarios.length, data);
                this.buscando = false;
                this.showClear = true;
            }
            catch (e) {
                this.noEncontrado = e.response.data.data;
                this.buscando = false;
            }
        },
        cambiarPagina(url) {
            axios.get(url)
                .then(response => {
                    this.urls = response.data;
                    this.usuarios = response.data.data;
                    $("html, body").animate({
                        scrollTop: $("#encontrados").offset().top
                    });
                })
                .catch(error => {
                    this.errores = error.response.data.error;
                });
        },
        obtenerFunciones() {
            axios.get("obtener-funciones")
                .then(response => {
                    this.funcionesDisponibles = response.data.data;
                })
                .catch(error => {
                    this.errores = error.response.data;
                });
        },
        mostrarMensaje(msj) {
            this.obtenerUsuarios();
            setTimeout(() => (this.mensaje = msj), 250);
        }
    }
};
</script>
