<template>
    <div class="container">
       <!--  <div class="card">
            <div class="card-body">
                <div class="alert">
                    <span>Por el momento no existen capacitaciones disponibles. </span>
                </div>
            <button class="btn btn-primary" @click="navigateBack()">Volver</button>
            </div>
        </div> -->
        <div class="formulario_capacitacion_container">
            <h2 class="titulo_capacitacion_container">Capacitación Abierta para Beneficiarios de la CRJPPF sobre Prevención en el Uso de la Tecnología</h2>
            <div class="formulario_capacitacion_card card ">
                <form ref="formulario_capacitacion" class="row" >            
                    <div class="col-12 col-md-6 form-group">
                      <label for="nombre">Nombre</label>
                      <input type="text" class="form-control"  aria-describedby="nombre" v-model="formulario.nombre">
                    </div>
                    <div class="  col-12 col-md-6 form-group">
                        <label for="apellido">Apellido</label>
                        <input type="text" class="form-control" aria-describedby="apellido" v-model="formulario.apellido">
                    </div>
                    <div class="col-4 form-group align-self-end">
                        <label for="documento_tipo">Tipo Documento </label>
                        <select  class="w-100  form-select" aria-label="tipo_documento_select" v-model="selected">
                            <option v-for="(value, key) in tiposDni" :value="key" >{{value}} 
                                <i class="fas fa-sortdown"></i>
                            </option>
                        </select>
                    </div>
                      <div class="col-8 form-group align-self-end">
                        <label for="numero_documento">Numero de Documento</label>
                        <input type="text" class="form-control" aria-describedby="numero_documento" v-model="formulario.numero_documento">
                      </div>
                      <div class=" col-4 form-group">
                        <label for="beneficio_clase">Clase</label>
                        <input type="text" class="form-control" aria-describedby="beneficio_clase " maxlength="2" v-model="formulario.clase">
                      </div>
                      <div class="col-8 form-group">
                        <label for="beneficio_numero">Beneficio</label>
                        <input type="text" class="form-control" aria-describedby="beneficio_numero" maxlength="6" v-model="formulario.beneficio">
                      </div>
                      <div class="col-12 form-group">
                        <label for="beneficio_email">Email</label>
                        <input type="mail" class="form-control" aria-describedby="beneficio_email" v-model="formulario.email">
                      </div>
                      <div class="col-12" v-if="emtyField">
                        <small style="color:red"><strong>* Todos los campos son obligatorios, revise la informacion.</strong></small>
                      </div>
                    <div class="col-12">
                        <p>Es muy importante que al registrarse, proporcione sus datos correctos y completos en este formulario de inscripción. Esto asegurará que pueda recibir el enlace para acceder a la capacitación sin inconvenientes. Verifique que la información proporcionada sea precisa, especialmente su correo electrónico, ya que será la vía a través de la cual le enviaremos el enlace de acceso a la sesión.</p>
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary" @click="realizarInscripcion" style="float:right">Enviar</button>
                    </div>
                  </form>
                 
            </div>
        </div>
        
    </div>
</template>
<script>
import axios from 'axios';
import router from '../../routes';


export default{
    data(){
        return{

            tiposDni:{
                1: "DNI",
                2: "LC",
                3: "CI",
                4: "LE",
            },

            selected:1,

            emtyField : false,
            formulario: {
                "nombre": "",
                "apellido": "",
                "tipo_documento":"DNI",
                "numero_documento":"",
                "clase": "",
                "beneficio": "",
                "email":"",
            }
        }
    },

    methods:{
        navigateBack(){
            this.$router.push({path: '/' })
        },
        async realizarInscripcion(){
            if(Object.values(this.formulario).includes('')){
                this.emtyField=true;
            }else{
                var formData = new FormData()
                for (const [key, value] of Object.entries(this.formulario)) {
                formData.append(`${key}`, value.toString());
                }
                axios.post("/capacitacion-uso-tecnologia", formData)
                .then(successResponse =>{      
                    console.log(successResponse.data.message);
                    this.$swal.fire({
                        title: "Ha comenzado el proceso correctamente ",
                        text: successResponse.data.message,
                        icon: "success"
                        }).then(() => {
                            this.navigateBack()
                        })
                })
                .catch( error =>{
                     this.$swal.fire({
                    title: error.response.data.message ,
                    icon: "error"
                    })
                }); 
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.titulo_capacitacion_container{
    width: 80%;
}
.formulario_capacitacion_container{
    width: 100%;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

}
.formulario_capacitacion_card{
    max-width: 60%;
    padding:2em;
    border-radius: 1em;

}

@media screen and (max-width: 1000px) {
    .formulario_capacitacion_card {
      min-width: 90%;

    }
  }
</style>