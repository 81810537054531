
const obtenerEstadoBeneficio = (tipoDocumento, numeroDocumento ) => {
    return axios.get(`personas/${tipoDocumento}/${numeroDocumento}/beneficio-sube`)
}

const habilitarServicio = (tipoDocumento, numeroDocumento ) => {
    let payload = new FormData();
    payload.append("tipo_documento", tipoDocumento);
    payload.append("numero_documento", numeroDocumento);
    return axios.post('personas/beneficio-sube/alta', payload);
}

const deshabilitarServicio = (tipoDocumento, numeroDocumento ) => {
    let payload = new FormData();
    payload.append("tipo_documento", tipoDocumento);
    payload.append("numero_documento", numeroDocumento);
    return axios.post('personas/beneficio-sube/baja', payload);
}

const getNovedadesSube = () => {
    return axios.get("personas/beneficio-sube/descarga-masiva")
}

/*
const reenviarVerificacion = (email) => {
    return axios.post("gestion-beneficiarios/reenviar-verificacion", {email: email})
}

const desbloquearUsuario = (datos) => {
    return axios.post("gestion-beneficiarios/desbloquear-alta", datos)
} */

export {
    obtenerEstadoBeneficio,
    habilitarServicio,
    deshabilitarServicio,
    getNovedadesSube
  /*   verificarPassword,
    modificarEmail,
    reenviarVerificacion,
    desbloquearUsuario */
}
