<template>
    <div>
        <!-- <h4 Recibos CUIT {{persona.persona_id}} - {{persona.nombre}} {{persona.apellido}}</h4>
         -->
         <div v-if="recibosEncontrados?.length > 0">
             <transition-group  appear name="mis-recibos-penitenciaria" id="mis-recibos-penitenciaria" tag="div" mode="out-in" v-for="(recibo, index) in recibosEncontrados"
                                  :key="recibo['ruta_recibo']"
                                  :index="index">
                    <recibo
                        :key="recibo['ruta_recibo']"
                        :recibo="recibo"
                        :index="index"
                        :persona="persona"
                    />
            </transition-group>
         </div>
        <div v-else-if="recibosEncontrados?.length ==0 && recibosBuscados" class="card">
            <div class="card-body">No se encontraron Recibos para ese CUIT / BENEFICIO</div>
        </div>
    </div>
</template>

<script>
import Recibo from '../recibo/Recibo.vue'
import  {mapState} from 'vuex';
    
export default {
    name : "ListaRecibosPenitenciaria",
    props:["recibosEncontrados", "persona"],
    components:{
            Recibo
    },
    computed: {
        ...mapState({
            recibosBuscados: state => {
                return  state.gestionpenitenciaria.recibosBuscados
            } 
                
        })
    },
}
</script>

