const getCertificados = () => {
    return axios.get('beneficiarios/mis-documentos/certificados-complementarias')
}

const descargarCertificado = id => {
    return axios.get(`beneficiarios/mis-documentos/certificados-complementarias/${id}/descargar`, {responseType: 'blob'})
}

export {
    getCertificados,
    descargarCertificado
}
