<template>
    <div>
        <h2>Buscar sede</h2>
    </div>
</template>

<script>
export default {
name: "MisAplicacionesOperacionesOrganismosSedeBuscar"
}
</script>

<style scoped>

</style>
