<template>
    <div class="row  busqueda-transferencias">
        <h3 class="busqueda-transferencias__titulo col-12">Transferencias {{ pagina_busqueda }}</h3>
            <form
              
                @submit.prevent
                @keyup.esc="eliminarBusqueda"
            >
            <div class="row w-100">
                <div  class="col-8" >
                    <input
                    class=" w-100"
                    type="text"
                    id="busqueda"
                    name="busqueda"
                    v-model="busqueda"
                    placeholder="Buscar..."
                />
                </div>
                <div class="col-4">
                    <div class="row">
                        <label class=" etiqueta_busqueda col-12">
                        Buscar Por:
                        </label>
                        <select  class="form-select col-12 form-select-lg w-100" aria-label="tipodebusqueda">
                            <option v-for="option of Object.keys(tipos_de_busqueda)"  :value="option" >{{ tipos_de_busqueda[option] }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end mt-3">
                <button
                    class="btn btn-primary transferencia__accion col-4 mr-3 "
                   
                >
            <!--  @keyup.enter="buscarTransf(busqueda)"
                @click="buscarTransf(busqueda)" -->
                <i class="fas fa-search"></i> Buscar
            </button>
            </div>
            </form>
    </div> 
</template>
<script>
export default{
    name: "BuscadorTransferencias",
    props:["tipo_transferencia"],
    data (){
        /*
        private const BUSQUEDA_ID = 1;
    private const BUQUEDA_ASUNTO = 2 ;
    private const BUSQUEDA_NOMBRE_APELLIDO = 3; 
    private const BUSQUEDA_DNI=4;
    private const BUSQUEDA_ORGANISMO=5;
        */
        return {
            pagina_busqueda :"",
            busqueda:"",
            tipos_de_busqueda:{
                1 : "ID",
                2 : "Asunto",
                3 : "Nombre y Apellido",
                4 : "DNI",
                5 : "Organismo(Id o Nombre)",
            }
        }
    },
    created: function(){
        this.pagina_busqueda = this.$props.tipo_transferencia;
    }
}
</script>
<style lang="scss" scoped>
.etiqueta_busqueda{
    font-size: small;
    font-weight: bolder
}
.busqueda-transferencias__eliminar {
}

.busqueda-transferencias__eliminar h5 {
    margin-right: 1rem;
    margin-bottom: 0 !important;
}

@media screen and (min-width: 576px) {
    .busqueda-transferencias__eliminar h5 {
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 768px) {
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>