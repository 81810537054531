<template>
  <div class="w-100" v-if="accesoPermitido">
    <router-view></router-view>
  </div>

  <div class="w-100" v-else>
    <div class="alert alert-primary text-center"> {{ mensaje }} </div>
  </div>
</template>

<script>

export default {
  data() {
      return {
        accesoPermitido: false,
        mensaje: 'Verificando acceso a la aplicación...'
      }
  },
  created() {
      this.accederOperaciones()
  },
  methods: {
      accederOperaciones: function() {
          axios.get('acceder-operaciones')
                .then((response) => {
                    this.accesoPermitido = true
                })
                .catch((error) => {
                    this.accesoPermitido = false
                    this.mensaje = 'No podés acceder a la aplicación Operaciones en este momento.'
                })
      }
  }
};
</script>
