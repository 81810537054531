<template>
	<div class="container">
		<copyright-notice class="row copyright"></copyright-notice>
	</div>
</template>

<script>
	import MenuFooter from './footer/MenuFooter.vue';
	import CopyrightNotice from './footer/CopyrightNotice.vue';

	export default {
		components: {
			'menu-footer': MenuFooter,
			'copyright-notice': CopyrightNotice
		},
		data: function(){
			return {
				usuario_logueado: this.$store.state.usuario_logueado
			}
		}
	}

</script>