<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><router-link :to="{name: 'MisDocumentosMenu'}">Mis Documentos</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Mis Certificados de Complementarias</li>
            </ol>
        </nav>

        <div class="w-100">
            <h2 class="titulo-aplicacion">Mis Certificados de Complementarias <i class="far fa-file-alt"></i></h2>
            <div class="menu-beneficios">
                <button type="button" class="btn btn-primary boton-beneficio mr-3"
                        v-for="beneficio in beneficios"
                        @click="beneficioSelect = beneficio.beneficio_id"
                >
                    Beneficio {{ beneficio.beneficio_id | formatearBeneficio }}
                </button>

                <button type="button" class="btn btn-primary boton-beneficio mr-3"
                        v-for="apoderado in apoderados"
                        @click="beneficioSelect = apoderado.beneficio_id"
                >
                    Apoderado {{ apoderado.beneficio_id | formatearBeneficio }}
                </button>
            </div>

            <div class="row flex-md-column">
                <Loader v-if="buscando"/>
                <SinCertificadoComplementaria class="m-auto" v-else-if="certificados.length === 0"
                    :beneficio="beneficioSelect"
                />
                <CertificadoComplementaria v-else
                                           v-for="certificado in certificados"
                                           :key="certificado.proceso"
                                           :certificado="certificado"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {getCertificados} from "../../../../api/misdocumentos-complementarias";
import CertificadoComplementaria from "./elementos/CertificadoComplementaria.vue";
import SinCertificadoComplementaria from "./elementos/SinCertificadoComplementaria.vue";
import Loader from "../../../elementos/loaders/Loader.vue";

export default {
    name: "MisCertificadosComplementarias",
    components: {Loader, SinCertificadoComplementaria, CertificadoComplementaria},
    data() {
        return {
            certificadosEncontrados: [],
            beneficioSelect: null,
            error: null,
            buscando: true
        }
    },
    created() {
        this.obtenerCertificados()
    },
    computed: {
         ...mapState({
             beneficios: state => {
                 return state.beneficios.beneficios
             },
             apoderados: state => {
                 return state.beneficios.apoderados
             }
         }),
        certificados() {
             return this.beneficioSelect != null
                 ? this.certificadosEncontrados.filter(beneficio => beneficio.beneficio === this.beneficioSelect)
                 : []
        }
    },
    methods: {
        async obtenerCertificados() {
            try {
                const { data } = await getCertificados()
                this.certificadosEncontrados = data.data
                this.beneficioSelect = this.beneficios[0]?.beneficio_id
                this.buscando = false
            }
            catch (e) {
                this.error = 'Hubo un error al buscar los certificados de complementarias'
                this.buscando = false
            }
        }
    }
}
</script>

<style scoped>

</style>
