
    <template>
        <div class="contenedor_novedades w-100" v-if="accesoPermitido">
            <router-view></router-view>
        </div>
    
        <div class="contenedor_novedades" v-else>
            <div class="alert alert-primary text-center mensaje_ingreso"> {{ mensaje }} </div>
        </div>
    </template>

<script>
import {
    accederPublicacionesMenu,
} from "../../../../api/novedades";

export default {
    data(){
        return{
            accesoPermitido : false,
            mensaje:""
        }
    },
    created() {
       this.accesoPermitido = accederPublicacionesMenu();
       if(!this.accesoPermitido) {this.mensaje = "Acceso No Permitido"}
    },
}
</script>
<style scoped>
.contenedor_novedades{
    margin-top: 80px;
}
.contenedor_novedades .mensaje_ingreso{
    font-weight: bolder;
}
</style>