<template>
	<div class="w-100">
		<div class="p-3 p-md-4 formulario formulario-ingresar">

			<div class="w-100 alertas" v-if="alertaSuccess">
				<div class="alert alert-success alert-dismissible fade show" role="alert" v-if="alertaSuccess">
					Certificado generado correctamente. <button class="btn btn-link alert-link pt-0 px-0" v-on:click.stop.prevent="descargarCertificado()">Descargar certificado</button>
					<button type="button" class="close" data-dismiss="alert" aria-label="Close">
					    <span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>

			<div class="w-100 alertas" v-if="alertaError || alertaAdvertencia">
				<div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="alertaAdvertencia">
					{{ alertaAdvertencia }}
					<button type="button" class="close" data-dismiss="alert" aria-label="Close">
					    <span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alertaError">
					{{ alertaError }}
					<button type="button" class="close" data-dismiss="alert" aria-label="Close">
					    <span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<h4>Nuevo Certificado de Haberes</h4>
				</div>
			</div>

			<form id="formularioNuevoCertificadoEmpleadoBuscarBeneficio" novalidate @submit.prevent>
				<div class="row">
					<div class="col-12 form-group">
						<label for="beneficio_id">¿Para qué beneficio querés generar el certificado de haberes?</label>
						<input ref="beneficio_id" class="form-control input-buscar-beneficio" type="text" name="beneficio_id" v-model="beneficio_id" id="beneficio_id" v-mask="'##-######/#'" placeholder="00-000000/0" onclick="this.select();">
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<button class="btn btn-primary" v-on:click="buscarBeneficio(beneficio_id)">
							<span v-if="buscandoBeneficio==='buscar' || buscandoBeneficio==='encontrado'">Buscar beneficio</span>
							<span v-else-if="buscandoBeneficio==='buscando'">Buscando beneficio <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
						</button>
					</div>
				</div>
			</form>

			<form class="mt-4" id="formularioNuevoCertificadoEmpleado" novalidate @submit.prevent v-if="datosBeneficio">
				<div>
					<h5>{{ datosBeneficio.personas[0].nombre }} {{ datosBeneficio.personas[0].apellido }} | CUIL {{ datosBeneficio.personas[0].cdi_cuil }} | {{ datosBeneficio.personas[0].tipo_documento }}  {{ datosBeneficio.personas[0].numero_documento }}</h5>

					<div v-if="datosBeneficio.info_cert_haberes">
						<p><strong>Información correspondiente al {{ datosBeneficio.info_cert_haberes.nombre_proceso | nombreReciboPB }}</strong><br>
						   Haber bruto: {{ datosBeneficio.info_cert_haberes.haber_bruto | currency }}<br>
						   Haber neto: {{ datosBeneficio.info_cert_haberes.haber_neto | currency }}<br>
						   Porcentaje de afectación: {{ datosBeneficio.info_cert_haberes.porcentaje_afectacion | currency }}<br>
						   Afectación disponible: {{ datosBeneficio.info_cert_haberes.afectacion_disponible | currency }}
						</p>

						<div class="row" v-if="certificadosHaberesJefe">
							<div class="select-container col-12 form-group">
								<label for="descuento_recibo">¿Es para descontar por recibo de haberes?</label>
								<select class="form-control" name="descuento_recibo" v-model="descuento_recibo" id="descuento_recibo">
									<option value="" disabled>Seleccioná una opción (obligatorio)</option>
									<option value="si">Sí, es para descontar por recibo de haberes</option>
									<option value="no">No, no es para descontar por recibo de haberes</option>
								</select>
							</div>
						</div>

						<div class="row" v-if="descuento_recibo === 'si'">
							<div class="select-container col-12 form-group">
                                <label for="organismo_concepto">¿Ante qué entidad querés presentar el certificado?</label>
                                <select class="form-control" id="organismo_concepto" name="organismo_concepto" v-model="organismo_concepto" required>
                                    <option value="" disabled>Seleccioná una entidad (obligatorio)</option>
                                    <option v-for="organismo in organismosPorClase" :value="organismo.codigo">{{organismo.codigo}} - {{ organismo.organismo.nombre_organismo }}</option>
                                </select>
                            </div>
						</div>

						<div class="row" v-if="descuento_recibo ==='no'">
							<div class="col-12 form-group">
								<label for="destino">Este certificado de haberes será para ser presentado ante</label>
								<input class="form-control" type="text" name="destino" v-model="destino" id="destino" placeholder="Escribí el nombre de la entidad">
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<button class="btn btn-primary" disabled v-if="generando==='generado'">
									<span>Certificado generado</span>
								</button>
								<button class="btn btn-primary" v-on:click="generarCertificado" v-else>
									<span v-if="generando==='generar'">Generar certificado</span>
									<span v-else-if="generando==='generando'">Generando certificado <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
								</button>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="alert alert-warning">No hay información para generar el certificado de haberes</div>
					</div>
				</div>
			</form>
		</div>

		<!-- Modal Información Importante -->
		<div class="modal fade" id="infoBeneficio" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="nuevoCertHaberInfoImportante" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
			    <div class="modal-content">
			    	<div class="modal-header">
			    		<h5 class="modal-title d-flex align-items-center" id="exampleModalLongTitle" v-html="modalTitle"></h5>
			    	</div>
			       	<div class="modal-body">
			      		<p>{{ modalBody }}</p>
			        	<p v-if="(certificadosHaberesJefe) && (infoModalBtnContinuar)">{{ modalQuestion }}</p>
			      	</div>
			      	<div class="modal-footer" v-if="certificadosHaberesJefe">
			        	<button type="button" class="btn btn-primary" data-dismiss="modal" v-if="infoModalBtnContinuar" @click="descuentoReciboNo">Si, continuar</button>
			        	<button type="button" class="btn btn-secondary" v-on:click="cancelarBeneficio">No, cancelar</button>
			      	</div>
			      	<div class="modal-footer" v-else>
			        	<button type="button" class="btn btn-secondary" v-on:click="cancelarBeneficio">Cancelar</button>
			      	</div>
			    </div>
			</div>
		</div>
	</div>
</template>

<script>

	import moment from 'moment'
    import {mapState} from "vuex";

	export default {
		created() {
			this.obtenerConceptosOrganismos()
		},
		computed: {
            ...mapState({
                certificadosHaberesJefe: state => {
                    return state.user.aplicaciones.some(a => a.aplicacion === 'TAD-CERTIFICADOS-HABERES' && a.funcion === 'CERTHABERES-JEFE')
                },
                certificadosHaberesEmpleado: state => {
                    return state.user.aplicaciones.some(a => a.aplicacion === 'TAD-CERTIFICADOS-HABERES' && a.funcion === 'CERTHABERES-EMPLEADO')
                }
            }),
			organismosPorClase(){
				if(this.clase === '') return

				if( this.clasesP18.indexOf(this.clase) >= 0) {
					return this.organismos.filter(el => el.programa_18 == 'S')
				}

				if( this.clasesP19.indexOf(this.clase) >= 0) {
					return this.organismos.filter(el => el.programa_19 == 'S')
				}
				if( this.clasesP20.indexOf(this.clase) >= 0) {
					return this.organismos.filter(el => el.programa_20 == 'S')
				}
				if( this.clasesP21.indexOf(this.clase) >= 0) {
					return this.organismos.filter(el => el.programa_21 == 'S')
				}
			}
		},
		mounted() {
			this.$refs.beneficio_id.focus()
		},
		methods: {
			obtenerConceptosOrganismos: function(){
				axios.get('obtener-organismos-conceptos?por_pagina=200')
                    .then((response) =>{
                        this.organismos = response.data.data
                    })
                    .catch((error) => {
                        if(error.response.status >= 500){
                            this.alertaError = 'Hubo un inconveniente con la generación del certificado de haberes. Por favor volvé a intentarlo en unos minutos.'
                        }
                    })
			},
			buscarBeneficio: function(beneficio){
				if (beneficio === ''){
					this.alertaAdvertencia = 'Tenés que buscar un beneficio.'
					this.buscandoBeneficio = 'buscar'
					return
				}

				var beneficioABuscar = beneficio.replace(/ /g, '')
				beneficioABuscar = beneficioABuscar.replace(/-/g, '')
				beneficioABuscar = beneficioABuscar.replace(/\//g, '')
				beneficioABuscar = beneficioABuscar.padEnd(9, '0')

				if(this.beneficioBuscado === beneficioABuscar){
					this.alertaAdvertencia = 'Ya estás consultado este beneficio'
					this.buscandoBeneficio = 'buscar'
					return
				}

				this.datosBeneficio = null
				this.alertaError = null
				this.alertaAdvertencia = null
				this.alertaSuccess = null
				this.buscandoBeneficio = 'buscando'
				this.generando = 'generar'
				this.destino = ''
				this.organismo_concepto = ''
				this.descuento_recibo = ''
				axios.get('tramites-a-distancia/certificados-haberes/' + beneficioABuscar + '/datos')
                    .then(response => {
                        if(response.data.length === 0){
                            this.alertaAdvertencia = 'No se encontró información con ese número de clase y beneficio.'
                            return
                        }

                        if(this.certificadosHaberesEmpleado){
                            this.descuento_recibo = 'si'
                        }

                        this.buscandoBeneficio = 'encontrado'
                        this.beneficio_id = beneficioABuscar
                        this.datosBeneficio = response.data
                        this.beneficioBuscado = beneficioABuscar


                        if(this.datosBeneficio.info_cert_haberes == null){
                            this.modalTitle = 'Se produjo un error.'
                            this.modalBody = 'Hubo un inconveniente al intentar recuperar la información del beneficio.'
                            this.infoModalBtnContinuar = false
                            $('#infoBeneficio').modal('show');
                        }

                        if(this.datosBeneficio.info_cert_haberes.baja_tramite === 'S'){
                            this.modalTitle = 'Trámite de baja'
                            this.modalBody = 'Este beneficio tiene trámite de baja'
                            this.infoModalBtnContinuar = false
                            $('#infoBeneficio').modal('show');
                        }

                        if(this.datosBeneficio.boca_pago_id === 7){
                            this.modalTitle = '<i class="fas fa-exclamation-circle fa-2x warning-icon pr-3"></i> Código de pago 7'
                            this.modalBody = 'Este beneficio tiene código de pago 7'
                            this.modalQuestion = '¿Querés generar un certificado de haberes que no sea válido para descuento por recibo de haberes?'
                            this.infoModalBtnContinuar = true
                            $('#infoBeneficio').modal('show');
                        }

                        if(this.datosBeneficio.boca_pago_id === 4){
                            this.modalTitle = '<i class="fas fa-exclamation-circle fa-2x warning-icon pr-3"></i> Código de pago 4'
                            this.modalBody = 'Este beneficio tiene código de pago 4'
                            this.infoModalBtnContinuar = false
                            $('#infoBeneficio').modal('show');
                        }

                        if(this.datosBeneficio.tiene_cert_convenido != null){
                            let fechaCertConvenido = moment(this.datosBeneficio.tiene_cert_convenido.created_at).format('L')

                            this.modalTitle = '<i class="fas fa-exclamation-circle fa-2x warning-icon pr-3"></i> Certificado de haberes vigente'
                            this.modalBody = `Este beneficio tiene vigente el certificado de haberes número ${this.datosBeneficio.tiene_cert_convenido.numero} emitido el ${fechaCertConvenido} para descuento por recibo de haberes.`
                            this.modalQuestion = '¿Querés generar un certificado de haberes que no sea válido para descuento por recibo de haberes?'
                            this.infoModalBtnContinuar = true
                            $('#infoBeneficio').modal('show');
                        }

                        this.clase = this.datosBeneficio.beneficio_id.substr(0, 2)

                    })
                    .catch(error => {
                        this.buscandoBeneficio = 'buscar'
                        this.beneficioBuscado = beneficioABuscar
                        this.datosBeneficio = null

                        switch(error.response.status) {
                            case 500:
                                this.alertaError = 'Hubo un inconveniente con la búsqueda de información para generar el certificado de haberes. Por favor volvé a intentarlo en unos minutos.'
                                break;

                            case 404:
                                this.alertaError = 'No se encontró información con el beneficio ingresado.'
                                break;

                            default:
                                this.alertaError = error.response.data.error
                        }
                    })
			},
			cancelarBeneficio: function() {
				$('#infoBeneficio').modal('hide');
				this.buscandoBeneficio = 'buscar'
				this.beneficio_id = ''
				this.datosBeneficio = null
				this.beneficioBuscado = ''
			},
			generarCertificado: function(){
				// Inicializar valores
				this.alertaError = null
				this.alertaAdvertencia = null
				this.alertaSuccess = null
				this.generando = 'generando'

				// Validaciones
				if (this.beneficioBuscado == ''){
					this.alertaAdvertencia = 'Tenés que seleccionar un beneficio.'
					this.generando = 'generar'
					return
				}

				if(this.descuento_recibo == ''){
					this.alertaAdvertencia = 'Tenés que elegir si el certificado se utilizará para realizar descuento por recibo de haberes.'
					this.generando = 'generar'
					return
				}

				if (this.descuento_recibo == 'si' && this.organismo_concepto == '' && this.certHaberConvenido){
					this.alertaAdvertencia = 'Ya tenés emitido un certificado de haberes para realizar descuento por recibo de haberes. Recordá que cuando se aplique el descuento en tu recibo de haberes, vas a poder emitir un nuevo certificado.'
					this.generando = 'generar'
					return
				}

				if (this.descuento_recibo == 'si' && this.organismo_concepto == ''){
					this.alertaAdvertencia = 'Tenés que seleccionar una entidad para presentar el certificado.'
					this.generando = 'generar'
					return
				}

				if (this.descuento_recibo == 'no' && (this.destino == null || this.destino == '')){
					this.alertaAdvertencia = 'Tenés que escribir el nombre de la entidad para presentar el certificado.'
					this.generando = 'generar'
					return
				}

				if (this.descuento_recibo == 'no'){
					this.organismo_concepto = null
				}

				const data = {
					persona_id: this.datosBeneficio.personas[0].persona_id,
				    beneficio_id: this.datosBeneficio.beneficio_id,
				    destino: this.destino,
				    organismo_concepto: this.organismo_concepto
				}

				axios.post('tramites-a-distancia/certificados-haberes', data)
                    .then((response) =>{
                        this.generando = 'generado'
                        this.certificado = response.data
                        this.alertaSuccess = 'Certificado generado correctamente. '
                    })
                    .catch((error) => {
                        this.generando = 'generar'
                        if(error.response.status >= 500){
                            this.alertaError = 'Hubo un inconveniente con la generación del certificado de haberes. Por favor volvé a intentarlo en unos minutos.'
                        }else{
                            this.alertaError = error.response.data.error
                        }
                    })
			},
			descargarCertificado: function(){
			 	this.descargandoCertificado = 1
			 	axios.get('/tramites-a-distancia/certificados-haberes/' + this.certificado.id + '/descargar', {
			 		responseType: 'blob'
			 	})
                    .then((response) => {
                        this.descargandoCertificado = 2
                        var filename = 'CERTHAB-' + this.certificado.numero + '-' + this.beneficioBuscado + '.pdf'
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch((error) => {
                        this.descargandoCertificado = 3
                    })
			},
			descuentoReciboNo(){
				this.descuento_recibo = 'no'

			}
		},
		watch: {
			descuento_recibo: function(){
				if (this.descuento_recibo != ''){
					this.alertaAdvertencia = null
				}
			},
			beneficio_id: function(){
				if (this.beneficio_id != 'null'){
					this.alertaAdvertencia = null
				}
			},
			organismo_concepto: function(){
				if (this.organismo_concepto != 'null'){
					this.alertaAdvertencia = null
				}
			},
			destino: function(){
				if (this.organismo_concepto == 'null' && (this.destino != null) || (this.destino != '')){
					this.alertaAdvertencia = null
				}
			}
		},
		data() {
			return {
				environment: process.env.MIX_ENVIRONMENT,
				organismos: null,
				datosBeneficio: null,
				clase: '',
				clasesP18: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','31','32','33','34','35','36','51','52','53','54','55','56','57','58','59','60','61','62','63','64','65','66','67','68','69','70','71','72','73','74','75','76','77','78','79','81','82','83','84','85','86'],
				clasesP19: ['38', '88'],
				clasesP20: ['37', '87'],
				clasesP21: ['39', '89'],

				// Campos del formulario
				descuento_recibo: '',
				beneficio_id: '',
				organismo_concepto: '',
				destino: 'quien corresponda',

				// Acción enviar
				generando: 'generar',
				buscandoBeneficio: 'buscar',
				beneficioBuscado: null,

				// Alertas
				modalTitle: '',
				modalBody: '',
				modalQuestion: '',
				infoModalBtnContinuar: false,
				alertaError: null,
				alertaAdvertencia: null,
				alertaSuccess: null,

				// Datos para el certificado
				personaCertificado: null,
				certificado: null,
			}
		}
	};

</script>
