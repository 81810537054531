<template>
	<div class="w-100">
		<nav aria-label="breadcrumb">
  			<ol class="breadcrumb">
  				<li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
	    		<li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
	    		<li class="breadcrumb-item active" aria-current="page">Trámites a Distancia</li>
  			</ol>
		</nav>

		<div class="row">
			<router-link class="col-12 col-sm-6 col-lg-4 mb-4" style="text-decoration: none;" :to="{name: 'MisAplicacionesTramitesADistanciaCertificadosHaberesBuscar'}" v-if="mostrarCertificadosHaberes">
				<div class="card card-menu p-4 shadow-sm rounded-lg h-100 border-0">
					<div class="card-body text-center card-menu__body">
						<i class="fas fa-file-alt fa-4x mb-3 icono-micaja"></i>
						<h5 class="card-title card-menu__titulo">Certificados de Haberes</h5>
						<p class="card-text text-muted">Administración de certificados de haberes electrónicos</p>
					</div>
				</div>
			</router-link>
		</div>

	</div>
</template>

<script>

	import {mapState} from "vuex";

    export default {
		computed: mapState({
            mostrarCertificadosHaberes: state => {
                return state.user.aplicaciones.some(a => a.aplicacion === 'TAD-CERTIFICADOS-HABERES')
            },
        })
	};

</script>
