<template>
    <div>
    <h2 class="titulo-aplicacion">Envio de Notificaciones</h2>
    <div class="contenedor_gestion_complementarias">
        <button tag="button" class="btn btn-primary btn_complementarias" to="#" @click="verCargaNotificaciones">
            <i class="fas fa-plus fa-2x icono-micaja"></i><br>
            Cargar Nueva Notificacion Complementarias (Y)
        </button>
    </div>
    <div v-if="cargaNotificaciones">
        <div class="container"> 
            <div class="row justify-content-center"> 
                <div class="col-md-12"> 
                    <div class="card"> 
                        <h5 class="card-header card_header_carga">Carga de Notificaciones</h5> 
                            
                        <vue-dropzone 
                            v-on:vdropzone-success="completedUpdate" 
                            v-on:vdropzone-error="updateError" 
                            ref="myVueDropzone" 
                            id="dropzone" 
                            name="vueDropzone" 
                            :options="dropzoneOptions">
                        </vue-dropzone> 
                    </div>                         
                </div>
            </div> 
        </div>    
    </div> 
    <div v-if="uploaded" id="uploadedAlert" class="alert alert-success alert-dismissible fade show d-flex justify-content-between " role="alert">
            <span>{{successMessage}}</span>
            <span @click="uploaded=false" ><i class="fas fa-times "></i></span> 
    </div>
    <div v-if="success"  class="alert alert-success alert-dismissible fade show d-flex justify-content-between " role="alert">
            <span>{{successMessage}}</span>
            <span @click="success=false" ><i class="fas fa-times "></i></span> 
    </div>
    <div v-if="uploadError" class="alert alert-danger alert-dismissible fade show  d-flex justify-content-between " role="alert">
            <span>{{errorMessage}}</span>
            <span @click="uploadError=false" ><i class="fas fa-times "></i></span> 
    </div>
  

    <div class="w-100 searchBarContainer">
        <h4 >Búsqueda por proceso</h4>
        <div class=" searchBarNotificaciones navbar row justify-content-around">
            <input class="col-md-9 col-lg-9 col-sm-12" type="search" v-model="busquedaNotificaciones" name="busquedaNotificaciones" id="busquedaNotificaciones" placeholder="Ej:110622Y1" aria-label="Search" />
            <button class="btn btn-outline-primary col-lg-2 col-md-2 col-sm-12 btn-search" @click="buscarNotificaciones()" type="submit"><b>Buscar</b><i class="fas fa-search searchIcon" ></i></button>
        </div>
    </div>  

    <div class="w-100 container container-desktop">
        <div v-if="estado != ''">
            <div class="alert alert-info" role="alert">
                {{estado}}
            </div>
        </div>
        <div v-else>
            <table class="formulario table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Proceso</th>
                        <th scope="col">Fecha de Carga</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Totales</th>
                        <th scope="col">Pendientes</th>
                        <th scope="col">Fallidos</th>
                        <th scope="col">Descargar</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="notificacion in notificacionesEncontradas" :key="notificacion.id">
                        <td class="align-content-center">{{notificacion.proceso}}</td>
                        <td class="align-content-center">{{notificacion.fecha_carga}}</td>
                        <td class="align-content-center">{{notificacion.user}}</td>
                        <td class="align-content-center">{{notificacion.estado}}</td>
                        <td class="align-content-center">{{notificacion.total}}</td>
                        <td class="align-content-center">{{notificacion.pendientes}}</td>
                        <td class="align-content-center">{{notificacion.fallidos}}</td>
                        <td class="align-content-center">
                            <button class="btn btn-primary align-content-center col-12" @click="descargarArchivo(notificacion.id)" type="submit" >Descargar Archivo</button>
                        </td>
                    
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>

    <div class="w-100 container container-mobile">
        <div class="col-md-12">
            <div v-if="estado != ''">
                <div class="alert alert-info" role="alert">
                    {{ estado }}
                </div>
            </div>
            <div v-else>
                <div class="card registro" v-for="notificacion in notificacionesEncontradas" :key="notificacion.id">
                    <div div class="registroTexto card-body">
                        <div class="info_proceso">
                            <p><span><b>Proceso: </b></span><span>{{notificacion.proceso}}</span></p>
                            <p><span><b>Fecha de Carga: </b></span><span>{{notificacion.fecha_carga}}</span></p>
                            <p><span><b>Usuario: </b></span><span>{{notificacion.user}}</span></p>
                            <p><span><b>Estado: </b></span><span>{{notificacion.estado}}</span></p>
                            <p><span><b>Totales: </b></span><span>{{notificacion.total}}</span></p> 
                            <p><span><b>Pendientes: </b></span><span>{{notificacion.pendientes}}</span></p> 
                            <p><span><b>Fallidos: </b></span><span>{{notificacion.fallidos}}</span></p> 
                        </div>
                        <div>
                            <button
                            class="btn btn-primary align-content-center col-12" 
                            @click="descargarArchivo(notificacion.id)" 
                            type="submit">
                            Descargar Archivo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <paginacion v-if="mostrarTodas" :pagination="notificaciones"
                    v-on:cambiar-pagina="cambiarPagina($event)"
        ></paginacion>
    </div>

  </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    import pagination from "../../../../../elementos/pagination/Pagination";
    import axios from "axios";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import { fileURLToPath } from 'url';

    export default {
        name: "EnvioNotificaciones",

        components: {
        vueDropzone: vue2Dropzone,
        'paginacion' : pagination,
        },

        data() {
            return {
                mostrarDropzone:false,
                timeout: 50000,
                dropzoneOptions: {
                    url: 'api/gestion-notificaciones/enviar-complementarias',
                    acceptedFiles: ".txt",
                    autoProcessQueue: true,
                    method:"post",
                    maxFilesize: 5000000,
                    chunking: true,
                    chunkSize : 2000000,
                    headers: {
                    'X-XSRF-TOKEN' : $cookies.get("XSRF-TOKEN")
                    }
                },
                cargaNotificaciones:false,
                notificaciones: [],
                estado: '',
                success: false,
                resultado: '',
                busqueda: false,
                por_pagina: 10,
                uploaded: false,
                uploadError: false,
                successMessage :"",
                errorMessage : "",
                busquedaNotificaciones:"",
                mostrarTodas:true,
                mostrarUna:false
            }
        },
        created() {
            if (this.$route.params.msg){
                this.successMessage = this.$route.params.msg
            }
            this.getNotificaciones();
        },

        computed: {
        notificacionesEncontradas(){
            if(this.mostrarTodas){
                return this.notificaciones.data
            }else{
                return this.notificaciones
            }
        },

      
       
        },

        
        
           
        methods: {
            verCargaNotificaciones:function(){
                
                this.cargaNotificaciones = !this.cargaNotificaciones;
                this.uploaded = false;
                this.successMessage = "";

            },
            
            completedUpdate(){
                this.getNotificaciones();
                this.cargaNotificaciones = !this.cargaNotificaciones;
                this.uploaded = true;
                this.successMessage = "Notificaciones Generadas Correctamente";
                setTimeout(function() {this.uploaded = false} , 5000);
            },

            updateError(file, message, xhr){
                this.uploadError = true;
                this.errorMessage = message.error
                this.cargaRecibos = !this.cargaRecibos
                
                setTimeout(() => this.uploadError = false, 5000)
            }, 

            getNotificaciones(){
                this.complementarias_buscar = ''
                this.estado = "Buscando..."
                this.busqueda = false

                axios.get('gestion-notificaciones/notificaciones?por_pagina='+this.por_pagina)
                .then(response => {
                    this.estado = '';
                    this.notificaciones = response.data;
                    this.resultado = "Se encontraron "+ response.data.total +" notificaciones"
                })
                .catch(error => {
                    this.estado = "Hubo un error, no se encontraron notificaciones"
                })
            },

            descargarArchivo(id){
                axios.post('gestion-notificaciones/notificaciones/descargar', {id: id} , {
					responseType: 'blob'
				})
                .then((response) => {
					const filename = response.headers['content-disposition'].split('=')[1].replace(/^\"+|\"+$/g, '')
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                   	const link = document.createElement('a');
   					link.href = url;
   					link.setAttribute('download', filename);
   					document.body.appendChild(link);
   					link.click();                
				})
            },
           
            cambiarPagina(url){
                axios.get(url)
                    .then(response =>{
                        this.notificaciones = response.data
                    })
                    .catch(error =>{
                        this.estado = "Hubo un error, no se encontraron complementarias"
                    })
            },

            buscarNotificaciones( ){

                const formdata = new FormData()
                formdata.append("proceso", this.busquedaNotificaciones)
                
              axios.get(`gestion-notificaciones/notificaciones/buscar/${this.busquedaNotificaciones}`)
              .then(
                response=>{
                    this.notificaciones = [response.data];
                    this.mostrarTodas =false;
                }
              )
              .catch(
                (error)=>{
                   
                    this.uploadError = true;
                    this.errorMessage= error.response.data.error
                    setTimeout(() => this.uploadError = false, 5000)
                   
                }
              )
            }

        } 

    }
</script>

<style scoped>
.searchBarNotificaciones{
   background-color: #e9ecef;
   border-radius: 0.25rem;
}
.searchIcon{
    margin-left: 8px;
}
.btn-search{
    height: 4rem;
}

.btn_complementarias{
    width:100%;

}
.formulario th{
    text-align: center;
}
.formulario td{
    text-align: center;
}
.card-header{
    text-align: center;
}

.card_header_carga{
    text-align: center;
}
.contenedor_gestion_complementarias{
    text-align: center;
    margin-top:40px;
    
}
.searchBarContainer{
    margin-top:40px
}

.registroTexto div p{
    line-height: 0.5;
}

@media (max-width: 991px) {
    .container-desktop {
        display: none;
    }
}
@media (min-width: 992px) {
    .container-mobile{
        display: none;
    }
}

@media (max-width: 366px) {
    .registroTexto div p{
        line-height: 1;
    }
}
</style>