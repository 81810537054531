<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div class="contenedor-titulo-seccion">
                    <div class="titulo-seccion">
                        <h2 class="titulo-seccion__texto" v-if="this.$route.query.password === 'modificada'">Contraseña generada</h2>
                        <h2 class="titulo-seccion__texto" v-else>Ingresar</h2>
                    </div>
                </div>

                <div v-if="this.$route.query.verificada === 'true'">
                    <div class="alert alert-success" role="alert">
                        Su cuenta se verifico correctamente.
                    </div>
                </div>

                <div v-if="this.$route.query.password === 'modificada'">
                    <div class="alert alert-success" role="alert">
                        Su nueva contraseña fue generada correctamente.<br />
                        <a class="alert-link" :href="mix_url">¿Quiere ingresar ahora?</a>
                    </div>
                </div>

                <div class="formulario formulario--ingresar" v-else>
                    <div class="col-12 alertas" v-if="verificada">
                        <div class="alert alert-warning" role="alert" v-if="verificada === false">
                            Hubo un inconveniente con la verificación de su cuenta.
                        </div>
                        <div class="text-success alert alert-success" role="alert" v-else-if="verificada === true">
                            Verificó su cuenta correctamente
                        </div>
                    </div>

                    <form @submit.prevent>
                        <div class="row">
                            <div class="col-12 form-group ingresar__usuario">
                                <label for="usuario">CUIL o Correo Electrónico</label>
                                <input type="text" ref="usuario" autofocus name="usuario" v-model="usuario" class="form-control" id="usuario" :class="{'input--error': errorUsuario}" placeholder="Ingrese su CUIL o correo electrónico"/>
                                <span v-if="errorUsuario">
                                    <small class="text-danger">Tiene que ingresar su CUIL o correo electrónico</small>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group ingresar__password">
                                <label for="password">Contraseña</label>
                                <input id="password" ref="password" :type="passwordInputType" name="password" class="form-control" :class="{'input--error': errorPassword}" v-model="password" placeholder="Ingrese su contraseña"/>
                                <span v-if="errorPassword">
                                    <small class="text-danger">Tiene que ingresar su contraseña</small>
                                </span>
                                <button type="button" class="btn btn-mostrar-password" @click="modificarVisibilidadPassword">
                                    <i class="far fa-eye-slash" v-if="mostrarPassword"></i>
                                    <i class="far fa-eye" v-else></i>
                                </button>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col">
                                <invisible-recaptcha sitekey="6LeXGaoUAAAAACu03CB--50FBeLuAhPmtrBRy-PF" :callback="ingresar" type="submit" class="btn boton-formulario-ingresar" v-if="environment === 'produ'">
                                    <span v-if="acciones === ''">Ingresar</span>
                                    <span v-else>{{ acciones }}
                                        <i class="fas fa-circle-notch faa-spin animated faa-fast"></i>
                                    </span>
                                </invisible-recaptcha>

                                <button class="btn boton-formulario-ingresar" :class="{ 'boton--error': errores }" @click="ingresar" v-else>
                                    <span v-if="acciones == null && errores == null">Ingresar</span>
                                    <span v-else-if="errores && acciones == null" >{{ errores }}</span>
                                    <span v-else-if="acciones && errores == null">{{ acciones }} <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="row">
                    <div class="col-12 p-2 mt-3 text-center formulario-ingresar__otras-acciones">
                        <router-link class="otras-acciones__link text-decoration-none" :to="{name: 'solicitudRecuperarPassword',query: { usuario: usuario }}" v-if="usuario">Olvidé mi contraseña</router-link>
                        <router-link class="otras-acciones__link text-decoration-none" :to="{ name: 'solicitudRecuperarPassword' }" v-else>Olvidé mi contraseña</router-link>
                    </div>
                    <div class="col-12 p-2 text-center formulario-ingresar__otras-acciones">
                        <router-link class="otras-acciones__link text-decoration-none" :to="{ name: 'Registrarse' }">Todavía no estoy registrado</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
    components: {
        "invisible-recaptcha": InvisibleRecaptcha
    },
    data() {
        return {
            mix_url: process.env.MIX_URL,
            environment: process.env.MIX_ENVIRONMENT,
            usuario: null,
            password: null,
            errores: null,
            errorUsuario: false,
            errorPassword: false,
            acciones: null,
            verificada: this.$route.query.verificada,
            mostrarPassword: false,
            passwordInputType: "password"
        };
    },
    watch: {
        usuario() {
            if (this.usuario) {
                this.errorUsuario = null;
                this.errores = null;
            }
        },
        password() {
            if (this.password) {
                this.errorPassword = null;
                this.errores = null;
                this.acciones = null;
            }
        }
    },
    methods: {
        modificarVisibilidadPassword () {
            if (this.mostrarPassword) {
                this.mostrarPassword = false;
                this.passwordInputType = "password";
            }
            else {
                this.mostrarPassword = true;
                this.passwordInputType = "text";
            }
        },
        async ingresar () {
            this.errorUsuario = this.errorPassword = false;
            this.errores = this.acciones = null;

            if (this.usuario == null || this.usuario === "") {
                this.errorUsuario = true;
                this.$refs.usuario.focus();
                return;
            }
            if (this.password == null || this.password === "") {
                this.errorPassword = true;
                this.$refs.password.focus();
                return;
            }

            this.acciones = "Verificando datos de acceso...";

            let dato_usuario = this.usuario.replace(/ /g, "");
            if (!dato_usuario.includes("@")) {
                dato_usuario = dato_usuario.replace(/_/g, "");
                dato_usuario = dato_usuario.replace(/-/g, "");
            }

            const credenciales = {
                usuario: dato_usuario,
                password: this.password.trim()
            };

            try {
                await axios.get("/sanctum/csrf-cookie");
                await axios.post("/login", credenciales)
                await this.$store.dispatch("setGuest", "isNotGuest" );

                this.acciones = "Cargando su perfil de usuario...";

                await this.$store.dispatch("guardarDatosUsuario")

                await this.$router.push({name: "Inicio"});
            }
            catch (e) {
                this.acciones = null;
                if (e.response.status === 429) {
                    this.errores = "Limite de intentos excedido. Por favor vuelva a intentar en un minuto";
                }
                else if (e.response.status === 422) {
                    this.password = null;
                    let erroresValidacion = Object.values(e.response.data.errors)
                    this.errores = erroresValidacion[0][0]
                }
                else {
                    this.errores = "Hubo un inconveniente con el ingreso a la aplicación. Por favor intente en unos minutos";
                }
            }
        }
    }
};
</script>
