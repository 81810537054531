<template>
	<div class="w-100" v-if="accesoPermitido">
		<nav aria-label="breadcrumb">
  			<ol class="breadcrumb">
  				<li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
	    		<li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
	    		<li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesTramitesADistanciaMenu'}">Trámites a Distancia</router-link></li>
	    		<li class="breadcrumb-item active" aria-current="page">Certificados de haberes</li>
  			</ol>
		</nav>
		<h2 class="titulo-aplicacion">Certificados de Haberes <i class="far fa-file-alt"></i></h2>

		<router-link class="btn btn-small btn-info btn__menu-transferencias mx-0 mb-2 mb-md-0" tag="button" v-if="!certificadosHaberesConsulta" :to="{name: 'MisAplicacionesTramitesADistanciaCertificadosHaberesNuevo'}">Generar nuevo certificado</router-link>
		<router-link class="btn btn-small btn-gris btn__menu-transferencias mx-0 mb-2 mb-md-0" tag="button" :to="{name: 'MisAplicacionesTramitesADistanciaCertificadosHaberesBuscar'}">Buscar certificados</router-link>

		<router-view class="mt-4"></router-view>
	</div>
	<div class="w-100" v-else>
		<div class="alert alert-primary text-center"> {{ mensaje }} </div>
	</div>
</template>

<script>

	import {mapState} from "vuex";

    export default {
		data() {
			return {
				accesoPermitido: false,
				mensaje: 'Verificando acceso a la aplicación...'
			}
		},
		created() {
			this.accederCertHaberes()
		},
        computed: mapState({
            certificadosHaberesConsulta: state => {
                return state.user.aplicaciones.some(a => a.aplicacion === 'CERTHABERES-CONSULTAS')
            },
        }),
		methods: {
			accederCertHaberes: function() {
				axios.get('acceder-certhaberes')
                    .then(() => {
                        this.accesoPermitido = true
                    })
                    .catch(() => {
                        this.accesoPermitido = false
                        this.mensaje = 'No podés acceder a la aplicación Certificados de Haberes en este momento.'
                    })
			}
		}
	};

</script>
