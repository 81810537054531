<template>
    <!-- <div class="container"> -->
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en:
                    <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Mis Consultas
                </li>
            </ol>
        </nav>
        <div>
            <div class="row">
                <div
                    class="container p-3 p-md-4 formulario formulario-ingresar"
                >
                    <div class="row">
                        <div class="col-12 alertas" v-if="errores">
                            <div class="alert alert-warning" role="alert">
                                {{ errores }}
                            </div>
                        </div>
                        <div class="col-12 alertas" v-if="envioExitoso">
                            <div class="alert alert-success" role="alert">
                                {{ envioExitoso }}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 ">
                            <h5>Formulario de contacto</h5>
                            <p class="text-muted">
                                Las consultas que realice a través de este
                                formulario de contacto, serán respondidas
                                directamente a su casilla de correo
                                <strong>{{ $store.state.user.email }}</strong>
                            </p>
                        </div>
                    </div>

                    <form
                        id="formularioEnviarMensaje"
                        novalidate
                        @submit.prevent
                    >
                        <div class="row">
                            <div class="select-container col-12 form-group">
                                <label for="motivo"
                                    >¿Sobre qué tema quiere escribirnos?</label
                                >
                                <select
                                    class="form-control"
                                    name="motivo"
                                    v-model="motivo"
                                    id="motivo"
                                >
                                    <option
                                        value="ganancias"
                                        selected="selected"
                                        >Impuesto a las Ganancias</option
                                    >
                                    <option value="supervivencia"
                                        >Supervivencia</option
                                    >
                                    <option value="liquidaciones"
                                        >Liquidaciones</option
                                    >
                                    <option value="devengados"
                                        >Haberes devengados</option
                                    >
                                    <option value="poderes">Poderes</option>
                                    <option
                                        value="liquidacion_asignaciones_familiares"
                                        >Liquidación Asignaciones
                                        Familiares</option
                                    >
                                    <option
                                        value="liquidacion_retenciones_judiciales"
                                        >Liquidación Retenciones
                                        Judiciales</option
                                    >
                                    <option value="datos_personales"
                                        >Datos Personales</option
                                    >
                                    <option value="varios"
                                        >Otras consultas</option
                                    >
                                </select>
                            </div>
                        </div>

                        <div class="row" v-if="beneficios.length >= 1">
                            <div class="select-container col-12 form-group">
                                <label for="beneficio_id"
                                    >¿Sobre qué beneficio es la consulta?</label
                                >
                                <select
                                    class="form-control"
                                    id="beneficio_id"
                                    name="beneficio_id"
                                    v-model="beneficio_id"
                                    tabindex="0"
                                >
                                    <option
                                        v-for="beneficio in beneficios"
                                        :value="beneficio"
                                        >{{ beneficio }}</option
                                    >
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group">
                                <label for="asunto">Asunto</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="asunto"
                                    v-model="asunto"
                                    required
                                    id="asunto"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 form-group">
                                <label for="mensaje">Mensaje</label>
                                <textarea
                                    class="form-control"
                                    id="mensaje"
                                    name="mensaje"
                                    v-model="mensaje"
                                ></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12" v-if="environment == 'produ'">
                                <invisible-recaptcha
                                    sitekey="6LeXGaoUAAAAACu03CB--50FBeLuAhPmtrBRy-PF"
                                    :callback="enviarMensaje"
                                    type="submit"
                                    class="btn btn-success"
                                    v-if="envioExitoso"
                                >
                                    <span v-if="acciones == ''"
                                        >Consulta enviada</span
                                    >
                                </invisible-recaptcha>
                                <invisible-recaptcha
                                    sitekey="6LeXGaoUAAAAACu03CB--50FBeLuAhPmtrBRy-PF"
                                    :callback="enviarMensaje"
                                    type="submit"
                                    class="btn btn-primary"
                                    v-else
                                >
                                    <span v-if="acciones == ''"
                                        >Enviar consulta</span
                                    >
                                    <span v-else
                                        >{{ acciones }}
                                        <i
                                            class="fas fa-circle-notch faa-spin animated faa-fast"
                                        ></i
                                    ></span>
                                </invisible-recaptcha>
                            </div>
                            <div class="col-12" v-else>
                                <button
                                    class="btn btn-success"
                                    @click="enviarMensaje"
                                    v-if="envioExitoso"
                                >
                                    <span v-if="acciones == ''"
                                        >Consulta enviada</span
                                    >
                                </button>
                                <button
                                    class="btn btn-primary"
                                    @click="enviarMensaje"
                                    v-else
                                >
                                    <span v-if="acciones == ''"
                                        >Enviar consulta</span
                                    >
                                    <span v-else
                                        >{{ acciones }}
                                        <i
                                            class="fas fa-circle-notch faa-spin animated faa-fast"
                                        ></i
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
    components: {
        "invisible-recaptcha": InvisibleRecaptcha
    },
    data() {
        return {
            environment: process.env.MIX_ENVIRONMENT,
            motivo: "",
            asunto: "",
            mensaje: "",
            acciones: "",
            errores: null,
            envioExitoso: "",
            beneficios: [],
            beneficio_id: null
        };
    },
    beforeMount: function() {
        if (this.$store.state.beneficios) {
            for (let beneficio of this.$store.state.beneficios.beneficios) {
                this.beneficios.push(
                    this.$options.filters.formatearBeneficio(
                        beneficio.beneficio_id
                    )
                );
            }
        }

        if (this.$store.state.beneficios.apoderados) {
            for (let apoderado of this.$store.state.beneficios.apoderados) {
                this.beneficios.push(
                    this.$options.filters.formatearBeneficio(
                        apoderado.beneficio_id
                    )
                );
            }
        }
    },
    methods: {
        enviarMensaje: function() {
            if (this.motivo == "" || this.asunto == "" || this.mensaje == "") {
                this.errores = "Tiene que completar todos los campos.";
                return;
            }

            var beneficio = this.beneficio_id.replace("-", "");
            beneficio = beneficio.replace("/", "");

            this.envioExitoso = "";
            this.acciones = "Enviando consulta";

            axios
                .post(
                    "enviar-consulta",
                    {
                        motivo: this.motivo,
                        asunto: this.asunto,
                        mensaje: this.mensaje,
                        beneficio_id: beneficio
                    }
                )
                .then(response => {
                    this.acciones = "";
                    this.errores = null;
                    this.envioExitoso = "El mensaje fue enviado correctamente";
                })
                .catch(error => {
                    this.acciones = "";
                    this.errores = "Se produjo un error al enviar la consulta";
                });
        }
    }
};
</script>
