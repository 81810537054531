<template>
	<div class="d-flex justify-content-center align-items-center">
		<div class="row d-flex justify-content-center">
			<p class="w-100 text-center"><i class="far fa-folder-open display-1 text-muted"></i></p>
			<p class="w-100 text-center">No se encontraron certificados de haberes para el beneficio <strong>{{ beneficio | formatearBeneficio }}</strong></p>
			<!--<router-link class="w-100 btn btn-outline-primary" tag="button" :to="{name: 'CertificadosHaberesNuevo'}">¿Querés generar un nuevo certificado?</router-link>-->
		</div>
	</div>
</template>

<script>
	
	export default {
		props: [
			'beneficio'
		]
	};

</script>