<template>
    <div class="modal fade show" id="exampleModalCenteredScrollable" tabindex="-1" aria-labelledby="exampleModalCenteredScrollableTitle" style="display: block; padding-right: 17px;" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>Margen de Afectacion y baja certificados</h2>
                </div>
                <div class="modal-body">
                    <vue-dropzone 
                    v-on:vdropzone-sending="sendFile"
                    ref="myVueDropzone" 
                    id="dropzone" 
                    name="vueDropzone" 
                    :options="dropzoneOptions"
                    :use-custom-slot=true
                    >
                        <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">Subir el nuevo Margen de Afectacion</h3>
                            <div class="subtitle">...o haga click para seleccionar en el explorador</div>
                        </div>
                    </vue-dropzone>
                </div>
                <div class="modal-footer">
                    <div class="footer_container">
                        <div style="margin:auto">
                            <button class="modal-default-button btn btn-danger " @click="closeModal" >
                            Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';


export default {
    emits: ["close"],
    name:"cargaNuevaAfectacion",
    components : {
    
        vueDropzone : vue2Dropzone
    },
    data() {
        return {
            dropzoneOptions: {
                url: 'api/cargar-nueva-tabla-afectacion',
                acceptedFiles: ".txt",
                autoProcessQueue: true,
                method: "post",
                maxFilesize: 5000000,
                chunking: true,
                chunkSize: 2000000,
                headers: {
                    'X-XSRF-TOKEN': $cookies.get("XSRF-TOKEN")
                }
            },
        }
    },
    methods:{
        sendFile(file, xhr, formData) {
        },

        closeModal(){
            this.$emit("close")
        },

        
    }
}

</script>
<style scoped>

.footer_container{
    display:flex;
    flex-direction: column;
    min-width: 95%;

  }

  .modal-dialog{
  max-width: 90;
  height: 50vh;
  }
    .modal-header h3 {
      margin-top: 0;
    }
    
    .modal-body {
      margin: 20px 0;
      height: 50vh;
    }
    
    .modal-default-button {
      float: right;
    }
    
    
    

</style>