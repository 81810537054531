<template>
	<div>
		<div class="card">
	  			<div class="card-body">
	  				<span class="badge badge-pill badge-secondary">Apoderado del Beneficio</span>
	    			<h5 class="card-title">Beneficio {{ datosapoderado.beneficio_id | formatearBeneficio }}</h5>
	    			<p class="card-text" v-if="datosapoderado.vencimiento_poder">Vencimiento del poder: {{ moment(datosapoderado.vencimiento_poder).format('LL') }}</p>
	  			</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		props: [
			// 'beneficio',
			'datosapoderado'
		]
	}

</script>