<template>
    <div class="mt-8" v-if="accesoPermitido">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en:
                    <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{ name: 'MisAplicacionesMenu' }">Mis Aplicaciones</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Gestión de Recibos Penitenciaria
                </li>
            </ol>
        </nav>

        <div class="container">
            <transition name="fade" mode="out-in" :duration="100">
                <router-view></router-view>
            </transition>
        </div>
    </div>

    <div class="mt-8 w-100" v-else>
        <div class="alert alert-primary text-center">{{ mensaje }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            accesoPermitido: false,
            mensaje: "Verificando acceso a la aplicación..."
        };
    },
    created() {
        this.accederGestionRecibosPenitenciaria();
    },
    methods: {
        accederGestionRecibosPenitenciaria: function() {
            axios
                .get("acceder-gestion-penitenciaria")
                .then(response => {
                    this.accesoPermitido = true;
                })
                .catch(error => {
                    this.accesoPermitido = false;
                    this.mensaje = "No puede acceder a la aplicación Gestión de Recibos de Penitenciaria en este momento.";
                });
        }
    }
};
</script>
