<template>
    <div class="nueva-transferencia">
        <div class="formulario">
            <h5 class="subtitulo-liquidaciones">Generar nueva transferencia</h5>
            <form enctype="multipart/form-data" id="formularioNuevaTransferencia" novalidate @submit.prevent
                class="formulario__nueva-transferencia">
                <div class="row">
                    <div class="col-12 texto-informativo">
                        <label for="tipo_id">Tipo de transferencia</label><br />
                        <small class="text-muted">
                            Una <strong>transferencia masiva</strong> debe
                            contener información de más de una persona. Por
                            ejemplo, un lote de casos a procesar, información
                            sobre varios beneficios, etc. Una
                            <strong>transferencia individual</strong> debe
                            contener información sobre una sola persona o
                            beneficio.
                        </small>
                    </div>
                    <div class="select-container col-12 form-group">
                        <select class="form-control" name="tipo_id" id="tipo_id" v-model="tipo_id">
                            <option value="1">Masiva</option>
                            <option value="2">Individual</option>
                        </select>
                    </div>
                    <div class="col-12 texto-informativo">
                        <small v-if="this.tipo_id == 1">
                            Al generar una transferencia masiva tiene que
                            detallar, además del motivo y del asunto, el mes o
                            el proceso mensual al que corresponden los casos a
                            enviar. También puede agregar observaciones.
                        </small>
                        <small v-if="this.tipo_id == 2">
                            Al generar una transferencia individual tiene que
                            detallar, además del motivo y del asunto, los datos
                            personales que te solicitamos sobre esa persona o
                            beneficio (nombre y apellido, CUIL o CDI, tipo y
                            número de documento).
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="select-container col-12 form-group" v-if="tipo_id != null">
                        <label for="motivo_id">Motivo de la transferencia</label>
                        <select class="form-control" name="motivo_id" v-model="motivo_motivo">
                            <option v-for="motivo in motivos" :value="motivo.id" :key="motivo.id">
                                {{ motivo.motivo }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row" v-if="td != 1">
                    <div class="select-container col-12 form-group" v-if="tipo_id != null">
                        <label for="organismo">Organismo de destino</label>
                        <select class="form-control" name="organismo" v-model="organismo">
                            <option v-for="organismo in organismos" :value="organismo.codigo" :key="organismo.codigo">
                                {{ organismo.codigo | formatoCodigoOrganismo }} - {{ organismo.nombre_organismo }}
                            </option>
                        </select>
                    </div>

                    <div class="select-container col-12 form-group" v-if="tipo_id != null && organismo != null">
                        <label for="subsector_id">Sector de destino</label>
                        <select class="form-control" name="subsector_id" v-model="subsector_id">
                            <option v-for="sectorOrganismo in sectoresOrganismo" id="subsector_id"
                                :value="sectorOrganismo.subsectores[0].id" :key="sectorOrganismo.subsectores[0].id">
                                {{ sectorOrganismo.nombre_sector }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- <div class="row">
                    <div
                        class="select-container col-12 form-group"
                        v-if="esDescuento && organismoHabilitado"
                    >
                        <label for="subtipo_descuento">Tipo de descuento</label>
                        <select
                            class="form-control"
                            name="subtipo"
                            v-model="subtipo"
                        >
                            <option value="" disabled>Seleccione el tipo de descuento</option>
                            <option value="U">Cuota Social</option>
                            <option value="B">Créditos</option>
                        </select>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div
                        class="select-container col-12 form-group"
                        v-if="esDescuento && organismoHabilitado && existenConceptos"
                    >
                        <label for="subtipo_descuento">Seleccione el concepto</label>
                        <select
                            class="form-control"
                            name="concepto_codigo"
                        >
                            <option
                                v-for="concepto in conceptos_acuerdo"
                                id="conceptos_acuerdo_item"
                                :value="concepto.codigo"
                                :key="concepto.codigo"
                            >
                                {{ concepto.codigo }} - {{ concepto.descripcion }}
                            </option>
                        </select>
                    </div>

                    <div
                        class="alert-container col-12 form-group"
                        v-else-if="esDescuento && organismoHabilitado && !existenConceptos && subtipo !== null && buscando_concepto"
                    >
                        <div class="spinner-border text-danger" role="status">
                            <span class="sr-only">Cargando Datos de Transferencias...</span>
                        </div>
                    </div>

                    <div
                        class="alert-container col-12 form-group"
                        v-else-if="esDescuento && organismoHabilitado && !existenConceptos && subtipo !== ''"
                    >
                        <div class="alert alert-danger" role="alert">
                            No existen conceptos para este tipo de descuento
                        </div>
                    </div>
                </div> -->

                <div class="row">
                    <div class="form-group col-12" v-if="tipo_id != null">
                        <label for="asunto">Asunto</label>
                        <input class="form-control" id="asunto" type="text" name="asunto" v-model="asunto"
                            placeholder="Asunto de la transferencia" />
                    </div>
                </div>

                <!-- <div v-if="tipo_id == 1 && (!esDescuento || !organismoHabilitado) "> -->
                <div v-if="tipo_id == 1">
                    <div class="row">
                        <div class="col-12 form-group">
                            <label for="nombre_proceso">Correspondiente al mes o proceso</label>
                            <input class="form-control" id="nombre_proceso" type="text" name="nombre_proceso"
                                v-model="nombre_proceso" placeholder="Mes o proceso mensual" />
                        </div>
                    </div>
                </div>

                <div v-else-if="tipo_id == 2">
                    <div class="row">
                        <div class="col-12 form-group">
                            <label for="cdi_cuil">Número de CDI o CUIL (sin guiones ni espacios)</label>
                            <input class="form-control" type="text" name="cdi_cuil" id="cdi_cuil" v-model="cdi_cuil"
                                placeholder="Número de CUIL o CDI" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="select-container col-12 form-group">
                            <label for="tipo_documento">Tipo de documento</label>
                            <select class="form-control" id="tipo_documento" name="tipo_documento" v-model="tipo_documento">
                                <option value="DNI" selected="selected">DNI</option>
                                <option value="LC">LC</option>
                                <option value="LE">LE</option>
                                <option value="CI">CI</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-12">
                            <label for="numero_documento">Número de documento</label>
                            <input class="form-control" id="numero_documento" type="text" name="numero_documento"
                                v-model="numero_documento" placeholder="Número de documento" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-12">
                            <label for="nombre">Nombre y apellido</label>
                            <input class="form-control" id="nombre" type="text" name="nombre" v-model="nombre"
                                placeholder="Nombre y apellido" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group" v-if="tipo_id != null">
                        <label for="observaciones">Observaciones</label>
                        <textarea class="form-control" name="observaciones" id="observaciones" v-model="observaciones"
                            placeholder="Observaciones..."></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group" v-if="tipo_id != null">
                        <!-- <label for="archivo" v-if="esDescuento && organismoHabilitado">
                            Archivo (sólo se admite un archivo .txt con todos los descuentos a enviar)
                        </label>
                        <label for="archivo" v-else>
                            Archivo (sólo se admite un archivo .zip con todos los documentos a enviar
                        </label> -->
                        <label for="archivo">
                            Archivo (sólo se admite un archivo .zip con todos los documentos a enviar
                        </label>
                        <!--  <input
                            class="form-control-file"
                            type="file"
                            ref="file"
                            name="archivo"
                            id="archivo"
                            :accept="['.zip']"
                            @change="subirArchivo"
                        /> -->
                        <input class="form-control-file" type="file" ref="file" name="archivo" id="archivo"
                            :accept="['.zip']" @change="subirArchivo" />
                    </div>
                </div>

                <!-- Errores -->
                <div class="alert alert-danger for" v-if="errores">
                    {{ errores }}
                </div>

                <!-- msg error validacion -->
                <div class="alert alert-danger for" v-if="errores_validacion.length">
                    <ul v-for="error in errores_validacion" :key="error.index">
                        <li>{{ error }}</li>
                    </ul>
                </div>

                <!-- Botones enviar transferencia -->
                <div class="nueva-transferencia__acciones">
                    <button class="btn btn-outline-secondary btn-cancelar nueva-transferencia__accion"
                        @click="cancelarEnvioTransferencia">
                        Cancelar envío
                    </button>
                    <button class="btn btn-primary btn-enviar nueva-transferencia__accion" @click="enviarTransferencia">
                        <span v-if="enviandoTransferencia">
                            <!--  <span v-if="esDescuento && organismoHabilitado">Generando reporte... <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                            <span v-else>Enviando transferencia... <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span> -->
                            <span>Enviando transferencia... <i
                                    class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                        </span>
                        <span v-else>Enviar transferencia</span>
                    </button>
                </div>
                <span v-if="enviandoTransferencia">
                    <small class="text-muted">El envío de la transferencia puede demorar de acuerdoal tamaño del archivo
                        adjunto</small>
                </span>
            </form>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            tipo_id: null,
            organismos: null,
            organismo: null,
            concepto: null,
            conceptos: null,
            motivos: null,
            motivo_id: null,
            motivo_motivo: null,
            nombre_organismo: null,
            sectoresOrganismo: null,
            nombre_sector: null,
            subsector_id: null,
            organismo_id: null,
            asunto: null,
            observaciones: null,
            nombre_proceso: null,
            archivo: null,
            cdi_cuil: null,
            tipo_documento: "DNI",
            numero_documento: null,
            nombre: null,
            errores: null,
            status_error: null,
            status_ok: false,
            enviandoTransferencia: false,
            errores_validacion: [],
            validacion_ok: false,
            msg_validacion_ok: null,
            organismo_user: this.$store.state.user.subsectores[0].sector.sede.organismo.codigo,
            subtipo_descuento:null,
            conceptos_acuerdo:[],
            buscando_concepto : false
        };
    },
    computed: {
        funciones: {
            get() {
                return this.$store.state.user.roles;
            },
            set(val) {
                this.$store.state.user.roles = val;
            }
        },
        td() {

            if (this.$store.state.user.aplicaciones.some(a=>a.funcion==="TRANSFDOC-USUARIO")) {
                return 1;
            }
            if (this.$store.state.user.aplicaciones.some(a=>a.funcion==="TRANSFDOC-EMPLEADO")) {
                return 2;
            }
            if (this.$store.state.user.aplicaciones.some(a=>a.funcion==="TRANSFDOC-COORDINADOR")) {
                return 3;
            }
            if (this.$store.state.user.aplicaciones.some(a=>a.funcion==="TRANSFDOC-JEFE")) {
                return 4;
            }
            if (this.$store.state.user.aplicaciones.some(a=>a.funcion==="TRANSFDOC-DIRECTOR")) {
                return 5;
            }
        },
        /* esDescuento(){
            return this.td == 1 && this.motivo_motivo == 22 && this.tipo_id == 1
        
        },

        organismoHabilitado(){
            return this.organismo_user==14 || this.organismo_user==17
        }, */
        existenConceptos(){
            return this.conceptos_acuerdo.length>0
        }
    },
    created: function() {


        function getOrganismosPuntoaPunto(){
            return axios.get("organismos-puntoapunto") 
        };
        function getMotivosPuntoaPunto(){
            return  axios.get("motivos-puntoapunto")

        };
        /* function getConceptosPuntoaPunto(){
             return axios.get("concepto-organismo/" + this.organismo_user)
        }; */
        Promise.all([getOrganismosPuntoaPunto(), getMotivosPuntoaPunto()])
        .then((results)=>{
                        this.organismos = results[0].data.data;
                        this.motivos = results[1].data.data;
                        //this.conceptos = conceptosResponse.data.data;
            
        })
        .catch((error)=>{
            this.errores = error.response.data.error
            this.status_error = error.response.data.code
   
        })
        
        /* axios
            .all([
                axios.get("organismos-puntoapunto"),
                axios.get("motivos-puntoapunto"),
                //axios.get("concepto-organismo/" + this.organismo_user)
            ])
            .then(
                axios.spread(
                    (
                        organismosResponse,
                        motivosResponse,
                        conceptosResponse
                    ) => {
                        this.organismos = organismosResponse.data.data;
                        this.motivos = motivosResponse.data.data;
                        this.conceptos = conceptosResponse.data.data;
                    }
                )
            )
            .catch(
                axios.spread(
                    (organismosError, motivosError, conceptosError) => {
                        this.status_error = organismosError.response.status||null;
                        this.status_error = motivosError.response.status||null;
                        this.status_error = conceptosError.response.status||null;
                        this.errores = organismosError.response.data.error||null;
                        this.errores = motivosError.response.data.error||null;
                        this.errores = conceptos.response.data.error||null;
                    }
                )
            ); */
    
    },
    watch: {
        organismo: function() {
            axios
                .get("organismos/" + this.organismo + "/sectores")
                .then(response => {
                    this.sectoresOrganismo = response.data.data;
                })
                .catch(error => {
                    this.status_error = error.response.status;
                    this.errores = error.response.data.error;
                });
        }
    },
    methods: {
        enviarTransferencia: function() {
            var form = document.getElementById("formularioNuevaTransferencia");
            let formData = new FormData(form);
            this.enviandoTransferencia = true;
            this.errores = null;
            this.errores_validacion = [];

            if (this.archivo != null) {
                /*if (this.esDescuento){
                    if (this.archivo.name
                        .split(".")
                        .pop()
                        .toLowerCase() != "txt"){
                        this.enviandoTransferencia = false;
                        this.errores = "El archivo adjunto debe tener la extensión .txt";
                        return false;
                    }
                }
                else*/
               /*  if (this.archivo.name.split(".").pop() != "zip" && !this.esDescuento && !this.organismoHabilitado){

                    this.enviandoTransferencia = false;
                    this.errores = "El archivo adjunto debe tener la extensión .zip";
                    return false;
                }else if(this.archivo.name.split(".").pop() != "txt" && this.esDescuento && this.organismoHabilitado){
                    this.enviandoTransferencia = false;
                    this.errores = "El archivo adjunto debe tener la extensión .txt";
                    return false;
                } */
                
                /* if (this.esDescuento && this.organismoHabilitado){
                    formData.append( "subtipo", this.subtipo_descuento)
                    formData.append("nombre_proceso", moment(Date.now()).format('MMMM d'))
                } */

            } else {
                this.errores = "No se adjuntó ningun archivo";
                this.enviandoTransferencia = false;
                return false;
            }

            axios.post("transferencias", formData)
                .then(response => {
                    this.$router.push({
                        name: "PuntoAPuntoEnviadas",
                        query: { transferencia: "enviada" }
                    });
                    this.status_ok = true;
                    this.enviandoTransferencia = false;
                })
                .catch(error => {
                    if (error.response.status == 422) {
                        let erroresValidacion
                        if(error.response.data.errors){
                            erroresValidacion = Object.values(
                            error.response.data.errors
                        )
                        this.errores_validacion = erroresValidacion.map(
                            error => {
                                return error[0];
                            }
                        );
                        } else{
                           this.errores = error.response.data.error.archivo[0]
                        }
                        
                    } else {
                        this.errores_validacion.push(
                            "Hubo un problema al realizar la transferencia"
                        );
                    }
                    this.enviandoTransferencia = false;
                });
        },
        subirArchivo: function() {
            this.archivo = this.$refs.file.files[0];
        },
        cancelarEnvioTransferencia: function() {
            this.$router.push({ name: "PuntoAPuntoNovedades" });
        },
         obtenerConceptosAcuerdos: async function(){
            this.conceptos_acuerdo=[]
            this.buscando_concepto =true;
            const response= await axios.get(`organismo/${this.organismo_user}/conceptos/d/${this.subtipo_descuento}`) 
            this.buscando_concepto =false;
            this.conceptos_acuerdo =response.data.data;
           
        }
    }
}

</script>
