<template>
    <div class="w-100" v-if="accesoPermitido">
      <router-view></router-view>
    </div>
  
    <div class="w-100" v-else>
      <div class="alert alert-primary text-center"> {{ mensaje }} </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
        return {
          accesoPermitido: false,
          mensaje: 'Verificando acceso a la aplicación...'
        }
    },
    created() {
        this.accederAdministradorHaberes()
    },
    methods: {
        accederAdministradorHaberes: function() {
            axios.get('acceder-administrador-haberes')
                  .then((response) => {
                      this.accesoPermitido = true
                  })
                  .catch((error) => {
                      this.accesoPermitido = false
                      this.mensaje = 'No podés acceder al Administrador de Recibos en este momento.'
                  })
        }
    }
  };
  </script>