<template>
  <div class="beneficio-sube">
    <div class="image-container  mt-8">
      <img class="image-render" src="https://micaja.crjppf.gov.ar/imagenes/beneficio_sube.jpg" alt="Imagen Sube">
    </div>
    <div class="cards-container">
      <div class="card card_sube" >
        <h2>Gestión del Beneficio SUBE</h2>
        <p>Administra el estado de tu beneficio SUBE con facilidad y rapidez.</p>
        <div class="spacer"></div>
        <small><strong>*El descuento que se aplica en la tarifa es del 55%</strong></small>
        <br/>
        <div v-if="searchError">
          <ul v-for="error in errorsArray">
            <li>{{error}}</li>
          </ul>
        </div>
        <subeAltaPendiente v-if="estado == 'alta_pendiente'"></subeAltaPendiente>
        <subeActivo v-if="estado == 'activo'"></subeActivo>
        <subeBajaPendiente v-if="estado == 'baja_pendiente'"></subeBajaPendiente>
        <subeInactivo  v-if="estado == 'sin_beneficio' || estado == 'inactivo'"></subeInactivo>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState} from 'vuex';
import Inactivo from "./estados/Inactivo.vue";
import AltaPendiente from "./estados/AltaPendiente.vue";
import BajaPendiente from "./estados/BajaPendiente.vue";
import Activo from "./estados/Activo.vue";


export default {
  components:{
    subeInactivo: Inactivo,
    subeAltaPendiente : AltaPendiente,
    subeBajaPendiente: BajaPendiente,
    subeActivo : Activo

  },
  computed: {
    ...mapState({
      numeroDocumento: state => state.persona.numero_documento,
      tipoDocumento: state => state.persona.tipo_documento,
      estado: state => state.gestionbeneficiosube.estado,
      searchError : state => state.gestionbeneficiosube.errors.length > 0,
      errorsArray : state => state.gestionbeneficiosube.errors
    }),
  },

  methods: {
    ...mapActions('gestionbeneficiosube', [
      'obtenerEstadosBeneficiario',
    ]),
  },

  mounted() {
      this.obtenerEstadosBeneficiario({ "tipo_documento": this.tipoDocumento, "numero_documento": this.numeroDocumento });
  }
 
};
</script>

<style scoped lang="scss">


  .cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin-top: 1rem;
  }

  .card_sube {
    width: 80%;
    padding:30px;
    border-radius:1rem;
  }
  .spacer{
    width: 100%;
    border-bottom: 2px solid rgb(170, 170, 170) ;
    box-shadow: 1px 1px 2px 1px rgba(179, 178, 178, 0.449);
    margin-left: auto;
    margin-right: auto;
    margin-bottom:1rem;
  }
  .card_sube:hover {
    transform: translateY(-5px); /* Efecto de levantamiento al hacer hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al hacer hover */
  }
 
  .swal-center-text {
    text-align: center;
  }

  .image-container{
    height: 500px;
  }

  .image-container > .image-render{
    object-fit:cover;
    width: 100%;
    height: 500px;
  }


  
  .image-render{
    object-fit:contain ;
  }
  .checkbox_terminos input{
    height: 1em !important;
    width: 1em;
}



</style >

<style lang="scss">

div:has( .beneficio-sube) {

max-width: 100vw;
margin-left: 0px;
margin-right: 0px;
padding-left: 1px;
padding-right: 1px;

 }
 .boton_alta{
  float:right;
}
 .beneficio_sube_card_body_text{
  font-size: smaller;
  padding: 5px;
  margin-left: 10px;
}  
.card_condiciones_servicio{
    padding:20px;
    background-color: rgba(255, 175, 175, 0.634)!important;;
  }
</style>