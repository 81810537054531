<template>
    <div class="container-fluid buscar_usuario usuarios_empleados_buscar">
        <div class="row">
            <form class="formulario" v-on:submit.prevent>
                <h4 class="busqueda-transferencias__titulo">Buscar usuarios</h4>
                <div class="row">
                    <div class="form-group select-container col-12 col-md-4">
                        <label for="select">Buscar por: </label><br/>

                        <select class="form-control" name="select" v-model="select" id="select">
                            <option value="1">Organismo</option>
                            <option value="2">Dni o Cuil</option>
                        </select>
                    </div>

                    <div class="form-group col-12 col-md-8" v-if="select == '1'">
                        <label for="selectOrganismo">Organismo</label><br />
                        <multiselect id="selectOrganismo" v-model="selectOrganismo" :options="organismos" label="nombre_organismo" track-by="codigo" placeholder="Ingrese el nombre del organismo"></multiselect>
                    </div>

                    <div class="form-group col-12 col-md-8" v-if="select == '2'">
                        <label class="d-block" for="cuil_input">Buscar</label>
                        <div class="buscador-usuario">
                            <input class="form-control" type="text" id="cuil_input" name="cuil_input" v-model="cuil_input" placeholder="Ingrese el Dni o Cuil" v-mask="['#########', '##-########-#']"/>
                            <button class="btn btn-primary" @click="buscarUsuario(cuil_input)">
                                <i class="fas fa-search px-5"></i>
                            </button>
                        </div>
                    </div>

                    <div class="col-12 my-2">
                        <p class="text-muted">
                            <small>Importante: en este listado no figuran todos los organismos, solamente aparecen aquellos organismos que tengan usuarios registrados en Mi Caja</small>
                        </p>
                    </div>
                </div>
            </form>
        </div>

        <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="mensaje">
            <p>{{ mensaje }}</p>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="mensaje = ''">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div v-if="buscando">
            <div class="alert alert-info">Buscando información...</div>
        </div>
        <div class="alert alert-danger col-12" role="alert" v-else-if="errores">
            <p>{{ errores }}</p>
        </div>
        <div v-else-if="noEncontrado" class="alert alert-warning">
            <p>No se econtraron usuarios</p>
        </div>
        <div v-else>
            <div v-if="usuarios.length > 0" id="usuarios">
                <Usuario v-for="(usuario, index) in usuarios"
                         :key="usuario.id"
                         :usuario="usuario"
                         :organismos="organismos"
                         :index="index"
                         @actualizarUser="actualizarUsuario($event)"
                />

                <pagination
                    :pagination="links"
                    @cambiar-pagina="cambiarPagina($event)"
                />
            </div>
        </div>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import axios from "axios";
import Usuario from "./elementos/Usuario.vue";
import pagination from "../../../../elementos/pagination/Paginacion.vue";
import Multiselect from "vue-multiselect";

export default {
    name: "buscarUsuario",
    components: {
        Usuario,
        pagination,
        Multiselect
    },
    data() {
        return {
            select: 1,
            selectOrganismo: '',
            organismos: [],
            usuarios: [],
            errores: null,
            mensaje: '',
            buscando: false,
            cuil_input: '',
            organismoSeleccionado: '',
            links: {},
            noEncontrado: false
        };
    },
    async created() {
        try{
            this.buscando = true
            const request1 = axios.get('usuarios/organismos/users');
            const request2 = axios.get('organismos-con-sede');
            const [usuarios, organismos] = await axios.all([request1,request2]);
            this.usuarios = usuarios.data.data
            this.links = {
                links: usuarios.data.links,
                meta: usuarios.data.meta
            }
            this.organismos = organismos.data.data
        }
        catch (e) {
            this.errores = 'Hubo un error al obtener los datos'
        }
        this.buscando = false

        if (this.$route.params.mensaje) {
            this.mensaje = this.$route.params.mensaje;
        }
    },
    watch: {
        async selectOrganismo(newValor) {
            this.buscando = true
            this.errores = null

            let url = newValor.codigo === "" ? 'usuarios/organismos/users' : `usuarios/organismos/${newValor.codigo}/users`

            try {
                const {data} = await axios.get(url)
                this.usuarios = data.data
                this.links = {
                    links: data.links ? data.links : '',
                    meta: data.meta ? data.meta : ''
                }
                this.buscando = false
            }
            catch (e) {
                this.buscando = false
                if (e.response.status === 404) {
                    this.noEncontrado = true
                }
                else {
                    this.errores = 'Hubo un error al buscar el usuario'
                    this.buscando = false
                }
            }
        }
    },
    methods: {
        async buscarUsuario(cuil) {
            this.noEncontrado = false
            this.errores = null
            this.buscando = true

            let dato_usuario = cuil.replace(/-/g, "");
            dato_usuario = dato_usuario.replace(/_/g, "");
            dato_usuario = dato_usuario.replace(/ /g, "");

            try {
                const {data} = await axios.get(`usuarios/organismos/${dato_usuario}/user`)
                this.usuarios = data.data != null ? [data.data] : []
                this.buscando = false
            }
            catch (e) {
                this.buscando = false
                if (e.response.status === 404) {
                    this.noEncontrado = true
                }
                else {
                    this.errores = 'Hubo un error al buscar el usuario'
                    this.buscando = false
                }
            }
        },
        async cambiarPagina(url) {
            const {data} = await axios.get(url)
            this.usuarios = data.data
            this.links = {
                links: data.links,
                meta: data.meta
            }
            $("html, body").animate({ scrollTop: $("#usuarios").offset().top }, 500);
        },
        actualizarUsuario(user) {
            this.$set(this.usuarios, user.index, user.usuario)
        }
    }
}
</script>
