<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Está en: <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link></li>
                <li class="breadcrumb-item active" aria-current="page"><router-link
                        :to="{ name: 'MisDocumentosMenu' }">Mis Documentos</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Mis Recibos de Haberes</li>
            </ol>
        </nav>

        <div class="w-100">
            <h2 class="titulo-aplicacion">Mis Recibos de Haberes <i class="far fa-file-alt"></i></h2>
        </div>
        <div class="card">
            <div class="card-title">¡Atención Beneficiario Residente en el Exterior!</div>
            <div class="card-body">
                Recuerde que para los beneficiarios que residen fuera del país tienen la obligación de acreditar
                supervivencia semestralmente en los meses de abril y octubre para garantizar la normal percepción de sus
                haberes previsionales.
                La presentación próxima corresponde al mes de Octubre para lo que se estarán recibiendo su trámites, desde
                el 01 al 31 de Octubre del 2024 a través de los canales habilitados. Para más información consulte nuestra página web: <a
                    href="https://www.argentina.gob.ar/cajaderetiroPFA/supervivencia-0"
                    target="_blank">https://www.argentina.gob.ar/cajaderetiroPFA/supervivencia-0</a>
            </div>
        </div>
        <Loader v-if="buscando" />
        <div v-else-if="errorRecibos" class="alert alert-danger" role="alert">
            {{ errorRecibos }}
        </div>
        <div class="mt-4" v-else>
            <div class="menu-beneficios mb-3">
                <button v-if="legajos" type="button" class="btn btn-primary boton-beneficio mr-3"
                    v-for="legajo in legajos" @click="mostrarRecibos(legajo)">
                    Legajo {{ legajo }}
                </button>

                <button v-if="beneficios" type="button" class="btn btn-primary boton-beneficio mr-3"
                    v-for="beneficio in beneficios" @click="key = mostrarRecibos(beneficio.beneficio_id)">
                    Beneficio {{ beneficio.beneficio_id | formatearBeneficio }}
                </button>

                <button v-if="apoderados" type="button" class="btn btn-primary boton-beneficio mr-3"
                    v-for="apoderado in apoderados" @click="key = mostrarRecibos(apoderado.beneficio_id)">
                    Apoderado {{ apoderado.beneficio_id | formatearBeneficio }}
                </button>
            </div>
            <sin-recibos-pb class="mt-4" v-if="recibos.length === 0 && recibos.tipo === 'PB'" :beneficio="key" />
            <sin-recibos-pi class="mt-4" v-else-if="recibos.length === 0 && recibos.tipo === 'PI'" :legajo="key" />
            <transition-group v-else appear name="mis-recibos" id="mis-recibos" tag="div" mode="out-in"
                v-for="(recibo, index) in recibos" :key="recibo['ruta_recibo']" :index="index">
                <recibo :key="recibo['ruta_recibo']" :recibo="recibo" :tipo="recibos.tipo" :index="index" :seccion="'rh'"
                    :persona="persona" />
            </transition-group>
        </div>
    </div>
</template>

<script>
import Recibo from "./Recibo.vue";
import SinRecibosPB from "./elementos/SinRecibosPB.vue";
import SinRecibosPI from "./elementos/SinRecibosPI.vue";
import Loader from "../../../elementos/loaders/Loader.vue";
import { mapGetters, mapState } from "vuex";
import axios from "axios";


export default {
    components: {
        recibo: Recibo,
        "sin-recibos-pb": SinRecibosPB,
        "sin-recibos-pi": SinRecibosPI,
        Loader
    },
    data() {
        return {
            recibos:[],
            recibosTodos: {},
            errorRecibos: null,
            selectedKey:"",
            key: '',
            buscando: true,
            perPage: 3,
            currentPage: 1,
        };
    },
    methods: {
        mostrarRecibos(key){
            this.recibos = this.recibosTodos[key]
        }
    },
    async created() {

        for (let i = 0; i < this.tipo.length; i++) {

            let url = this.tipo[i] === 'PB' ? 'beneficiarios' : 'empleados'

            if (this.tipo[i] === 'PB' && this.beneficios.length > 0) {
                this.key = this.beneficios[0].beneficio_id
            }
            else if (this.tipo[i] === 'PB' && this.apoderados.length > 0) {
                this.key = this.apoderados[0].beneficio_id
            }
            else if (this.tipo[i] === 'PI') {
                this.key = this.legajos[0]
            }

            axios.get(`/${url}/mis-documentos/recibos`)
            .then((response) => {
                    for (const data in response.data.data) {
                        this.recibosTodos[data] = response.data.data[data];
                        this.recibosTodos[data].tipo = this.tipo[i]
                        
                    }
                    this.mostrarRecibos(this.key)
            }).catch((e) =>{
                if (e.response.status === 500) {
                    this.errorRecibos = "Hubo un inconveniente con la busqueda de recibos. Por favor intente en unos minutos.";
                } else if (e.response.status === 404) {
                    this.recibos = [];
                } else {
                    this.errorRecibos = e.response.data.error;
                }
            })
        };
        this.buscando = false
        },
        computed: {
        ...mapGetters({
            tipo: 'getTiposRecibo',
            beneficios: 'getBeneficios',
            apoderados: 'getApoderados',
            persona: 'getPersona',
            legajos: 'getLegajos'
        }),
    ...mapState({
        esBeneficiario: state => {
            return state.user.roles.includes('BENEFICIARIO')
        }
    }),
   
    rows() {
        return this.recibos.length
    },
    }

};
</script>
