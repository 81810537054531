const getRecibosPenitenciariaPorCUIT = async (cuit) => {
    return axios.get("gestion-penitenciaria/recibos/buscar-cuit/" + cuit)
                

}

const getRecibosPenitenciariaPorBeneficio = (beneficio) => {
    return axios.get("gestion-penitenciaria/recibos/buscar-beneficio/" + beneficio)
}

export {
    getRecibosPenitenciariaPorCUIT,
    getRecibosPenitenciariaPorBeneficio
}
