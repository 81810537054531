import {
    obtenerPublicaciones,
    eliminarPublicaciones,
    crearPublicacion,
    traerPublicacionesParaEdicion,
    traerPublicacionPorID,
    editarPublicacion
} from "../../../api/novedades";

const state = {
    novedades: [],
    id_novedad_seleccionada: null,
    novedades_edicion:[],
    novedad_edicion_seleccionada:null
};

const getters = {
    getNovedades(state) {
        
        return state.novedades;

    },
    getUltimasNovedades (state){
        return state.novedades.filter((data, index) => index <=3);
    },
    getDatosSeleccionados(state){
        return state.novedades.filter((novedad)=>{ 
            return parseInt(state.id_novedad_seleccionada) == novedad.id;
        });
    }
      
};

const mutations = {
    guardarNovedades(state, data) {
        state.novedades = [...data]
    },
    agregarNovedadesALista(state, data){
        state.novedades.push(data);
    },
    guardar_id_seleccionada(state, data) {
        state.id_novedad_seleccionada = data;
    },
    guardar_novedades_edicion(state, data){
        state.novedades_edicion = [...data]
    },
    guardar_novedades_edicion_seleccionada(state, data){
        state.novedad_edicion_seleccionada = data
    }
    
};

const actions = {

    async editarNovedadByID({commit}, formData){
        const {data, status} = await editarPublicacion(formData);
        if (status == 201){ 
            commit("guardar_novedades_edicion_seleccionada", data);
            return {
                 data,
                 status
             };       
        }else{
         return{
             status,
             error_message : "No se pudo editar la noticia, chequee los datos y vuelva a intentar"
         }
        } 
   
    },

    async getNovedadByID({commit}, id){
        const {data, status} = await traerPublicacionPorID(id);
        if (status == 202){
            commit('guardar_novedades_edicion_seleccionada', data)
            return {
                 status,
                 data,
             };       
        }else{
         return{
             status,
             error_message : "Error: No se pudo encontrar la noticia"
         }
        } 
    },
    async getNovedades({commit}) {
        const {data, status} = await obtenerPublicaciones();
        if(status===202){
            commit('guardarNovedades', data)
            return data;
        }

    },
    async getNovedadesParaEdicion({commit}) {
        const {data, status} = await traerPublicacionesParaEdicion();
        if(status===202){
            commit('guardar_novedades_edicion', data)
            return data;
        }

    },

    async eliminarNoticia({commit},id) {
        const {data, status} = await eliminarPublicaciones(id);
        if (status == 204){
            return {
                 status,
                 data,
             };       
        }else{
         return{
             status,
             error_message : "No se pudo eliminar la noticia, chequee los datos y vuelva a intentar"
         }
        } 

    },
    async agregarNovedad({commit}, novedad){
       const {data, status}  = await crearPublicacion(novedad);
       if (status == 201){
           commit("agregarNovedadesALista", data);
           return {
                data,
                status
            };       
       }else{
        return{
            status,
            error_message : "No se pudo generar la noticia, chequee los datos y vuelva a intentar"
        }
       } 
    },
    getNovedadporId({commit}, id){
        commit('guardar_id_seleccionada', id);
        
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
