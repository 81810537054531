<template>
    <div class="container_main" style="margin-bottom:7rem">
        <div class="navegador_noticia  w-100">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Noticias - Nombre de la noticias</li>
                </ol>
            </nav>
        </div>
        <div class="container_noticia mt-8">
            <img  class=" imagen_noticia w-100" v-bind:src="'data:'+datos.extension_imagen+ ';base64,'+ datos.image_blob_publicacion">
        </div>
        <div class="card_noticias_container container">
            <div v-if="datos!=null" class="card card_noticias">
                <div class="card-body">
                    <div class="card-title ">
                        <h1 class="titulo_noticia">{{datos.titulo_publicacion}}</h1>
                    </div>
                    <div class="w-100">
                        <h4 class="subtitulo_noticia">{{datos.descripcion_publicacion}}</h4>
                    </div>
                    <div class="w-100 fecha_container">
                        <p class="fecha_publicacion"> Publicado el {{this.fecha}}</p>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-11 division_card"></div>
                    </div>
                    
                    <div class="w-100 card_noticias_cuerpo_container">
                        <div class="card_noticias_cuerpo_publicacion" v-html="datos.html_cuerpo_publicacion"></div> 
                    </div>
                </div> 
            </div>
        </div>
        
        <h1  class="mt-2" style="font-family:'Encode Sans';font-size: calc(1rem + 1.8vw);">Ultimas Noticias</h1>
         <div v-if="ultimas_novedades!=null" class="row h-100 no-gutters justify-content-around">
                <div v-for="novedad in ultimas_novedades" class="col-lg-3 col-md-6 col-xs-1 mb-3">
                    <router-link style="text-decoration: none; color:black" :to="{ name: 'DetalleNoticiasBeneficiarios', params: { id_noticia: ''+novedad.id+'' } }" >
                        <div  class=" m-2 card card_noticias_thumbnail" >
                            <img  class=" card_noticias_thumbnail_imagen card-img-top" v-bind:src="'data:'+novedad.extension_imagen+ ';base64,'+ novedad.image_blob_publicacion"> 
                            <div class="card-body">
                                <h3 class="card_noticias_thumbnail_titulo">{{ novedad.titulo_publicacion }}</h3>
                                <h6 class="card-text" style="font-size: calc(0.6rem + 1.0vw)" v-if="novedad.descripcion_novedad!=''">{{novedad.descripcion_publicacion}}</h6>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="card_noticias_thumbnail_btn_container w-100 " >
                    <button class="btn btn-primary card_noticias_thumbnail_btn">Ver Todas</button>
            </div>

    </div>
    </template>
    <script>
    import {mapGetters , mapActions} from 'vuex';
    import { formatearStringFechaPublicacion } from '../../../helpers';
    
    export default{
        data(){
            return{
                id_novedad_seleccionada:null,
                datos:null,
                ultimas_novedades: [],
                fecha:null,
                novedad_seleccionada: null,
  
            }
        },
        methods:{
            ...mapGetters('novedades',[
                'getDatosSeleccionados',
                'getUltimasNovedades'
            ]),
            ...mapActions('novedades',[
                'getNovedadporId'
                ,'getNovedades'
            ]),
            async getbyId(id_noticia){
                await this.getNovedadporId(id_noticia);
                this.datos = this.getDatosSeleccionados()[0];
                this.fecha = formatearStringFechaPublicacion(this.datos.created_at)
            }
        },
        
        async created(){
            await this.getNovedades()
            this.ultimas_novedades = await this.getUltimasNovedades();
            await this.getbyId(this.$route.params.id_noticia);
        },
        watch: { 
        '$route.params.id_noticia': {
        handler: function(id_noticia) {
            this.getbyId(id_noticia);
            window.scrollTo({
                top:0,
                left:0,
                behavior:"smooth"
            })
        },
        deep: true,
  }
}
    }
    </script>
    <style scoped>
        .imagen_noticia{
            width: 100vw ;
            object-fit: cover ;
            height: calc(5rem + 22vw);
        }
        .container_noticia{
            position:absolute;
            /*box-sizing: content-box;
            */left:0;
            padding-right: 0;
            width:100% !important;
            padding-left:0 !important;
            margin-bottom: 50px;
            display:flex;
           
        }
        .division_card{
            margin-bottom:4rem;
            margin-top:1rem;
            line-height: 2rem;
            border-bottom: 1px solid rgb(157, 158, 164) ;
            color: rgb(47, 54, 66);
        }
        .navegador_noticia{
            padding-top: 2.5rem;
        }
        .card_noticias_container{
            font-family: 'Encode Sans';
            display:flex;
            padding:0;
            flex-direction: column;
            align-items: center;
            padding-top: calc(3rem + 18vw);  
            margin:0;
        }
        .card_noticias{
            width: 55rem;
            background-color: rgba(233, 233, 233, 0.8);
            border-radius: 2vw;
           margin-bottom:50px;
            color:rgb(51, 51, 51);
            max-width: 80vw;
        }   
        .titulo_noticia{
            margin-top:1rem;
            font-weight:700;
            font-size: calc(1rem + 1.8vw);
        }
        .subtitulo_noticia{
            margin-top:calc(0.3rem + 0.6vw);
            font-weight: 500;
            font-size:calc(0.5rem + 0.7vw);
        }
        .fecha_publicacion{
            width: 100%;
            text-align: end;
            font-weight: 500;
            color: rgb(85, 85, 85);
            font-size:calc(0.5rem + 0.4vw);
            padding-top:0
        }

        .fecha_container{
            margin-top: calc(0.3rem + 0.6vw);
            margin-bottom:2rem
        }
        .card_noticias_thumbnail {
            padding: 0;
            border-radius:7%;
            margin-top:5px;
            font-family: 'Encode Sans';
        }
        .card_noticias_thumbnail_imagen{
            border-radius: 15px 15px 0px 0px;
            height: 10rem;
            object-fit:cover;    
        }
        .card_noticias_thumbnail_titulo{
            font-weight: 600;
            font-size: calc(0.7rem + 1.1vw)!important;
        }
        .card_noticias_thumbnail_btn{
            float: right;
        }
        .card_noticias_thumbnail{
            box-shadow: 0px 1px 3px 0px rgba(59,143,197,1),
            0px 1px 2px 0px rgba(0,0,0,0.24);
            transition: box-shadow 0.3s ease-in-out;
            height:100%;
        }
        .card_noticias_thumbnail:hover{
            box-shadow: 0px -25px 20px -20px rgba(0,114,187,1),
            25px 0px 20px -20px rgba(0,0,0,0.45),
            0px 25px 20px -20px rgba(0,0,0,0.45),
            -25px 0px 20px -20px rgba(0,0,0,0.45);
        }
        .card_noticias_thumbnail:hover > img{
            opacity: 0.7;
        }
        
        .card_noticias_thumbnail_btn_container{
            margin-top:2rem;
        }

        .card_noticias_cuerpo_publicacion{
            display:flex;
            flex-direction: column;
        }

        
        
    </style>
    <style>
    
    .card_noticias_cuerpo_publicacion > p > img{
        max-width: 100%!important;
        max-height: 100%!important;
    }
    .card_noticias_cuerpo_publicacion h1{
        font-size: calc(0.9rem + 1.6vw)!important;

    }
    .card_noticias_cuerpo_publicacion h2{
        font-size: calc(0.8rem + 1.3vw)!important;

    }
    .card_noticias_cuerpo_publicacion h3{
        font-size: calc(0.7rem + 1.1vw)!important;

    }
    .card_noticias_cuerpo_publicacion h4{
        font-size: calc(0.6rem + 0.9vw)!important;

    }
    .card_noticias_cuerpo_publicacion h6{
        font-size: calc(0.4rem + 0.9vw)!important;

    }

    .card_noticias_cuerpo_publicacion p {
        font-size:calc(0.5rem + 0.7vw);
    }
    .card_noticias_cuerpo_publicacion li {
        font-size:calc(0.5rem + 0.7vw);
    }
    </style>