<template>
    <div>
        <h3>Buscar organismos</h3>

        <div class="formulario">
            <form v-on:submit.prevent>
                <h4>Buscador</h4>
                <div class="d-flex flex-column flex-md-row">
                    <input type="text" placeholder="Ingrese el organismo" class="form-control col-md-9 mr-md-2 mb-2 mb-md-0" v-model="organismo_buscar">
                    <button class="btn btn-primary col" @click="search(organismo_buscar)">
                        Buscar
                    </button>
                </div>
            </form>
        </div>

        <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="success">
            {{success}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="success = ''">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="table-responsive mt-4">
            <router-link tag="button" class="btn btn-success mb-2" :to="{name: 'MisAplicacionesOpereacionesOrganismosNuevo'}">
                Nuevo organismo
            </router-link>

            <div class="d-flex justify-content-between mb-2">
                <p class="text-muted my-2">{{resultado}}</p>
                <button class="btn btn-outline-danger transferencia__accion" v-if="busqueda"  @click="getOrganismos">
                    <i class="fas fa-trash"></i> Limpiar búsqueda
                </button>
            </div>

            <div v-if="estado != ''">
                <div class="alert alert-info" role="alert">
                    {{estado}}
                </div>
            </div>
            <div v-else>
                <table class="formulario table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Codigo</th>
                            <th scope="col">Organismo</th>
                            <th scope="col">Nombre organismo</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="organismo in organismosEncontrados" :key="organismo.id">
                            <td>{{organismo.codigo}}</td>
                            <td>{{organismo.organismo}}</td>
                            <td>{{organismo.nombre_organismo}}</td>
                            <td class="d-flex">
                                <router-link tag="button" class="btn btn-primary mr-2" :to="{name: 'MisAplicacionesOperacionesOrganismosEditar', params: {id: organismo.codigo}}">
                                    Editar
                                </router-link>
                                <button class="btn btn-danger" @click="eliminar(organismo.codigo)">Eliminar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div>
            <paginacion :pagination="organismos"
                        v-on:cambiar-pagina="cambiarPagina($event)"
            ></paginacion>
        </div>
    </div>
</template>

<script>
import pagination from "../../../../../elementos/pagination/Pagination";
import axios from "axios";

export default {
    name: "MisAplicacionesOperacionesOrganismosBuscar",
    components: {
        'paginacion' : pagination
    },
    data(){
        return{
            organismos: [],
            estado: '',
            organismo_buscar: '',
            success: '',
            resultado: '',
            busqueda: false,
            por_pagina: 20
        }
    },
    created() {
        if (this.$route.params.msg){
            this.success = this.$route.params.msg
        }
        this.getOrganismos();
    },
    computed: {
      organismosEncontrados(){
          return this.organismos.data
      }
    },
    methods: {
        getOrganismos(){
            this.organismo_buscar = ''
            this.estado = "Buscando..."
            this.busqueda = false

            axios.get('gestion-organismos/organismos?por_pagina='+this.por_pagina)
            .then(response => {
                this.estado = '';
                this.organismos = response.data;
                this.resultado = "Se encontraron "+ response.data.total +" organismos para todos los resultados"
            })
            .catch(error => {
                this.estado = "Hubo un error, no se encontraron organismos"
            })
        },
        cambiarPagina(url){
            axios.get(url)
                .then(response =>{
                    this.organismos = response.data
                })
                .catch(error =>{
                    this.estado = "Hubo un error, no se encontraron organismos"
                })
        },
        search(organismo){
            this.estado = "Buscando..."
            this.busqueda = true

            axios.post('gestion-organismos/organismos/search', {search: organismo})
            .then(response => {
                if (response.data.data.length > 0){
                    this.estado = ''
                    this.organismos = response.data
                    this.resultado = "Se encontraron "+ response.data.total +" organismos para "+organismo
                }
                else{
                    this.estado = "No se encontraron resultados"
                    this.resultado = ""
                }
            })
        },
        eliminar(codigo){
            let organismoEliminar = this.organismosEncontrados.filter(org => org.codigo === codigo)

            this.$swal.fire({
                title: '¿Esta seguro que desea eliminar este organismo?',
                text: organismoEliminar[0].nombre_organismo,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('gestion-organismos/organismos/destroy', {codigo: codigo})
                    .then(response => {
                        this.getOrganismos()
                        this.success = response.data.data
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
