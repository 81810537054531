<template>
    <div>
        <div class="card datos-usuario">
            <div class="card-body">
                <h5 class="card-title">Datos de usuario</h5>
                <div v-if="noEsBeneficiario">
                    <div v-if="datosusuario.subsectores.length != 0">
                        <h5>{{ datosusuario.subsectores[0].sector.sede .organismo.nombre_organismo }}</h5>
                        <p class="text-muted">{{datosusuario.subsectores[0].sector.nombre_sector}}</p>
                    </div>
                    <div v-else>
                        <h5>{{datosusuario.legajos[0].sector.sede.organismo.nombre_organismo }}</h5>
                        <p class="text-muted">{{ datosusuario.legajos[0].sector.nombre_sector }}</p>
                    </div>
                </div>

                <div>
                    <p class="card-text" v-if="datosusuario.email_nuevo">Correo electrónico: {{ datosusuario.email_nuevo }}</p>
                    <p class="card-text" v-else-if="datosusuario.email">Correo electrónico: {{ datosusuario.email }}</p>
                </div>

                <div v-if="datosusuario.email_alternativo">
                    <p class="card-text">Correo electrónico institucional: {{ datosusuario.email_alternativo }}</p>
                </div>

                <p>Está registrado desde el {{ moment(datosusuario.usuario_desde).format("LLL") }} ( {{moment(datosusuario.usuario_desde, "YYYY-MM-DD h:mm:ss").fromNow() }} )</p>

                <div class="datos-usuario__acciones">
                    <button class="btn btn-outline-secondary btn-modificar" data-toggle="modal" data-target="#verificarPassword" v-if="datosusuario.email">
                        <span>Modificar correo electrónico</span>
                    </button>

                    <button class="btn btn-outline-secondary btn-modificar" data-toggle="modal" data-target="#modificarPassword">
                        <span>Modificar contraseña</span>
                    </button>
                </div>

                <div v-if="confirmacion" class="alert alert-success">
                    {{ confirmacion }}
                </div>
            </div>
        </div>

        <!-- Modal modificación de passwords -->
        <div class="modal fade" id="modificarPassword" tabindex="-1" role="dialog" aria-labelledby="ventanaModificarPassword" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Modificar contraseña
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert" v-if="errores">
                            {{ errores }}
                        </div>
                        <form @submit.prevent="">
                            <label for="password_original">Contraseña anterior</label>
                            <input class="form-control" type="password" id="password_original" name="password_original" v-model="password_original"/>
                            <label for="password_nueva">Nueva contraseña</label>
                            <input class="form-control" type="password" id="password_nueva" name="password_nueva" v-model="password_nueva"/>
                            <label for="password_nueva_repetir">Repetir nueva contraseña</label>
                            <input class="form-control" type="password" id="password_nueva_repetir" name="password_nueva_repetir" v-model="password_nueva_repetir"/>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="modificarPassword" @keyup.enter="modificarPassword">
                            Modificar contraseña
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal verificación de contraseña -->
        <div class="modal fade" id="verificarPassword" tabindex="-1" role="dialog" aria-labelledby="ventanaVerificarPassword" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Verificar Contraseña</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert" v-if="errores">
                            {{ errores }}
                        </div>
                        <p>
                            Por motivos de seguridad, antes de realizar alguna
                            modificación en su perfil, le pedimos que ingrese su
                            contraseña.
                        </p>

                        <form @submit.prevent="">
                            <label for="email_nuevo">Contraseña</label>
                            <input class="form-control" type="password" id="email_nuevo" name="verificar_password" v-model="verificar_password"/>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="verificarPassword" @keyup.enter="verificarPassword">
                            Continuar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal modificación de email -->
        <div class="modal fade" id="modificarEmail" tabindex="-1" role="dialog" aria-labelledby="ventanaModificarEmail" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Modificar correo electrónico</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert" v-if="errores">
                            {{ errores }}
                        </div>
                        <p>
                            Al modificar su correo electrónico, le enviaremos un
                            email a su nueva casilla para que lo verifique. Si
                            no verifica el nuevo correo electrónico, no podrá
                            acceder a su información.
                        </p>

                        <form @submit.prevent="">
                            <label for="email_nuevo">Nuevo correo electrónico</label>
                            <input class="form-control" type="email" name="email_nuevo" v-model="email_nuevo"/>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="modificarEmail('modificar')" @keyup.enter="modificarEmail('modificar')">
                            <span v-if="acciones == ''">Modificar correo electrónico</span>
                            <span v-else>{{ acciones }}<i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            confirmacion: null,
            errores: null,
            email_nuevo: null,
            password_original: null,
            password_nueva: null,
            password_nueva_repetir: null,
            verificar_password: null,
            acciones: ""
        };
    },
    props: ["datosusuario"],
    computed: {
      noEsBeneficiario() {
          return !this.datosusuario.roles.includes('BENEFICIARIO')
      }
    },
    methods: {
        modificarEmail: function(accion) {
            axios
                .post(
                    "modificar-email",
                    {
                        email_nuevo: this.email_nuevo
                    }
                )
                .then(response => {
                    this.acciones = "";
                    this.confirmacion = response.data.data;
                    this.datosusuario.email = this.email_nuevo;
                    this.$store.dispatch("modificarVerificado");
                    $("#modificarEmail").modal("hide");
                })
                .catch(error => {
                    this.acciones = "";
                    this.errores = error.response.data.error;
                });
        },
        modificarPassword: function() {
            axios
                .put(
                    "modificar-password",
                    {
                        password_original: this.password_original,
                        password_nueva: this.password_nueva,
                        password_nueva_repetir: this.password_nueva_repetir
                    }
                )
                .then(response => {
                    $("#modificarPassword").modal("hide");
                    this.confirmacion = response.data.data;
                    this.errores = null;
                    this.password_original = null;
                    this.password_nueva = null;
                    this.password_nueva_repetir = null;
                })
                .catch(error => {
                    this.errores = error.response.data.error;
                });
        },
        verificarPassword: function() {
            axios
                .post(
                    "verificar-password",
                    {
                        verificar_password: this.verificar_password
                    }
                )
                .then(response => {
                    this.errores = null;
                    $("#modificarEmail").modal("show");
                    $("#verificarPassword").modal("hide");
                    this.verificar_password = null;
                })
                .catch(error => {
                    this.errores = error.response.data.error;
                });
        }
    }
};
</script>
