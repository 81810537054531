import Vue from "vue";
import {
    getRecibosPorRango,
    getOpcionesDeBusquedaPermitidas,
    obtenerFechaPrimerRecibo
} from "../../../api/administradorreciboshaberes";
import { dateFormat } from "vue2-daterange-picker/src/components/dateformat";

const state =  {
   
        recibos: [],
        recibosBuscados:false,
        loading:false,
        loading_opciones_busqueda:true,
        beneficios:[],
        apoderados:[],
        persona:[],
        errors: [],
        opciones_busqueda:{},
        recibosDescargaMasiva:{},
        fecha_primer_recibo: null
        
    
};

const getters= {
}

const mutations = {
    setFechaPrimerRecibo(state, fecha){
        let formateada =new Date(fecha)
        console.log(formateada);
      state.fecha_primer_recibo =  formateada;
    },

    resetAll(state){
        state.recibos= [],
        state.loading=false,
        state.beneficios=[],
        state.apoderados=[],
        state.persona=[],
        state.errors= [],
        state.recibosDescargaMasiva={} 
    },
    setBeneficios(state, beneficios){
        //Devuelve Objeto Beneficios
        state.beneficios = beneficios;
    },
    setApoderados(state, apoderados){
        state.apoderados = apoderados;
    },
    setRecibos(state,recibos){
        state.recibos = recibos;
    },
    setPersona(state, persona){
        state.persona = persona;
    },
    emptyErrors(state){
        state.errors=[];
    },
    setError(state, error) {
        state.errors = error;
    },
    setOpcionesBusqueda(state, object_opciones){
        state.loading_opciones_busqueda = false
        state.opciones_busqueda = object_opciones
    },
    setLoading(state, active){
        state.loading = active
    },
    setRecibosBuscados(state, buscado){
        state.recibosBuscados = buscado;
    },
    agregarARecibosParaDescarga(state, datos_recibo){
        Vue.set(state.recibosDescargaMasiva, datos_recibo[0], datos_recibo[1])
    },
    eliminarDeRecibosParaDescargar(state, indice){
        Vue.delete(state.recibosDescargaMasiva, indice)
    }
}
const actions = {

    async getMinDate({commit}){
        const { data } = await obtenerFechaPrimerRecibo();
        commit("setFechaPrimerRecibo", data)
    },
    limpiarListaDescargas({commit}){
        commit("resetAll")
    },
    agregarAlistaDescargas({commit}, datos_recibo){
        commit("agregarARecibosParaDescarga", datos_recibo)
    }
    ,
    quitarListaDescargas({commit}, recibo_index){
        commit("eliminarDeRecibosParaDescargar", recibo_index)
    },
    async traerOpcionesDeBusqueda({commit}){
        const { data } = await getOpcionesDeBusquedaPermitidas();
        commit("setOpcionesBusqueda", data);

    },
    async traerRecibosPorRango({commit}, payload){
        commit("setLoading", true);
        commit("resetAll")
        try {
            commit("setError", []);
            const { data } = await getRecibosPorRango(payload)
            const {beneficios, apoderados, recibos, ...persona} = data;
            const recibosArray = []
            for (const [beneficioKey, arrayRecibos] of Object.entries(recibos)) {
                arrayRecibos.forEach(recibo => {
                    recibosArray.push(recibo);
                });
            }
            commit('setBeneficios', beneficios);
            commit('setApoderados', apoderados);
            commit('setRecibos', recibosArray);
            commit('setPersona', persona);
            commit("setLoading", false);
            commit("setRecibosBuscados", true)
        }
        catch (e) {
            commit('setBeneficios', []);
            commit('setApoderados', []);
            commit('setRecibos', []);
            commit('setPersona', []);    
            commit('setError',[ e.response.data.error])
            commit("setLoading", false);
            commit("setRecibosBuscados", false)
        }
    },

}

export default {
    namespaced: true,
    state ,
    actions,
    mutations,
    getters
}