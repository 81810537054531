<template>
    <div>
        <div
            class="alert alert-success"
            v-if="this.$route.query.transferencia == 'enviada'"
        >
            Transferencia enviada
        </div>

        <div class="card card_formulario_busqueda">
            <div class="row  busqueda-transferencias">
                <h3 class="busqueda-transferencias__titulo col-12">Transferencias Archivadas</h3>
                    <form
                        class="container"
                        @submit.prevent
                        @keyup.esc="eliminarBusqueda"
                    >
                        <div class="row  w-100 justify-content-center">
                            <div  class="col-12 align-self-center col-md-9" >
                                <input
                                type="text"
                                class="w-100"
                                id="busqueda"
                                name="busqueda"
                                v-model="busqueda"
                                placeholder="Buscar..."
                            />
                            </div>
                            <div class="col-12 col-md-3">
                                    <label class=" etiqueta_busqueda col-12">
                                    <strong>Buscar Por:</strong>
                                    </label>
                                    <select  v-model="tipo_busqueda_seleccionada" class=" align-self-center form-select form-select-color-bs-primary col-12 form-select-lg" aria-label="tipodebusqueda">
                                        <option 
                                        class=""
                                        v-for="(option, index) of Object.keys(tipos_de_busqueda)" 
                                        :value="option" 
                                        :selected="tipo_busqueda_seleccionada == (index+1) ? 'true' : 'false'"   
                                        >{{ tipos_de_busqueda[option] }}</option>
                                    </select>
                                
                            </div>
                        </div>
                        <div class="row justify-content-end mt-3">
                            <button
                                class="btn btn-primary transferencia__accion col-4 mr-3 "
                                @keyup.enter="buscarTransf()"
                                @click="buscarTransf()"
                            >
                            <i class="fas fa-search"></i> Buscar
                        </button>
                        </div>
                    </form>
            </div> 

        </div> 

        <div class="busqueda-transferencias__eliminar" v-if="busqueda_realizada">
            <h5>Resultados para la búsqueda: {{ busqueda }}</h5>
            <button
                class="btn btn-outline-danger transferencia__accion"
                v-if="busqueda"
                @click="eliminarBusqueda"
            >
                <i class="fas fa-trash"></i> Limpiar búsqueda
            </button>
        </div>

        <div class="alertas" v-if="transferencias == null">
            <div v-if="errores">
                <div class="alert alert-danger">
                    {{ errores }}
                </div>
            </div>
            <div v-else>
                <div v-if="buscando">
                    <div class="alert alert-info">
                        Buscando transferencias...
                    </div>
                </div>
                <div class="alert alert-warning" v-else>
                    No se encontraron transferencias...
                </div>
            </div>
        </div>

        <div v-else>
            <div class="busqueda-transferencias__texto-paginacion text-muted">
                Se encontraron {{ total }} transferencias archivadas por su
                sector. Se muestran {{ por_pagina }} por página (página
                {{ current_page }} de {{ last_page }})
            </div>

            <transition-group name="fade" tag="p" :duration="200">
                <div
                    v-for="transferencia in transferencias"
                    :key="transferencia.id"
                >
                    <div class="card transferencia">
                        <transferencia
                            :transferencia="transferencia"
                            :sectores="sectores"
                            :pagina="pagina"
                        ></transferencia>
                    </div>

                    <!-- <div class="card transferencia" v-if="rol=='USUARIO'">
						<transferencias-usuarios :transferencia='transferencia' @buscar="buscarTransf"></transferencias-usuarios>
					</div>
					<div class="card transferencia" v-else-if="rol=='EMPLEADO'">
						<transferencias-empleados :transferencia='transferencia' @buscar="buscarTransf" :sectores="sectores"></transferencias-empleados>
						<transferencias-empleados :transferencia='transferencia' :sectores="sectores"></transferencias-empleados>     
					</div> -->
                </div>
            </transition-group>
        </div>

        <div>
            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_primero)"
                v-if="link_primero && last_page != 1"
            >
                <i class="fas fa-backward"></i> Primero
            </button>
            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_anterior)"
                v-if="link_anterior && link_anterior != link_primero"
            >
                <i class="fas fa-caret-left"></i> Anterior
            </button>

            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_siguiente)"
                v-if="link_siguiente && link_siguiente != link_ultimo"
            >
                Siguiente <i class="fas fa-caret-right"></i>
            </button>
            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_ultimo)"
                v-if="link_ultimo && last_page != 1"
            >
                Último <i class="fas fa-forward"></i>
            </button>
        </div>
    </div>
</template>

<script>
// import TransferenciasUsuarios from '../common/TransferenciasUsuarios.vue'
// import TransferenciasEmpleados from '../common/TransferenciasEmpleados.vue'
import Transferencia from "../transferencia/Transferencia.vue";

export default {
    data() {
        return {
            pagina: "archivadas",
            tipos_de_busqueda:{
                1 : "ID",
                2 : "Asunto",
                3 : "Nombre y Apellido",
                4 : "DNI",
                5 : "Organismo (Id o Nombre)",
            },
            tipo_busqueda_seleccionada : 1,
            transferencias: null,
            buscando: false,
            rol: this.$store.state.user.rol,
            subsector: this.$store.state.user.subsectores[0].id,
            sector: this.$store.state.user.subsectores[0].sector.id,
            errores: null,
            mensaje: null,
            query: this.$route.query,
            busqueda: "",
            busqueda_realizada :false,
            terminos_busqueda: null,
            resultado: false,
            link_primero: "",
            link_ultimo: "",
            link_anterior: "",
            link_siguiente: "",
            current_page: "",
            total: "",
            last_page: "",
            sectores: null,
            por_pagina: 20
        };
    },
    components: {
        transferencia: Transferencia
    },
    created: function() {
        this.buscando = true;
        this.errores = null;
        this.mensaje = this.$route.params.transferencia;
        this.buscarTransf();
        
			axios.get('obtener-subsectores-caja',
                {
                    headers: {
                            Authorization: 'Bearer ' + this.$store.state.user.token_acceso
                        }
                    
                })
            .then((response) => {
                this.sectores = response.data.data
            })
            .catch((error) => {
            })
    },
    methods: {
        buscarTransf: function() {
            this.buscando = true;
            var route = `/transferencias-archivadas/${this.subsector}/${this.sector}?busqueda=${this.busqueda}`;
            if(this.busqueda !=""){
                route += `&tipo_busqueda=${this.tipo_busqueda_seleccionada}`
                this.busqueda_realizada = true;
            };
            axios
                .get(
                    route+`&por_pagina=${this.por_pagina}`
                )
                .then(response => {
                    this.buscando = false;
                    this.errores = null;
                    this.mensaje = null;
                    if (response.data.data.length >= 1) {
                        this.link_primero = response.data.first_page_url;
                        this.link_anterior = response.data.prev_page_url;
                        this.link_ultimo = response.data.last_page_url;
                        this.link_siguiente = response.data.next_page_url;

                        this.resultado = false;
                        this.current_page = response.data.current_page;
                        this.total = response.data.total;
                        this.last_page = response.data.last_page;
                        if (response.data.data.length >= 1) {
                            this.transferencias = response.data.data;
                        }
                    }
                })
                .catch(error => {
                    this.buscando = false;
                    this.mensaje = null;
                    this.errores = error.response.data.error;
                });
        },
        eliminarBusqueda: function() {
            this.busqueda="";
            this.buscarTransf();
            this.busqueda_realizada = false;
        },
        actualizarTransferencias: function(link) {
            this.buscando = true;
            this.errores = null;
            this.mensaje = this.$route.params.transferencia;
            axios
                .get(link)
                .then(response => {
                    this.link_primero = response.data.first_page_url;
                    this.link_anterior = response.data.prev_page_url;
                    this.link_ultimo = response.data.last_page_url;
                    this.link_siguiente = response.data.next_page_url;
                    this.current_page = response.data.current_page;
                    this.total = response.data.total;
                    this.last_page = response.data.last_page;
                    this.buscando = false;
                    this.errores = null;
                    this.mensaje = null;
                    this.resultado = false;
                    if (response.data.data.length >= 1) {
                        this.transferencias = response.data.data;
                    }
                })
                .catch(error => {
                    this.buscando = false;
                    this.mensaje = null;
                    this.errores = error.response.data.error;
                });
        }
    },
    watch: {
        busqueda: function() {
            if (!this.busqueda) {
                this.eliminarBusqueda();
                this.busqueda_realizada = false;
            }
        }
    }
};
</script>

<style scoped>
.opcion_busqueda:checked{
    background-color: rgb(93, 93, 191);
}
.card_formulario_busqueda{
    background-color:#b3d8e2;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
