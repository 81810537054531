<template>
    <div class="container">
        <div class="row">
            <div class="col btn btn-light btn_busqueda d-flex justify-content-center align-items-center"  
            :class="[tipodato==1?'btn_busqueda_selected':'']"
            @click="setearBusquedaCuit">
            <i class="fas fa-address-card fa-lg"></i>
            <span>Buscar por CUIT</span>
            </div>
            <div class="col btn btn-light btn_busqueda d-flex justify-content-center align-items-center" 
            :class="[tipodato==2?'btn_busqueda_selected':'']" 
            @click="setearBusquedaBeneficio">
                <i class="fas fa-money-check-alt fa-lg"></i>
                <span>Buscar por Beneficio</span>
            </div>
        </div>
        <br/>
        <div class="row">
            <h5 v-if="tipodato == 1">Ingrese el numero de CUIT:</h5> 
            <h5 v-else-if="tipodato == 2">Ingrese el numero de Beneficio:</h5> 
        </div>
        <div class="row no-gutters barra_buscador">
            <input class="w-100 col-11 col-sm-10" type ="text" v-model="textoBusqueda"></input>
            <button class="btn btn-primary col-1 col-sm-2" @click="buscarRecibos()"><i class="fas fa-search"></i></button>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
    export default {
        name : "BuscadorRecibosPenitenciaria",        
        
        data(){
            return{
                tipodato: 1,
                textoBusqueda:""
              
            }
        },
   
        methods: {
        ...mapActions('gestionpenitenciaria', [
            "traerRecibosPorCUIT",
            "traerRecibosPorBeneficio",
        ]),
    
            setearBusquedaBeneficio(){
                this.tipodato = 2;
            },
            setearBusquedaCuit(){
                this.tipodato = 1;
            },
            buscarRecibos(){
                switch (this.tipodato) {
                    case 1:
                        this.traerRecibosPorCUIT(this.textoBusqueda)         
                        break;
                    case 2:
                        this.traerRecibosPorBeneficio(this.textoBusqueda)
                    break;
                    default:
                        break;
            }
            }
        },        
    }
</script>

<style lang="scss" scoped>
.btn_busqueda{
    height:200px;
    vertical-align: middle;
    
}
.btn_busqueda_selected{
    background-color: #0c659c;
    color:white;
    font-weight: bold;

}
.barra_buscador{
    height:70px;
}
</style>