<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div class="contenedor-titulo-seccion">
                    <div class="titulo-seccion">
                        <h2 class="titulo-seccion__texto">
                            Recuperar contraseña
                        </h2>
                    </div>
                </div>

                <div class="row" v-if="recuperacionEnviada">
                    <div class="alert alert-primary" role="alert">
                        <span>Le enviamos un correo electrónico a {{ usuario }} para que comience con el proceso de recuperación de su contraseña.</span>
                    </div>
                </div>

                <div class="contenedor-formulario" v-else>
                    <form @submit.prevent class="container p-4 formulario formulario-ingresar">
                        <div class="row">
                            <div class="col-12 alert alert-danger" role="alert" v-if="errores">
                                {{ errores }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 form-group ingresar__usuario">
                                <label for="input__usuario" v-if="usuario">
                                    ¿Es este su correo electrónico?
                                </label>
                                <label for="input__usuario" v-else>
                                    Ingrese su correo electrónico
                                </label>
                                <input
                                    type="text"
                                    name="usuario"
                                    class="form-control"
                                    id="input__usuario"
                                    autofocus
                                    :class="{campo__error: errorUsuario}"
                                    v-model="usuario"
                                    placeholder="Correo electrónico"
                                />
                            </div>
                        </div>

                        <div class="row" v-if="!recuperacionEnviada">
                            <div class="col form-group" v-if="environment == 'produ'">
                                <invisible-recaptcha
                                    sitekey="6LeXGaoUAAAAACu03CB--50FBeLuAhPmtrBRy-PF"
                                    :callback="recuperarPassword"
                                    v-if="usuario"
                                    type="submit"
                                    class="btn btn-primary boton-formulario-ingresar"
                                >
                                    <span v-if="acciones == ''">Sí, recuperar contraseña</span>
                                    <span v-else>{{ acciones }}<i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                                </invisible-recaptcha>

                                <invisible-recaptcha
                                    sitekey="6LeXGaoUAAAAACu03CB--50FBeLuAhPmtrBRy-PF"
                                    :callback="recuperarPassword"
                                    v-else
                                    type="submit"
                                    class="btn btn-primary boton-formulario-ingresar"
                                >
                                    <span v-if="acciones == ''">Recuperar contraseña</span>
                                    <span v-else>{{ acciones }} <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                                </invisible-recaptcha>
                            </div>
                            <div class="col form-group" v-else>
                                <button
                                    class="btn btn-primary boton-formulario-ingresar"
                                    @click.prevent="recuperarPassword"
                                    v-if="usuario"
                                >
                                    <span v-if="acciones == ''">Sí, recuperar contraseña</span>
                                    <span v-else>{{ acciones }}<i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                                </button>
                                <button
                                    class="btn btn-primary boton-formulario-ingresar"
                                    @click.prevent="recuperarPassword"
                                    v-else
                                >
                                    <span v-if="acciones == ''">Recuperar contraseña</span>
                                    <span v-else>{{ acciones }}<i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-2 mt-3 text-center formulario-ingresar__otras-acciones">
                <router-link
                    class="otras-acciones__link text-decoration-none"
                    :to="{ name: 'Ingresar' }">
                    Recordé mi contraseña
                </router-link>
            </div>
            <div class="col-12 p-2 text-center formulario-ingresar__otras-acciones">
                <router-link
                    class="otras-acciones__link text-decoration-none"
                    :to="{ name: 'Registrarse' }">
                    Todavía no estoy registrado
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import InvisibleRecaptcha from "vue-invisible-recaptcha";

export default {
    components: {
        "invisible-recaptcha": InvisibleRecaptcha
    },
    data() {
        return {
            environment: process.env.MIX_ENVIRONMENT,
            usuario: this.$route.query.usuario,
            errores: "",
            acciones: "",
            errorUsuario: false,
            recuperacionEnviada: false,
            email_verificacion: ""
        };
    },
    methods: {
        async recuperarPassword() {
            if (this.usuario == null || this.usuario == "") {
                this.errores = "Tiene que ingresar su número de CUIL o su correo electrónico";
                return;
            }

            var dato_usuario = this.usuario.replace(/ /g, "");

            if (!dato_usuario.includes("@")) {
                dato_usuario = dato_usuario.replace(/_/g, "");
                dato_usuario = dato_usuario.replace(/-/g, "");
            }

            this.acciones = "Enviando correo electrónico..."

            try {
                await axios.get("/sanctum/csrf-cookie");
                await axios.post("/forgot-password", {email: dato_usuario})

                this.acciones = "Correo electrónico enviado"
                this.recuperacionEnviada = true
                this.errores = null
            }
            catch (e) {
                this.acciones = "";
                this.recuperacionEnviada = false
                if (e.response.status === 422){
                    let erroresValidacion = Object.values(e.response.data.errors)
                    this.errores = erroresValidacion[0][0]
                }
                else {
                    this.errores = "Hubo un inconveniente con la aplicación. Por favor intente en unos minutos.";
                }
            }
        }
    }
};
</script>
