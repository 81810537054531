<template>
    <div class="col">
        <div class="card certificado-haberes shadow-sm rounded-lg border-0 mb-3 p-4">
            <div class="d-flex flex-column flex-md-row justify-content-start justify-content-md-between">
                <div class="certificado-haberes__badges mb-3 order-1 order-md-2">
                    <BadgeTramite
                        :tramite="certificado.tramite.codigo.codigo"
                    />
                </div>
                <div class="certificado-haberes__titulo order-2 order-md-1">
                    <p>{{ certificado.tramite.codigo.codigo }}-{{ certificado.id }}</p>
                    <h5>Complementaria {{ certificado.proceso }}</h5>
                    <p>{{ certificado.tramite.persona.tipo_documento }} {{ certificado.tramite.persona.numero_documento }} | Beneficio: {{ certificado.beneficio | formatearBeneficio }}</p>
                </div>
            </div>

            <div class="certificado-haberes__acciones">
                <div class="accion-recibo d-flex flex-column flex-md-row justify-content-end">
                    <button type="button" class="btn btn-primary mr-0 ml-md-2 btn-sm" @click="descargarCertificado()">
                        <i class="fas fa-arrow-alt-circle-down fa-2x"></i>
                        <span class="px-2">Descargar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {descargarCertificado} from "../../../../../api/misdocumentos-complementarias";
import BadgeTramite from "../../../../elementos/badges/BadgeTramite.vue";
export default {
    name: "CertificadoComplementaria",
    components: {BadgeTramite},
    props: [
        'certificado'
    ],
    data() {
        return {
            error: null
        }
    },
    methods: {
        async descargarCertificado() {
           try {
               const { id, beneficio } = this.certificado
               const { data } = await descargarCertificado(id)
               const fileURL = window.URL.createObjectURL(new Blob([data]));
               const fileLink = document.createElement('a');

               fileLink.href = fileURL;
               fileLink.setAttribute('download', `CERTCOMPLEMENTARIA-${id}-${beneficio}.pdf`);
               document.body.appendChild(fileLink);
               fileLink.click();

               this.$vToastify.success('Certificado descargado correctamente', 'Certificado de Complementaria');
           }
           catch (e) {
               this.error = 'Hubo un error al intentar descargar el certificado'
           }
        }
    }
}
</script>

<style scoped>

</style>
