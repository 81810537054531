<template>
    <div class="card w-100 card__novedades">
        <div class="row no-gutters m-3">
            <div class="col-12">
                <div class="card-body">
                    <h3 class="card-title text-center">&Uacute;ltimas Novedades</h3>
                    <div class="col-6 mx-auto">
                        <hr />
                    </div>
                </div>
            </div>
            <div v-if="ultimas_novedades!=null" class="row h-100 no-gutters justify-content-around">
                <div v-for="novedad in ultimas_novedades" class="col-lg-3 col-md-6 col-xs-1 mb-3">
                    <router-link style="text-decoration: none; color:black" :to="{ name: 'DetalleNoticiasBeneficiarios', params: { id_noticia: ''+novedad.id+'' } }" >
                        <div  class=" m-2 card card_noticias_thumbnail" >
                            <img  class=" card_noticias_thumbnail_imagen card-img-top" v-bind:src="'data:'+novedad.extension_imagen+ ';base64,'+ novedad.image_blob_publicacion"> 
                            <div class="card-body">
                                <h3 class="card_noticias_thumbnail_titulo">{{ novedad.titulo_publicacion }}</h3>
                                <h6 class="card-text" v-if="novedad.descripcion_novedad!=''">{{novedad.descripcion_publicacion}}</h6>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
           <!-- <div class="card_noticias_thumbnail_btn_container w-100" >
                    <button class="btn btn-primary card_noticias_thumbnail_btn">Ver Todas</button>
            </div> -->

            </div>
            </div>            
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            ultimas_novedades: [],
            novedad_seleccionada: null,
        }
    },
    methods: {
        ...mapActions('novedades', [
            'getNovedades'
        ]),
        ...mapGetters('novedades', [
            'getUltimasNovedades'
        ])
    },
    async created() {
        await this.getNovedades();
        this.ultimas_novedades = await this.getUltimasNovedades();
      
    },
}
</script>
<style scoped>
.card_noticias_thumbnail {
    padding: 0;
    border-radius:7%;
    margin-top:5px;
   min-width: 240px;
}
.card_noticias_thumbnail_imagen{
    border-radius: 15px 15px 0px 0px;
    height: 10rem;
    object-fit:cover;    
}
.card_noticias_thumbnail_titulo{
    font-weight: 600;
    font-size: 1.15rem;
}
.card_noticias_thumbnail_btn{
    float: right;
}
.card_noticias_thumbnail{
    box-shadow: 0px 1px 3px 0px rgba(59,143,197,1),
    0px 1px 2px 0px rgba(0,0,0,0.24);
    transition: box-shadow 0.3s ease-in-out;
    height:100%;
}
.card_noticias_thumbnail:hover{
    box-shadow: 0px -25px 20px -20px rgba(0,114,187,1),
    25px 0px 20px -20px rgba(0,0,0,0.45),
    0px 25px 20px -20px rgba(0,0,0,0.45),
    -25px 0px 20px -20px rgba(0,0,0,0.45);
}
.card_noticias_thumbnail:hover > img{
    opacity: 0.7;
}

.card_noticias_thumbnail_btn_container{
    margin-top:2rem;
}
</style>