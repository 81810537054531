<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Está en: <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Mis Notificaciones</li>
            </ol>
        </nav>

        <div class="w-100">
            <h2 class="titulo-aplicacion">Mis Notificaciones <i class="fas fa-comment"></i></h2>
        </div>

        <div class="mt-4">
            <Notificacion class="my-2" v-for="notificacion in notificaciones" :key="notificacion.id"
                :notificacion="notificacion"
            />

            <div class="alert alert-info" role="alert" v-if="notificaciones.length === 0">
                No tienes notificaciones.
            </div>
        </div>
    </div>
</template>

<script>
import Notificacion from "./Notificacion.vue";
import {mapActions, mapGetters, mapState} from "vuex";
export default {
    name: "MisNotificaciones",
    components: {
        Notificacion
    },
    destroyed() {
        if (this.notificacionesNuevas.length > 0) this.leerNotificaciones()
    },
    computed: {
        ...mapState({
            notificaciones: state => {
                return state.notificaciones.notificaciones
            }
        }),
        ...mapGetters({
            notificacionesNuevas: 'notificaciones/getNotificacionesSinLeer'
        })
    },
    methods: {
        ...mapActions('notificaciones', [
            'leerNotificaciones',
        ]),
    }
}
</script>

<style scoped>

</style>
