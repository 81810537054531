<template>
    		<div class="card recibo-haberes">
  			<div class="recibo-haberes__titulo">
	    		<h5 class="card-title recibo-haberes__concepto text-dark" >{{ recibo['nombre_recibo'] }}</h5>
	    		<p class="text-muted recibo-haberes__info" ><small>Beneficio {{recibo['beneficio_id'] | formatearBeneficio }}</small></p>
	    	</div>

	    	<div class="recibo-haberes__acciones">
	    		<div class="accion-recibo">
		    		<button class="btn btn-outline-secondary accion-recibo__descargar" @click="descargarRecibo()" v-if="descargandoRecibo === 0">
			   			<i class="fas fa-cloud-download-alt"></i>
			   			<span>Descargar</span>
			   		</button>
			   		<button class="btn btn-outline-info accion-recibo__descargar"  v-else-if="descargandoRecibo === 1">
			   			<i class="fas fa-ellipsis-h"></i>
			   			<span>Descargando</span>
			   		</button>
			   		<button class="btn btn-outline-danger accion-recibo__descargar" v-else-if="descargandoRecibo === 3">
			   			<i class="fas fa-times-circle"></i>
			   			<span>Error</span>
			   		</button>
			   		<button class="btn btn-outline-success accion-recibo__descargar" @click="descargarRecibo()" v-else>
			   			<i class="fas fa-check-circle"></i>
			   			<span>Descargado</span>
			   		</button>
			   	</div>

    			<div class="accion-recibo">
	    			<button class="btn btn-outline-secondary accion-recibo__enviar" v-if="enviandoReciboMail === 0" data-toggle="modal" :data-target="['#compartirPorMail' + index + recibo['beneficio_id']]">
						<i class="fas fa-envelope"></i>
						<span>Enviar por mail</span>
					</button>
					<button class="btn btn-outline-info  accion-recibo__enviar" v-else-if="enviandoReciboMail === 1">
						<i class="fas fa-ellipsis-h"></i>
						<span>Enviando</span>
					</button>
					<button class="btn btn-outline-danger  accion-recibo__enviar" v-else-if="enviandoReciboMail === 3" data-toggle="modal" :data-target="['#compartirPorMail' + index + recibo['beneficio_id']]">
						<i class="fas fa-times-circle"></i>
						<span>Error</span>
					</button>
					<button class="btn btn-outline-success  accion-recibo__enviar" v-else data-toggle="modal" :data-target="['#compartirPorMail' + index + recibo['beneficio_id']]">
						<i class="fas fa-check-circle"></i>
						<span>Enviado</span>
					</button>
				</div>
    		</div>
            <div class="modal fade" :id="['compartirPorMail' + index + recibo['beneficio_id']]" tabindex="-1" role="dialog" aria-labelledby="ventanaCompartirPorMail" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content text-dark">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle"><i class="fas fa-share-square"></i> Compartir archivo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert" v-if="errores">
                            {{ errores }}
                        </div>
                        <p>Archivo: {{ recibo['nombre_recibo'] }}</p>
                        <p>Beneficio: {{recibo['beneficio_id'] | formatearBeneficio }}</p>
                        <form class="form-group" @submit.prevent>
                            <label for="email">Correo electrónico de destino</label>
                            <input class="form-control" id="email" type="email" name="email" v-model="email" placeholder="Ingresa el email de destino">
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="enviarReciboMail()" @keyup.enter="enviarReciboMail()">
                            Enviar archivo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    	</div>
</template>

<script >
export default{
    props:["recibo", "index", "persona"],
    data(){
        return{
            descargandoRecibo: 0,
            enviandoReciboMail: 0,
            email:"",
            errores:""
        }
    },
    methods:{
        async descargarRecibo() {
				this.descargandoRecibo = 1
                this.observacion_recibo = 'descargado desde gestión de Recibos Penitenciaria'
                
                const recibo = {
                    ruta_recibo: this.recibo['ruta_recibo'],
                    observacion_recibo: this.observacion_recibo
                }

                try {
                    const { data, headers } = await axios.post('gestion-penitenciaria/descargar-recibo', recibo, { responseType: 'blob'})
                    this.descargandoRecibo = 2
                    const filename = headers['content-disposition'].split('=')[1].replace(/^\"+|\"+$/g, '')
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }
                catch (e) {
                    this.descargandoRecibo = 3
                }
			},
            async enviarReciboMail() {
				$('#compartirPorMail' + this.index + this.recibo['beneficio_id']).modal('hide');

				this.enviandoReciboMail = 1;
                this.observacion_recibo = 'Enviado desde Gestión de Penitenciaria'
        
                const recibo = {
                    ruta_recibo: this.recibo['ruta_recibo'],
                    email: this.email,
                    observacion_recibo: this.observacion_recibo,
                    persona: this.persona
                }

                try {
                    const { data } = await axios.post(`gestion-penitenciaria/enviar-recibo-mail`, recibo)
                    this.enviandoReciboMail = 2
                    this.enviadoEmail = data.data
                }
                catch (e) {
                    this.enviandoReciboMail = 3
                }
			}
    }
}
</script>

<style scoped>

</style>