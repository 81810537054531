<template>
    <div>
        <div class="busqueda-transferencias">
            <h2 class="titulo-aplicacion">Gestión de Beneficiarios <i class="far fa-user"></i></h2>

            <form class="busqueda-transferencias__formulario" @submit.prevent @submit="buscarInformacion">
                <input class="form-control"
                    type="text"
                    id="cuil_input"
                    name="cuil_input"
                    v-model="cuil_input"
                    placeholder="Ingrese CUIL o número de documento"
                    v-mask="['#########', '##-########-#']"
                />
                <button class="btn btn-primary" type="submit">
                    <i class="fas fa-search"></i> Buscar
                </button>
            </form>
            <small class="text-muted busqueda-transferencias__texto-ayuda">En caso de detectar algún dato o información errónea, por favor escríbanos a<a :href="`mailto:soporteweb@crjppf.gov.ar`">soporteweb@crjppf.gov.ar</a></small>
        </div>

        <div class="alert alert-primary" v-if="!existeBeneficiario && !error && !buscando">
            Puede realizar la búsqueda de información por número de documento o número de CUIL
        </div>

        <div class="alert alert-warning" v-if="advertencia">
            {{ advertencia }}
        </div>

        <div v-if="error">
            <div class="alert alert-danger">{{ error }}</div>
        </div>
        <Loader v-else-if="buscando" />
        <div v-else-if="existeBeneficiario">
            <Persona />

            <Usuario />

            <h4 class="mt-4">Beneficios</h4>
            <div class="card transferencia"
                 v-if="beneficios.length >= 1"
                 v-for="(beneficio, index) in beneficios"
                 :key="beneficio.beneficio_id"
            >
                <Beneficio
                    :tipo="'Titular'"
                    :beneficio="beneficio"
                    :index="index"
                    :recibos="recibos[beneficio.beneficio_id]"
                    :certificadosGanacias="certificadosGanancias[beneficio.beneficio_id]"
                />
            </div>

            <div class="card transferencia"
                 v-if="apoderados.length >= 1"
                 v-for="(apoderado, index) in apoderados"
                 :key="apoderado.beneficio_id"
            >
                <Beneficio
                    :tipo="'Apoderado'"
                    :beneficio="apoderado"
                    :index="index"
                    :recibos="recibos[apoderado.beneficio_id]"
                    :certificadosGanacias="certificadosGanancias[apoderado.beneficio_id]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Persona from "./elementos/Persona";
import Usuario from "./elementos/Usuario";
import Beneficio from "./elementos/Beneficio";
import Loader from "../../../elementos/loaders/Loader";
import {mapActions, mapState} from "vuex";

export default {
    components: {
        Loader,
        Beneficio,
        Persona,
        Usuario
    },
    data() {
        return {
            cuil_input: '',
            advertencia: ''
        };
    },
    created() {
        this.resetBeneficiario()
    },
    methods: {
        ...mapActions('gestionbeneficiarios', [
            'buscarBeneficiario',
            'resetBeneficiario'
        ]),
        buscarInformacion: function (e) {
            this.advertencia = ''

            if(!this.cuil_input) {
                return this.advertencia = 'Tenes que ingresar un numero de documento o CUIL'
            }
            
            let numero_cuil = this.cuil_input.replace(/-/g, "")
            numero_cuil = numero_cuil.replace(/\ /g, "")

            this.buscarBeneficiario(numero_cuil).then(r=>console.log(state))
         
        },
    },
    computed: {
        ...mapState({
            error: state => {
                return state.gestionbeneficiarios.error
            },
            buscando: state => {
                return state.gestionbeneficiarios.buscando
            },
            beneficios: state => {
                return state.gestionbeneficiarios.beneficios
            },
            apoderados: state => {
                return state.gestionbeneficiarios.apoderados
            },
            existeBeneficiario: state => {
                return state.gestionbeneficiarios.existeBeneficiario
            },
            recibos: state => {
                return state.gestionbeneficiarios.recibos
            },
            certificadosGanancias: state => {
                return state.gestionbeneficiarios.certificadosGanancias
            },
        }),
    }
};
</script>
