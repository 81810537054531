const state = {
	loggedIn: false,
	email: '',
	email_alternativo: '',
	usuario_desde: null,
	verificado: 'N',
	persona_id: null,
    legajos: [],
    roles: [],
    aplicaciones: [],
    subsectores: [],
};

const getters = {
    authUser: state => {
        return state.loggedIn;
    },
    guest: () => {
        const storageItem = window.localStorage.getItem("crjppf");
        if (!storageItem) return false;
        if (storageItem === "isGuest") return true;
        if (storageItem === "isNotGuest") return false;
    },
    getTiposRecibo: state => {
        let result = []
        if(state.roles.includes('BENEFICIARIO') ){
            result.push('PB')
        }
        if(state.roles.includes('EMPLEADO')){
            result.push('PI')
        }
        return result
    },
    getLegajos: state => {
        return state.legajos
    }
};

const mutations = {
	guardarUser(state, data){
        const {persona, roles, funciones, subsectores, legajos, ...user} = data
        state.loggedIn = true
        state.email = user.email
        state.email_alternativo = user.email_alternativo
        state.verificado = user.verificado
        state.usuario_desde = user.created_at
        state.persona_id = user.persona_id
		state.legajos = legajos.map(l => l.legajo)
        state.roles = roles.map(r => r.rol)
        state.aplicaciones = funciones.map(a => {
            return {
                aplicacion: a.aplicacion.aplicacion,
                funcion: a.funcion
            }
        })
        state.subsectores = subsectores
	},
	eliminarUser(state){
        state.loggedIn = false
        state.email = ''
        state.email_alternativo = ''
        state.usuario_desde = null
        state.verificado = 'N'
        state.persona_id = null
        state.legajos = []
        state.roles = []
        state.aplicaciones = []
        state.subsectores = []
	},
	MODIFICARVERIFICADO(state){
		state.verificado = 'N'
	},
    setCookieGuest(state, value){
        window.localStorage.setItem("crjppf", value);
    },
};

const actions = {
	guardarDatosUsuario({commit, dispatch}){
		return new Promise((resolve, reject) => {
		    axios.get('/obtener-info-usuario')
                .then(response => {
                    commit('guardarUser', response.data)
                    commit('guardarPersona', response.data.persona)
                    commit('guardarBeneficios', response.data.persona.beneficios)
                    commit('guardarApoderados', response.data.persona.apoderados)
                    dispatch('notificaciones/obtenerNotificaciones')

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
		})
	},
	salir({commit, dispatch}){
		return new Promise((resolve, reject) => {
			axios.post('/logout')
                .then( () => {
                    commit('eliminarUser')
                    commit('eliminarPersona')
                    commit('eliminarBeneficios')
                    commit('eliminarApoderados')
                    dispatch('notificaciones/eliminarNotificaciones')

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
		})
	},
	reenviarVerificacion(){
		return new Promise((resolve, reject) => {
			axios.post('/usuarios/reenviar-verificacion')
                .then(function(response) {
                    resolve()
                })
                .catch(function(error) {
                    reject(error.response)
                })
		})
	},
    modificarVerificado({commit}){
        commit('MODIFICARVERIFICADO')
    },
    setGuest({commit, state }, value) {
        commit('setCookieGuest', value)
    },
};

export default {
	state,
	actions,
	mutations,
	getters
}
