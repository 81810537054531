<template>
    <div>
        <p>Estado: <span class="text-success">Trámite en proceso.</span></p>
        <p class="beneficio_sube_card_body_text text-danger">El beneficio SUBE comenzará a regir a partir del quinto día habil del mes siguiente:</p>
        <div class="card beneficio_sube_card_alta_pendiente">
            <p class="beneficio_sube_card_body_text" >La Tarifa Social Federal será aplicada si la tarjeta SUBE se encuentra registrada con el DNI del beneficiario.</p>
            <p class="beneficio_sube_card_body_text">Una vez que recibas el correo de confirmación de estado ACTIVO del beneficio o figure en estado activo en "MI CAJA", debes apoyar tu tarjeta SUBE en una <a href="https://tarjetasube.sube.gob.ar/SubeWeb/WebForms/admin/views/mapa-sube.aspx?id=4" target="_blank">Terminal Automática</a> o consultar saldo desde la <a href="https://play.google.com/store/apps/details?id=com.sube.app&hl=es_AR&gl=US&pli=1" target="_blank">App SUBE</a> (celular Andriod 6 o superior y tecnologia NFC) para que el beneficio se haga efectivo en tu tarjeta.</p>
        </div>
    </div>
</template>
<script>
export default{}
</script>
