<template>
    <div>
        <p>Estado: <span class="text-danger">Baja en Trámite.</span></p>
        <div class="card">
            <p class=" text-danger beneficio_sube_card_body_text">Una vez confirmada la baja del beneficio, apoyá tu SUBE en una <a href="https://tarjetasube.sube.gob.ar/SubeWeb/WebForms/admin/views/mapa-sube.aspx?id=4" target="_blank">Terminal Automática</a> o consultá el saldo desde la <a href="https://play.google.com/store/apps/details?id=com.sube.app&hl=es_AR&gl=US&pli=1" target="_blank">App SUBE</a>, si tenés un celular Android 6 o superior y tecnología NFC.</p>
        </div>
    </div>

</template>
<script>
export default{}

</script>