<template>
    <div class="container-fluid usuarios_empleados_buscar">
        <div class="row">
            <div class="col-12">
                <form class="formulario " v-on:submit.prevent>
                    <h4 class="busqueda-transferencias__titulo mb-3">
                        Registrar empleado
                    </h4>
                    <div class="buscador-usuario">
                        <input
                            type="text"
                            class="form-control"
                            name="input_cuil"
                            id="input_cuil"
                            placeholder="Ingrese el Dni o Cuil"
                            v-model="input_cuil"
                            v-mask="['#########', '##-########-#']"
                        />
                        <button
                            class="btn btn-primary"
                            @click="buscarEmpleado(input_cuil)"
                        >
                            <i class="fas fa-search px-5"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="buscando">
            <div class="alert alert-info" role="alert">
                Buscando usuario...
            </div>
        </div>
        <div v-else-if="noEncontrado">
            <div class="alert alert-warning" role="alert">
                {{ noEncontrado }}
            </div>
        </div>
        <div v-else>
            <div v-if="encontrado">
                <usuario :usuario="empleado"></usuario>

                <div class="container-fluid mt-2">
                    <form class="formulario form-row" v-on:submit.prevent>
                        <div class="form-group col-12 col-md-5">
                            <label for="email">Email</label>
                            <input
                                v-bind:class="[
                                    'form-control',
                                    { 'input--error': errorEmail }
                                ]"
                                type="email"
                                name="email"
                                id="email"
                                v-model="persona.legajos[0].email_institucional"
                            />
                        </div>
                        <div class="form-group col-12 col-md-5">
                            <label for="password">Contraseña</label>
                            <input
                                v-bind:class="[
                                    'form-control',
                                    { 'input--error': errorPassword }
                                ]"
                                :type="passwordInputType"
                                name="password"
                                id="password"
                                v-model="password"
                            />

                            <button
                                type="button"
                                class="btn btn-mostrar-password"
                                v-on:click="modificarVisibilidadPassword"
                            >
                                <i
                                    class="far fa-eye-slash"
                                    v-if="mostrarPassword"
                                ></i>
                                <i class="far fa-eye" v-else></i>
                            </button>
                        </div>
                        <button
                            class="btn btn-primary boton_buscar boton_registrar"
                            v-on:prevent.default
                            v-if="acciones"
                        >
                            {{ acciones }}
                            <i
                                class="fas fa-circle-notch faa-spin animated faa-fast"
                            ></i>
                        </button>
                        <button
                            v-else
                            class="btn btn-primary boton_buscar boton_registrar"
                            @click="registrar"
                        >
                            Registrar
                        </button>

                        <div
                            class="alert alert-warning col-12 mt-2"
                            role="alert"
                            v-if="errores"
                        >
                            <p v-html="errores"></p>
                        </div>
                    </form>
                </div>
            </div>
            <div v-else>
                <div class="alert alert-info" role="alert">
                    <p>Ingrese una busqueda para poder registrar un empleado</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import usuario from "./elementos/Usuario.vue";
export default {
    components: {
        usuario: usuario
    },
    data() {
        return {
            input_cuil: "",
            persona: {},
            buscando: false,
            noEncontrado: "",
            encontrado: false,

            password: "",
            errores: "",
            errorEmail: false,
            errorPassword: false,

            mostrarPassword: false,
            passwordInputType: "password",
            acciones: ""
        };
    },
    computed: {
        empleado() {
            return this.persona;
        }
    },
    methods: {
        buscarEmpleado(cuil) {
            this.buscando = true;
            this.noEncontrado = "";
            this.errores = "";

            var dato_usuario = cuil.replace(/-/g, "");
            dato_usuario = dato_usuario.replace(/_/g, "");
            dato_usuario = dato_usuario.replace(/ /g, "");

            axios
                .get("obtener-empleados-sin-usuario/" + dato_usuario)
                .then(response => {
                    this.encontrado = true;
                    this.persona = response.data;
                    this.buscando = false;
                })
                .catch(error => {
                    this.encontrado = false;
                    this.noEncontrado = error.response.data.data;
                    this.buscando = false;
                });
        },
        registrar() {
            this.errores = "";
            this.errorPassword = false;
            this.errorEmail = false;
            this.acciones = "Registrando...";

            if (this.persona.legajos[0].email_institucional == "") {
                this.errores += "<p> El campo email no puede estar vacio </p>";
                this.errorEmail = true;
                this.acciones = "";
            }

            if (this.password == "") {
                this.errores +=
                    "<p> El campo contraseña no puede estar vacio </p>";
                this.errorPassword = true;
                this.acciones = "";
            }

            if (this.errores) return this.errores;

            const formData = {
                email: this.persona.legajos[0].email_institucional,
                password: this.password,
                confirmar_password: this.password,
                persona_id: this.persona.persona_id,
                tipo_documento: this.persona.tipo_documento,
                numero_documento: this.persona.numero_documento,
                legajo: this.persona.legajos[0].legajo,
                sector_id: this.persona.legajos[0].sector_id
            };


            axios
                .post("/registrarse/empleados", formData)
                .then(response => {
                    var emailEmpleado = "";
                    if (response.data.email) {
                        emailEmpleado = response.data.email;
                    } else {
                        emailEmpleado = response.data.email_alternativo;
                    }

                    this.$router.push({
                        name: "MisAplicacionesUsuariosEmpleadosBuscar",
                        params: {
                            mensaje:
                                "El empleado " +
                                emailEmpleado +
                                " se registro correctamente"
                        }
                    });
                })
                .catch(error => {
                    this.errores = error.response.data;
                    this.acciones = "";
                });
        },
        modificarVisibilidadPassword() {
            if (this.mostrarPassword) {
                this.mostrarPassword = false;
                this.passwordInputType = "password";
            } else {
                this.mostrarPassword = true;
                this.passwordInputType = "text";
            }
        }
    }
};
</script>
