<template>
    <div>
        <h2>Nuevo organismo</h2>

        <div class="formulario mt-4">
            <div class="alert alert-danger" role="alert" v-if="errores.length > 0">
                <p>Corrige los siguientes errores: </p>
                <ul>
                    <li v-for="error in errores">
                        {{error}}
                    </li>
                </ul>
            </div>

            <form v-on:submit.prevent>
                <div class="form-group">
                    <label for="codigo">Codigo</label>
                    <input type="text" name="codigo" id="codigo" placeholder="Ingrese el codigo" class="form-control" v-model="organismo.codigo">
                </div>
                <div class="form-group">
                    <label for="organismo">Organismo</label>
                    <input type="text" name="organismo" id="organismo" placeholder="Ingrese el organismo" class="form-control" v-model="organismo.organismo">
                </div>
                <div class="form-group">
                    <label for="nombre_organismo">Nombre del organismo</label>
                    <input type="text" name="nombre_organismo" id="nombre_organismo" placeholder="Ingrese el nombre del organismo" class="form-control" v-model="organismo.nombre_organismo">
                </div>
                <button class="btn btn-primary" @click="registrar">
                    Registrar
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "MisAplicacionesOpereacionesOrganismosNuevo",
    data(){
        return{
            organismo: {
                codigo: '',
                organismo: '',
                nombre_organismo: ''
            },
            errores: [],
            estado: ''
        }
    },
    methods: {
        registrar(){
            this.errores = []

            axios.post('gestion-organismos/organismos', this.organismo)
            .then(response => {
                this.$router.push({name: 'MisAplicacionesOperacionesOrganismosBuscar', params: {msg: response.data.data}})
            })
            .catch(error => {
                if (error.response.status == 422){
                    let erroresValidacion = Object.values(error.response.data.errors)
                    this.errores = erroresValidacion.map(error => {
                        return error[0]
                    })
                }
                else {
                    this.errores.push("Hubo un problema no se puede registrar en este momento")
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
