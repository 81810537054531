var render = function render(){var _vm=this,_c=_vm._self._c;return (
        _vm.historia.estado.estado == 'comentado' &&
            _vm.$store.state.user.funciones.td == 1
    )?_c('div'):_c('div',{staticClass:"historia-transferencia text-muted"},[_c('small',[_vm._v("\n        "+_vm._s(_vm.moment(_vm.historia.created_at).format("LLL"))+" (\n        "+_vm._s(_vm.moment(_vm.historia.created_at, "YYYY-MM-DD h:mm:ss").fromNow())+"\n        )"),_c('br'),_vm._v(" "),_c('strong',[_vm._v("Estado: "+_vm._s(_vm.historia.estado.estado)+"\n            "),(_vm.historia.estado.estado == 'transferido')?_c('span',[_vm._v("a "+_vm._s(_vm.historia.subsector.sector.nombre_sector)+" -\n                "+_vm._s(_vm.historia.subsector.subsector))]):_vm._e()]),_c('br'),_vm._v("\n        Usuario: "+_vm._s(_vm.historia.user.persona.nombre)+"\n        "+_vm._s(_vm.historia.user.persona.apellido)+" -\n        "),(_vm.historia.user.email_alternativo)?_c('a',{attrs:{"href":`mailto:${_vm.historia.user.email_alternativo}`}},[_vm._v(_vm._s(_vm.historia.user.email_alternativo))]):(_vm.historia.user.email)?_c('a',{attrs:{"href":`mailto:${_vm.historia.user.email}`}},[_vm._v(_vm._s(_vm.historia.user.email))]):_vm._e(),_vm._v(" "),_c('br'),_vm._v("\n\n        "+_vm._s(_vm.historia.user.subsectores[0].sector.nombre_sector)+" -\n        "),(
                _vm.historia.user.subsectores[0].sector.sede.organismo_id == 0
            )?_c('span',[_vm._v("\n            Caja de Retiros, Jubilaciones y Pensiones de la Policía\n            Federal")]):_c('span',[_vm._v(_vm._s(_vm.historia.user.subsectores[0].sector.sede.organismo
                .nombre_organismo))]),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.historia.observaciones)?_c('span',[_vm._v("Observaciones: "+_vm._s(_vm.historia.observaciones)),_c('br')]):_vm._e()]),_vm._v(" "),(_vm.historia.archivo)?_c('button',{staticClass:"btn btn-outline-secondary transferencia__accion-descargar",on:{"click":function($event){return _vm.descargarArchivo(_vm.historia.archivo)}}},[(_vm.descargando)?_c('span',[_vm._v("Descargando...")]):_c('span',[_vm._v("Descargar archivo")])]):_vm._e(),_vm._v(" "),(_vm.descargando)?_c('span',[_c('small',{staticClass:"text-muted"},[_vm._v("La descarga puede demorar en base al tamaño del archivo.")])]):(_vm.descargado)?_c('span',[_c('small',{staticClass:"text-success"},[_vm._v("Archivo descargado correctamente.")])]):(_vm.errorDescarga)?_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v("Error al descargar el archivo. Intente nuevamente.")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }