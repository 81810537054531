<template class="container">
	<div class="d-flex justify-content-center my-4">
		<div class="d-flex align-items-center">
			<button class="btn btn-outline-primary mx-2" @click="cambiarPagina(pagination.first_page_url)" :disabled="pagination.current_page <=1"><i class="fas fa-angle-double-left"></i> Primera página</button>
			<button  class="btn btn-outline-primary mx-2" @click="cambiarPagina(pagination.prev_page_url)" :disabled="pagination.prev_page_url <= pagination.current_page"><i class="fas fa-angle-left"></i> Página anterior</button>
			<span>Página {{ pagination.current_page }} de {{ pagination.last_page }}</span>
			<button  class="btn btn-outline-primary mx-2" @click="cambiarPagina(pagination.next_page_url)" :disabled="pagination.next_page_url <= pagination.last_page">Próxima página <i class="fas fa-angle-right"></i></button>
			<button class="btn btn-outline-primary mx-2"  @click="cambiarPagina(pagination.last_page_url)" :disabled="pagination.current_page >= pagination.last_page">Ultima página <i class="fas fa-angle-double-right"></i></button>
		</div>
	</div>
</template>

<script>

	export default {
		data() {
			return {
			}
		},
		props: [
			'pagination'
		],
		methods: {
			cambiarPagina(nuevaPagina){
				this.$emit('cambiar-pagina', nuevaPagina)
			}
		}
	};


</script>
