<template>
	<div>
		<div class="w-100">
			<h3>Búsqueda de certificados de Haberes</h3>

			<div class="busqueda-transferencias">
				<form class="busqueda-transferencias__formulario" @submit.prevent @keyup.esc="obtenerCertificados()">
					<input class="form-control input-buscar-beneficio" type="text" id="busqueda" name="busqueda" v-model="beneficioABuscar" v-mask="'##-######/#'" placeholder="00-000000/0" onclick="this.select();" />
					<button class="btn btn-primary transferencia__accion" :disabled="buscandoCertificado" @keyup.enter="buscarCertificados(beneficioABuscar)" @click="buscarCertificados(beneficioABuscar)"><i class="fas fa-search"></i> Buscar</button>
				</form>
				<div class="busqueda-transferencias__eliminar" v-if="busqueda">
					<h5> Resultados para la búsqueda {{beneficioBuscado}}</h5>
					<button class="btn btn-danger btn-sm" v-if="busqueda"  @click="obtenerCertificados()">
						Limpiar búsqueda
					</button>
				</div>
			</div>

            <div class="alert alert-warning mt-4" v-if="alertaAdvertencia">{{alertaAdvertencia}}</div>

			<div class="alert alert-info mt-4" v-if="buscandoCertificado">Buscando certificados de haberes <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></div>
			<sin-certificados class="mt-4" v-else-if="certificadosEncontrados.length === 0"></sin-certificados>
			<div class="mt-4" v-else>
				<certificado-haber-empleado v-for="certificado in certificadosEncontrados"
                                            :key="certificado.id"
                                            :certificado="certificado"
                                            :beneficio="certificado.beneficio_id"
                ></certificado-haber-empleado>

                <pagination :pagination="certificados"
                            v-on:cambiar-pagina="cambiarPagina($event)"
                ></pagination>
			</div>
		</div>


	</div>
</template>

<script>
	import CertificadoHaberEmpleado from './elementos/CertificadoHaberEmpleado.vue'
	import SinCertificados from './elementos/SinCertificados.vue'
	import Pagination from '../../../../elementos/pagination/Pagination.vue'
    import axios from "axios";

	export default {
		components: {
			'certificado-haber-empleado': CertificadoHaberEmpleado,
			'sin-certificados': SinCertificados,
			'pagination': Pagination
		},
		data() {
			return {
				certificados: {
				    data: []
                },
                error: false,
                alertaErrores: '',
                alertaAdvertencia: '',
				beneficioABuscar: '',
				beneficioBuscado: '',
				busqueda: false,
                buscandoCertificado: false,
			}
		},
		created() {
			this.obtenerCertificados()
		},
		computed: {
			certificadosEncontrados: function(){
				return this.certificados.data
			}
		},
		methods: {
			obtenerCertificados: function(){
                this.beneficioABuscar = ''
				this.busqueda = false
                this.error = false
				this.alertaErrores = ''
				this.certificados = []
                this.buscandoCertificado = true
                this.beneficioBuscado = null
                this.alertaAdvertencia = ''

				axios.get('tramites-a-distancia/certificados-haberes?por_pagina=20')
                    .then((response) => {
                        this.buscandoCertificado = false
                        this.certificados = response.data
                    })
                    .catch(() => {
                        this.buscandoCertificado = false
                        this.error = true
                        this.alertaErrores = 'Hubo un inconveniente en la aplicación. Volvé a intentarlo en unos minutos'
                    })
			},
			buscarCertificados: function(beneficio){
			    this.alertaAdvertencia = ''

                if (beneficio === ''){
                    this.alertaAdvertencia = 'Tenés que buscar un beneficio.'
                    return
                }

                if(beneficio === this.beneficioBuscado){
                    this.alertaAdvertencia = 'Ya estás consultado este beneficio'
                    return
                }

				this.alertaErrores = ''
				this.certificados = []
                this.buscandoCertificado = true

                this.beneficioABuscar = beneficio.replace(/ /g, '')
                this.beneficioABuscar = this.beneficioABuscar.replace(/-/g, '')
                this.beneficioABuscar = this.beneficioABuscar.replace(/\//g, '')
                this.beneficioABuscar = this.beneficioABuscar.padEnd(9, '0')

                this.beneficioBuscado = beneficio

				axios.get('tramites-a-distancia/certificados-haberes?beneficio_id=' + this.beneficioABuscar)
                    .then((response) => {
                        this.busqueda = true
                        this.buscandoCertificado = false
                        this.certificados = response.data
                        this.beneficioBuscado = beneficioABuscar
                    })
                    .catch(() => {
                        this.buscandoCertificado = false
                        this.error = true
                        this.alertaErrores = 'Hubo un inconveniente en la aplicación. Volvé a intentarlo en unos minutos'
                    })
			},
            cambiarPagina(url){
                this.buscandoCertificado = true

                axios.get(url)
                    .then(response => {
                        this.certificados = response.data
                        this.buscandoCertificado = false
                    })
                    .catch(() => {
                        this.buscandoCertificado = false
                        this.alertaErrores = "Hubo un error, no se encontraron certificados"
                    })
            }
		},
	};

</script>
