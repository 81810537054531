const getBeneficiario = (numero_cuil) => {

    return axios.post("gestion-beneficiarios/obtener-informacion", {numero_cuil: numero_cuil})
}

const verificarPassword = (password) => {
    return axios.post("verificar-password", {verificar_password: password})
}

const modificarEmail = (datos) => {
    return axios.post("gestion-beneficiarios/modificar-email", datos)
}

const reenviarVerificacion = (email) => {
    return axios.post("gestion-beneficiarios/reenviar-verificacion", {email: email})
}

const desbloquearUsuario = (datos) => {
    return axios.post("gestion-beneficiarios/desbloquear-alta", datos)
}

export {
    getBeneficiario,
    verificarPassword,
    modificarEmail,
    reenviarVerificacion,
    desbloquearUsuario
}
