<template>
	<div class="d-flex justify-content-center align-items-center">
		<div class="row d-flex justify-content-center">
			<p class="w-100 text-center"><i class="far fa-folder-open display-1 text-muted"></i></p>
			<p class="w-100 text-center text-muted">No existen recibos de haberes para el legajo <strong>{{ legajo }}</strong></p>
			
		</div>
	</div>
</template>

<script>
	
	export default {
		props: [
			'legajo'
		]
	};

</script>