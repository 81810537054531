<template>
	<div>

		<span class="badge transferencia__badge--estado badge-prefectura" v-if="organismo==70">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- prefectura naval -->
        <span class="badge transferencia__badge--estado badge-gendarmeria" v-else-if="organismo==97">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- gendarmeria nacional -->
         <span class="badge transferencia__badge--estado badge-azul" v-else-if="organismo==0">Caja de Ret Jub Pens Pol Fed</span> <!-- caja de retiros -->
        <span class="badge transferencia__badge--estado badge-amugenal" v-else-if="organismo==84">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- amugenal -->
        <span class="badge transferencia__badge--estado badge-csubofpfa" v-else-if="organismo==16">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- circulo suboficiales - pfa -->
        <span class="badge transferencia__badge--estado badge-csubofpna" v-else-if="organismo==74">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- cicrulo suboficiales - pna -->
        <span class="badge transferencia__badge--estado badge-smsv" v-else-if="organismo==14">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- smsv -->
        <span class="badge transferencia__badge--estado badge-iosfa" v-else-if="organismo==69">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- iosfa -->
        <span class="badge transferencia__badge--estado badge-cmf" v-else-if="organismo==93">{{nombreOrganismo}} ( {{organismo}} )</span> <!-- cmf -->

        <span class="badge transferencia__badge--estado badge-azul" v-else>{{nombreOrganismo}} ( {{organismo}} )</span>
    	
	</div>
</template>

<script>
	
	export default {
		props: [
			'organismo',
			'nombreOrganismo'
		]
	}

</script>