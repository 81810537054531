<template>
    <div>
        <div class="card transferencia">
            <div class="transferencia__contenedor">
                <strong>Datos de Usuario</strong>
                <p>
                    Correo electrónico: <a :href="`mailto:${user.email}`">{{user.email }}</a> <br/>
                    Registrado desde: {{ moment(user.created_at).format("LLL") }} ({{ moment(user.created_at, "YYYY-MM-DD h:mm:ss").fromNow() }}) <br/>
                    Usuario verificado: <span v-if="user.verificado === 'S'">Sí</span><span class="text-danger" v-else>No</span> <br/>
                    Usuario bloqueado: <span class="text-danger" v-if="user.activo === 'N'">Sí</span><span v-else>No</span>
                </p>

                <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-outline-secondary"
                            id="boton-modificar-email"
                            @click="acciones"
                            v-if="botonModificarEmail"
                    >
                        Modificar correo electrónico
                    </button>

                    <button class="btn btn-outline-secondary"
                            id="boton-reenviar-verificacion"
                            @click="acciones"
                            v-if="botonReenviarVerificacion"
                    >
                        Reenviar email de verificación
                    </button>

                    <button class="btn btn-outline-secondary"
                            id="boton-desbloquear-usuario"
                            @click="acciones"
                            v-if="botonDesbloquearUsuario"
                    >
                        Desbloquear usuario
                    </button>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modificarEmail" tabindex="-1" role="dialog" aria-labelledby="ventanaModificarEmail" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Modificar correo electrónico</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                         <div class="alert alert-danger" role="alert" v-if="errorAccion">
                             {{ errorAccion }}
                         </div>
                        <p>
                            Está por modificar el correo electrónico con el que
                            se creó esta cuenta ({{ user.email }}).
                            Recuérdele al usuario que su perfil en Mi Caja
                            <strong>quedará pendiente de verificación</strong> y
                            que se le enviará un correo electrónico a la nueva
                            casilla para realizar dicha verificación.
                        </p>
                        <form @submit.prevent>
                            <label for="email_nuevo">Nuevo correo electrónico</label>
                            <input id="email_nuevo" class="form-control" type="email" name="email_nuevo" v-model="email_nuevo" />
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="accionModificarEmail" @keyup.enter="accionModificarEmail">
                            <span v-if="!accion">Modificar correo electrónico</span>
                            <span v-else>{{ accion }}<i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="desbloquearUsuario" tabindex="-1" role="dialog" aria-labelledby="ventananuevaVerificacionCuenta" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Desbloquear usuario</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert" v-if="errorAccion">
                            {{ errorAccion }}
                        </div>
                        <p>
                            Está por enviar una nueva verificación de cuenta a
                            {{ persona.nombre }} {{ persona.apellido }}.
                            Recuérdele al usuario que se le enviará un correo
                            electrónico a su casilla de correo para realizar la
                            nueva verificación.
                        </p>
                        <form @submit.prevent>
                            <div class="form-group">
                                <label for="emaildesbloqueo">Email</label>
                                <input type="email" id="emaildesbloqueo" class="form-control" v-model="emailDesbloqueo" />
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="accionDesbloquearUsuario" @keyup.enter="accionDesbloquearUsuario">
                            <span v-if="!accion">Enviar</span>
                            <span v-else>{{ accion }} <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="nuevaVerificacion" tabindex="-1" role="dialog" aria-labelledby="ventananuevaVerificacionCuenta" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reenviar verificacion de cuenta</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert" v-if="errorAccion">
                            {{ errorAccion }}
                        </div>
                        <p>
                            Está por enviar una nueva verificación de cuenta a
                            {{ persona.nombre }} {{ persona.apellido }}.
                            Recuérdele al usuario que se le enviará un correo
                            electrónico a su casilla de correo para realizar la
                            nueva verificación.
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="accionReenviarVerificacion" @keyup.enter="accionReenviarVerificacion">
                            <span v-if="!accion">Enviar</span>
                            <span v-else>{{ accion }} <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="verificarPassword" tabindex="-1" role="dialog" aria-labelledby="ventanaVerificarPassword" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Verificar Contraseña</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger" role="alert" v-if="errorAccion">
                            {{ errorAccion }}
                        </div>
                        <p>
                            Por motivos de seguridad, antes de realizar alguna
                            modificación en un perfil de usuario, tenes que
                            ingresar tu contraseña.
                        </p>
                        <form @submit.prevent>
                            <label for="password">Contraseña</label>
                            <input id="password" class="form-control" type="password" name="verificar_password" v-model="verificar_password" />
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" @click="accionVerificarPassword" @keyup.enter="accionVerificarPassword">
                            <span v-if="!accion">Verificar</span>
                            <span v-else>{{ accion }} <i class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "Usuario",
    data() {
        return {
            email_nuevo: '',
            verificar_password: ''
        }
    },
    computed: {
        ...mapState({
            esGestor: state => {
                return state.user.aplicaciones.some(a => a.aplicacion === 'GESTIONBENEFICIARIOS' && a.funcion === 'GESTIONBENEFICIARIOS-GESTOR')
            },
            persona: state => {
                return state.gestionbeneficiarios.persona
            },
            user: state => {
                return state.gestionbeneficiarios.usuario
            },
            accion: state => {
                return state.gestionbeneficiarios.accion
            },
            errorAccion: state => {
                return state.gestionbeneficiarios.errorAccion
            },
            empleadoPasswordVerificada: state => {
                return state.gestionbeneficiarios.verificacionPassword
            }
        }),
        botonModificarEmail() {
            return this.user.activo === 'S' && this.esGestor
        },
        botonReenviarVerificacion() {
            return this.user.verificado === 'N' && this.esGestor
        },
        botonDesbloquearUsuario() {
            return this.user.activo === 'N' && this.esGestor
        },
        emailDesbloqueo: {
            get() {
                return this.user.email ? this.user.email : ''
            },
            set(newValue) {
                this.user.email = newValue
            }
        }
    },
    methods: {
        ...mapActions('gestionbeneficiarios', [
            'modificarEmail',
            'verificarPassword',
            'reenviarVerificacion',
            'desbloquearUsuario',
            'resetErrorAccion'
        ]),
        acciones(e) {
            if (!this.empleadoPasswordVerificada) return $("#verificarPassword").modal("show");

            this.resetErrorAccion()

            switch (e.target.id) {
                case 'boton-modificar-email':
                    $("#modificarEmail").modal("show")
                    break
                case 'boton-reenviar-verificacion':
                    $("#nuevaVerificacion").modal("show")
                    break
                case 'boton-desbloquear-usuario':
                    $("#desbloquearUsuario").modal("show")
                    break
            }
        },
        accionVerificarPassword() {
            this.verificarPassword(this.verificar_password).then(() => this.errorAccion ?? $("#verificarPassword").modal("hide"))
        },
        accionModificarEmail() {
            this.modificarEmail(this.email_nuevo).then(() => this.errorAccion ?? $("#modificarEmail").modal("hide"))
        },
        accionReenviarVerificacion() {
            this.reenviarVerificacion().then(() => this.errorAccion ?? $("#nuevaVerificacion").modal("hide"))
        },
        accionDesbloquearUsuario() {
            this.desbloquearUsuario(this.emailDesbloqueo).then(() => this.errorAccion ?? $("#desbloquearUsuario").modal("hide"))
        }
    },
}
</script>
