const getRecibosGendarmeriaPorCUIT = async (cuit) => {
    return axios.get("gestion-gendarmeria/recibos/buscar-cuit/" + cuit)
                

}

const getRecibosGendarmeriaPorBeneficio = (beneficio) => {
    return axios.get("gestion-gendarmeria/recibos/buscar-beneficio/" + beneficio)
}

export {
    getRecibosGendarmeriaPorCUIT,
    getRecibosGendarmeriaPorBeneficio
}
