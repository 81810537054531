<template>
    <div class="w-100">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Estás en: <router-link :to="{name: 'Inicio'}">Mi Caja</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesMenu'}">Mis Aplicaciones</router-link></li>
                <li class="breadcrumb-item"><router-link :to="{name: 'MisAplicacionesOperacionesMenu'}">Operaciones</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Gestion de organismos</li>
            </ol>
        </nav>
        <h2 class="titulo-aplicacion">Gestion de organismos</h2>

        <div class="contenedor__menu-transferencias">
            <router-link tag="button" class="btn btn__submenu" :to="{name: 'MisAplicacionesOperacionesOrganismosBuscar'}">
                <i class="fas fa-hotel fa-2x icono-micaja"></i><br>
                Organismos
            </router-link>
            <router-link tag="button" class="btn btn__submenu" :to="{name: 'MisAplicacionesOperacionesOrganismosSedeBuscar'}">
                <i class="fas fa-hotel fa-2x icono-micaja"></i><br>
                Sedes
            </router-link>
            <router-link tag="button" class="btn btn__submenu" :to="{name: 'MisAplicacionesOperacionesOrganismosSectorBuscar'}">
                <i class="fas fa-hotel fa-2x icono-micaja"></i><br>
                Sectores
            </router-link>
            <router-link tag="button" class="btn btn__submenu" :to="{name: 'MisAplicacionesOperacionesOrganismosSubsectorBuscar'}">
                <i class="fas fa-hotel fa-2x icono-micaja"></i><br>
                Subsectores
            </router-link>
        </div>

        <router-view class="mt-4"></router-view>

    </div>
</template>

<script>
export default {
    name: "MisAplicacionesOperacionesOrganismosContenedor"
}
</script>

<style scoped>

</style>
