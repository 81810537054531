import { render, staticRenderFns } from "./NovedadesBeneficiariosMiCaja.vue?vue&type=template&id=40523d39&scoped=true&"
import script from "./NovedadesBeneficiariosMiCaja.vue?vue&type=script&lang=js&"
export * from "./NovedadesBeneficiariosMiCaja.vue?vue&type=script&lang=js&"
import style0 from "./NovedadesBeneficiariosMiCaja.vue?vue&type=style&index=0&id=40523d39&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40523d39",
  null
  
)

export default component.exports