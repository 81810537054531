<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    Está en: <router-link :to="{ name: 'Inicio' }">Mi Caja</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{ name: 'MisDocumentosMenu' }">Mis Documentos</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Mis Certificados de Ganancias
                </li>
            </ol>
        </nav>

        <div class="w-100">
            <h2 class="titulo-aplicacion">
                Mis Certificados de Ganancias <i class="far fa-file-alt"></i>
            </h2>

            <div class="menu-beneficios">
                <button v-if="beneficios"
                        type="button"
                        class="btn btn-primary boton-beneficio mr-3"
                        v-for="beneficio in beneficios"
                        @click="key = beneficio.beneficio_id">

                    Beneficio {{ beneficio.beneficio_id | formatearBeneficio }}
                </button>

                <button
                    v-if="apoderados"
                    type="button"
                    class="btn btn-primary boton-beneficio mr-3"
                    v-for="apoderado in apoderados"
                    @click="key = apoderado.beneficio_id">

                    Apoderado {{ apoderado.beneficio_id | formatearBeneficio }}
                </button>
            </div>

            <Loader v-if="buscando" />
            <div class="alert alert-warning mt-4" v-else-if="errorCertificados">
                {{ errorCertificados }}
            </div>
            <sin-certificados class="mt-4" v-else-if="certificados[key].length === 0"
                :beneficio="key"
            ></sin-certificados>
            <certificado v-else v-for="(certificado, index) in certificados[key]"
                :key="certificado['archivo']"
                :certificado="certificado"
                :beneficio="key"
                :index="index"
            ></certificado>
        </div>
    </div>
</template>

<script>
import SinCertificados from "./elementos/SinCertificados.vue";
import Certificado from "./elementos/Certificado.vue";
import Loader from "../../../elementos/loaders/Loader";

export default {
    components: {
        Loader,
        SinCertificados,
        Certificado
    },
    data() {
        return {
            certificados: [],
            beneficios: this.$store.state.beneficios.beneficios,
            apoderados: this.$store.state.beneficios.apoderados,
            errorCertificados: null,
            key: '',
            buscando: true
        };
    },
    created() {
        if (this.beneficios.length > 0){
            this.key = this.beneficios[0].beneficio_id
        }
        else if (this.apoderados.length > 0){
            this.key = this.apoderados[0].beneficio_id
        }

        axios.get("beneficiarios/mis-documentos/certificados-ganancias")
            .then(response => {
                this.certificados = response.data;
                this.buscando = false
            })
            .catch(error => {
                this.buscando = false
                this.errorCertificados = "Hubo un inconveniente con el ingreso a la aplicación. Por favor intente en unos minutos.";
            });
    },
};
</script>
