<template>
    <div>
        <h2>Buscar sector</h2>
    </div>
</template>

<script>
export default {
name: "MisAplicacionesOperacionesOrganismosSectorBuscar"
}
</script>

<style scoped>

</style>
