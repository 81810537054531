<template>
    <div>
        <div
            class="alert alert-success"
            v-if="this.$route.query.transferencia == 'enviada'"
        >
            Transferencia enviada
        </div>

        <div class="card card_formulario_busqueda">
            <div class="row  busqueda-transferencias">
                <h3 class="busqueda-transferencias__titulo col-12">Transferencias Recibidas</h3>
                    <form
                        class="container"
                        @submit.prevent
                        @keyup.esc="eliminarBusqueda"
                    >
                        <div class="row  w-100 justify-content-center">
                            <div  class="col-12 align-self-center col-md-9" >
                                <input
                                type="text"
                                class="w-100"
                                id="busqueda"
                                name="busqueda"
                                v-model="busqueda"
                                placeholder="Buscar..."
                            />
                            </div>
                            <div class="col-12 col-md-3">
                                    <label class=" etiqueta_busqueda col-12">
                                    <strong>Buscar Por:</strong>
                                    </label>
                                    <select  v-model="tipo_busqueda_seleccionada" class="opcion_busqueda align-self-center form-select col-12 form-select-lg selector-busqueda" aria-label="tipodebusqueda">
                                        <option 
                                        v-for="(option, index) of Object.keys(tipos_de_busqueda)" 
                                        :value="option" 
                                        :selected="tipo_busqueda_seleccionada == (index+1) ? 'true' : 'false'"   
                                        >{{ tipos_de_busqueda[option] }}</option>
                                    </select>
                                
                            </div>
                        </div>
                        <div class="row justify-content-end mt-3">
                            <button
                                class="btn btn-primary transferencia__accion col-4 mr-3 "
                                @keyup.enter="buscarTransf()"
                                @click="buscarTransf()"
                            >
                            <i class="fas fa-search"></i> Buscar
                        </button>
                        </div>
                    </form>
            </div> 

        </div>

        <div class="busqueda-transferencias__eliminar" v-if="busqueda_realizada">
            <h5>Resultados para la búsqueda: {{ busqueda }}</h5>
            <button
                class="btn btn-danger btn-sm"
                v-if="busqueda"
                @click="eliminarBusqueda"
            >
                Limpiar búsqueda
            </button>
        </div>

        <div class="alertas" v-if="transferencias == null">
            <div v-if="errores">
                <div class="alert alert-danger">
                    {{ errores }}
                </div>
            </div>
            <div v-else>
                <div v-if="buscando">
                    <div class="alert alert-info">
                        Buscando transferencias...
                    </div>
                </div>
                <div class="alert alert-warning" v-else>
                    No se encontraron transferencias...
                </div>
            </div>
        </div>
        <div v-else>
            <div class="busqueda-transferencias__texto-paginacion text-muted">
                Se encontraron {{ total }} transferencias recibidas por tu
                sector. Se muestran {{ por_pagina }} por página (página
                {{ current_page }} de {{ last_page }})
            </div>
            <transition-group name="fade" tag="div" :duration="200">
                <div
                    v-for="transferencia in transferencias"
                    :key="transferencia.id"
                >
                    <div class="card transferencia">
                        <transferencia
                            :transferencia="transferencia"
                            :sectores="sectores"
                            :pagina="pagina"
                        ></transferencia>
                    </div>

                    <!--<div class="card transferencia" v-if="rol=='USUARIO'">
						<transferencias-usuarios :transferencia='transferencia' @buscar="buscarTransf"></transferencias-usuarios>
					</div>
					<div class="card transferencia" v-else-if="rol=='EMPLEADO'">
						
						<transferencias-empleados :transferencia='transferencia' :sectores="sectores"></transferencias-empleados>     
					</div>-->
                </div>
            </transition-group>
        </div>

        <div>
            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_primero)"
                v-if="link_primero && last_page != 1"
            >
                <i class="fas fa-backward"></i> Primero
            </button>
            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_anterior)"
                v-if="link_anterior && link_anterior != link_primero"
            >
                <i class="fas fa-caret-left"></i> Anterior
            </button>

            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_siguiente)"
                v-if="link_siguiente && link_siguiente != link_ultimo"
            >
                Siguiente <i class="fas fa-caret-right"></i>
            </button>
            <button
                class="btn btn-outline-secondary"
                @click="actualizarTransferencias(link_ultimo)"
                v-if="link_ultimo && last_page != 1"
            >
                Último <i class="fas fa-forward"></i>
            </button>
        </div>
    </div>
</template>

<style scoped>
.busqueda-transferencias__eliminar {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    justify-content: flex-start;
    align-items: center;
}

.busqueda-transferencias__eliminar h5 {
    flex: 0 0 100%;
    margin-right: 1rem;
    margin-bottom: 0 !important;
}
.card_formulario_busqueda{
    background-color:#b3d8e2;
}

@media screen and (min-width: 576px) {
    .busqueda-transferencias__eliminar h5 {
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 768px) {
    .busqueda-transferencias__eliminar h5 {
        display: inline-block;
        flex: 0 0 auto;
    }
    .transferencia__accion {
        flex: 0 0 24%;
        font-size: 1.2rem;
        height: calc(1.5em + 2px + 0.75rem);
    }

    .busqueda-transferencias__eliminar {
        justify-content: flex-start;
    }
}

.transferencia__accion {
    flex: 0 0 100%;
    max-width: 300px;
    margin: 0.5rem 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

<script>
import Transferencia from "../transferencia/Transferencia.vue";

export default {
    data() {
        return {
            // van
            tipos_de_busqueda:{
                1 : "ID",
                2 : "Asunto",
                3 : "Nombre y Apellido",
                4 : "DNI",
                5 : "Organismo (Id o Nombre)",
            },
            pagina: "recibidas",
            busqueda: "",
            busqueda_realizada :false,
            buscando: false,
            tipo_busqueda_seleccionada : 1,
            subsector: this.$store.state.user.subsectores[0].id,
            sector: this.$store.state.user.subsectores[0].sector.id,

            //no van
            transferencias: null,

            rol: this.$store.state.user.rol,
            errores: null,
            status_error: null,
            mensaje: null,
            query: this.$route.query,

            terminos_busqueda: null,
            resultado: false,
            link_primero: "",
            link_ultimo: "",
            link_anterior: "",
            link_siguiente: "",
            current_page: "",
            total: "",
            last_page: "",
            sectores: null,
            por_pagina: 20
        };
    },
    components: {
        transferencia: Transferencia
    },
    created() {
        this.buscando = true;
        this.errores = null;
        this.mensaje = this.$route.params.transferencia;
        this.buscarTransf();
            axios
                .get("subsectores-caja/" + this.subsector + "/" + this.sector)
                .then(response => {
                    this.sectores = response.data.data;
                })
                .catch(error => {
                    this.status_error = error.response.status;
                    this.errores = error.response.data.error;
                });
    },
    methods: {
        buscarTransf: function() {
            this.buscando = true;
            var route = `/transferencias-recibidas/${this.subsector}/${this.sector}?busqueda=${this.busqueda}`;
            if(this.busqueda !=""){
                route += `&tipo_busqueda=${this.tipo_busqueda_seleccionada}`
                this.busqueda_realizada = true;
            };
            axios
                .get(
                    route+`&por_pagina=${this.por_pagina}`
                )
                .then(response => {
                    this.buscando = false;
                    this.errores = null;
                    this.mensaje = null;
                    if (response.data.data.length >= 1) {
                        this.link_primero = response.data.first_page_url;
                        this.link_anterior = response.data.prev_page_url;
                        this.link_ultimo = response.data.last_page_url;
                        this.link_siguiente = response.data.next_page_url;

                        this.resultado = false;
                        this.current_page = response.data.current_page;
                        this.total = response.data.total;
                        this.last_page = response.data.last_page;
                        if (response.data.data.length >= 1) {
                            this.transferencias = response.data.data;
                        }
                    }
                })
                .catch(error => {
                    this.buscando = false;
                    this.mensaje = null;
                    this.errores = error.response.data.error;
                });
        },
        eliminarBusqueda: function() {
            this.busqueda="";
            this.buscarTransf();
            this.busqueda_realizada = false;
        },
        actualizarTransferencias: function(link) {
            this.buscando = true;
            this.errores = null;
            this.mensaje = this.$route.params.transferencia;
            axios
                .get(link)
                .then(response => {
                    this.buscando = false;
                    this.errores = null;
                    this.mensaje = null;
                    if (response.data.data.length >= 1) {
                        this.link_primero = response.data.first_page_url;
                        this.link_anterior = response.data.prev_page_url;
                        this.link_ultimo = response.data.last_page_url;
                        this.link_siguiente = response.data.next_page_url;

                        this.resultado = false;
                        this.current_page = response.data.current_page;
                        this.total = response.data.total;
                        this.last_page = response.data.last_page;
                        if (response.data.data.length >= 1) {
                            this.transferencias = response.data.data;
                        }
                    }
                })
                .catch(error => {
                    this.buscando = false;
                    this.mensaje = null;
                    this.errores = error.response.data.error;
                });
        }
    },
    watch: {
        busqueda: function() {
            if (!this.busqueda) {
                this.eliinarBusqueda();              
                this.busqueda_realizada = false;
            }
        }
    }
};
</script>
