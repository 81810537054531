<template>
  <div class="modal fade show" id="exampleModalCenteredScrollable" tabindex="-1" aria-labelledby="exampleModalCenteredScrollableTitle" style="display: block; padding-right: 17px;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
            <h3 >Detalle del Procesos {{ proceso_modal }}</h3>
            <slot >
            </slot>
        
        </div>

        <div class="modal-body">
                   
           
            <a class="toggleButton toggleEnviadas bg-success text-white" data-toggle="collapse" href="#altasEnviadas"  @click="mostrarEnviadas = !mostrarEnviadas" aria-expanded="false" aria-controls="altasEnviadas">
                <p>Altas Enviadas</p>
                <i v-show="!mostrarEnviadas" class="fas fa-sharp fa-solid fa-angle-right"></i>
                <i v-show="mostrarEnviadas" class="fas fa-sharp fa-solid fa-angle-down"></i>
            
              </a>
          <div v-if="enviadas.length>0" >
            <div class="collapse" id="altasEnviadas" >
              <div class="card card-body" v-for="enviada in enviadas">
                <h4 class="card-title">{{enviada.email}}</h4>
                <p>CUIT/CUIL: {{enviada.persona_id}}</p>
                <p>Verificado: {{enviada.verificado}}</p>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="collapse" id="altasFallidas" >
              <p>No se existen altas generadas</p>
            </div>

          </div>

    
            <a  class="toggleButton toggleFallidas bg-danger text-white" data-toggle="collapse" href="#altasFallidas" @click="mostrarFallidas = !mostrarFallidas"  aria-expanded="false" aria-controls="altasFallidas">
                <p>Altas Fallidas</p>
                <i v-show="!mostrarFallidas" class="fas fa-sharp fa-solid fa-angle-right"></i>
                <i v-show="mostrarFallidas" class="fas fa-sharp fa-solid fa-angle-down"></i>
                
            </a>          
          
          <div v-if="fallidas.length>0">
          <div class="collapse" id="altasFallidas" >
            <div class="card card-body" v-for="fallida in fallidas">
              <h4 class="card-title">{{fallida.email}}</h4>
              <p>CUIT/CUIL: {{fallida.persona.persona_id}}</p>
              <p>Nombre y Apellido : {{fallida.persona.nombre + " " + fallida.persona.apellido }}</p>
              <p>Beneficio: {{fallida.beneficio_id}}</p>
            </div>
          </div>
        </div>
          <div v-else>
            <div class="collapse" id="altasFallidas" >
              <p>No existen altas fallidas</p>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button class="modal-default-button btn btn-primary" @click="descargarReporte()">
              Descargar Reporte
            </button>
            <button class="modal-default-button btn btn-primary" @click="mostrarFormularioMail = !mostrarFormularioMail">
              Enviar por Mail
            </button>
            <button class="modal-default-button btn btn-primary" @click="$emit('close')" >
              Cerrar
            </button>
            
          </slot>
        </div>
        <div>
          <slot>
          <form class="modal-form" v-show="mostrarFormularioMail">
            <label>E-mail</label>
            <input type="email" v-model="direccionDestino" required/>
            <button class="btn btn-primary btn-form" @click="enviarPorMail()">Enviar</button>
          </form>
        </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

  export default {
        name: "DetalleAltaModal",
        props: ["proceso_modal"],

        data(){
          return {
            fallidas :[],
            enviadas:[],
            mostrarEnviadas:false,
            mostrarFallidas:false,
            buscarMail:"",
            mostrarFormularioMail:false,
            direccionDestino:"",

          }
        },

        created(){
            this.getDetallesComplementaria()
        },

        methods: {
            getDetallesComplementaria(){
                
                axios.get(`gestion-altas-web/detalle-altas/${this.proceso_modal}`)
                .then((response) => {
                    this.enviadas = response.data.data[0];
                    this.fallidas = response.data.data[1];
                }).catch((error)=>{
                })
            },

            descargarReporte(){
              axios.get(`gestion-altas-web/detalle-altas/descargar/${this.proceso_modal}`, {
                    responseType: "blob"
                })
              .then( (response)=>{
                var filename = "reporte_proceso_" + this.proceso_modal + ".pdf";
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
              })
              .catch((error)=>{
              })
              },

            enviarPorMail(){
              var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if(this.direccionDestino.match(mailformat)){
                  let formData = new FormData()
                  formData.append('email', this.direccionDestino)
                  formData.append('proceso', this.proceso_modal)
                  axios.post("gestion-altas-web/detalle-altas/enviar", formData)
              .then( response=>{
                

              })
              .catch((error)=>{
              })
                }else{
                }
              
            },

        },
    }

</script>

<style scoped>

.modal-dialog{
max-width: 90;
}
  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }
  
  .modal-body {
    margin: 20px 0;
  }
  
  .modal-default-button {
    float: right;
  }
  
  .toggleButton{
    display: flex;
    text-decoration: none;
    direction: row;
    min-width: 90%;
    height: 60px;
    border-radius: 5px ;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    
  }

  .toggleButton p{
    margin-left: 10px;
    margin-top:15px;
    vertical-align:middle;
  }
  .toggleButton i {
    margin-right: 10px;
  }
  
  .toggleEnviadas{
    border : 1px rgb(94, 143, 96) solid;
  }

  .toggleFallidas{
    border : 1px rgb(221, 134, 134) solid;
  }
  
  .modal-form {
    margin: 20px 5px;
    text-align: center;
    display:flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .modal-form>input{
    height: 20px;

  }


@media (min-width: 768px) {
    .modal-form{
      flex-direction: column;
    }
}
</style>