<template>
    <div class="container">
        <div class="registrar_usuario">
            <form
                class="formulario"
                @submit.prevent="registrarUsuario"
                v-on:keyup.enter.prevent="registrarUsuario"
            >
                <h4 class="busqueda-transferencias__titulo">Alta usuario</h4>

                <div class="container mb-4">
                    <div class="row">
                        <div class="col-12 text-muted">
                            <p>
                                <small
                                    >* Todos los campos son obligatorios</small
                                >
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <p class="title text-center mt-4">
                                Información personal
                            </p>
                        </div>
                        <div class=" row col-md-8">
                            <div class="form-group col-12 col-md-6">
                                <label for="nombre">Nombre</label>
                                <input
                                    type="text"
                                    id="nombre"
                                    name="nombre"
                                    class="form-control"
                                    v-model="nombre"
                                    placeholder="Ingrese el nombre"
                                    autofocus
                                />
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="apellido">Apellido</label>
                                <input
                                    type="text"
                                    id="apellido"
                                    name="apellido"
                                    class="form-control"
                                    v-model="apellido"
                                    placeholder="Ingrese el apellido"
                                    autofocus
                                />
                            </div>

                            <div
                                class="select-container form-group col-12 col-md-6"
                            >
                                <label for="tipo_documento"
                                    >Tipo de documento</label
                                >
                                <select
                                    class="form-control"
                                    id="tipo_documento"
                                    name="tipo_documento"
                                    v-model="tipo_documento"
                                    tabindex="0"
                                >
                                    <option value="DNI" selected="selected"
                                        >DNI</option
                                    >
                                    <option value="LC">LC</option>
                                    <option value="LE">LE</option>
                                    <option value="CI">CI</option>
                                </select>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="numero_documento"
                                    >Número de documento</label
                                >
                                <input
                                    type="text"
                                    id="numero_documento"
                                    name="numero_documento"
                                    class="form-control"
                                    v-model="numero_documento"
                                    placeholder="Ingrese el número de documento"
                                    v-mask="['#######', '########']"
                                    tabindex="0"
                                />
                            </div>

                            <div class="form-group col-12 col-sm-6">
                                <label for="cdi_cuil">CUIL o CDI</label>
                                <input
                                    type="text"
                                    id="cdi_cuil"
                                    name="cdi_cuil"
                                    class="form-control"
                                    v-model="cdi_cuil"
                                    placeholder="Ingrese el CUIL o CDI"
                                    v-mask="['##-#######-#', '##-########-#']"
                                    autofocus
                                />
                            </div>

                            <div
                                class="select-container form-group col-12 col-sm-6"
                            >
                                <label for="nacionalidad">Nacionalidad</label>
                                <select
                                    class="form-control"
                                    id="nacionalidad"
                                    name="nacionalidad"
                                    v-model="nacionalidad"
                                    tabindex="0"
                                >
                                    <option value="A" selected="selected"
                                        >Argentina</option
                                    >
                                    <option value="E">Extranjero</option>
                                </select>
                            </div>

                            <div
                                class="select-container form-group col-12 col-sm-6"
                            >
                                <label for="sexo">Sexo</label>
                                <select
                                    class="form-control"
                                    id="sexo"
                                    name="sexo"
                                    v-model="sexo"
                                    tabindex="0"
                                >
                                    <option value="F">Femenino</option>
                                    <option value="M">Masculino</option>
                                </select>
                            </div>

                            <div class="form-group col-12 col-sm-6">
                                <label for="fecha_nacimiento"
                                    >Fecha Nacimiento:</label
                                >
                                <input
                                    type="date"
                                    id="fecha_nacimiento"
                                    name="fecha_nacimiento"
                                    class="form-control"
                                    v-model="fecha_nacimiento"
                                    value="1970-01-01"
                                />
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="row">
                        <div class="col-md-4">
                            <p class="title text-center mt-4">
                                Información de contacto
                            </p>
                        </div>
                        <div class="row col-md-8">
                            <div class="col-12 form-group">
                                <label for="email">Correo electrónico</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="email"
                                    id="email"
                                    v-model="email"
                                    placeholder="Ingrese el correo electrónico"
                                    tabindex="0"
                                />
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="row">
                        <div class="col-md-4">
                            <p class="title text-center mt-4">
                                Información de organismo
                            </p>
                        </div>
                           
                        <div class="row  col-md-8">

                            <div class="select-container col-12 col-lg-5 my-auto form-group">
                                <label class="typo__label">Organismo</label>
                                <br />
                                <select
                                    class="form-control"
                                    name="organismoSeleccionado"
                                    id="organismoSeleccionado"
                                    v-model="organismoSeleccionado"
                                >
                                    <option value="" selected="">Seleccione un organismo</option>
                                    <option
                                        v-for="org in organismos"
                                        :value="org.codigo"
                                        :key="org.codigo"
                                    >
                                        {{ org.nombre_organismo }}
                                    </option>
                                </select>
                            </div>

                            <div class="select-container col-12 col-lg-5 my-auto form-group">
                                <label for="subsector_id">Sector del organismo</label>
                                <select
                                    class="form-control"
                                    name="subsector_id"
                                    id="subsector_id"
                                    v-model="subsector_id"
                                >
                                <option value="" selected="">Seleccione un sector</option>
                                    <option
                                        v-for="sector in sectoresOrganismo"
                                        :value="sector.subsectores[0].id"
                                        :key="sector.id"
                                    >
                                        {{ sector.nombre_sector }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 col-lg-2 ">
                                <button
                                class="btn btn-primary col-12 col-lg-2 boton-formulario-nuevo-organismo "
                                v-on:click.prevent="form_nuevo_organismo"
                                >+ 
                                </button>
                            </div>
                        
                        </div>
                     
                    </div>

                    <hr />
                    
                    <form 
                    method="POST"
                    v-show="nuevo_organismo === true" 
                    @submit.prevent="crearOrganismo"
                    
                    >
                   
                        <div  class="row">
                            
                            <div class="col-md-4">
                                <p class="title text-center mt-4">
                                    Crear nuevo Organismo
                                </p>
                            </div>
                            <div class=" row col-md-8">
                                <div class="form-group col-12 col-lg-6">
                                    <label for="nombre">Nombre del Organismo</label>
                                    <input
                                        type="text"
                                        id="nombre_nuevo_organismo"
                                        name="nombre_nuevo_organismo"
                                        v-model="nombre_nuevo_organismo"
                                        class="form-control"
                                    
                                        placeholder="Ingrese el nombre del Organismo"
                                        autofocus
                                    />
                                </div>
                                <div class="form-group col-12 col-lg-6">
                                    <label for="apellido">Código del Organismo</label>
                                    <input
                                        type="text"
                                        id="codigo_nuevo_organismo"
                                        name="codigo_nuevo_organismo"
                                        class="form-control"
                                        v-model="codigo_nuevo_organismo"
                                        placeholder="Ingrese el codigo del Organismo"
                                        autofocus
                                    />
                                </div>
                                <div class="form-group col-12 col-lg-6">
                                        <label for="nombre">Nombre de la Sede</label>
                                        <input
                                        type="text"
                                        id="nombre_nueva_sede"
                                        name="nombre_nueva_sede"
                                        v-model="nombre_nueva_sede"
                                        class="form-control"
                                        placeholder="Ingrese el nombre de la sede"
                                        autofocus
                                        />
                                </div>
                                <div class="form-group col-12 col-lg-6">
                                        <label for="nombre">Sector</label>
                                        <input
                                        type="text"
                                        id="nombre_nuevo_sector"
                                        name="nombre_nuevo_sector"
                                        class="form-control"
                                        v-model="nombre_nuevo_sector"
                                        placeholder="Ingrese su sector"
                                        autofocus
                                        />
                                
                                </div>
                               
                                <div class="col-12">
                                    <div class="row justify-content-end">
                                        <button class="btn btn-primary  boton-crear-nuevo-organismo" >Agregar</button>
                                    </div>
                                </div>

                                
                            </div>
                            
                         
                            
                        </div>
                        <div class="row">
                        <div class="col-12" v-if="errores_nuevo_organismo.length > 0">
                            <div class="alert alert-warning" role="alert">
                                <div v-for="(value, index) in errores_nuevo_organismo">
                                    {{ value }}
                                </div>
                            </div>
                        </div>
                    </div>
                    </form> 
                    <hr />

                    <div class="row justify-content-end mt-4">
                        <div class="col-12 col-md-6">
                            <button
                                class="btn btn-primary boton-formulario-login"
                                v-if="acciones"
                            >
                                {{ acciones }}
                                <i
                                    class="fas fa-circle-notch faa-spin animated faa-fast"
                                ></i>
                            </button>
                            <button
                                class="btn btn-primary boton-formulario-ingresar"
                                v-else
                            >
                                Registrar
                            </button>
                        </div>
                    </div>
                        

                    <div class="row">
                        <div class="col-12" v-if="errores.length > 0">
                            <div class="alert alert-warning" role="alert">
                                <div v-for="(value, index) in errores">
                                    {{ value }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 alertas" v-if="mensaje">
                            <div class="alert alert-warning" role="alert">
                                {{ mensaje }}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            nuevo_organismo:false,
            nombre: "",
            apellido: "",
            cdi_cuil: "",
            tipo_documento: "DNI",
            numero_documento: "",
            sexo: null,
            nacionalidad: null,
            fecha_nacimiento: "",
            usuario: "",

            acciones: "",
            email: "",

            organismo: "",
            organismos: [],
            subsector_id: "",
            sectoresOrganismo: [],
            status_error: null,
            status_ok: false,
            errores: [],



            organismoSeleccionado: "",
            mensaje: "",

           
            codigo_nuevo_organismo:"",
            nombre_nuevo_organismo:"",
            nombre_nueva_sede:"",
            nombre_nuevo_sector:"",
            newuserCreated :false,
            errores_nuevo_organismo:[]

        };
    },
    created: function() {
        this.traerOrganismos();
    },

    watch: {
        organismoSeleccionado: function() {
            axios
                .get("organismos/" + this.organismoSeleccionado + "/sectores")
                .then(response => {
                    this.sectoresOrganismo = response.data.data;
                    this.subsector_id = "";
                })
                .catch(error => {
                    this.status_error = error.response.status;
                    this.errores = error.response.data.error;
                });
        },

        newuserCreated(){
            if(this.newuserCreated==true){
                 this.traerOrganismos()
                 this.newuserCreated=false;
                 this.codigo_nuevo_organismo="";
                 this.nombre_nuevo_organismo="";
                 this.nombre_nueva_sede="";
                 this.nombre_nuevo_sector="";
            }
           
        }
    },
    methods: {
        form_nuevo_organismo: function(){
           
           this.nuevo_organismo = !this.nuevo_organismo
       
        },

       crearOrganismo: function() {
           
            const formData = {
            nombre_nuevo_sector: this.nombre_nuevo_sector,
            codigo_nuevo_organismo:this.codigo_nuevo_organismo,
            nombre_nuevo_organismo:this.nombre_nuevo_organismo,
            nombre_nueva_sede:this.nombre_nueva_sede,
            
            };

        axios
            .post("crear-organismo",formData)
            .then(response=> {
                this.nuevo_organismo=false,
                this.newuserCreated=true;
            })
            .catch(error => {
                    if (error.response && error.response.status === 422) {
                        var errors = error.response.data.errors;
                        for (var item in errors) {
                            this.errores_nuevo_organismo.push(errors[item][0]);
                        }
                    }

                    if (error.response && error.response.status === 420) {
                        this.errores_nuevo_organismo.push(error.response.data.datos)
                    }
            
            });
            
        },
 
        traerOrganismos: function() {
            axios
                .get("organismos-con-sede")
                .then(response => {
                    
                    this.organismos = response.data.data;
                })
                .catch(error => {
                    //this.errores = error.response.data.error
                });
        },

        registrarUsuario: function(event) {
            this.acciones = "Registrando usuario";

            var dato_usuario = this.cdi_cuil.replace(/-/g, "");
            dato_usuario = dato_usuario.replace(/_/g, "");
            dato_usuario = dato_usuario.replace(/ /g, "");

            const formData = {
                nombre: this.nombre,
                apellido: this.apellido,
                cdi_cuil: parseInt(dato_usuario),
                tipo_documento: this.tipo_documento,
                numero_documento: parseInt(this.numero_documento),
                sexo: this.sexo,
                nacionalidad: this.nacionalidad,
                fecha_nacimiento: this.fecha_nacimiento,
                subsector_id: parseInt(this.subsector_id),
                email: this.email,
                afiliado: ""
            };

            axios
                .post("nuevo-usuario-organismo", formData)
                .then(response => {
                    this.errores = [];
                    this.acciones = "";
                    this.mensaje = "";
                    this.$router.push({
                        name: "MisAplicacionesUsuariosOrganismosBuscar",
                        params: {
                            mensaje:
                                "El usuario " +
                                response.data.email +
                                " se creo correctamente"
                        }
                    });
                })
                .catch(error => {
                    this.errores = [];
                    this.acciones = "";
                    if (error.response && error.response.status === 422) {
                        var errors = error.response.data.errors;
                        for (var item in errors) {
                            this.errores.push(errors[item][0]);
                        }
                    } else if (
                        error.response &&
                        error.response.status === 500
                    ) {
                        this.errores.push(
                            "Hubo un error, en este momento no se puede registrar"
                        );
                    }
                });
        }
    }
};
</script>
